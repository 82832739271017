import {
  Box,
  Card,
  CardBody,
  Divider,
  HStack,
  Icon,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { Button, HFlex, VFlex, BillOverlayHeader } from 'ui'
import {
  ReviewItems,
  ReviewVendorSummary,
} from '../components/ReviewBillOverlay'
import { useBillStore } from '../../stores/billStore'
import { PaymentSchedulePreview } from './CreateRecurringBill'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'
import {
  CheckApprovalDocument,
  CreateApprovalDocument,
  CreateRecurringBillDocument,
  RecurringBillFrequency,
  RecurringBillsDocument,
} from '../../../operations-types'
import { useState } from 'react'
import { Category, Item, LineItem } from 'ui/src/types'

type RecurringBillReviewProps = {
  setOpen: (open: boolean) => void
}
export function RecurringBillReview({ setOpen }: RecurringBillReviewProps) {
  const {
    paymentSummary,
    recurringDetails,
    setRecurringPage,
    selectedSavedPaymentMethod,
    selectedVendorPayoutMethod,
    sendMoneyAmountResult,
    setApprovalPolicy,
    reset,
  } = useBillStore((state) => ({
    paymentSummary: state.paymentSummary,
    approvalPolicy: state.approvalPolicy,
    recurringDetails: state.recurringDetails,
    setRecurringPage: state.setRecurringPage,
    selectedVendorPayoutMethod: state.selectedVendorPayoutMethod,
    sendMoneyAmountResult: state.sendMoneyAmountResult,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    setApprovalPolicy: state.setApprovalPolicy,
    reset: state.reset,
  }))

  const [createRecurringBill] = useMutation(CreateRecurringBillDocument, {
    refetchQueries: [RecurringBillsDocument],
  })

  const [createApproval] = useMutation(CreateApprovalDocument)

  const { data: approvalData } = useQuery(CheckApprovalDocument, {
    variables: {
      submittedAmountCents: sendMoneyAmountResult?.submittedAmountCents!,
      paymentMethodId: selectedSavedPaymentMethod?.id!,
      vendorId: recurringDetails?.vendor?.id!,
    },
    onCompleted: (data) => {
      const policy = data.checkApproval?.approvalPolicies?.at(0)
      if (policy) {
        setApprovalPolicy(policy)
      }
    },
  })

  const approvalPolicy = approvalData?.checkApproval?.approvalPolicies?.at(0)
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const categories = recurringDetails?.lineItems?.filter(
    (item: LineItem) => item?.type === 'category',
  )

  const items = recurringDetails?.lineItems?.filter(
    (item: LineItem): item is Item => item?.type === 'item',
  )

  const executeRecurringBill = async () => {
    setLoading(true)

    const recurringBillResponse = await createRecurringBill({
      variables: {
        vendorId: recurringDetails?.vendor?.id || '',
        paymentMethodId: selectedSavedPaymentMethod?.id || '',
        vendorPayoutMethodId: selectedVendorPayoutMethod?.id || '',
        amountCents: paymentSummary?.amountCents() || 0,
        reason: sendMoneyAmountResult?.reason || '',
        frequency:
          recurringDetails?.frequency || RecurringBillFrequency.Monthly,
        startDate: recurringDetails?.startDate?.toISOString() || '',
        endDate: recurringDetails?.endDate?.toISOString() || '',
        lines: {
          categories:
            categories?.map((item: Category) => ({
              id: item.id,
              amountCents: currency(item.lineDetails.amountCents || 0, {
                fromCents: true,
              }).intValue,
            })) || [],
          items:
            items?.map((item: Item) => ({
              id: item.id,
              quantity: currency(item.lineDetails.quantity || 0, {
                fromCents: false,
              }).value,
              rate: currency(item.lineDetails.rate || 0, {
                fromCents: false,
              }).value,
              amountCents: currency(item.lineDetails.amountCents || 0, {
                fromCents: true,
              }).intValue,
            })) || [],
        },
      },
    })

    const recurringBillId =
      recurringBillResponse?.data?.createRecurringBill?.recurringBill?.id

    if (
      !recurringBillId ||
      recurringBillResponse.data?.createRecurringBill?.error
    ) {
      toast({
        title:
          recurringBillResponse.data?.createRecurringBill?.error?.message ||
          'Error creating recurring bill',
        description: 'Please try again',
        status: 'error',
      })
      setLoading(false)
      return
    }

    if (approvalPolicy) {
      const { data } = await createApproval({
        variables: {
          paymentMethodId: selectedSavedPaymentMethod?.id || '',
          vendorPayoutMethodId: selectedVendorPayoutMethod?.id || '',
          recurringBillId: recurringBillId,
        },
      })

      if (data?.createApproval?.approval?.id) {
        setLoading(false)
        //setRecurringPage('approval')
        toast({
          title: 'Recurring bill sent for approval',
          status: 'success',
        })
      }
    }

    if (recurringBillId) {
      setLoading(false)
      toast({
        title: 'Recurring bill created and activated',
        status: 'success',
      })
    }
    setRecurringPage('recurringSuccess')
  }

  return (
    <>
      <BillOverlayHeader
        onClose={() => {
          setOpen(false)
          reset()
        }}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Recurring Bills' },
          { label: 'Create' },
          { label: 'Pay' },
          { label: 'Review', isActive: true },
        ]}
        title={`You'll be charged ${currency(
          paymentSummary?.amountCents() || 0,
          {
            fromCents: true,
          },
        ).format()}`}
        subtitle={`Please review and confirm this transaction`}
        type="recurring"
      />
      {approvalPolicy && (
        <VFlex
          alignItems="center"
          justifyContent="center"
          w="100%"
          pb="4"
          px="8"
        >
          <Card w="100%" px="4" bgColor="yellow.100" border="0">
            <CardBody w="100%">
              <VFlex>
                <HStack>
                  <Icon
                    as={ExclamationCircleIcon}
                    color="yellow.700"
                    boxSize="4"
                    strokeWidth="1.5"
                  />
                  <Text fontWeight="medium" color="yellow.700">
                    Approval is required
                  </Text>
                </HStack>
                <Text fontSize="sm" color="yellow.700">
                  {`Approved before 2:45PM EST on ${moment(
                    recurringDetails?.startDate,
                  ).format('MMM D, YYYY')} to avoid any delays`}
                </Text>
              </VFlex>
            </CardBody>
          </Card>
        </VFlex>
      )}
      <Divider />
      <VFlex w="100%" py="4" gap="4">
        <ReviewItems />
        <Divider />

        <ReviewVendorSummary />
      </VFlex>
      <Divider />

      <VFlex w="100%" gap={4} px={8} py={4}>
        <PaymentSchedulePreview
          startDate={recurringDetails?.startDate!}
          schedule={recurringDetails?.frequency}
          endDate={recurringDetails?.endDate!}
        />
      </VFlex>
      <Box w="100%" mt="auto">
        <Divider />
        <HFlex gap={4} w="100%" justifyContent="flex-end" p="4">
          <Button
            label="Back"
            variant="outline"
            onClick={() => setRecurringPage('recurringPaymentPayout')}
          />
          <Spacer />
          <Button
            label="Confirm and Schedule"
            iconName="arrowRight"
            iconPosition="right"
            onClick={executeRecurringBill}
            isLoading={loading}
          />
        </HFlex>
      </Box>
    </>
  )
}

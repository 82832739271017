import { Text, VStack } from '@chakra-ui/react'
import { Column, HFlex, VFlex } from 'ui'

type VendorDetailsProps = {
  vendorStatus: string
  taxId: string
  address: string
  city: string
  state: string
  zip: string
}

export default function VendorDetails({
  vendorStatus,
  taxId,
  address,
  city,
  state,
  zip,
}: VendorDetailsProps) {
  return (
    <VFlex w="100%" gap="6" px={8} pb={8}>
      <Column wGrow gap="large">
        <Text fontSize="xl" fontWeight="semibold">
          Vendor Details
        </Text>
        <HFlex minW="320px" alignItems="start" w="100%">
          <VStack alignItems="start" w="100%" spacing="0">
            <Text fontSize="xs" fontWeight="400" color="gray.500">
              Status
            </Text>
            <Text fontSize="lg" fontWeight="500">
              {vendorStatus}
            </Text>
          </VStack>
          {taxId && (
            <VStack alignItems="start" w="100%" spacing="0">
              <Text fontSize="xs" fontWeight="400" color="gray.500">
                Tax Id
              </Text>
              <Text fontSize="lg" fontWeight="500">
                {taxId || ''}
              </Text>
            </VStack>
          )}
        </HFlex>
        {address && (
          <VStack alignItems="start" spacing="0" w="100%">
            <Text fontSize="xs" fontWeight="400" color="gray.500">
              Billing Address
            </Text>
            <Text fontSize="lg" fontWeight="500">
              {address}
            </Text>
            <Text fontSize="lg" fontWeight="500">
              {city}, {state} {zip}
            </Text>
          </VStack>
        )}
      </Column>
    </VFlex>
  )
}

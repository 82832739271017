import {
  Card,
  CardBody,
  HStack,
  VStack,
  Icon,
  Box,
  Text,
} from '@chakra-ui/react'
import {
  BuildingLibraryIcon,
  CheckCircleIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline'
import { PayoutMethodType, PayoutMethodBase } from '../../types'
import { Button } from '../v3/button/Button'

interface VendorPayoutMethodCardProps {
  vendorPayoutMethod: PayoutMethodBase | undefined | null
  type: PayoutMethodType
  setEditMethod?: () => void
  isActive?: boolean
}

export function VendorPayoutMethodCard({
  vendorPayoutMethod,
  type,
  setEditMethod,
  isActive,
}: VendorPayoutMethodCardProps) {
  return (
    <Card
      bgColor="white"
      borderRadius="lg"
      borderWidth="1px"
      borderColor="gray.200"
      w="100%"
      borderBottomLeftRadius={isActive ? '0' : 'base'}
      borderBottomRightRadius={isActive ? '0' : 'base'}
    >
      <CardBody>
        <HStack justify="space-between" alignItems="center">
          <HStack spacing={4}>
            <Box
              bgColor="gray.100"
              borderRadius="full"
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                as={type === 'ACH' ? BuildingLibraryIcon : EnvelopeIcon}
                w={6}
                h={6}
                color="gray.600"
              />
            </Box>
            <VStack alignItems="flex-start" spacing={0}>
              <Text fontSize="md" fontWeight="medium">
                {type === 'ACH'
                  ? 'Direct Deposit via ACH'
                  : 'Deliver physical check'}
              </Text>
              {vendorPayoutMethod ? (
                <HStack spacing={1} alignItems="center">
                  <Icon
                    as={CheckCircleIcon}
                    boxSize="4"
                    color="white"
                    fill="gray.500"
                  />
                  {type === 'ACH' ? (
                    <>
                      <Text color="gray.600" fontSize="xs">
                        {vendorPayoutMethod?.bankName &&
                        vendorPayoutMethod.bankName.length > 20
                          ? `${vendorPayoutMethod.bankName.slice(0, 20)}...`
                          : vendorPayoutMethod?.bankName}
                        {' ••'}
                        {vendorPayoutMethod?.accountNumber?.slice(-4)}{' '}
                        {vendorPayoutMethod?.accountType?.toUpperCase()}
                      </Text>
                    </>
                  ) : (
                    <Text color="gray.600" fontSize="xs">
                      {`${vendorPayoutMethod?.street}, ${vendorPayoutMethod?.city}, ${vendorPayoutMethod?.state} ${vendorPayoutMethod?.zip}`}
                    </Text>
                  )}
                </HStack>
              ) : (
                <Text color="gray.600" fontSize="xs">
                  Missing details
                </Text>
              )}
            </VStack>
          </HStack>
          {setEditMethod && (
            <Button
              variant="ghost"
              size="xs"
              onClick={setEditMethod}
              label={
                isActive
                  ? 'Cancel'
                  : vendorPayoutMethod
                  ? 'Edit details'
                  : 'Add Details'
              }
            />
          )}
        </HStack>
      </CardBody>
    </Card>
  )
}

export default VendorPayoutMethodCard

import { Overlay } from 'ui/src/components/Overlay/Overlay'
import { BillPayMasterOverlay } from '../../ap/BillPayOverlay'
import { useState } from 'react'
import { BillDocument } from '../../../operations-types'
import { useQuery } from '@apollo/client'
import { Spinner, useToast, VStack } from '@chakra-ui/react'
import { useBillStore } from '../../stores/billStore'
import { ScheduleBillOverlay } from './ScheduleBillOverlay'
import { ReviewBillOverlay } from './ReviewBillOverlay'
import { calculatePaymentSummaryForMerchant } from 'ui/paymentCalculation'
import currency from 'currency.js'
import { AddDeliveryMethodOverlay } from './AddDeliveryMethodOverlay'
import { NewBillOverlay } from '../../ap/NewBillOverlay'

type BillOverlayProps = {
  open: boolean
  setOpen: (open: boolean) => void
  billId: string
  state: BillOverlayStates
  setState: (state: BillOverlayStates) => void
  setBillId: (billId: string) => void
  skipOverlay?: boolean
}

export type BillOverlayStates =
  | 'view'
  | 'schedule'
  | 'review'
  | 'addDeliveryMethod'
  | 'newBill'

export function BillOverlay({
  open,
  setOpen,
  billId,
  state,
  setState,
  setBillId,
  skipOverlay = false,
}: BillOverlayProps) {
  const [type, setType] = useState<'ACH' | 'Check' | null>(null)
  const toaster = useToast()
  const {
    bill,
    setBill,
    reset,
    setPaymentSummary,
    setSendMoneyAmountResult,
    setVendorEmails,
  } = useBillStore((state) => ({
    bill: state.bill,
    setBill: state.setBill,
    reset: state.reset,
    setPaymentSummary: state.setPaymentSummary,
    setSendMoneyAmountResult: state.setSendMoneyAmountResult,
    setVendorEmails: state.setVendorEmails,
  }))

  const { loading } = useQuery(BillDocument, {
    variables: { billId: billId },
    onCompleted: (data) => {
      if (data?.bill?.error) {
        toaster({
          title: 'Error',
          description: data?.bill?.error?.message,
          status: 'error',
        })
        setOpen(false)
      } else {
        if (data?.bill?.bill) {
          setBill(data?.bill)
          setPaymentSummary(
            calculatePaymentSummaryForMerchant(
              currency(data?.bill?.bill?.billData?.amountDue || 0).intValue,
              'ACH Transfer',
              'ACH',
            ),
          )
          setSendMoneyAmountResult({
            reason: '',
            submittedAmountCents: currency(
              data?.bill?.bill?.billData?.amountDue || 0,
            ).intValue,
          })
          setVendorEmails(data?.bill?.bill?.vendor?.emails || [])
        } else if (state !== 'newBill') {
          toaster({
            title: 'Error',
            description: 'Bill not found',
            status: 'error',
          })
          setOpen(false)
        }
      }
    },
    skip: !open || state === 'newBill',
  })

  const onClose = () => {
    setOpen(false)
    reset()
  }

  const components: Record<BillOverlayStates, React.ReactNode> = {
    view: (
      <BillPayMasterOverlay
        billId={billId}
        onClose={onClose}
        onMakePayment={() => setState('schedule')}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Bill', onClick: () => setState('view'), isActive: true },
        ]}
      />
    ),
    newBill: (
      <NewBillOverlay
        isOpen={open}
        setOpen={setOpen}
        onPayBill={(billId) => {
          setBillId(billId)
          setState('schedule')
        }}
      />
    ),
    addDeliveryMethod: (
      <AddDeliveryMethodOverlay
        onClose={onClose}
        onBack={() => setState('schedule')}
        vendorId={bill?.bill?.vendor?.id || ''}
        type={type || 'ACH'}
        onSave={() => {
          setState('schedule')
        }}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Bill', onClick: () => setState('view') },
          { label: 'Pay', onClick: onClose, isActive: true },
        ]}
      />
    ),
    schedule: (
      <ScheduleBillOverlay
        onClose={onClose}
        onBack={() => setState('view')}
        onReview={() => setState('review')}
        onAddDeliveryMethod={(type) => {
          setType(type)
          setState('addDeliveryMethod')
        }}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Bill', onClick: () => setState('view') },
          { label: 'Pay', onClick: onClose, isActive: true },
        ]}
      />
    ),
    review: (
      <ReviewBillOverlay
        onClose={onClose}
        onBack={() => setState('schedule')}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Bill', onClick: () => setState('view') },
          { label: 'Pay', onClick: onClose, isActive: true },
        ]}
      />
    ),
  }

  const content = (
    <>
      {loading ? (
        <VStack h="100%" w="100%" justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </VStack>
      ) : (
        components[state]
      )}
    </>
  )

  if (skipOverlay) {
    return content
  }

  return (
    <Overlay
      open={open}
      setOpen={(bool) => {
        if (!bool) {
          onClose()
        }
      }}
    >
      {content}
    </Overlay>
  )
}

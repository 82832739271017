import { Text, VStack } from '@chakra-ui/react'
import { ManualVendorDetails } from './AddVendorStore'
import { VFlex } from 'ui'
import VendorPayoutDetailsV2 from './VendorPayoutDetailsV2'
import { Vendor } from '../../../operations-types'

type Address = {
  street_line: string
  secondary?: string
  city: string
  state: string
  zipcode: string
  entries?: number
}

export function AddVendorPayoutDetailsForm({
  onContinue,
  onBack,
  manualVendorDetails,
  setManualVendorDetails,
}: {
  onContinue: () => void
  onBack: () => void
  manualVendorDetails: ManualVendorDetails | null
  setManualVendorDetails: (details: ManualVendorDetails) => void
}) {
  return (
    <>
      <VFlex w="100%" h="100%" gap="4" px={8}>
        <VStack spacing={0} alignItems="start" pb={4}>
          <Text fontSize="xl" fontWeight="semibold" color="gray.800">
            How will the vendor receive payments?
          </Text>
          <Text fontSize="sm" color="gray.500">
            Enter your vendor's preferences and payment details
          </Text>
        </VStack>
        <VendorPayoutDetailsV2
          vendorPayoutMethods={
            manualVendorDetails?.vendorAchPayoutMethod
              ? ([
                  {
                    id: 'temp-id',
                    type: 'ACH',
                    accountNumber:
                      manualVendorDetails.vendorAchPayoutMethod.accountNumber,
                    routingNumber:
                      manualVendorDetails.vendorAchPayoutMethod.routingNumber,
                    accountType:
                      manualVendorDetails.vendorAchPayoutMethod.accountType,
                    bankName:
                      manualVendorDetails.vendorAchPayoutMethod.bankName,
                  },
                ] as Vendor['vendorPayoutMethods'])
              : []
          }
          vendorId={''}
          vendorEmail={''}
          paymentMethod="ACH"
          internalButtons={false}
          onChangeRoutingNumber={(number, bankName) => {
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorAchPayoutMethod: {
                ...manualVendorDetails?.vendorAchPayoutMethod,
                routingNumber: number || '',
                bankName: bankName || '',
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
          onChangeAccountNumber={(number) => {
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorAchPayoutMethod: {
                ...manualVendorDetails?.vendorAchPayoutMethod,
                accountNumber: number || '',
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
          onChangeAccountType={(type) => {
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorAchPayoutMethod: {
                ...manualVendorDetails?.vendorAchPayoutMethod,
                accountType: type || '',
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
        />
        <VendorPayoutDetailsV2
          vendorPayoutMethods={
            manualVendorDetails?.vendorCheckPayoutMethod
              ? ([
                  {
                    id: '',
                    type: 'CHECK',
                    street:
                      manualVendorDetails.vendorCheckPayoutMethod
                        .recipientAddress?.street1,
                    city: manualVendorDetails.vendorCheckPayoutMethod
                      .recipientAddress?.city,
                    state:
                      manualVendorDetails.vendorCheckPayoutMethod
                        .recipientAddress?.state,
                    zip: manualVendorDetails.vendorCheckPayoutMethod
                      .recipientAddress?.zipCode,
                  },
                ] as Vendor['vendorPayoutMethods'])
              : []
          }
          vendorId={''}
          vendorEmail={''}
          paymentMethod="check"
          internalButtons={false}
          onChangeRecipientAddress={(address) => {
            const addressObject = JSON.parse(address) as Address
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorCheckPayoutMethod: {
                recipientAddress: {
                  ...manualVendorDetails?.vendorCheckPayoutMethod
                    ?.recipientAddress,
                  street1: addressObject.street_line || '',
                  city: addressObject.city || '',
                  state: addressObject.state || '',
                  zipCode: addressObject.zipcode || '',
                },
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
          onChangeCity={(city) => {
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorCheckPayoutMethod: {
                recipientAddress: {
                  ...manualVendorDetails?.vendorCheckPayoutMethod
                    ?.recipientAddress,
                  city: city || '',
                  street1: manualVendorDetails?.recipientAddress?.street1 || '',
                },
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
          onChangeState={(state) => {
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorCheckPayoutMethod: {
                recipientAddress: {
                  ...manualVendorDetails?.vendorCheckPayoutMethod
                    ?.recipientAddress,
                  state: state || '',
                  street1: manualVendorDetails?.recipientAddress?.street1 || '',
                  city: manualVendorDetails?.recipientAddress?.city || '',
                },
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
          onChangeZip={(zip) => {
            setManualVendorDetails({
              ...manualVendorDetails,
              vendorName: manualVendorDetails?.vendorName || '',
              vendorEmails: manualVendorDetails?.vendorEmails || [],
              vendorCheckPayoutMethod: {
                recipientAddress: {
                  ...manualVendorDetails?.vendorCheckPayoutMethod
                    ?.recipientAddress,
                  zipCode: zip || '',
                  street1: manualVendorDetails?.recipientAddress?.street1 || '',
                  city: manualVendorDetails?.recipientAddress?.city || '',
                  state: manualVendorDetails?.recipientAddress?.state || '',
                },
              },
              vendorType: manualVendorDetails?.vendorType,
            })
          }}
        />
      </VFlex>
    </>
  )
}

export default AddVendorPayoutDetailsForm

import { ReactNode } from 'react'
import { VFlex } from 'ui'
import { Flex } from '@chakra-ui/react'

export type BatchLayoutProps = {
  header?: ReactNode
  children: ReactNode
  footer?: ReactNode
  verticallyCentered?: boolean
}

export function BatchLayout(props: BatchLayoutProps) {
  return (
    <VFlex height="100vh">
      {props.header}
      <Flex
        justifyContent={props.verticallyCentered ? 'center' : 'flex-start'}
        flexDirection="column"
        alignItems="center"
        height="100%"
        style={{
          height: '-webkit-fill-available',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          overflowY: 'scroll',
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {props.children}
        </Flex>
      </Flex>
      {props.footer}
    </VFlex>
  )
}

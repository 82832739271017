import * as yup from 'yup'
import { Form, FormikValidatingInput, VFlex } from 'ui'
import { Text, VStack } from '@chakra-ui/react'
import { Button, HFlex, Separator } from 'ui'
import { useAddVendorStore } from './AddVendorStore'
import { VendorEmails } from '../../bills/components/VendorEmails'

const RequestVendorSchema = yup.object({
  vendorName: yup.string().required('Vendor name is required'),
  vendorEmails: yup
    .array()
    .of(yup.string().required())
    .min(1, 'At least one email is required')
    .required()
    .transform((value) => value?.filter(Boolean)),
  message: yup.string(),
  requireTaxDocuments: yup.boolean(),
})

export type RequestVendorFormData = yup.InferType<typeof RequestVendorSchema>

type RequestVendorDetailsFormProps = {
  onSubmit: (values: RequestVendorFormData) => void
  loading: boolean
}

export function RequestVendorDetailsForm({
  onSubmit,
  loading,
}: RequestVendorDetailsFormProps) {
  const { setPage } = useAddVendorStore()
  return (
    <Form<RequestVendorFormData>
      initialValues={{
        vendorName: '',
        vendorEmails: [],
        message: '',
        requireTaxDocuments: false,
      }}
      validationSchema={RequestVendorSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
      className="h-full"
    >
      {(formik) => (
        <VFlex justifyContent="space-between" w="100%" h="100%" gap={8}>
          <VStack gap={6} px={8} alignItems="start" width="100%">
            <VStack spacing={0} alignItems="start">
              <Text fontSize="xl" fontWeight="semibold" color="gray.800">
                Invite your vendor
              </Text>
              <Text fontSize="sm" color="gray.500">
                We'll send them a secure link to enter their payment details
              </Text>
            </VStack>

            <FormikValidatingInput
              fieldName="vendorName"
              label="Vendor Name"
              placeholder="Enter vendor name"
            />

            <VStack spacing={0} alignItems="start" width="100%">
              <Text fontWeight="medium" mb={2}>
                Email Address
              </Text>
              <VendorEmails
                vendorEmails={formik.values.vendorEmails}
                setVendorEmails={(emails: string[]) => {
                  formik.setFieldValue('vendorEmails', emails)
                }}
              />
            </VStack>
            {/* 
            <Box
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              p={4}
              width="100%"
            >
              <HFlex justifyContent="space-between" alignItems="center">
                <VStack alignItems="start" spacing={0}>
                  <Text fontWeight="medium">Also require tax ID number</Text>
                  <Text fontSize="sm" color="gray.500">
                    e.g. EIN, SSN, etc.
                  </Text>
                </VStack>
                <Switch
                  name="requireTaxDocuments"
                  isChecked={formik.values.requireTaxDocuments}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'requireTaxDocuments',
                      e.target.checked,
                    )
                  }
                />
              </HFlex>
            </Box> */}
          </VStack>

          <VStack spacing={0} mt="auto" w="100%" alignItems="start">
            <Separator orientation="horizontal" />
            <HFlex px={8} pt={4} gap={4} justify="space-between" width="100%">
              <Button
                label="Cancel"
                variant="outline"
                onClick={() => setPage('options')}
              />
              <Button
                label="Invite"
                iconPosition="left"
                iconName="envelope"
                onClick={() => {
                  formik.submitForm()
                }}
                isDisabled={!formik.isValid}
                isLoading={loading}
              />
            </HFlex>
          </VStack>
        </VFlex>
      )}
    </Form>
  )
}

import {
  Box,
  Center,
  ModalContent,
  ModalOverlay,
  Spinner,
  useToast,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Modal } from '@chakra-ui/react'
import {
  BraintreeCardDetailsForm,
  Button,
  GoBackButton,
  MerchantFormData,
  MerchantPaymentFormSchema,
  PaymentMethodOptions,
  PaytheoryCardDetails,
  VFlex,
} from 'ui'
import { Formik, useFormikContext } from 'formik'
import {
  ManualACHDetails,
  PaymentMethodPicker,
} from '../../ap/components/PaymentMethodPicker'
import {
  CreateMerchantCreditCardPaymentMethodDocument,
  GetAllMerchantPaymentMethodsDocument,
  CreateMerchantAchPaymentMethodDocument,
  GetClientTokenDocument,
} from '../../../operations-types'
import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { PaymentMethod } from '../../ap/components/SendPaymentRouter'
import BraintreeAddressDetails from '../../forms/BraintreeAddressSection'

type NewPaymentMethodModalProps = {
  isOpen: boolean
  setModalOpen: (open: boolean) => void
  onSuccessfulPaymentMethodAdded: (paymentMethod: PaymentMethod) => void
}

type NewPaymentMethodModalContentProps = {
  onSuccessfulPaymentMethodAdded: (paymentMethod: PaymentMethod) => void
}

function NewPaymentMethodModalContent({
  onSuccessfulPaymentMethodAdded,
}: NewPaymentMethodModalContentProps) {
  const { setFieldValue, setFieldTouched, values, isValid } =
    useFormikContext<MerchantFormData>()

  const { data } = useQuery(GetClientTokenDocument, {})

  const clientToken = data?.getClientToken?.clientToken ?? null

  const [loading, setLoading] = useState(false)
  const toaster = useToast()
  const [hostedFields, setHostedFields] = useState<braintree.HostedFields>()
  const [paytheory, setPaytheory] = useState<any | null>()
  const isPaytheoryEnabled = useFeatureFlagEnabled('isPaytheoryEnabled')
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false)

  const [createMerchantACHPaymentMethod] = useMutation(
    CreateMerchantAchPaymentMethodDocument,
    {
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
    },
  )

  const [createMerchantCreditCardPaymentMethod] = useMutation(
    CreateMerchantCreditCardPaymentMethodDocument,
    {
      onCompleted: (data) => {
        if (data?.createMerchantCreditCardPaymentMethod?.error) {
          toaster({
            title: 'Error',
            description:
              data.createMerchantCreditCardPaymentMethod.error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
          setLoading(false)
          return
        }

        if (data.createMerchantCreditCardPaymentMethod?.paymentMethod) {
          setLoading(false)
        }
      },
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
    },
  )

  const paymentMethod = values.paymentMethod

  const onManualAddBankAccountClick = async () => {
    setLoading(true)

    const createdPaymentMethod = await createMerchantACHPaymentMethod({
      variables: {
        accountNumber: values.paymentMethodDetails?.bankAccountNumber,
        accountType: values.paymentMethodDetails?.accountType,
        routingNumber: values.paymentMethodDetails?.bankRoutingNumber,
      },
    })

    if (createdPaymentMethod.data?.createMerchantACHPaymentMethod?.error) {
      toaster({
        title: 'Error',
        description:
          createdPaymentMethod.data.createMerchantACHPaymentMethod.error
            .message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setLoading(false)
      return
    }

    const paymentMethod =
      createdPaymentMethod.data?.createMerchantACHPaymentMethod?.paymentMethod

    if (paymentMethod) {
      onSuccessfulPaymentMethodAdded(paymentMethod)
    } else {
      toaster({
        title: 'Error',
        description: 'Failed to add payment method',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const onBraintreeCardClick = async () => {
    if (!hostedFields) {
      // Throw error here
      return
    }

    await hostedFields
      .tokenize({
        cardholderName: values?.billingAddress?.fullName,
        billingAddress: {
          firstName: (values?.billingAddress?.fullName as string | undefined)
            ?.split(' ')
            ?.at(0),
          lastName: (values?.billingAddress?.fullName as string | undefined)
            ?.split(' ')
            ?.at(1),
          postalCode: values?.billingAddress?.zipCode,
          streetAddress: values?.billingAddress?.streetAddress,
          locality: values?.billingAddress?.city,
          region: values?.billingAddress?.state,
        },
      })
      .then(async (payload) => {
        setLoading(true)
        const createdPaymentMethod =
          await createMerchantCreditCardPaymentMethod({
            variables: {
              nonce: payload.nonce,
              provider: 'braintree',
            },
          })

        const paymentMethod =
          createdPaymentMethod.data?.createMerchantCreditCardPaymentMethod
            ?.paymentMethod

        if (paymentMethod) {
          onSuccessfulPaymentMethodAdded(paymentMethod)
        } else {
          toaster({
            title: 'Error',
            description: 'Failed to add payment method',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      })
  }

  const onPaytheoryCardClick = async () => {
    if (!paytheory) {
      console.error('Paytheory not loaded')
      return
    }

    setLoading(true)

    if (values.paymentMethod === PaymentMethodOptions.CREDIT_CARD) {
      const result = await paytheory.tokenizePaymentMethod({
        payorInfo: {
          first_name: (values.billingAddress?.fullName as string).split(' ')[0],
          last_name: (values.billingAddress?.fullName as string).split(' ')[1],
          personal_address: {
            line1: values.billingAddress.streetAddress,
            city: values.billingAddress.city,
            region: values.billingAddress.state,
            postal_code: values.billingAddress.zipCode,
            country: 'USA',
          },
        },
        billingInfo: {
          name: values.billingAddress.fullName,
          address: {
            line1: values.billingAddress.streetAddress,
            city: values.billingAddress.city,
            region: values.billingAddress.state,
            postal_code: values.billingAddress.zipCode,
            country: 'USA',
          },
        },
        skipValidation: true,
      })

      if (result.type === 'TOKENIZED') {
        const createdPaymentMethod =
          await createMerchantCreditCardPaymentMethod({
            variables: {
              nonce: result.body.payment_method_id,
              provider: 'paytheory',
            },
          })

        const paymentMethod =
          createdPaymentMethod.data?.createMerchantCreditCardPaymentMethod
            ?.paymentMethod

        if (paymentMethod) {
          onSuccessfulPaymentMethodAdded(paymentMethod)
        } else {
          toaster({
            title: 'Error',
            description: 'Failed to add payment method',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      } else {
        toaster({
          title: 'Error',
          description: result.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setLoading(false)
      }
    }
  }

  const onCardClick = async () => {
    if (hostedFields) {
      await onBraintreeCardClick()
    } else if (paytheory) {
      await onPaytheoryCardClick()
    } else {
      console.error('No sdk loaded')
    }
  }

  const onAddPaymentMethodPlaidLink = async (paymentMethod: PaymentMethod) => {
    if (paymentMethod) {
      onSuccessfulPaymentMethodAdded(paymentMethod)
    } else {
      toaster({
        title: 'Error',
        description: 'Failed to add payment method',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <VFlex gap={6}>
      <VFlex>
        <Text fontSize="xl" fontWeight="semibold">
          Add Payment Method
        </Text>
        <Text fontSize="sm" color="gray.500">
          Add a new bank account, or add a credit card to send your payment
          quicker.
        </Text>
      </VFlex>
      {!paymentMethodSelected && !loading && (
        <PaymentMethodPicker
          cardDescription={'Your payment arrives in the next business day.'}
          bankDescription={'Your payment arrives in 2 to 3 business days.'}
          achEnabled={true}
          cardEnabled={true}
          noCardFee={false}
          onManualPayByBankClicked={() => {
            setFieldValue('paymentMethod', 'achDebit')
            // Clear out credit card fields, since the Braintree form will require them to be re inputted
            setFieldValue('paymentMethodDetails.creditCardCVV', false)
            setFieldValue('paymentMethodDetails.creditCardNumber', false)
            setFieldValue(
              'paymentMethodDetails.creditCardExpirationDate',
              false,
            )
            setPaymentMethodSelected(true)
          }}
          onPayByCardClicked={() => {
            setFieldValue('paymentMethod', PaymentMethodOptions.CREDIT_CARD)
            setPaymentMethodSelected(true)
          }}
          setLoading={setLoading}
          onBankAccountAdded={onAddPaymentMethodPlaidLink}
          onPayByBankClicked={() => {
            setFieldValue('paymentMethod', 'achDebit')
            // Clear out credit card fields, since the Braintree form will require them to be re inputted
            setFieldValue('paymentMethodDetails.creditCardCVV', false)
            setFieldValue('paymentMethodDetails.creditCardNumber', false)
            setFieldValue(
              'paymentMethodDetails.creditCardExpirationDate',
              false,
            )
          }}
        />
      )}

      {!paymentMethodSelected && loading && (
        <Center w="100%">
          <Spinner />
        </Center>
      )}

      {paymentMethod !== PaymentMethodOptions.SAVED_PAYMENT_METHOD && (
        <>
          {paymentMethod === PaymentMethodOptions.CREDIT_CARD && (
            <>
              {clientToken && !isPaytheoryEnabled && (
                <VFlex gap={4}>
                  <VFlex gap={2}>
                    <Text color="gray.800" fontSize="sm" fontWeight="medium">
                      Card Details
                    </Text>
                    <BraintreeCardDetailsForm
                      clientToken={clientToken}
                      setHostedFields={setHostedFields}
                      onCVVValidityChange={async (valid) => {
                        await setFieldTouched(
                          'paymentMethodDetails.creditCardCVV',
                        )

                        await setFieldValue(
                          'paymentMethodDetails.creditCardCVV',
                          valid,
                        )
                      }}
                      onCardNumberValidityChange={async (valid) => {
                        await setFieldTouched(
                          'paymentMethodDetails.creditCardNumber',
                        )
                        await setFieldValue(
                          'paymentMethodDetails.creditCardNumber',
                          valid,
                        )
                      }}
                      onExpiryValidityChange={async (valid) => {
                        await setFieldTouched(
                          'paymentMethodDetails.creditCardExpirationDate',
                        )

                        await setFieldValue(
                          'paymentMethodDetails.creditCardExpirationDate',
                          valid,
                        )
                      }}
                    />
                  </VFlex>
                  <BraintreeAddressDetails popupContainerTarget=".chakra-card__body" />
                </VFlex>
              )}
              {isPaytheoryEnabled && (
                <VFlex gap={4}>
                  <VFlex gap={2}>
                    <Text color="gray.800" fontSize="sm" fontWeight="medium">
                      Card Details
                    </Text>
                    <PaytheoryCardDetails
                      sdkUrl={import.meta.env.VITE_PAYTHEORY_SDK_URL}
                      apiKey={import.meta.env.VITE_PAYTHEORY_API_KEY}
                      setFieldValue={setFieldValue}
                      onPaytheoryLoad={setPaytheory}
                    />
                  </VFlex>
                  <BraintreeAddressDetails popupContainerTarget=".chakra-card__body" />
                </VFlex>
              )}
            </>
          )}
          {paymentMethod === PaymentMethodOptions.ACH_DEBIT &&
            paymentMethodSelected && <ManualACHDetails />}
          {paymentMethodSelected && (
            <VFlex gap={3}>
              <Button
                isDisabled={!isValid}
                isLoading={loading}
                label="Save"
                w="100%"
                iconName="wallet"
                iconPosition="right"
                onClick={() => {
                  if (paymentMethod === PaymentMethodOptions.CREDIT_CARD) {
                    onCardClick()
                  } else {
                    onManualAddBankAccountClick()
                  }
                }}
              />
              <GoBackButton
                onClick={() => {
                  setFieldValue('paymentMethod', null)
                  setPaymentMethodSelected(false)
                }}
              />
            </VFlex>
          )}
        </>
      )}
    </VFlex>
  )
}

export function NewPaymentMethodModal(props: NewPaymentMethodModalProps) {
  const { isOpen, setModalOpen } = props

  return (
    <Modal isOpen={isOpen} onClose={() => setModalOpen(false)} size="xl">
      <ModalOverlay />
      <ModalContent w="100%">
        <Box p={6}>
          <ModalCloseButton />
          <Formik<MerchantFormData>
            {...{
              initialValues: {} as MerchantFormData,
              validationSchema: MerchantPaymentFormSchema,
              onSubmit: async (values) => {},
            }}
          >
            {(formik) => {
              return (
                <NewPaymentMethodModalContent
                  onSuccessfulPaymentMethodAdded={
                    props.onSuccessfulPaymentMethodAdded
                  }
                />
              )
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  )
}

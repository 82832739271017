import {
  CreditCardMap,
  CreditCardType,
  HFlex,
  Icon,
  LineItem,
  PaymentSelectContent,
  VFlex,
  BillOverlayHeader,
  Breadcrumb,
  IconName,
  ScrollableColumn,
  addBusinessDays,
} from 'ui'
import {
  HStack,
  Text,
  Divider,
  Box,
  Flex,
  Card,
  useToast,
  Fade,
  Textarea,
} from '@chakra-ui/react'
import { useBillStore } from '../../stores/billStore'
import { Button } from 'ui'
import { PaymentMethodIdentifier } from 'ui/types'
import {
  AddBillPayableDocument,
  ApprovalPolicy,
  CheckApprovalDocument,
  CreateApprovalDocument,
  OrganizationTier,
  PayBillDocument,
  ReceiptResponse,
  SelfDocument,
} from '../../../operations-types'
import {
  getEstimatedDaysToDelivery,
  vendorPayoutMethodToIdentifier,
} from '../../../lib/utils'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import currency from 'currency.js'
import { getRoleLabel } from '../../settings/Team'
import { getPaymentMethodString } from '../../ap/components/DashboardChoosePaymentMethod'
import moment from 'moment'
import { useDashboardOutletContext } from '../../../lib/outletContext'
import { useAuth } from '../../../lib/auth'
import { SmallBillPaymentReceipt } from '../../ap/components/BillPaymentReceipt'
import { VendorEmails } from './VendorEmails'
import Sentry from '../../../lib/sentry'
import { capitalize } from 'lodash'

type ReviewBillOverlayProps = {
  onClose: () => void
  onBack: () => void
  breadcrumbs?: Breadcrumb[]
}

// Unfortunately copied from portal because we cant share graphql types
type PaymentMethod = Pick<
  NonNullable<
    NonNullable<Pick<ReceiptResponse, 'receipt'>['receipt']>['paymentMethod']
  >,
  'card' | 'achDebit' | 'type'
>

type ReviewItemCardSuccessProps = {
  scheduled: boolean
}
function ReviewItemCardSuccess({ scheduled }: ReviewItemCardSuccessProps) {
  const { paymentSummary, bill, recurringDetails } = useBillStore((state) => ({
    paymentSummary: state.paymentSummary,
    bill: state.bill,
    recurringDetails: state.recurringDetails,
  }))

  if (!paymentSummary) {
    return <></>
  }

  return (
    <Card p={4}>
      <Flex flexDirection="column" gap={3}>
        <LineItem
          title="Vendor"
          subtitle={
            bill?.bill?.vendor?.name || recurringDetails?.vendor?.name || ''
          }
        />
        <LineItem
          title="Payment Amount"
          subtitle={paymentSummary.amountWithoutFeeString()}
        />
        {paymentSummary.feePresenters().map((fee, idx) => (
          <LineItem key={idx} title={fee.name} subtitle={fee.amount} />
        ))}
        <Divider />
        <LineItem
          bold
          icon="bolt"
          title={scheduled ? 'You will be charged' : 'You were charged'}
          subtitle={paymentSummary.totalString()}
        />
        {recurringDetails && (
          <>
            <Divider />

            <LineItem
              title="Frequency"
              subtitle={
                capitalize(recurringDetails.frequency?.toLowerCase()) || ''
              }
            />
            <LineItem
              title="Starts on"
              subtitle={recurringDetails.startDate?.toLocaleDateString() || ''}
            />
            <LineItem
              title="Ends on"
              subtitle={recurringDetails.endDate?.toLocaleDateString() || ''}
            />
          </>
        )}
      </Flex>
    </Card>
  )
}

function ReviewItemCard() {
  const { paymentSummary, bill, recurringDetails } = useBillStore((state) => ({
    paymentSummary: state.paymentSummary,
    bill: state.bill,
    recurringDetails: state.recurringDetails,
  }))

  if (!paymentSummary) {
    return <></>
  }

  return (
    <Flex flexDirection="column" gap={3}>
      <LineItem
        title="Vendor Name"
        subtitle={
          bill?.bill?.vendor?.name || recurringDetails?.vendor?.name || ''
        }
      />
      <LineItem
        title="Payment Amount"
        subtitle={paymentSummary.amountWithoutFeeString()}
      />
      {paymentSummary.feePresenters().map((fee, idx) => (
        <LineItem key={idx} title={fee.name} subtitle={fee.amount} />
      ))}
      <LineItem
        bold
        title={'You will be charged'}
        subtitle={paymentSummary.totalString()}
      />
    </Flex>
  )
}

export function ReviewItems() {
  const { paymentSummary } = useBillStore((state) => ({
    paymentSummary: state.paymentSummary,
  }))

  if (!paymentSummary) {
    return <></>
  }

  return (
    <VFlex p={8} w="100%">
      <Text fontSize="xl" color="gray.800" fontWeight="medium">
        Transaction Details
      </Text>
      <Box pt={5}>
        <ReviewItemCard />
      </Box>
    </VFlex>
  )
}

type NotifyVendorProps = {
  vendorEmails: string[]
}

export function NotifyVendor({ vendorEmails }: NotifyVendorProps) {
  const { setVendorEmails, sendMoneyAmountResult, setSendMoneyAmountResult } =
    useBillStore((state) => ({
      vendorEmails: state.vendorEmails,
      setVendorEmails: state.setVendorEmails,
      sendMoneyAmountResult: state.sendMoneyAmountResult,
      setSendMoneyAmountResult: state.setSendMoneyAmountResult,
    }))

  return (
    <VFlex p={8} w="100%" gap={5}>
      <Text fontSize="lg" color="gray.800" fontWeight="medium">
        Send a receipt to your vendor
      </Text>
      <VFlex gap={2}>
        <Text fontSize="sm" color="gray.800" fontWeight="medium">
          Emails
        </Text>
        <VendorEmails
          vendorEmails={vendorEmails}
          setVendorEmails={setVendorEmails}
        />
      </VFlex>
      <VFlex gap={2}>
        <Text fontSize="sm" color="gray.800" fontWeight="medium">
          Add a note (optional)
        </Text>
        <Textarea
          placeholder="This is a note to your vendor"
          value={sendMoneyAmountResult?.reason || ''}
          onChange={(e) => {
            if (!sendMoneyAmountResult) {
              return
            }

            setSendMoneyAmountResult({
              ...sendMoneyAmountResult,
              reason: e.target.value || '',
            })
          }}
          rows={2}
          border="1px solid #D6D3D1"
          focusBorderColor="gray.300"
          boxShadow="none"
          borderRadius="md"
          p={3}
        />
      </VFlex>
    </VFlex>
  )
}

type PaymentAndDeliveryMethodProps = {
  paymentMethod: {
    iconName: string
    type: 'card' | 'ach_debit'
    displayString: string
  }
  withdrawalDate: Date
  deliveryMethod: {
    type: 'ACH' | 'CHECK'
    displayString: string
  }
  estimatedDeliveryDate: Date | string
}

export const PaymentAndDeliveryMethod = ({
  paymentMethod,
  withdrawalDate,
  deliveryMethod,
  estimatedDeliveryDate,
}: PaymentAndDeliveryMethodProps) => {
  return (
    <VFlex p={8} w="100%" gap={5}>
      <Text fontSize="lg" color="gray.800" fontWeight="medium">
        Payment and Delivery Method
      </Text>
      <HStack>
        <VFlex gap={2} w="100%">
          <Text fontSize="xs" color="gray.800" fontWeight="medium">
            Payment Method
          </Text>
          <Card px={4} py={3}>
            <PaymentSelectContent
              iconName={paymentMethod.iconName as IconName}
              label={
                paymentMethod.type === 'card' ? 'Credit Card' : 'ACH Transfer'
              }
              subtitle={paymentMethod.displayString}
            />
          </Card>
          <Text fontSize="xs" color="gray.500" fontWeight="medium">
            Withdrawal Date: {moment(withdrawalDate).format('MMM DD, YYYY')}
          </Text>
        </VFlex>
        <VFlex gap={2} w="100%">
          <Text fontSize="xs" color="gray.800" fontWeight="medium">
            Delivery Method
          </Text>
          <Card px={4} py={3}>
            <PaymentSelectContent
              iconName={deliveryMethod.type === 'ACH' ? 'wallet' : 'paycheck'}
              label={deliveryMethod.type === 'ACH' ? 'ACH Transfer' : 'Check'}
              subtitle={deliveryMethod.displayString}
            />
          </Card>
          <Text fontSize="xs" color="gray.500" fontWeight="medium">
            Est Delivery Date:{' '}
            {moment(estimatedDeliveryDate).format('MMM DD, YYYY')}
          </Text>
        </VFlex>
      </HStack>
    </VFlex>
  )
}

export function ReviewVendorSummary() {
  const {
    paymentSummary,
    vendorDeliveryMethod,
    selectedSavedPaymentMethod,
    achWithdrawalDate,
    cardWithdrawalDate,
  } = useBillStore((state) => ({
    paymentSummary: state.paymentSummary,
    vendorDeliveryMethod: state.selectedVendorPayoutMethod,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    achWithdrawalDate: state.achWithdrawalDate,
    cardWithdrawalDate: state.cardWithdrawalDate,
  }))

  const { data: loggedInUser } = useQuery(SelfDocument)

  if (!paymentSummary || !vendorDeliveryMethod) {
    return <></>
  }

  const getPaymentMethod: (
    paymentMethod: PaymentMethod | null,
  ) => PaymentMethodIdentifier = (paymentMethod) => {
    if (!paymentMethod) {
      return {
        paymentMethodString: 'N/A',
      }
    }

    if (paymentMethod?.type === 'card') {
      return {
        iconName:
          CreditCardMap[
            paymentMethod?.card?.brand?.toLowerCase() as CreditCardType
          ],
        paymentMethodString: `·· ${paymentMethod?.card?.last4}`,
      }
    }

    return {
      iconName: 'wallet',
      paymentMethodString: `${paymentMethod?.achDebit?.bankName} ···· ${paymentMethod?.achDebit?.last2}`,
    }
  }

  const identifier = getPaymentMethod(selectedSavedPaymentMethod ?? null)
  const paymentMethodType = selectedSavedPaymentMethod?.card?.brand
    ? 'card'
    : 'ach_debit'
  const withdrawalDate =
    paymentMethodType === 'ach_debit' ? achWithdrawalDate : cardWithdrawalDate

  const deliveryType = vendorDeliveryMethod?.type === 'CHECK' ? 'CHECK' : 'ACH'
  const deliveryMethodToDays = getEstimatedDaysToDelivery(
    deliveryType,
    paymentMethodType,
    loggedInUser?.user?.user?.organization?.accountInfo?.tier ||
      OrganizationTier.FreeTier,
    moment(withdrawalDate).isSame(moment(), 'day'),
  )

  return (
    <PaymentAndDeliveryMethod
      paymentMethod={{
        iconName: identifier.iconName,
        type: paymentMethodType,
        displayString: getPaymentMethodString(selectedSavedPaymentMethod!),
      }}
      withdrawalDate={withdrawalDate}
      deliveryMethod={{
        type: deliveryType,
        displayString: vendorPayoutMethodToIdentifier(vendorDeliveryMethod),
      }}
      estimatedDeliveryDate={addBusinessDays(
        withdrawalDate,
        deliveryMethodToDays,
      ).toDate()}
    />
  )
}

function SuccessSentForApproval() {
  return (
    <VFlex py={10} px={8} w="100%">
      <VFlex gap={4}>
        <HStack>
          <Icon
            name="checkCircleFull"
            className="fill-green-600 !h-[54px] !w-[54px]"
          />
          <Box>
            <Text fontSize="xl" color="gray.800" fontWeight="medium">
              Your payment has been sent for approval
            </Text>
            <Text fontSize="sm" fontWeight="medium" color="gray.500">
              We've notified your approvers and you'll receive an email when
              approved or denied.
            </Text>
          </Box>
        </HStack>
      </VFlex>
      <Box pt={4}>
        <ReviewItemCard />
      </Box>
    </VFlex>
  )
}

type SuccessProps = {
  billPaymentId?: string
  scheduled: boolean
}

export function Success({ billPaymentId, scheduled }: SuccessProps) {
  const data = useDashboardOutletContext()
  const { getAccountantUserId } = useAuth()

  const { organization, email } = data

  const { notificationSettings } = organization?.accountInfo || {}

  const notificationSettingsJSON = notificationSettings

  const currentUserEmail = getAccountantUserId()
    ? organization?.accountant?.user?.email
    : email

  const receiptEmail = (
    notificationSettingsJSON?.merchant?.billPaymentMade?.emails || []
  )
    .filter((email) => email === currentUserEmail)
    ?.at(0)

  return (
    <VFlex py={10} px={8} w="100%">
      <VFlex gap={4}>
        <HStack>
          <Icon
            name="checkCircleFull"
            className="fill-green-600 !h-[54px] !w-[54px]"
          />
          <Box>
            <Text fontSize="xl" color="gray.800" fontWeight="medium">
              Thank you for {scheduled ? 'scheduling' : 'sending'} your payment
            </Text>
            {receiptEmail ? (
              <Text fontSize="sm" fontWeight="medium" color="gray.500">
                You'll receive a receipt to {receiptEmail} once your payment is
                processed.
              </Text>
            ) : (
              <Text fontSize="sm" fontWeight="medium" color="gray.500">
                Your vendor will receive an email once your payment is
                processed.
              </Text>
            )}
          </Box>
        </HStack>
        <ReviewItemCardSuccess scheduled={scheduled} />
        {billPaymentId && (
          <SmallBillPaymentReceipt paymentId={billPaymentId} route="/api/pdf" />
        )}
      </VFlex>
    </VFlex>
  )
}

type ApprovalPolicyNotificationProps = {
  approvalPolicy: ApprovalPolicy
}

function ApprovalPolicyNotification({
  approvalPolicy,
}: ApprovalPolicyNotificationProps) {
  // TODO(claudio.wilson): Support multiple approval steps and conditions and different steps and conditions
  const approvalStep = approvalPolicy.approvalSteps?.at(0)
  const approvalCondition = approvalPolicy?.approvalConditions?.at(0)

  return (
    <Flex
      flexDirection="column"
      gap={0}
      borderRadius="md"
      bgColor="yellow.100"
      p={4}
    >
      <HFlex gap={1}>
        <Box mt={0.5}>
          <Icon name="exclamationCircle" status="warning" className="" />
        </Box>
        <Box>
          <Text fontSize="sm" fontWeight="semibold" color="yellow.700">
            Approval Needed
          </Text>
          <Text color="yellow.700" fontWeight="normal" fontSize="13px">
            {`This payment requires approval from ${
              approvalStep?.approver?.email ||
              `a ${getRoleLabel(approvalStep?.approverRole || '')}`
            } because it is greater than ${currency(
              approvalCondition?.paymentAmountCents || 0,
              { fromCents: true },
            ).format()}.`}
          </Text>
        </Box>
      </HFlex>
    </Flex>
  )
}

export function ReviewBillOverlay({
  onClose,
  onBack,
  breadcrumbs,
}: ReviewBillOverlayProps) {
  const {
    bill,
    sendMoneyAmountResult,
    selectedSavedPaymentMethod,
    vendorPayoutMethodId,
    vendorEmails,
    setApprovalPolicy,
    achWithdrawalDate,
    cardWithdrawalDate,
    paymentSummary,
  } = useBillStore((state) => ({
    bill: state.bill,
    paymentSummary: state.paymentSummary,
    sendMoneyAmountResult: state.sendMoneyAmountResult,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    vendorPayoutMethodId: state.selectedVendorPayoutMethod?.id,
    setApprovalPolicy: state.setApprovalPolicy,
    achWithdrawalDate: state.achWithdrawalDate,
    cardWithdrawalDate: state.cardWithdrawalDate,
    vendorEmails: state.vendorEmails,
  }))

  const toast = useToast()
  const [billPaymentId, setBillPaymentId] = useState<string | null>(null)
  const [payBill] = useMutation(PayBillDocument, {
    refetchQueries: ['paymentActivities', 'bills', 'vendor'],
  })
  const [addBillPayable] = useMutation(AddBillPayableDocument)
  const [createApproval] = useMutation(CreateApprovalDocument, {
    refetchQueries: ['approvals'],
  })

  const { data: approvalData, loading: checkApprovalLoading } = useQuery(
    CheckApprovalDocument,
    {
      variables: {
        submittedAmountCents: sendMoneyAmountResult?.submittedAmountCents!,
        paymentMethodId: selectedSavedPaymentMethod?.id!,
        vendorId: bill?.bill?.vendor?.id!,
      },
      onCompleted: (data) => {
        const policy = data.checkApproval?.approvalPolicies?.at(0)
        if (policy) {
          setApprovalPolicy(policy)
        }
      },
    },
  )

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState<
    'review' | 'success' | 'approval' | 'scheduled'
  >('review')

  // For now just get the first approval policy, we can support multiple later
  const approvalPolicy = approvalData?.checkApproval?.approvalPolicies?.at(0)

  const withdrawalDate =
    selectedSavedPaymentMethod?.type === 'ach_debit'
      ? achWithdrawalDate
      : cardWithdrawalDate

  const hasWithdrawalDate =
    selectedSavedPaymentMethod?.type === 'ach_debit'
      ? moment(addBusinessDays(withdrawalDate, 0)).isAfter(
          addBusinessDays(moment(), 0),
          'days',
        )
      : moment(withdrawalDate).isAfter(moment(), 'days')

  const executePayBill = async () => {
    setLoading(true)

    if (vendorEmails.length === 0) {
      toast({
        title: 'Please add at least one vendor email',
        status: 'error',
      })
      setLoading(false)
      return
    }

    const billPayableResponse = await addBillPayable({
      variables: {
        amountDueCents: sendMoneyAmountResult?.submittedAmountCents || 0,
        invoiceDate: new Date().getTime(),
        dueDate: new Date().getTime(),
        vendorId: bill?.bill?.vendor?.id || '',
        billType: bill?.bill?.externalId ? 'BILL' : 'ONE_OFF',
        externalId: bill?.bill?.externalId ? bill.bill.externalId : null,
        vendorEmails: vendorEmails,
        vendorMemo: sendMoneyAmountResult?.reason,
        merchantPaymentMethodId: selectedSavedPaymentMethod?.id || '',
        vendorPayoutMethodId: vendorPayoutMethodId || '',
        withdrawalDateEpochMillis: hasWithdrawalDate
          ? withdrawalDate.getTime()
          : null,
      },
    })

    const billPayableId =
      billPayableResponse?.data?.addBillPayable?.billPayable?.id

    if (billPayableResponse.data?.addBillPayable?.error || !billPayableId) {
      Sentry.captureException(
        billPayableResponse.data?.addBillPayable?.error?.message,
      )
      toast({
        title:
          billPayableResponse.data?.addBillPayable?.error?.message ||
          'Something went wrong',
        status: 'error',
      })
      setLoading(false)
      return
    }

    if (!selectedSavedPaymentMethod?.id || !vendorPayoutMethodId) {
      toast({
        title: 'Something went wrong',
        status: 'error',
      })
      setLoading(false)
      return
    }

    if (approvalPolicy) {
      const { data } = await createApproval({
        variables: {
          billPayableId: billPayableId,
          paymentMethodId: selectedSavedPaymentMethod.id,
          vendorPayoutMethodId: vendorPayoutMethodId,
        },
      })

      if (data?.createApproval?.approval?.id) {
        setLoading(false)
        setPage('approval')
        return
      } else {
        toast({
          title: data?.createApproval?.error?.message,
          status: 'error',
        })
        setLoading(false)
        return
      }
    }

    if (hasWithdrawalDate) {
      setLoading(false)
      setPage('scheduled')
    } else {
      // Idempotency key here
      const billPayment = await payBill({
        variables: {
          billId: billPayableId,
          amountCents: paymentSummary?.amountWithoutFeeCents() || 0,
          merchantPaymentMethodId: selectedSavedPaymentMethod?.id || '',
          vendorPayoutMethodId: vendorPayoutMethodId || '',
          notificationEmails: vendorEmails,
        },
      })

      if (
        billPayment.data?.payBill?.error ||
        !billPayment.data?.payBill?.billPayment?.id
      ) {
        toast({
          title:
            billPayment.data?.payBill?.error?.message || 'Something went wrong',
          status: 'error',
        })

        Sentry.captureException(billPayment.data?.payBill?.error?.message)

        setLoading(false)
        return
      }

      setLoading(false)
      setBillPaymentId(billPayment.data?.payBill?.billPayment.id)
      setPage('success')
    }
  }

  const isSuccessPage = ['success', 'scheduled', 'approval'].includes(page)

  return (
    <>
      <ScrollableColumn grow wGrow>
        <BillOverlayHeader
          onClose={onClose}
          title={`You'll be charged ${paymentSummary?.totalString()}`}
          subtitle={'Please review and confirm your payment'}
          breadcrumbs={
            isSuccessPage
              ? [
                  { label: 'Bill Pay' },
                  { label: 'Bill' },
                  { label: 'Pay', isActive: true },
                ]
              : breadcrumbs
          }
          type="bills"
          renderHeader={!isSuccessPage}
        />
        <Divider />
        <Fade
          key={page}
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          {page === 'review' && (
            <>
              {approvalPolicy && (
                <>
                  <VFlex py={6} px={8} w="100%">
                    <ApprovalPolicyNotification
                      approvalPolicy={approvalPolicy}
                    />
                  </VFlex>
                  <Divider />
                </>
              )}
              <ReviewItems />
              <Divider />
              <ReviewVendorSummary />
              <Divider />
              <NotifyVendor vendorEmails={vendorEmails} />
            </>
          )}
          {page === 'scheduled' && <Success scheduled={true} />}
          {page === 'success' && billPaymentId && (
            <Success billPaymentId={billPaymentId} scheduled={false} />
          )}
          {page === 'approval' && approvalPolicy && <SuccessSentForApproval />}
        </Fade>
      </ScrollableColumn>
      <Divider />
      <HStack
        w="100%"
        px={10}
        py={6}
        justifyContent={isSuccessPage ? 'flex-end' : 'space-between'}
      >
        {!isSuccessPage && (
          <Button
            label="Cancel"
            variant="outline"
            onClick={onClose}
            justifySelf={'flex-end'}
          />
        )}
        <HStack>
          {!isSuccessPage && (
            <Button label="Back" variant="ghost" onClick={onBack} />
          )}
          <Button
            label={
              isSuccessPage
                ? 'Return to Dashboard'
                : approvalPolicy
                ? 'Send for Approval'
                : hasWithdrawalDate
                ? `Schedule Payment`
                : `Pay ${paymentSummary?.totalString()}`
            }
            isLoading={loading || checkApprovalLoading}
            onClick={isSuccessPage ? onClose : executePayBill}
          />
        </HStack>
      </HStack>
    </>
  )
}

import { create } from 'zustand'
import { AddVendorPage } from './types'
import { VendorType } from '../../../operations-types'
type VendorAddress = {
  street1?: string
  city?: string
  state?: string
  zipCode?: string
}

type VendorAchPayoutMethod = {
  routingNumber?: string
  accountNumber?: string
  accountType?: string
  bankName?: string
}

type VendorCheckPayoutMethod = {
  recipientAddress?: VendorAddress
}

export type ManualVendorDetails = {
  vendorName: string
  vendorEmails: string[]
  recipientAddress?: VendorAddress
  vendorAchPayoutMethod?: VendorAchPayoutMethod
  vendorCheckPayoutMethod?: VendorCheckPayoutMethod
  vendorType?: VendorType
}

type AddVendorState = {
  page: AddVendorPage
  manualVendorDetails: ManualVendorDetails | null
  vendorId: string | null
  // Actions
  setPage: (page: AddVendorPage) => void
  getPage: () => AddVendorPage
  setManualVendorDetails: (details: ManualVendorDetails) => void
  setVendorId: (id: string) => void
  getManualVendorDetails: () => ManualVendorDetails | null
  reset: () => void
}

const initialState: Pick<
  AddVendorState,
  'page' | 'manualVendorDetails' | 'vendorId'
> = {
  page: 'options',
  manualVendorDetails: null,
  vendorId: null,
}

export const useAddVendorStore = create<AddVendorState>((set, get) => ({
  ...initialState,
  setPage: (page: AddVendorPage) => set({ page }),
  getPage: () => get().page,
  setManualVendorDetails: (details: ManualVendorDetails) =>
    set({ manualVendorDetails: details }),
  reset: () => set({ ...initialState }),
  setVendorId: (id: string) => set({ vendorId: id }),
  getManualVendorDetails: () => get().manualVendorDetails,
}))

import { ReactMultiEmail } from 'react-multi-email'
import { Text } from '@chakra-ui/react'
import 'react-multi-email/dist/style.css'

type VendorEmailsProps = {
  vendorEmails: string[]
  setVendorEmails: (emails: string[]) => void
}

export function VendorEmails({
  vendorEmails,
  setVendorEmails,
}: VendorEmailsProps) {
  return (
    <ReactMultiEmail
      placeholder={
        <Text fontSize="16px" color="gray.400">
          Type and press enter to add vendor emails
        </Text>
      }
      style={{
        border: '1px solid #D6D3D1',
        width: '100%',
      }}
      emails={vendorEmails}
      getLabel={(email, index, removeEmail) => {
        return (
          <div data-tag key={index}>
            <div data-tag-item>{email}</div>
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        )
      }}
      onChange={(_emails: string[]) => setVendorEmails(_emails)}
    />
  )
}

type VendorEmailsSmallProps = VendorEmailsProps & {
  errorState?: boolean
}

// Used in conjunction with multi-email.css to override some default styles
export function VendorEmailsSmall({
  vendorEmails,
  setVendorEmails,
  errorState,
}: VendorEmailsSmallProps) {
  return (
    <ReactMultiEmail
      placeholder={
        <Text fontSize="12px" color={errorState ? 'red.500' : 'gray.500'}>
          Type and press enter to add vendor emails
        </Text>
      }
      inputClassName="text-xs"
      style={{
        border: 'none',
        padding: '0px',
        width: '100%',
      }}
      emails={vendorEmails}
      getLabel={(email, index, removeEmail) => {
        return (
          <div data-tag key={index}>
            <div data-tag-item>{email}</div>
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        )
      }}
      onChange={(_emails: string[]) => setVendorEmails(_emails)}
    />
  )
}

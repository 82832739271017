import { property } from '@nickeltech/brise'
import { StatusProps, VariantProps } from '../../types'

export type StatusComponentType = StatusProps & VariantProps

export const StatusBackgroundStyle = property<StatusComponentType>(
  'status',
  {
    info: (props) =>
      props.variant === 'primary' ? 'bg-dark-600' : 'bg-gray-100',
    warning: (props) =>
      props.variant === 'primary' ? 'bg-yellow-600' : 'bg-yellow-100',
    error: (props) =>
      props.variant === 'primary' ? 'bg-red-600' : 'bg-red-100',
    action: (props) =>
      props.variant === 'primary' ? 'bg-blue-600' : 'bg-blue-100',
    success: (props) =>
      props.variant === 'primary' ? 'bg-green-600' : 'bg-green-100',
    progress: (props) =>
      props.variant === 'primary' ? 'bg-purple-600' : 'bg-purple-100',
    none: '',
  },
  'none',
)

export const StatusTextColor = property<StatusComponentType>(
  'status',
  {
    info: (props) =>
      props.variant === 'primary' ? '!text-white' : '!text-gray-600',
    warning: (props) =>
      props.variant === 'primary' ? '!text-white' : '!text-yellow-600',
    error: (props) =>
      props.variant === 'primary' ? '!text-white' : '!text-red-600',
    action: (props) =>
      props.variant === 'primary' ? '!text-white' : '!text-blue-600',
    success: (props) =>
      props.variant === 'primary' ? '!text-white' : '!text-green-600',
    progress: (props) =>
      props.variant === 'primary' ? '!text-white' : '!text-purple-600',
    none: (props) => '',
  },
  'none',
)

export const StatusBorderColor = property<StatusComponentType>(
  'status',
  {
    info: (props) =>
      props.variant === 'primary' ? 'border-gray-600' : 'border-gray-200',
    warning: 'border-yellow-600',
    error: 'border-red-600',
    action: 'border-blue-600',
    success: 'border-green-600',
    progress: 'border-purple-600',
    none: '',
  },
  'none',
)

export default StatusTextColor

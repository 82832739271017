import {
  Box,
  Card,
  VStack,
  Text,
  HStack,
  Spinner,
  Center,
  useToast,
} from '@chakra-ui/react'
import {
  calculatePaymentSummaryForMerchant,
  PaymentSummary,
  BraintreeCardDetailsForm,
  VFlex,
  Button,
  Icon,
  SavedPaymentOption,
  CreditCardMap,
  IconName,
  CreditCardType,
  GoBackButton,
  MerchantFormData,
  MerchantPaymentFormSchema,
  PaytheoryCardDetails,
} from 'ui'
import {
  CreateMerchantAchPaymentMethodDocument,
  CreateMerchantCreditCardPaymentMethodDocument,
  DeattachMerchantPaymentDocument,
  GetAllMerchantPaymentMethodsDocument,
  GetClientTokenDocument,
  SelfDocument,
} from '../../../operations-types'
import { PaymentMethodOptions } from './AddPaymentMethod'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { SendMoneyAmountResult } from './SendPaymentRouter'
import { AmountResult } from '../../settings/billing/nickelplans/NickelPlansStore'
import { PaymentMethod } from './SendPaymentRouter'
import BraintreeAddressDetails from '../../forms/BraintreeAddressSection'
import { useLoggedInStore } from '../../layout/LoggedInStore'
import { QBOChartOfAccountsModal } from '../../erp/QBOChartofAccounts'
import { useCodatLinkedConnection } from '../../utils/CodatUtils'
import { Formik, useFormikContext } from 'formik'
import { ManualACHDetails, PaymentMethodPicker } from './PaymentMethodPicker'
import { getFeeString } from '../SchedulePayment'
import { useFeatureFlagEnabled } from 'posthog-js/react'

type DashboardChoosePaymentMethodProps = {
  setPage: () => void
  savedPaymentMethods: PaymentMethod[]
  setPaymentMethods: (_: PaymentMethod[]) => void
  sendMoneyAmountResult: SendMoneyAmountResult | AmountResult
  selectedSavedPaymentMethod: PaymentMethod | undefined | null
  setSelectedSavedPaymentMethod: (_: PaymentMethod | null) => void
  paymentSummary: PaymentSummary | undefined
  setPaymentSummary: (_: PaymentSummary) => void
  onGoBackClick: () => void
  achEnabled: boolean
  creditCardEnabled: boolean
  noCardFee?: boolean
  cardDescription?: string
  bankDescription?: string
}

type NewPaymentMethodProps = {
  clientToken: string
  setPage: () => void
  setChartOfAccountModalOpen: (_: boolean) => void
  onGoBackClicked: () => void
  setPaymentSummary: (_: PaymentSummary) => void
  baseAmountCents: number
  // sendMoneyAmountResult: SendMoneyAmountResult | AmountResult
  setSelectedSavedPaymentMethod: (_: PaymentMethod | null) => void
  achEnabled: boolean
  cardEnabled: boolean
  noCardFee: boolean
  cardDescription: string
  bankDescription: string
}

type AnotherPaymentMethodProps = {
  onClick?: () => void
  size?: 'small' | 'large'
}

export function AnotherPaymentMethod({
  onClick,
  size = 'large',
}: AnotherPaymentMethodProps) {
  return (
    <HStack justifyContent="center">
      <HStack cursor="pointer" onClick={onClick}>
        <Icon
          name="plusIcon"
          size={size === 'small' ? 'xsmall' : 'small'}
          className="stroke-purple-600 hover:stroke-purple-800 !cursor-pointer"
        />
        <Text
          color="purple.600"
          fontSize={size === 'small' ? '13px' : '16px'}
          fontWeight="medium"
          _hover={{
            color: 'purple.800',
          }}
        >
          Add a new payment method
        </Text>
      </HStack>
    </HStack>
  )
}

export const getPaymentMethodString = (paymentMethod: PaymentMethod) => {
  const brand =
    paymentMethod.card?.brand === 'American Express'
      ? 'Amex'
      : paymentMethod.card?.brand

  if (paymentMethod.card?.last4) {
    return `${brand} ·· ${paymentMethod.card.last4}`
  }

  return `${paymentMethod?.achDebit?.bankName} ·· ${paymentMethod.achDebit?.last2}`
}

export const getPaymentMethodIcon = (
  paymentMethod: PaymentMethod,
): IconName => {
  if (paymentMethod.card?.brand) {
    return CreditCardMap[
      paymentMethod?.card?.brand?.toLowerCase() as CreditCardType
    ]
  }

  return 'buildingLibrary'
}

type PreviousPaymentMethodProps = {
  onNextClick: () => void
  useNewPaymentMethodClick: () => void
  savedPaymentMethods: PaymentMethod[]
  setPaymentMethods: (_: PaymentMethod[]) => void
  sendMoneyAmountResult: SendMoneyAmountResult | AmountResult
  selectedSavedPaymentMethod: PaymentMethod | undefined | null
  setSelectedSavedPaymentMethod: (_: PaymentMethod | null) => void
  paymentSummary: PaymentSummary | undefined
  setPaymentSummary: (_: PaymentSummary) => void
  noCardFee: boolean
  cardDescription: string
  bankDescription: string
}

function PreviousPaymentMethods({
  onNextClick,
  useNewPaymentMethodClick,
  savedPaymentMethods,
  selectedSavedPaymentMethod,
  setPaymentSummary,
  paymentSummary,
  setSelectedSavedPaymentMethod,
  setPaymentMethods,
  noCardFee,
  cardDescription,
  bankDescription,
}: PreviousPaymentMethodProps) {
  const { setFieldValue } = useFormikContext<MerchantFormData>()

  const [deattachMerchantPaymentMethod] = useMutation(
    DeattachMerchantPaymentDocument,
    {
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
    },
  )

  return (
    <VFlex gap={6}>
      {savedPaymentMethods.map((method) => {
        return (
          <SavedPaymentOption
            key={method.id}
            title={getPaymentMethodString(method)}
            icon={getPaymentMethodIcon(method)}
            selected={selectedSavedPaymentMethod?.id === method.id}
            description={
              method?.card?.brand ? cardDescription : bankDescription
            }
            feeString={
              method?.card?.brand && !noCardFee ? getFeeString() : 'No Fees'
            }
            onClick={() => {
              setPaymentSummary(
                calculatePaymentSummaryForMerchant(
                  paymentSummary?.amountWithoutFeeCents() || 0,
                  method?.card?.last4 ? 'Credit Card' : 'ACH Transfer',
                  'ACH',
                ),
              )
              setFieldValue(
                'paymentMethod',
                PaymentMethodOptions.SAVED_PAYMENT_METHOD,
              )
              setSelectedSavedPaymentMethod(method)
            }}
            onRemove={async () => {
              const newPaymentMethods = savedPaymentMethods.filter(
                (g: PaymentMethod) => g.id !== method.id,
              )

              setPaymentMethods(newPaymentMethods)
              deattachMerchantPaymentMethod({
                variables: {
                  paymentMethodId: method.externalPaymentMethodId,
                },
              })

              if (selectedSavedPaymentMethod?.id === method.id) {
                if (newPaymentMethods.length > 0) {
                  setSelectedSavedPaymentMethod(newPaymentMethods[0])
                  setFieldValue(
                    'paymentMethod',
                    PaymentMethodOptions.SAVED_PAYMENT_METHOD,
                  )
                  setPaymentSummary(
                    calculatePaymentSummaryForMerchant(
                      paymentSummary?.amountWithoutFeeCents() || 0,
                      method.card?.brand ? 'Credit Card' : 'ACH Transfer',
                      'ACH',
                    ),
                  )
                } else {
                  setSelectedSavedPaymentMethod(null)
                  setFieldValue('paymentMethod', null)
                  setPaymentSummary(
                    calculatePaymentSummaryForMerchant(
                      paymentSummary?.amountWithoutFeeCents() || 0,
                      method.card?.brand ? 'Credit Card' : 'ACH Transfer',
                      'ACH',
                    ),
                  )
                }
              }
            }}
          />
        )
      })}
      <AnotherPaymentMethod onClick={useNewPaymentMethodClick} />
      <Button
        label="Next"
        iconName="wallet"
        iconPosition="right"
        onClick={onNextClick}
      />
    </VFlex>
  )
}

export function NewPaymentMethod({
  clientToken,
  setPage,
  setChartOfAccountModalOpen,
  onGoBackClicked,
  setPaymentSummary,
  baseAmountCents,
  setSelectedSavedPaymentMethod,
  achEnabled,
  cardEnabled,
  noCardFee,
  cardDescription,
  bankDescription,
}: NewPaymentMethodProps) {
  const { setFieldValue, setFieldTouched, values, isValid } =
    useFormikContext<MerchantFormData>()
  const [loading, setLoading] = useState(false)
  const toaster = useToast()
  const [hostedFields, setHostedFields] = useState<braintree.HostedFields>()
  const [paytheory, setPaytheory] = useState<any | null>()
  const isPaytheoryEnabled = useFeatureFlagEnabled('isPaytheoryEnabled')
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false)

  const [createMerchantACHPaymentMethod] = useMutation(
    CreateMerchantAchPaymentMethodDocument,
    {
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
    },
  )

  const [createMerchantCreditCardPaymentMethod] = useMutation(
    CreateMerchantCreditCardPaymentMethodDocument,
    {
      onCompleted: (data) => {
        if (data?.createMerchantCreditCardPaymentMethod?.error) {
          toaster({
            title: 'Error',
            description:
              data.createMerchantCreditCardPaymentMethod.error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
          setLoading(false)
          return
        }

        if (data.createMerchantCreditCardPaymentMethod?.paymentMethod) {
          setLoading(false)
        }
      },
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
    },
  )

  const loggedInUser = useLoggedInStore((state) => state.user)
  const { hasLinkedConnection } = useCodatLinkedConnection(
    loggedInUser.organization.accountInfo ?? null,
  )

  const paymentMethod = values.paymentMethod

  const onManualAddBankAccountClick = async () => {
    setLoading(true)

    const createdPaymentMethod = await createMerchantACHPaymentMethod({
      variables: {
        accountNumber: values.paymentMethodDetails?.bankAccountNumber,
        accountType: values.paymentMethodDetails?.accountType,
        routingNumber: values.paymentMethodDetails?.bankRoutingNumber,
      },
    })

    if (createdPaymentMethod.data?.createMerchantACHPaymentMethod?.error) {
      toaster({
        title: 'Error',
        description:
          createdPaymentMethod.data.createMerchantACHPaymentMethod.error
            .message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setLoading(false)
      return
    }

    const paymentMethod =
      createdPaymentMethod.data?.createMerchantACHPaymentMethod?.paymentMethod
    if (paymentMethod?.id) {
      setSelectedSavedPaymentMethod(paymentMethod)

      const paymentSummary = calculatePaymentSummaryForMerchant(
        baseAmountCents,
        'ACH Transfer',
        'ACH',
      )
      setPaymentSummary(paymentSummary)
      if (hasLinkedConnection) {
        setChartOfAccountModalOpen(true)
      } else {
        setPage()
      }
    }
  }

  const onBraintreeCardClick = async () => {
    if (!hostedFields) {
      // Throw error here
      return
    }

    await hostedFields
      .tokenize({
        cardholderName: values?.billingAddress?.fullName,
        billingAddress: {
          firstName: (values?.billingAddress?.fullName as string | undefined)
            ?.split(' ')
            ?.at(0),
          lastName: (values?.billingAddress?.fullName as string | undefined)
            ?.split(' ')
            ?.at(1),
          postalCode: values?.billingAddress?.zipCode,
          streetAddress: values?.billingAddress?.streetAddress,
          locality: values?.billingAddress?.city,
          region: values?.billingAddress?.state,
        },
      })
      .then(async (payload) => {
        setLoading(true)
        const createdPaymentMethod =
          await createMerchantCreditCardPaymentMethod({
            variables: {
              nonce: payload.nonce,
              provider: 'braintree',
            },
          })

        const paymentMethod =
          createdPaymentMethod.data?.createMerchantCreditCardPaymentMethod
            ?.paymentMethod
        if (paymentMethod) {
          setSelectedSavedPaymentMethod(paymentMethod)

          const paymentSummary = calculatePaymentSummaryForMerchant(
            baseAmountCents,
            'Credit Card',
            'ACH',
          )
          setPaymentSummary(paymentSummary)

          if (hasLinkedConnection) {
            setChartOfAccountModalOpen(true)
          } else {
            setPage()
          }
        }
      })
  }

  const onPaytheoryCardClick = async () => {
    if (!paytheory) {
      console.error('Paytheory not loaded')
      return
    }

    setLoading(true)

    if (values.paymentMethod === PaymentMethodOptions.CREDIT_CARD) {
      const result = await paytheory.tokenizePaymentMethod({
        payorInfo: {
          first_name: (values.billingAddress?.fullName as string).split(' ')[0],
          last_name: (values.billingAddress?.fullName as string).split(' ')[1],
          personal_address: {
            line1: values.billingAddress.streetAddress,
            city: values.billingAddress.city,
            region: values.billingAddress.state,
            postal_code: values.billingAddress.zipCode,
            country: 'USA',
          },
        },
        billingInfo: {
          name: values.billingAddress.fullName,
          address: {
            line1: values.billingAddress.streetAddress,
            city: values.billingAddress.city,
            region: values.billingAddress.state,
            postal_code: values.billingAddress.zipCode,
            country: 'USA',
          },
        },
        skipValidation: true,
      })

      if (result.type === 'TOKENIZED') {
        const createdPaymentMethod =
          await createMerchantCreditCardPaymentMethod({
            variables: {
              nonce: result.body.payment_method_id,
              provider: 'paytheory',
            },
          })

        const paymentMethod =
          createdPaymentMethod.data?.createMerchantCreditCardPaymentMethod
            ?.paymentMethod
        if (paymentMethod) {
          setSelectedSavedPaymentMethod(paymentMethod)

          const paymentSummary = calculatePaymentSummaryForMerchant(
            baseAmountCents,
            'Credit Card',
            'ACH',
          )
          setPaymentSummary(paymentSummary)

          if (hasLinkedConnection) {
            setChartOfAccountModalOpen(true)
          } else {
            setPage()
          }
        }
      } else {
        toaster({
          title: 'Error',
          description: result.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setLoading(false)
      }
    }
  }

  const onCardClick = async () => {
    if (hostedFields) {
      await onBraintreeCardClick()
    } else if (paytheory) {
      await onPaytheoryCardClick()
    } else {
      console.error('No sdk loaded')
    }
  }

  const onAddPaymentMethodPlaidLink = async (paymentMethod: PaymentMethod) => {
    if (paymentMethod?.id) {
      setSelectedSavedPaymentMethod(paymentMethod)

      const paymentSummary = calculatePaymentSummaryForMerchant(
        baseAmountCents,
        'ACH Transfer',
        'ACH',
      )
      setPaymentSummary(paymentSummary)
      if (hasLinkedConnection) {
        setChartOfAccountModalOpen(true)
      } else {
        setPage()
      }
    }
  }

  return (
    <VFlex gap={6}>
      {!paymentMethodSelected && !loading && (
        <PaymentMethodPicker
          cardDescription={cardDescription}
          bankDescription={bankDescription}
          achEnabled={achEnabled}
          cardEnabled={cardEnabled}
          onGoBackClicked={onGoBackClicked}
          noCardFee={noCardFee}
          onManualPayByBankClicked={() => {
            setPaymentSummary(
              calculatePaymentSummaryForMerchant(
                baseAmountCents,
                // sendMoneyAmountResult?.submittedAmountCents || 0,
                'ACH Transfer',
                'ACH',
              ),
            )
            setFieldValue('paymentMethod', 'achDebit')
            // Clear out credit card fields, since the Braintree form will require them to be re inputted
            setFieldValue('paymentMethodDetails.creditCardCVV', false)
            setFieldValue('paymentMethodDetails.creditCardNumber', false)
            setFieldValue(
              'paymentMethodDetails.creditCardExpirationDate',
              false,
            )
            setPaymentMethodSelected(true)
          }}
          onPayByCardClicked={() => {
            setPaymentSummary(
              calculatePaymentSummaryForMerchant(
                baseAmountCents,
                'Credit Card',
                'ACH',
              ),
            )
            setFieldValue('paymentMethod', PaymentMethodOptions.CREDIT_CARD)
            setPaymentMethodSelected(true)
          }}
          setLoading={setLoading}
          onBankAccountAdded={onAddPaymentMethodPlaidLink}
          onPayByBankClicked={() => {
            setPaymentSummary(
              calculatePaymentSummaryForMerchant(
                baseAmountCents,
                'ACH Transfer',
                'ACH',
              ),
            )
            setFieldValue('paymentMethod', 'achDebit')
            // Clear out credit card fields, since the Braintree form will require them to be re inputted
            setFieldValue('paymentMethodDetails.creditCardCVV', false)
            setFieldValue('paymentMethodDetails.creditCardNumber', false)
            setFieldValue(
              'paymentMethodDetails.creditCardExpirationDate',
              false,
            )
          }}
        />
      )}

      {!paymentMethodSelected && loading && (
        <Center w="100%">
          <Spinner />
        </Center>
      )}
      {paymentMethod !== PaymentMethodOptions.SAVED_PAYMENT_METHOD && (
        <>
          {paymentMethod === PaymentMethodOptions.CREDIT_CARD && (
            <>
              {clientToken && !isPaytheoryEnabled && (
                <Card p={4}>
                  <VFlex gap={4}>
                    <VFlex gap={2}>
                      <Text color="gray.800" fontSize="sm" fontWeight="medium">
                        Card Details
                      </Text>
                      <BraintreeCardDetailsForm
                        clientToken={clientToken}
                        setHostedFields={setHostedFields}
                        onCVVValidityChange={async (valid) => {
                          await setFieldTouched(
                            'paymentMethodDetails.creditCardCVV',
                          )

                          await setFieldValue(
                            'paymentMethodDetails.creditCardCVV',
                            valid,
                          )
                        }}
                        onCardNumberValidityChange={async (valid) => {
                          await setFieldTouched(
                            'paymentMethodDetails.creditCardNumber',
                          )
                          await setFieldValue(
                            'paymentMethodDetails.creditCardNumber',
                            valid,
                          )
                        }}
                        onExpiryValidityChange={async (valid) => {
                          await setFieldTouched(
                            'paymentMethodDetails.creditCardExpirationDate',
                          )

                          await setFieldValue(
                            'paymentMethodDetails.creditCardExpirationDate',
                            valid,
                          )
                        }}
                      />
                    </VFlex>
                    <BraintreeAddressDetails popupContainerTarget=".chakra-card__body" />
                  </VFlex>
                </Card>
              )}
              {isPaytheoryEnabled && (
                <Card p={4}>
                  <VFlex gap={4}>
                    <VFlex gap={2}>
                      <Text color="gray.800" fontSize="sm" fontWeight="medium">
                        Card Details
                      </Text>
                      <PaytheoryCardDetails
                        sdkUrl={import.meta.env.VITE_PAYTHEORY_SDK_URL}
                        apiKey={import.meta.env.VITE_PAYTHEORY_API_KEY}
                        setFieldValue={setFieldValue}
                        onPaytheoryLoad={setPaytheory}
                      />
                    </VFlex>
                    <BraintreeAddressDetails popupContainerTarget=".chakra-card__body" />
                  </VFlex>
                </Card>
              )}
            </>
          )}
          {paymentMethod === PaymentMethodOptions.ACH_DEBIT &&
            paymentMethodSelected && <ManualACHDetails />}
          {paymentMethodSelected && (
            <VFlex gap={4}>
              <Button
                isDisabled={!isValid}
                isLoading={loading}
                label="Next"
                w="100%"
                iconName="wallet"
                iconPosition="right"
                onClick={() => {
                  if (paymentMethod === PaymentMethodOptions.CREDIT_CARD) {
                    onCardClick()
                  } else {
                    onManualAddBankAccountClick()
                  }
                }}
              />
              <GoBackButton
                onClick={() => {
                  setFieldValue('paymentMethod', null)
                  setPaymentMethodSelected(false)
                }}
              />
            </VFlex>
          )}
        </>
      )}
    </VFlex>
  )
}

export function DashboardChoosePaymentMethod(
  props: DashboardChoosePaymentMethodProps,
) {
  const paymentMethods = props.savedPaymentMethods
  const {
    setSelectedSavedPaymentMethod,
    sendMoneyAmountResult,
    setPaymentSummary,
  } = props

  useEffect(() => {
    const executePrevPayment = async () => {
      if (
        paymentMethods &&
        paymentMethods.length > 0 &&
        !props.selectedSavedPaymentMethod
      ) {
        const defaultPaymentMethod = paymentMethods[0]
        setSelectedSavedPaymentMethod(defaultPaymentMethod)

        const paymentSummary = calculatePaymentSummaryForMerchant(
          sendMoneyAmountResult?.submittedAmountCents,
          defaultPaymentMethod.card?.brand ? 'Credit Card' : 'ACH Transfer',
          'ACH',
        )
        setPaymentSummary(paymentSummary)
      }
    }

    executePrevPayment()
  }, [
    paymentMethods,
    setSelectedSavedPaymentMethod,
    sendMoneyAmountResult,
    setPaymentSummary,
  ])

  const [newPaymentMethodClicked, setNewPaymentMethodClicked] = useState(false)
  const [clientToken, setClientToken] = useState<string | null>(null)

  useQuery(GetClientTokenDocument, {
    onCompleted: (data) => {
      setClientToken(data?.getClientToken?.clientToken ?? null)
    },
  })

  useEffect(() => {
    if (props.selectedSavedPaymentMethod) {
      const paymentSummary = calculatePaymentSummaryForMerchant(
        props.sendMoneyAmountResult?.submittedAmountCents,
        props.selectedSavedPaymentMethod.card?.brand
          ? 'Credit Card'
          : 'ACH Transfer',
        'ACH',
      )
      props.setPaymentSummary(paymentSummary)
    }
  }, [props.selectedSavedPaymentMethod])

  const [qboChartOfAccountModalOpen, setQboChartOfAccountModalOpen] =
    useState(false)

  const hasSavedPaymentMethods = paymentMethods.length > 0

  const { data } = useQuery(SelfDocument)
  return (
    <VStack alignSelf="center" w="548px" spacing="8">
      <Formik<MerchantFormData>
        {...{
          initialValues: {} as MerchantFormData,
          validationSchema: MerchantPaymentFormSchema,
          onSubmit: async (values) => {},
        }}
      >
        {(formik) => {
          return (
            <Box w="100%">
              {hasSavedPaymentMethods && !newPaymentMethodClicked && (
                <PreviousPaymentMethods
                  savedPaymentMethods={paymentMethods}
                  setPaymentMethods={props.setPaymentMethods}
                  sendMoneyAmountResult={props.sendMoneyAmountResult}
                  selectedSavedPaymentMethod={props.selectedSavedPaymentMethod}
                  setSelectedSavedPaymentMethod={
                    props.setSelectedSavedPaymentMethod
                  }
                  cardDescription={
                    props.cardDescription ||
                    'Your payment arrives in the next business day.'
                  }
                  bankDescription={
                    props.bankDescription ||
                    'Your payment arrives in 2 to 3 business days.'
                  }
                  paymentSummary={props.paymentSummary}
                  setPaymentSummary={props.setPaymentSummary}
                  onNextClick={() => {
                    if (
                      data?.user?.user?.organization?.accountInfo
                        ?.quickbooksCompanyId &&
                      !props.selectedSavedPaymentMethod?.codatChartOfAccountId
                    ) {
                      setQboChartOfAccountModalOpen(true)
                    } else {
                      props.setPage()
                    }
                  }}
                  noCardFee={!!props.noCardFee}
                  useNewPaymentMethodClick={() => {
                    setNewPaymentMethodClicked(true)
                  }}
                />
              )}
              {(!hasSavedPaymentMethods || newPaymentMethodClicked) && (
                <NewPaymentMethod
                  cardDescription={
                    props.cardDescription ||
                    'Your payment arrives in the next business day.'
                  }
                  bankDescription={
                    props.bankDescription ||
                    'Your payment arrives in 2 to 3 business days.'
                  }
                  noCardFee={!!props.noCardFee}
                  clientToken={clientToken || ''}
                  setChartOfAccountModalOpen={setQboChartOfAccountModalOpen}
                  setPaymentSummary={props.setPaymentSummary}
                  baseAmountCents={
                    props.sendMoneyAmountResult?.submittedAmountCents || 0
                  }
                  setSelectedSavedPaymentMethod={
                    props.setSelectedSavedPaymentMethod
                  }
                  setPage={props.setPage}
                  onGoBackClicked={() => {
                    if (!hasSavedPaymentMethods) {
                      props.onGoBackClick()
                    } else {
                      setNewPaymentMethodClicked(false)
                    }
                  }}
                  achEnabled={props.achEnabled}
                  cardEnabled={props.creditCardEnabled}
                />
              )}
            </Box>
          )
        }}
      </Formik>
      <QBOChartOfAccountsModal
        isOpen={qboChartOfAccountModalOpen}
        setModalOpen={setQboChartOfAccountModalOpen}
        paymentMethodId={props.selectedSavedPaymentMethod?.id}
        type={
          props.selectedSavedPaymentMethod?.card ? 'creditCard' : 'bankAccount'
        }
        accountString={
          props.selectedSavedPaymentMethod?.achDebit?.routingNumber
            ? `your ACH payment method ${props.selectedSavedPaymentMethod?.achDebit?.bankName} ·· ${props.selectedSavedPaymentMethod?.achDebit?.last2}`
            : `your credit card payment method ${props.selectedSavedPaymentMethod?.card?.brand} ·· ${props.selectedSavedPaymentMethod?.card?.last4}`
        }
        onCompleted={() => {
          setQboChartOfAccountModalOpen(false)
          props.setPage()
        }}
      />
    </VStack>
  )
}

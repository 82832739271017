import * as yup from 'yup'
import { useMutation } from '@apollo/client'
import { useToast, VStack } from '@chakra-ui/react'
import {
  BillOverlayHeader,
  Button,
  Form,
  FormikValidatingInput,
  HFlex,
  VFlex,
} from 'ui'
import { UpdateVendorDocument, VendorDocument } from '../../../operations-types'
import InputMask from 'react-input-mask'
import { TabType } from './types'
import { VendorEmails } from '../../bills/components/VendorEmails'

export const EditVendorDetailsValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  emails: yup.array().of(yup.string().required().email()),
  taxId: yup
    .string()
    .matches(
      /^(\d{9}|REQUESTED)$/,
      'Tax ID must be exactly 9 digits or REQUESTED',
    ),
})

export type EditVendorDetailsValidationSchemaType = yup.InferType<
  typeof EditVendorDetailsValidationSchema
>

type EditVendorDetailsProps = {
  name: string
  email: string
  taxId: string
  vendorId: string
  setTab: (tab: TabType) => void
}
export default function EditVendorDetails(props: EditVendorDetailsProps) {
  const [updateVendor] = useMutation(UpdateVendorDocument, {
    refetchQueries: [VendorDocument],
  })

  const toast = useToast()
  return (
    <>
      <BillOverlayHeader
        type="vendor"
        onClose={() => {
          props.setTab('overview')
        }}
        title={`Editing ${props.name}`}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Vendors' },
          { label: props.name },
          { label: 'Edit Vendor', isActive: true },
        ]}
      />

      <VFlex px={8} py={6} w="100%" gap="6">
        <Form
          {...{
            initialValues: {
              name: props.name,
              email: props.email,
              taxId: props.taxId,
            },
            validationSchema: EditVendorDetailsValidationSchema,
            onSubmit: async (values) => {
              const response = await updateVendor({
                variables: {
                  vendorId: props.vendorId,
                  name: values.name,
                  emails: values.emails,
                  taxId: values.taxId,
                },
              })

              if (response.data?.updateVendor?.error) {
                toast({
                  status: 'error',
                  description: response.data?.updateVendor?.error.message,
                  duration: 9000,
                  isClosable: true,
                })
                return
              }

              toast({
                status: 'success',
                title: 'Success',
                description: 'Vendor details updated!',
                duration: 9000,
                isClosable: true,
              })

              props.setTab('overview')
            },
          }}
        >
          {(formik) => (
            <VStack spacing={4} w="100%" alignItems="start">
              <FormikValidatingInput
                fieldName="name"
                label="Name"
                placeholder="Enter vendor name"
              />
              <VendorEmails
                vendorEmails={formik.values.emails}
                setVendorEmails={(emails) => {
                  formik.setFieldValue('emails', emails)
                }}
              />
              <InputMask
                mask="99-9999999"
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/\D/g, '')
                  formik.setFieldValue('taxId', rawValue)
                }}
                value={formik.values.taxId}
                onBlur={formik.handleBlur}
              >
                <FormikValidatingInput
                  fieldName="taxId"
                  label="Tax ID"
                  placeholder="Enter tax ID"
                />
              </InputMask>
              <HFlex justifyContent="end" w="100%" gap="4">
                <Button
                  alignSelf="end"
                  label="Cancel"
                  onClick={() => {
                    formik.resetForm()
                    props.setTab('overview')
                  }}
                />
                <Button
                  alignSelf="end"
                  label="Save"
                  onClick={async () => {
                    await formik.handleSubmit()
                  }}
                />
              </HFlex>
            </VStack>
          )}
        </Form>
      </VFlex>
    </>
  )
}

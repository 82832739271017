import { useQuery } from '@apollo/client'
import moment from 'moment'
import qs from 'querystring'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Column,
  LARGE_CELL_PX,
  Row,
  TableCellProps,
  TableV2,
  TransactionsToolbar,
} from 'ui'

import {
  getPaymentMethodPayoutIdentifier,
  reportErrorIfExists,
} from '../../lib/utils'
import {
  ActivityStatus,
  InputMaybe,
  PaymentActivitiesDocument,
  PaymentActivity,
  PaymentActivitySource,
  SortOrder,
} from '../../operations-types'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import { PageLayout } from '../layout/PageLayout'
import { TransactionsOverlay } from './TransactionsOverlay'
import { TRANSACTIONS_URL } from '../../lib/urls'
import { useDashboardOutletContext } from '../../lib/outletContext'
import ActivityNoteModal from '../ap/ActivityNoteModal'
import { PaymentActivityPresenter, transformTransactionData } from './utils'
import { Text } from '@chakra-ui/react'
import currency from 'currency.js'

const convertPaymentToCSV = (payments: PaymentActivity[]) => {
  const csv = [
    'Id,Transaction Type,Reference,Created At,Status,Company Name,Payment Method Holder Name,Amount Submitted,Amount Charged,Fees Charged,Payment Method',
  ]

  payments.forEach((payment) => {
    const billPayment = payment.billPayment

    csv.push(
      [
        payment.payment?.id ||
          payment.achReturn?.id ||
          payment.refund?.id ||
          payment.billPayment?.id ||
          '',
        payment.billPayment ? 'Payable' : 'Receivable',
        `"${
          payment.payment?.notes ||
          billPayment?.billPayable.bill?.billData?.reference ||
          ''
        }"`,
        `"${moment(payment.createdAt).format()}"`,
        payment.status,
        `"${
          payment.payment?.paymentMethod?.companyName ||
          billPayment?.paymentMethod?.companyName ||
          ''
        }"`,
        `"${
          payment.payment?.paymentMethod?.name ||
          billPayment?.paymentMethod?.name ||
          ''
        }"`,
        `"${currency(
          payment.payment?.submittedAmountInCents ||
            billPayment?.submittedAmountInCents ||
            0,
          {
            fromCents: true,
          },
        ).format()}"`,
        `"${currency(
          payment.payment?.amountInCents || billPayment?.amountInCents || 0,
          {
            fromCents: true,
          },
        ).format()}"`,
        `"${currency(
          payment.payment?.platformFeeInCents ||
            billPayment?.platformFeeInCents ||
            0,
          {
            fromCents: true,
          },
        ).format()}"`,
        `"${
          payment.payment?.paymentMethod
            ? getPaymentMethodPayoutIdentifier(payment.payment.paymentMethod)
                .identifier
            : getPaymentMethodPayoutIdentifier(
                billPayment?.paymentMethod || null,
              ).identifier
        }"`,
      ].join(','),
    )
  })

  return csv.join('\n')
}

const downloadDataAsCSV = async (transactions: PaymentActivity[]) => {
  const a = document.createElement('a')
  a.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
    convertPaymentToCSV(transactions),
  )}`
  a.target = '_blank'
  a.download = 'transactions.csv'
  a.click()
  a.remove()
}

export default function Transactions() {
  const navigate = useNavigate()

  const outletContext = useDashboardOutletContext()

  const defaultBankAccount =
    outletContext.organization?.accountInfo?.bankAccounts?.[0]

  const [page, setPage] = useState(1)

  const availableSpace = window.screen.availHeight - 400
  const pageSize = Math.floor(availableSpace / LARGE_CELL_PX)

  const [params, _] = useSearchParams()
  const navigator = useNavigate()

  const [selectedTab, setSelectedTab] = useState(params.get('type') || 'all')

  const [sortBy, setSortBy] = useState(
    params.get('sortBy') || SortOrder.DateDesc,
  )

  const [startDate, setStartDate] = useState(params.get('startDate') || '')

  const [endDate, setEndDate] = useState(params.get('endDate') || '')

  const [status, setStatus] = useState(params.get('status') || 'all')

  const [transactionId, setTransactionId] = useState(
    params.get('transactionId') || '',
  )

  const [customerName, setCustomerName] = useState(
    params.get('customerName') || '',
  )

  const queryOpts = {
    page,
    pageSize: pageSize,
    sortBy: (sortBy ||
      (SortOrder.DateDesc as InputMaybe<SortOrder>)) as InputMaybe<SortOrder>,
    source:
      selectedTab === 'all'
        ? undefined
        : (selectedTab as PaymentActivitySource),
    status: status === 'all' ? undefined : (status as ActivityStatus),
    startDate: startDate || undefined,
    endDate: endDate || undefined,
    transactionId: transactionId || undefined,
    customerName: customerName || undefined,
  }

  const { loading, data, error, refetch } = useQuery(
    PaymentActivitiesDocument,
    {
      variables: {
        ...queryOpts,
      },
    },
  )

  reportErrorIfExists(data?.paymentActivities?.error?.message || error)

  const totalResults = data?.paymentActivities?.totalResults || 0
  const paymentActivities = data?.paymentActivities?.paymentActivities || []
  const totalPages = Math.ceil(totalResults / pageSize)
  const transactions = transformTransactionData(
    paymentActivities,
    defaultBankAccount || null,
  )

  const [activityNoteModalOpen, setActivityNoteModalOpen] = useState(false)
  const [activityNoteId, setActivityNoteId] = useState('')
  const [activitySourceType, setActivitySourceType] = useState('')
  const [activityNote, setActivityNote] = useState('')
  const [reconciled, setReconciled] = useState(false)
  const [flagged, setFlagged] = useState(false)

  const table = (
    <TableV2
      {...{
        loading: loading,
        rowSize: 'large',
        cellSize: 'large',
        className: totalResults === 0 ? 'min-h-[400px]' : '',
        headers: [
          {
            type: 'date',
            keyName: 'createdAt',
            label: 'Date',
          },
          selectedTab === 'REFUND'
            ? {
                type: 'currency',
                keyName: 'originalAmount',
                label: 'Charged Amount',
              }
            : null,
          {
            type: 'colorText',
            keyName: 'amount',
            label: selectedTab === 'REFUND' ? 'Refund Amount' : 'Amount',
          },
          {
            type: 'methodForAccount',
            keyName: 'payoutMethod',
            label: 'Your Account',
            grow: 1,
          },
          {
            type: 'colorTextWithDirection',
            label: 'Money Flow',
            keyName: 'label',
            width: 'small',
          },
          {
            type: 'methodWithName',
            keyName: 'paymentMethod',
            label: 'Sent To / From',
            grow: 1,
          },
          {
            type: 'reference',
            keyName: 'reference',
            label: 'Purpose',
            grow: 1,
          },
          {
            type: 'transactionStatus',
            keyName: 'transactionStatus',
            label: 'Status',
            width: 'medium',
          },
          {
            type: 'note',
            keyName: 'note',
            label: 'Notes',
            fit: true,
            width: 'icon',
            onClick: (_: string, datum: PaymentActivityPresenter) => {
              setActivityNoteModalOpen(!activityNoteModalOpen)
              setActivityNoteId(datum.activityId)
              setActivitySourceType(datum.label.label as PaymentActivitySource)

              if (datum.note) {
                setActivityNote(datum.note.note || '')
              }

              setReconciled(datum.note?.reconciled || false)
              setFlagged(datum.note?.flagged || false)
            },
          },
        ].filter(Boolean) as TableCellProps<object>[],
        data: transactions,
        page: page,
        perPage: pageSize,
        totalPages: totalPages || 1,
        onChange: () => {},
        onClick: (x) => {
          if (x.type.label === 'Payable') {
            navigate(`${TRANSACTIONS_URL}/payable/${x.id}`)
          }

          if (x.type.label === 'Receivable') {
            navigate(`${TRANSACTIONS_URL}/receivable/${x.id}`)
          }

          if (x.type.label === 'Refund') {
            navigate(`${TRANSACTIONS_URL}/refund/${x.id}`)
          }

          if (x.type.label === 'ACH Return') {
            navigate(`${TRANSACTIONS_URL}/return/${x.id}`)
          }

          if (x.type.label === 'Vendor ACH Return') {
            navigate(`${TRANSACTIONS_URL}/vendorReturn/${x.id}`)
          }

          if (x.type.label === 'Chargeback') {
            navigate(`${TRANSACTIONS_URL}/chargeback/${x.id}`)
          }
          if (x.type.label === 'Advance') {
            navigate(`${TRANSACTIONS_URL}/advance/${x.id}`)
          }
        },
        onPage: (page: number) => {
          setPage(page)
        },
      }}
    />
  )

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem)]"
        grow
      >
        <Row className="justify-between p-4 h-[64px]" grow>
          <Text fontSize="xl" fontWeight="semibold" color="gray.800">
            Transactions
          </Text>
        </Row>
        <TransactionsOverlay queryOpts={queryOpts} />
        <Column gap="medium" grow wGrow>
          <div className="w-full">
            <TransactionTabsV2
              {...{
                selected: selectedTab,
                onSelect: (tab: string) => {
                  setSelectedTab(tab)
                  setPage(1)
                  navigator(
                    `/dashboard/transactions?${qs.stringify({
                      type: tab,
                      sortBy,
                      ...(status ? { status } : {}),
                      ...(startDate ? { startDate } : {}),
                      ...(endDate ? { endDate } : {}),
                      ...(transactionId ? { transactionId } : {}),
                      ...(customerName ? { customerName } : {}),
                    })}`,
                  )
                },
                tabs: [
                  {
                    label: 'All Transactions',
                    value: 'all',
                  },
                  {
                    label: 'Receivables',
                    value: 'RECEIVABLE',
                  },
                  {
                    label: 'Payables',
                    value: 'PAYABLE',
                  },
                  {
                    label: 'Refunds',
                    value: 'REFUND',
                  },
                  {
                    label: 'Returns',
                    value: 'RETURN',
                  },
                  {
                    label: 'Chargebacks',
                    value: 'CHARGEBACK',
                  },
                ],
              }}
            />
          </div>
          <div className="w-full px-4">
            <TransactionsToolbar
              {...{
                sortItems: [
                  {
                    label: 'Date Ascending',
                    value: SortOrder.DateAsc,
                  },
                  {
                    label: 'Date Descending',
                    value: SortOrder.DateDesc,
                  },
                ],
                setSelectedSort: (sort: string) => {
                  setSortBy(sort)
                  navigator(
                    `/dashboard/transactions?${qs.stringify({
                      sortBy: sort,
                      ...(status !== 'all' ? { status } : {}),
                      ...(startDate ? { startDate } : {}),
                      ...(endDate ? { endDate } : {}),
                      ...(transactionId ? { transactionId } : {}),
                      ...(customerName ? { customerName } : {}),
                      ...(selectedTab !== 'all' ? { selectedTab } : {}),
                    })}`,
                  )
                },
                currentFilters: [
                  transactionId
                    ? {
                        label: 'Transaction ID',
                        value: transactionId,
                      }
                    : null,
                  customerName
                    ? {
                        label: 'Customer Name',
                        value: customerName,
                      }
                    : null,
                  startDate && endDate
                    ? {
                        label: 'Date Range',
                        value: `${startDate} - ${endDate}`,
                      }
                    : null,
                ].filter((e) => e !== null),
                onCustomFilter: (valOne, valTwo, valThree, valFour) => {
                  setPage(1)
                  if (valOne && valTwo) {
                    setStartDate(moment(valOne).format('MM/DD/YYYY'))
                    setEndDate(moment(valTwo).format('MM/DD/YYYY'))
                  }

                  if (valThree) {
                    setTransactionId(valThree)
                  }

                  if (valFour) {
                    setCustomerName(valFour)
                  }

                  navigator(
                    `/dashboard/transactions?${qs.stringify({
                      ...(status !== 'all' ? { status } : {}),
                      ...(valOne && valTwo
                        ? {
                            startDate: moment(valOne).format('MM/DD/YYYY'),
                            endDate: moment(valTwo).format('MM/DD/YYYY'),
                          }
                        : {}),
                      ...(valThree || transactionId
                        ? { transactionId: valThree || transactionId }
                        : {}),
                      ...(valFour || customerName
                        ? { customerName: valFour || customerName }
                        : {}),
                      ...(selectedTab !== 'all' ? { selectedTab } : {}),
                      ...(sortBy !== SortOrder.DateDesc ? { sortBy } : {}),
                    })}`,
                  )
                },
                selectedSort: sortBy,
                setStatusFilter: (e) => {
                  setPage(1)
                  setStatus(e)

                  navigator(
                    `/dashboard/transactions?${qs.stringify({
                      status: e,
                      ...(startDate ? { startDate } : {}),
                      ...(endDate ? { endDate } : {}),
                      ...(transactionId ? { transactionId } : {}),
                      ...(customerName ? { customerName } : {}),
                      ...(selectedTab !== 'all' ? { selectedTab } : {}),
                      ...(sortBy !== SortOrder.DateDesc ? { sortBy } : {}),
                    })}`,
                  )
                },
                statusFilter: status,
                downloadCSV: async () => {
                  let currentPaymentActivities = paymentActivities
                  let currentPaymentSet = paymentActivities
                  let nextPage = page + 1

                  while (currentPaymentSet.length > 0) {
                    const { data: addedData } = await refetch({
                      ...queryOpts,
                      page: nextPage,
                    })

                    currentPaymentActivities = currentPaymentActivities.concat(
                      addedData.paymentActivities?.paymentActivities || [],
                    )

                    currentPaymentSet =
                      addedData.paymentActivities?.paymentActivities || []

                    nextPage += 1
                  }

                  await downloadDataAsCSV(
                    currentPaymentActivities as PaymentActivity[],
                  )
                },
                onClose: (label: string, value: string | Array<string>) => {
                  if (label === 'Transaction ID') {
                    setTransactionId('')

                    navigator(
                      `/dashboard/transactions?${qs.stringify({
                        ...(status !== 'all' ? { status } : {}),
                        ...(startDate && endDate
                          ? {
                              startDate: moment(startDate).format('MM/DD/YYYY'),
                              endDate: moment(endDate).format('MM/DD/YYYY'),
                            }
                          : {}),
                        ...(customerName ? { customerName } : {}),
                        ...(selectedTab !== 'all' ? { selectedTab } : {}),
                        ...(sortBy !== SortOrder.DateDesc ? { sortBy } : {}),
                      })}`,
                    )
                  } else if (label === 'Customer Name') {
                    setCustomerName('')

                    navigator(
                      `/dashboard/transactions?${qs.stringify({
                        status: status !== 'all' ? status : '',
                        ...(startDate ? { startDate } : {}),
                        ...(endDate ? { endDate } : {}),
                        ...(transactionId ? { transactionId } : {}),
                        ...(selectedTab !== 'all' ? { selectedTab } : {}),
                        ...(sortBy !== SortOrder.DateDesc ? { sortBy } : {}),
                      })}`,
                    )
                  } else if (label === 'Date Range') {
                    setStartDate('')
                    setEndDate('')

                    navigator(
                      `/dashboard/transactions?${qs.stringify({
                        status: status !== 'all' ? status : '',
                        ...(transactionId ? { transactionId } : {}),
                        ...(selectedTab !== 'all' ? { selectedTab } : {}),
                        ...(sortBy !== SortOrder.DateDesc ? { sortBy } : {}),
                        ...(customerName ? { customerName } : {}),
                      })}`,
                    )
                  }
                },
              }}
            />
          </div>
          {table}
          <ActivityNoteModal
            {...{
              open: activityNoteModalOpen,
              setOpen: setActivityNoteModalOpen,
              activityId: activityNoteId,
              sourceType: activitySourceType as PaymentActivitySource,
              initialNote: activityNote,
              reconciled,
              flagged,
              queryOpts: queryOpts,
            }}
          />
        </Column>
      </Column>
    </PageLayout>
  )
}

import { Box, Divider, Flex, HStack } from '@chakra-ui/react'
import { Button } from 'ui'

export type VendorOverlayFooterProps = {
  onDeleteVendor: () => void
  onPayVendor?: () => void
  isExternalVendor: boolean
  onBack?: () => void
}

export default function VendorOverlayFooter({
  onDeleteVendor,
  onPayVendor,
  isExternalVendor,
  onBack,
}: VendorOverlayFooterProps) {
  return (
    <Box w="100%" mt="auto">
      <Divider />
      <Flex
        justifyContent={!isExternalVendor ? 'space-between' : 'flex-end'}
        py={6}
        px={10}
        gap={4}
      >
        {!isExternalVendor && (
          <Button
            label="Delete Vendor"
            variant="outline"
            onClick={() => {
              onDeleteVendor()
            }}
          />
        )}
        <HStack gap="4">
          {onBack && (
            <Button
              label="Back"
              variant={onPayVendor ? 'ghost' : undefined}
              onClick={onBack}
            />
          )}
          {onPayVendor && <Button label="New Bill" onClick={onPayVendor} />}
        </HStack>
      </Flex>
    </Box>
  )
}

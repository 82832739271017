import { useMutation, useQuery } from '@apollo/client'
import { ActivityNote, QueryOptsActivityNote } from '../ap/ActivityNoteModal'
import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import { Card, Column, OverlayFooter, OverlaySectionReturn } from 'ui'
import {
  CancelBillPaymentDocument,
  GetBillPaymentDocument,
  PaymentActivitySource,
  VendorPayoutMethod,
} from '../../operations-types'
import currency from 'currency.js'
import {
  getTransactionStatusFromActivity,
  reportErrorIfExists,
} from '../../lib/utils'
import AdditionalPaymentDetail from '../payments/AdditionalPaymentDetail'

import { OverlayHeaderV2 } from '../payments/OverlayHeader'
import AmountHeader from '../payments/PaymentAmountSubmitted'
import PaymentDetails, {
  Check,
  ContactInfo,
  PaymentMethod,
} from '../payments/PaymentDetails'
import PaymentElements from '../payments/PaymentElements'
import BillPaymentDetails from './components/BillPaymentDetails'
import { paymentMethodIntoIdentifier } from './PaymentInnerOverlay'
import { TransactionActivity } from './TransactionActivity'
import moment from 'moment'
import { useState } from 'react'
import { ArrowDownIcon } from '@heroicons/react/24/outline'

type VendorPayout = Pick<
  VendorPayoutMethod,
  | 'type'
  | 'street'
  | 'bankName'
  | 'city'
  | 'state'
  | 'zip'
  | 'routingNumber'
  | 'accountNumber'
>

function vendorPayoutOrBankAccountIntoIdentifier(
  account: VendorPayout | null,
  paidAt: string | null,
  verb = 'Sent',
) {
  return {
    type:
      account?.type === 'CHECK'
        ? 'checkDeposit'
        : account?.type === 'ACH'
        ? 'bankAccount'
        : '',
    identifier:
      account?.type === 'CHECK'
        ? `${account.street}, ${account.city}, ${account.state} ${account.zip}`
        : `${
            account?.bankName || account?.routingNumber
          } ·· ${account?.accountNumber?.slice(-2)}`,
    text: paidAt
      ? `${verb} on ${moment(paidAt).format('MM/DD/YYYY')}`
      : 'Pending',
  }
}

type PayableInnerOverlayProps = {
  billPaymentId: string
  exitOverlay: () => void
  onPayableClick?: (billPaymentId: string) => void
  onBack?: () => void
  queryOpts?: QueryOptsActivityNote
  onReturnItem: (id: string) => void
}

export default function PayableInnerOverlay({
  exitOverlay,
  onBack,
  billPaymentId,
  queryOpts,
  onPayableClick,
  onReturnItem,
}: PayableInnerOverlayProps) {
  const { loading, data, error } = useQuery(GetBillPaymentDocument, {
    variables: { billPaymentId: billPaymentId },
  })
  const toaster = useToast()

  const [downloadLoading, setDownloadLoading] = useState(false)

  const [cancelBillPayment, { loading: cancelBillLoading }] = useMutation(
    CancelBillPaymentDocument,
    {
      refetchQueries: ['paymentActivities'],
      onCompleted: (data) => {
        if (data?.cancelBillPayment?.error?.message) {
          toaster({
            title: 'Error',
            description: data.cancelBillPayment.error.message,
            status: 'error',
          })
          return
        }

        toaster({
          title: 'Payment Cancelled',
          status: 'success',
        })
        exitOverlay()
      },
    },
  )
  reportErrorIfExists(data?.billPayment?.error?.message || error)

  if (loading) {
    return (
      <Column x="center" y="center" grow wGrow>
        <img src="/3-dots-fade.svg" alt="spinner" />
      </Column>
    )
  }

  const billPayment = data?.billPayment?.billPayment
  const payment = billPayment
  const payout = billPayment?.charges?.slice(-1)[0].vendorPayout

  const payoutMethod = billPayment?.vendorPayoutMethod
  const paymentMethod = billPayment?.paymentMethod

  const netPayment =
    (payment?.amountInCents || 0) - (payment?.platformFeeInCents || 0)

  const downloadOnClick = async () => {
    setDownloadLoading(true)

    fetch(`/api/pdf?fileName=billPayment&paymentId=${billPaymentId}` || '', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((e) => e.arrayBuffer())
      .then((e) => {
        const a = document.createElement('a')
        const uintArr = new Uint8Array(e)
        const blob = new Blob([uintArr], { type: 'application/pdf' })

        a.href = URL.createObjectURL(blob)

        a.download = `${moment.now().toString()}.pdf`

        a.click()

        setDownloadLoading(false)
      })
      .finally(() => {
        setDownloadLoading(false)
      })
  }

  const determineSentOnDate = () => {
    if (payoutMethod?.type === 'ACH') {
      return payout?.createdAt
        ? moment(parseInt(payout?.createdAt || '')).format('MM/DD/YYYY')
        : payout?.paidAt || null
    } else {
      return payout?.paidAt
        ? payout?.paidAt
        : payout?.createdAt
        ? moment(parseInt(payout?.createdAt || '')).format('MM/DD/YYYY')
        : null
    }
  }

  const determineVerb = () => {
    if (payoutMethod?.type === 'ACH') {
      return 'Sent'
    } else {
      return payout?.paidAt ? 'Cashed' : 'Sent'
    }
  }

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <OverlayHeaderV2
        onClose={exitOverlay}
        onBack={onBack}
        transactionStatus={
          getTransactionStatusFromActivity(
            billPayment?.activity?.status || '',
            billPayment?.activity?.flags || [],
          ).status
        }
      />
      <AmountHeader
        label={'Payment Amount'}
        amount={currency(netPayment, { fromCents: true }).format()}
      />
      <TransactionActivity
        payoutMethod={vendorPayoutOrBankAccountIntoIdentifier(
          payoutMethod || null,
          determineSentOnDate(),
          determineVerb(),
        )}
        paymentMethod={paymentMethodIntoIdentifier(
          billPayment?.paymentMethod || null,
          false,
          billPayment?.createdAt || '',
          'Initiated',
        )}
        completed={!!payout?.paidAt}
      />
      <PaymentElements
        {...{
          paymentNode: (
            <Column gap="large" wGrow className="w-full">
              <PaymentDetails
                {...{
                  headerLabel: 'Your Payment',
                  linkText:
                    billPayment?.billPayable.type === 'BILL'
                      ? 'View Bill'
                      : undefined,
                  onLinkClick: onPayableClick
                    ? () => onPayableClick(billPayment?.billPayable?.bill?.id!)
                    : undefined,
                  feesCollected: currency(
                    billPayment?.platformFeeInCents || 0,
                    { fromCents: true },
                  ).format(),
                  chargedAmount: currency(billPayment?.amountInCents || 0, {
                    fromCents: true,
                  }).format(),
                  netPayment: currency(
                    (billPayment?.amountInCents || 0) -
                      (billPayment?.platformFeeInCents || 0),
                    { fromCents: true },
                  ).format(),
                  paymentMethod: paymentMethod?.card?.last4 ? (
                    <PaymentMethod
                      card={{
                        last4: paymentMethod?.card?.last4,
                        brand: paymentMethod?.card?.brand || '',
                      }}
                    />
                  ) : (
                    <PaymentMethod
                      achDebit={{
                        routingNumber: paymentMethod?.achDebit?.routingNumber!,
                      }}
                    />
                  ),
                  contactInfo: (
                    <ContactInfo name={paymentMethod?.billingDetails?.name} />
                  ),
                }}
              />
              <BillPaymentDetails
                {...{
                  headerLabel: 'Vendor Payment',
                  onLinkClick: onPayableClick
                    ? () => onPayableClick(payment?.id!)
                    : undefined,
                  billPaymentAmount: currency(netPayment || 0, {
                    fromCents: true,
                  }).format(),
                  paymentMethod: payoutMethod?.routingNumber ? (
                    <PaymentMethod
                      achDebit={{
                        routingNumber:
                          payoutMethod?.accountNumber?.slice(-2) || '',
                      }}
                    />
                  ) : (
                    <Check checkNumber={payout?.checkNumber || ''} />
                  ),
                  vendorName: billPayment?.billPayable?.vendor?.name || '',
                  email:
                    billPayment?.billPayable?.vendor?.emails?.join(', ') || '',
                  deliveredTo: payoutMethod?.routingNumber
                    ? `${
                        payoutMethod.bankName
                      } ·· ${payoutMethod.accountNumber?.slice(-2)}`
                    : `${payoutMethod?.street}, ${payoutMethod?.city}, ${payoutMethod?.state} ${payoutMethod?.zip}`,
                }}
              />
              {billPayment?.billPayable.vendorMemo && (
                <Column className="w-full" gap="medium">
                  <Text fontWeight="semibold" color="dark.800" fontSize="xl">
                    Recipient Memo
                  </Text>
                  <Card className="w-full p-2 px-4">
                    {billPayment?.billPayable.vendorMemo || ''}
                  </Card>
                </Column>
              )}

              {billPayment?.charges?.[0]?.vendorPayout?.achVendorReturn && (
                <OverlaySectionReturn
                  achReturn={{
                    id: billPayment?.charges?.[0]?.vendorPayout?.achVendorReturn
                      .id,
                    amount: currency(
                      billPayment?.charges?.[0]?.vendorPayout?.achVendorReturn
                        .amountCents,
                      {
                        fromCents: true,
                      },
                    ).value,
                    paidAt: billPayment?.charges?.[0]?.vendorPayout
                      ?.achVendorReturn.payout?.paidAt
                      ? moment(
                          billPayment?.charges?.[0]?.vendorPayout
                            ?.achVendorReturn.payout?.paidAt,
                        ).format('MM/DD/YYYY')
                      : undefined,
                    status: billPayment?.charges?.[0]?.vendorPayout
                      ?.achVendorReturn.payout?.paidAt
                      ? 'Completed'
                      : 'Pending',
                  }}
                  onReturnClick={onReturnItem}
                />
              )}
              <ActivityNote
                {...{
                  activityId: billPayment?.activity?.id || '',
                  initialNote: billPayment?.activity?.note?.note || '',
                  sourceType: PaymentActivitySource.Refund,
                  reconciled: billPayment?.activity?.note?.reconciled || false,
                  flagged: billPayment?.activity?.note?.flagged || false,
                  queryOpts,
                }}
              />
            </Column>
          ),
          detailNode: (
            <AdditionalPaymentDetail
              {...{
                reference: {
                  billPaymentId: billPayment?.id || '',
                  reference:
                    billPayment?.billPayable.bill?.billData?.reference || '',
                },
              }}
            />
          ),
        }}
      />
      <Box mt="auto">
        <OverlayFooter
          buttonText="Cancel Payment"
          disabled={downloadLoading}
          buttonLoading={cancelBillLoading}
          options={[
            {
              icon: <ArrowDownIcon />,
              onClick: downloadOnClick,
              children: 'Download Receipt',
              isDisabled: downloadLoading,
            },
          ]}
          onButtonClick={
            billPayment?.activity?.status === 'PENDING'
              ? () => cancelBillPayment({ variables: { billPaymentId } })
              : undefined
          }
        />
      </Box>
    </Flex>
  )
}

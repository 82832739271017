import { Card, CardBody, Spacer, Text } from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import moment from 'moment'
import { capitalize, lowerCase } from 'lodash'
import { HFlex, Row, StatusPill, VFlex } from 'ui'

type BillCardProps = {
  reference?: string
  dueDate?: string | number
  status?: string
  amount?: number
  amountInCents?: boolean
  id: string
  onBillClick?: (billId: string) => void
}

export const BillCard = ({
  reference,
  dueDate,
  status,
  amount = 0,
  amountInCents = false,
  id,
  onBillClick,
}: BillCardProps) => {
  return (
    <Card w="100%" borderColor="gray.300">
      <CardBody w="100%" p="14px">
        <HFlex w="100%">
          <HFlex w="60%">
            <VFlex alignItems="start" gap="1">
              <Text fontSize="xs" color="gray.500">
                Bill #
              </Text>
              <Text
                className="text-md text-gray-900 text-semibold"
                noOfLines={1}
              >
                {reference || 'N/A'}
              </Text>
            </VFlex>
            <Spacer />
            <VFlex gap="1">
              <Text fontSize="xs" color="gray.500">
                Due Date
              </Text>
              <Text className="text-md text-gray-900 text-semibold">
                {dueDate ? moment(dueDate).format('MM/DD/YYYY') : 'N/A'}
              </Text>
            </VFlex>
            <Spacer />
            <VFlex gap="1">
              <Text fontSize="xs" color="gray.500">
                Status
              </Text>
              <StatusPill
                status={
                  status === 'ACTIVE'
                    ? 'info'
                    : status === 'SCHEDULED'
                    ? 'action'
                    : status === 'Paid'
                    ? 'success'
                    : 'error'
                }
                spacing="xsmallNarrow"
              >
                {capitalize(lowerCase(status || ''))}
              </StatusPill>
            </VFlex>
          </HFlex>
          <Spacer />
          <VFlex>
            <Text fontSize="xs" color="gray.500">
              Amount
            </Text>
            <Row
              gap="small"
              y="center"
              className="cursor-pointer"
              onClick={() => onBillClick?.(id)}
            >
              <Text fontSize="sm" whiteSpace="nowrap" fontWeight="medium">
                {currency(amount, { fromCents: amountInCents }).format()}
              </Text>
              <ArrowRightIcon className="h-4 w-4 text-gray-800" />
            </Row>
          </VFlex>
        </HFlex>
      </CardBody>
    </Card>
  )
}

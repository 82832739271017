import {
  Box,
  Card,
  CardBody,
  Divider,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ArrowRightIcon,
  BuildingStorefrontIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import { DataList } from '../Layout/DataList'
import { HFlex } from '../v3/FlexUtils'

type VendorPayoutMethod = {
  type?: string | null
  bankName?: string | null
  accountNumber?: string | null
  accountType?: string | null
  street?: string | null
  street2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

type VendorCardProps = {
  vendorName: string
  vendorId: string
  vendorPayoutMethod?: VendorPayoutMethod
  vendorEmail: string
  onVendorClick?: () => void
}

export function SlimVendorCard(props: VendorCardProps) {
  return (
    <Card w="100%" borderColor="gray.300">
      <CardBody py="2" px="3">
        <HFlex alignItems="center" justifyContent="space-between">
          <HFlex alignItems="center">
            <Box p={2} borderRadius="full" bgColor="gray.100">
              {props.vendorPayoutMethod ? (
                <BuildingStorefrontIcon className="w-4 h-4" />
              ) : (
                <ExclamationCircleIcon className="w-4 h-4" />
              )}
            </Box>
            <VStack alignItems="start" gap="0">
              <Text ps="2" fontSize="14px">
                {props.vendorName}
              </Text>
              <Text ps="2" fontSize="13px" color="gray.500">
                {props.vendorPayoutMethod ? 'Ready to pay' : 'Missing details'}
              </Text>
            </VStack>
          </HFlex>
          <Link
            onClick={props.onVendorClick}
            className="hover:text-black flex items-center gap-1 cursor-pointer"
            color="black.600"
            fontSize="13px"
            fontWeight="medium"
          >
            See vendor
            <ArrowRightIcon className="h-4 w-4 text-black" />
          </Link>
        </HFlex>
      </CardBody>
    </Card>
  )
}

export function VendorCard(props: VendorCardProps) {
  return (
    <Card w="100%" borderColor="gray.300">
      <CardBody>
        <HFlex alignItems="center" justifyContent="space-between">
          <HFlex alignItems="center">
            <BuildingStorefrontIcon className="w-4 h-4" />
            <Text ps="2" fontSize="14px">
              {props.vendorName}
            </Text>
          </HFlex>
          <Link
            href={`/dashboard/accounts-payable/vendors/${props.vendorId}`}
            className="hover:text-purple-800 flex items-center gap-1 cursor-pointer"
            color="purple.600"
            fontSize="14px"
          >
            Go to Vendor
            <ArrowRightIcon className="h-4 w-4 text-purple-600" />
          </Link>
        </HFlex>
        <Divider pt="4" color="gray.200" />
        <DataList
          className="pt-4"
          items={[
            {
              label: 'Payment Delivery Method',
              value: props.vendorPayoutMethod
                ? props.vendorPayoutMethod?.type === 'ACH'
                  ? 'Direct Deposit via ACH'
                  : 'Mailed Physical Check'
                : 'Missing details',
            },
            props.vendorPayoutMethod
              ? {
                  label: 'Send payments to',
                  value:
                    props.vendorPayoutMethod?.type === 'ACH'
                      ? `${
                          props.vendorPayoutMethod.bankName
                        }  ••${props.vendorPayoutMethod?.accountNumber?.slice(
                          -4,
                        )}`
                      : `${props.vendorPayoutMethod?.street || ''}, ${
                          props.vendorPayoutMethod?.city || ''
                        } ${props.vendorPayoutMethod?.state || ''}, ${
                          props.vendorPayoutMethod?.zip || ''
                        }`,
                }
              : null,
            {
              label: 'Primary Contact',
              value: props.vendorEmail,
            },
          ].filter((item) => !!item)}
        />
      </CardBody>
    </Card>
  )
}

import { Box, Fade, Flex, HStack, Text } from '@chakra-ui/react'
import {
  RegistrationHeader,
  RegistrationHeaderProps,
} from './RegistrationHeader'

type RegistrationLayoutProps = {
  title: string
  titleIcon?: React.ReactNode
  titleId?: string
  subtitle: string
  children: React.ReactNode
} & RegistrationHeaderProps

export function RegistrationLayout({
  title,
  subtitle,
  children,
  currentPage,
  titleIcon,
  titleId,
}: RegistrationLayoutProps) {
  return (
    <Box>
      <RegistrationHeader currentPage={currentPage} />
      <Fade
        key={currentPage}
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          my={10}
        >
          <Flex flexDirection="column" gap={8} px={4}>
            <Flex width={['340px', '540px']} flexDirection="column" gap={2}>
              <HStack>
                <Text fontSize="2xl" fontWeight="semibold" id={titleId}>
                  {title}
                </Text>
                {titleIcon && <>{titleIcon}</>}
              </HStack>
              <Text color="gray.500" fontSize="sm">
                {subtitle}
              </Text>
            </Flex>
            {children}
          </Flex>
        </Flex>
      </Fade>
    </Box>
  )
}

import {
  Card,
  CardBody,
  HStack,
  VStack,
  Icon,
  Box,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Button } from 'ui'
import { IconMap, IconName } from '../v3/Icons'

interface InfoCardProps {
  icon: IconName
  title: string
  subtitle?: string | React.ReactNode
  buttonLabel?: string
  onButtonClick?: () => void
  onClick?: () => void
  iconBgColor?: string
  iconColor?: string
  selected?: boolean
}

export function InfoCard({
  icon,
  title,
  subtitle,
  buttonLabel = 'Edit',
  onButtonClick,
  iconBgColor = 'gray.100',
  iconColor = 'gray.600',
  onClick,
  selected = false,
}: InfoCardProps) {
  return (
    <Card
      bgColor="white"
      borderRadius="lg"
      borderWidth="1px"
      borderColor={selected ? 'gray.800' : 'gray.200'}
      w="100%"
      cursor="pointer"
      _hover={{
        bgColor: 'gray.100',
      }}
      onClick={onClick}
    >
      <CardBody px={2} py={3}>
        <HStack justify="space-between" alignItems="center">
          <HStack spacing={4}>
            <Box
              bgColor={iconBgColor}
              borderRadius="full"
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={IconMap[icon]} w={6} h={6} color={iconColor} />
            </Box>
            <VStack alignItems="flex-start" spacing={0}>
              <Tooltip label={title}>
                <Text fontSize="md" fontWeight="medium" noOfLines={1}>
                  {title}
                </Text>
              </Tooltip>
              {subtitle && (
                <Text color="gray.600" fontSize="xs">
                  {subtitle}
                </Text>
              )}
            </VStack>
          </HStack>
          {onButtonClick && (
            <Button
              variant="ghost"
              size="xs"
              onClick={onButtonClick}
              label={buttonLabel}
            />
          )}
        </HStack>
      </CardBody>
    </Card>
  )
}

export default InfoCard

import {
  ACHForm,
  AddressInput,
  BaseVendorPayoutDetailsValidationSchema,
  Button,
  Form,
  US_STATES,
  VFlex,
  BillOverlayHeader,
  Breadcrumb,
} from 'ui'
import { Box, Divider, Fade, HStack, Text, useToast } from '@chakra-ui/react'
import {
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  UpdateVendorCheckPayoutMethodDocument,
} from '../../../operations-types'
import { VendorDocument } from '../../../operations-types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { UpdateVendorPayoutMethodDocument } from '../../../operations-types'

type AddDeliveryMethodOverlayProps = {
  onClose: () => void
  onBack: () => void
  vendorId: string
  type: 'ACH' | 'Check'
  onSave: () => void
  breadcrumbs?: Breadcrumb[]
}

export function AddDeliveryMethodOverlay({
  onClose,
  vendorId,
  type,
  onSave,
  onBack,
  breadcrumbs,
}: AddDeliveryMethodOverlayProps) {
  const toast = useToast()
  const [
    updateVendorPayoutMethod,
    { loading: updateVendorPayoutMethodLoading },
  ] = useMutation(UpdateVendorPayoutMethodDocument, {
    onCompleted: (data) => {
      if (data?.updateVendorPayoutMethod?.error?.message) {
        toast({
          status: 'error',
          title: 'Error',
          description: data?.updateVendorPayoutMethod?.error?.message,
        })
        return
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Delivery method updated successfully',
      })
      onSave()
    },
    refetchQueries: [VendorDocument],
  })

  const [
    updateVendorCheckPayoutMethod,
    { loading: updateVendorCheckPayoutMethodLoading },
  ] = useMutation(UpdateVendorCheckPayoutMethodDocument, {
    onCompleted: (data) => {
      if (data?.updateVendorCheckPayoutMethod?.error?.message) {
        toast({
          status: 'error',
          title: 'Error',
          description: data?.updateVendorCheckPayoutMethod?.error?.message,
        })
        return
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Delivery method updated successfully',
      })
      onSave()
    },
    refetchQueries: [VendorDocument],
  })
  const { data: vendor } = useQuery(VendorDocument, {
    variables: {
      vendorId,
    },
  })

  const handleSubmit = async (values: any) => {
    try {
      if (values.paymentVia === 'ACH') {
        await updateVendorPayoutMethod({
          variables: {
            vendorId,
            accountNumber: values.achDetails.accountNumber,
            routingNumber: values.achDetails.routingNumber,
            accountType: values.achDetails.accountType,
          },
        })
      } else if (values.paymentVia === 'check') {
        await updateVendorCheckPayoutMethod({
          variables: {
            vendorId,
            street: values.achDetails.recipientAddress.streetAddress,
            city: values.achDetails.recipientAddress.city,
            state: values.achDetails.recipientAddress.state,
            zip: values.achDetails.recipientAddress.zipCode,
          },
        })
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to update payment method',
      })
      throw error // Re-throw to handle in parent component if needed
    }
  }

  const vendorEmail = vendor?.vendor?.vendor?.emails?.join(', ')
  const vendorAchPayoutMethod =
    vendor?.vendor?.vendor?.vendorPayoutMethods?.find(
      (method) => method?.type === 'ACH',
    )
  const vendorCheckPayoutMethod =
    vendor?.vendor?.vendor?.vendorPayoutMethods?.find(
      (method) => method?.type === 'CHECK',
    )

  const [getBankName, { loading: getBankNameLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)

  return (
    <>
      <Form
        className="w-full h-full"
        validationSchema={BaseVendorPayoutDetailsValidationSchema}
        initialValues={{
          paymentVia: type === 'ACH' ? 'ACH' : 'check',
          email: vendorEmail || '',
          achDetails: {
            accountNumber: vendorAchPayoutMethod?.accountNumber || '',
            routingNumber: vendorAchPayoutMethod?.routingNumber || '',
            bankName: vendorAchPayoutMethod?.bankName || '',
            confirmedAccountNumber: '',
            accountType: vendorAchPayoutMethod?.accountType || 'checking',
            recipientAddress: {
              streetAddress: vendorCheckPayoutMethod?.street || '',
              city: vendorCheckPayoutMethod?.city || '',
              state:
                US_STATES.states.find(
                  (x) =>
                    x.abbreviation === vendorCheckPayoutMethod?.state ||
                    x.name === vendorCheckPayoutMethod?.state,
                )?.name || '',
              zipCode: vendorCheckPayoutMethod?.zip || '',
            },
          },
        }}
        onSubmit={async (values) => {
          await handleSubmit(values)
        }}
      >
        {(formik) => {
          return (
            <VFlex h="100%">
              <BillOverlayHeader
                onClose={onClose}
                title={`Add ${
                  type === 'ACH' ? 'an ACH' : 'a Check'
                } Delivery Method`}
                breadcrumbs={breadcrumbs}
                subtitle={`Add ${
                  type === 'ACH' ? 'an ACH' : 'a check'
                } delivery method to your vendor ${
                  vendor?.vendor?.vendor?.name || ''
                }`}
                type="bills"
              />
              <Divider />

              <Fade
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ width: '100%', height: '100%' }}
              >
                <VFlex px={8} py={8} w="100%" gap={3}>
                  {type === 'ACH' && (
                    <ACHForm
                      isDemo={false}
                      loading={getBankNameLoading}
                      routingNumber={
                        formik.values.achDetails?.routingNumber || ''
                      }
                      accountNumber={
                        formik.values.achDetails?.accountNumber || ''
                      }
                      confirmedAccountNumber={
                        formik.values.achDetails?.confirmedAccountNumber || ''
                      }
                      bankName={formik.values.achDetails?.bankName || ''}
                      accountType={formik.values.achDetails?.accountType || ''}
                      accountSelectInputName="achDetails.accountType"
                      onChangeRoutingNumber={async (number) => {
                        formik.setFieldValue('achDetails.routingNumber', number)
                        formik.setFieldValue('achDetails.bankName', '')
                        if (number.length === 9) {
                          const response = await getBankName({
                            variables: { bankRoutingNumber: number },
                          })
                          if (
                            response.data?.bankRoutingNumber?.bankRoutingNumber
                              ?.bankName
                          ) {
                            formik.setFieldValue(
                              'achDetails.bankName',
                              response.data.bankRoutingNumber.bankRoutingNumber
                                .bankName,
                            )
                          }
                        }
                      }}
                      onChangeAccountNumber={(number) => {
                        formik.setFieldValue('achDetails.accountNumber', number)
                      }}
                      onChangeConfirmAccountNumber={(number) => {
                        formik.setFieldValue(
                          'achDetails.confirmedAccountNumber',
                          number,
                        )
                      }}
                      onChangeAccountType={(type) => {
                        formik.setFieldValue('achDetails.accountType', type)
                      }}
                    />
                  )}
                  {type === 'Check' && (
                    <>
                      <Text fontSize="md" fontWeight="semibold">
                        Delivery Address
                      </Text>
                      <AddressInput
                        valueKey="achDetails.recipientAddress"
                        apiKey={import.meta.env.VITE_SMARTY_STREETS_KEY}
                      />
                    </>
                  )}
                </VFlex>
              </Fade>
              <Box mt="auto">
                <Divider />
                <HStack w="100%" px={10} py={6} justifyContent="space-between">
                  <Button label="Back" variant="outline" onClick={onBack} />
                  <HStack>
                    <Button
                      label="Save"
                      isLoading={
                        updateVendorPayoutMethodLoading ||
                        updateVendorCheckPayoutMethodLoading
                      }
                      isDisabled={!formik.isValid}
                      onClick={() => formik.submitForm()}
                    />
                  </HStack>
                </HStack>
              </Box>
            </VFlex>
          )
        }}
      </Form>
    </>
  )
}

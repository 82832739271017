import { create } from 'zustand'

type AddBillStoreData = {
  vendorId: string | null
}

type AddBillStoreFunctions = {
  setVendorId: (vendorId: string | null) => void
}

type AddBillStore = AddBillStoreData & AddBillStoreFunctions

const initialState: AddBillStoreData = {
  vendorId: null,
}

export const useAddBillStore = create<AddBillStore>((set) => ({
  ...initialState,
  setVendorId: (vendorId: string | null) => set({ vendorId }),
}))

import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from '@chakra-ui/react'
import { FormikProps } from 'formik'
import {
  ACHForm,
  AddressInput,
  BaseVendorPayoutDetailsValidationSchema,
  Button,
  Form,
  VendorPayoutDetails,
  VFlex,
} from 'ui'
import {
  BillsByIdDocument,
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  UpdateVendorCheckPayoutMethodDocument,
  UpdateVendorPayoutMethodDocument,
  VendorDocument,
  VendorPayoutMethod,
} from '../../operations-types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

type NewDeliveryMethodModalProps = {
  isOpen: boolean
  setModalOpen: (open: boolean) => void
  type: 'ACH' | 'Check'
  vendorId: string
  onSave: (deliveryMethod: VendorPayoutMethod) => void
}

type ACHDeliveryMethodFormProps = {
  formik: FormikProps<VendorPayoutDetails>
}

function ACHDeliveryMethodForm(props: ACHDeliveryMethodFormProps) {
  const { formik } = props

  const [getBankName, { loading: getBankNameLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)

  return (
    <VFlex w="100%" gap={3}>
      <ACHForm
        isDemo={false}
        loading={getBankNameLoading}
        routingNumber={formik.values.achDetails?.routingNumber || ''}
        accountNumber={formik.values.achDetails?.accountNumber || ''}
        confirmedAccountNumber={
          formik.values.achDetails?.confirmedAccountNumber || ''
        }
        bankName={formik.values.achDetails?.bankName || ''}
        accountType={formik.values.achDetails?.accountType || ''}
        accountSelectInputName="achDetails.accountType"
        onChangeRoutingNumber={async (number) => {
          formik.setFieldValue('achDetails.routingNumber', number)
          formik.setFieldValue('achDetails.bankName', '')
          if (number.length === 9) {
            const response = await getBankName({
              variables: { bankRoutingNumber: number },
            })
            if (response.data?.bankRoutingNumber?.bankRoutingNumber?.bankName) {
              formik.setFieldValue(
                'achDetails.bankName',
                response.data.bankRoutingNumber.bankRoutingNumber.bankName,
              )
            }
          }
        }}
        onChangeAccountNumber={(number) => {
          formik.setFieldValue('achDetails.accountNumber', number)
        }}
        onChangeConfirmAccountNumber={(number) => {
          formik.setFieldValue('achDetails.confirmedAccountNumber', number)
        }}
        onChangeAccountType={(type) => {
          formik.setFieldValue('achDetails.accountType', type)
        }}
      />
    </VFlex>
  )
}

export function NewDeliveryMethodModal(props: NewDeliveryMethodModalProps) {
  const { isOpen, setModalOpen, type, vendorId, onSave } = props

  const toast = useToast()
  const [
    updateVendorPayoutMethod,
    { loading: updateVendorPayoutMethodLoading },
  ] = useMutation(UpdateVendorPayoutMethodDocument, {
    onCompleted: (data) => {
      if (data?.updateVendorPayoutMethod?.error?.message) {
        toast({
          status: 'error',
          title: 'Error',
          description: data?.updateVendorPayoutMethod?.error?.message,
        })
        return
      }

      const deliveryMethod =
        data?.updateVendorPayoutMethod?.vendor?.vendorPayoutMethods?.[0]

      if (!deliveryMethod) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'No delivery method found',
        })
        return
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Delivery method updated successfully',
      })
      onSave(deliveryMethod)
    },
    refetchQueries: [VendorDocument, BillsByIdDocument],
  })

  const [
    updateVendorCheckPayoutMethod,
    { loading: updateVendorCheckPayoutMethodLoading },
  ] = useMutation(UpdateVendorCheckPayoutMethodDocument, {
    onCompleted: (data) => {
      if (data?.updateVendorCheckPayoutMethod?.error?.message) {
        toast({
          status: 'error',
          title: 'Error',
          description: data?.updateVendorCheckPayoutMethod?.error?.message,
        })
        return
      }

      const deliveryMethod =
        data?.updateVendorCheckPayoutMethod?.vendor?.vendorPayoutMethods?.[0]

      if (!deliveryMethod) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'No delivery method found',
        })
        return
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Delivery method updated successfully',
      })
      onSave(deliveryMethod)

      toast({
        status: 'success',
        title: 'Success',
        description: 'Delivery method updated successfully',
      })
      onSave(deliveryMethod)
    },
    refetchQueries: [VendorDocument, BillsByIdDocument],
  })

  const handleSubmit = async (values: any) => {
    try {
      if (values.paymentVia === 'ACH') {
        await updateVendorPayoutMethod({
          variables: {
            vendorId,
            accountNumber: values.achDetails.accountNumber,
            routingNumber: values.achDetails.routingNumber,
            accountType: values.achDetails.accountType,
          },
        })
      } else if (values.paymentVia === 'check') {
        await updateVendorCheckPayoutMethod({
          variables: {
            vendorId,
            street: values.achDetails.recipientAddress.streetAddress,
            city: values.achDetails.recipientAddress.city,
            state: values.achDetails.recipientAddress.state,
            zip: values.achDetails.recipientAddress.zipCode,
          },
        })
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to update payment method',
      })
      throw error // Re-throw to handle in parent component if needed
    }
  }

  const { data: vendor } = useQuery(VendorDocument, {
    variables: {
      vendorId,
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={() => setModalOpen(false)} size="xl">
      <ModalOverlay />
      <ModalContent w="100%">
        <Box>
          <ModalCloseButton />
          <ModalHeader>
            Pay {vendor?.vendor?.vendor?.name || 'your vendor'} via {type}
          </ModalHeader>
          <Form<VendorPayoutDetails>
            className="w-full h-full"
            validationSchema={BaseVendorPayoutDetailsValidationSchema}
            initialValues={
              {
                paymentVia: type === 'ACH' ? 'ACH' : 'check',
                email: '',
                achDetails: {},
              } as VendorPayoutDetails
            }
            onSubmit={async (values) => {
              await handleSubmit(values)
            }}
          >
            {(formik) => {
              return (
                <VFlex h="100%" px={6} py={2} gap={3} className="portal-target">
                  {type === 'ACH' && <ACHDeliveryMethodForm formik={formik} />}

                  {type === 'Check' && (
                    <>
                      <Text fontSize="md" fontWeight="semibold">
                        Delivery Address
                      </Text>
                      <AddressInput
                        valueKey="achDetails.recipientAddress"
                        apiKey={import.meta.env.VITE_SMARTY_STREETS_KEY}
                        popupContainerTarget=".portal-target"
                      />
                    </>
                  )}
                  <Flex w="100%" justify="flex-end" gap={3}>
                    <Button
                      label="Cancel"
                      variant="ghost"
                      onClick={() => setModalOpen(false)}
                    />
                    <Button
                      label="Save"
                      isLoading={
                        updateVendorPayoutMethodLoading ||
                        updateVendorCheckPayoutMethodLoading
                      }
                      isDisabled={!formik.isValid}
                      onClick={() => formik.submitForm()}
                    />
                  </Flex>
                </VFlex>
              )
            }}
          </Form>
        </Box>
      </ModalContent>
    </Modal>
  )
}

import { Box, Text } from '@chakra-ui/react'
import { HFlex, VFlex } from 'ui'
import { VendorEmails } from '../../bills/components/VendorEmails'

type VendorBillingContactProps = {
  emails: string[]
  onClick: (emails: string[]) => void
}

export default function VendorBillingContact({
  emails,
  onClick,
}: VendorBillingContactProps) {
  return (
    <VFlex w="100%" gap="6" px={8} py={8}>
      <Box>
        <Text fontSize="xl" fontWeight="semibold">
          Vendor Contacts
        </Text>
        <Text fontSize="sm" color="gray.500">
          Add at least one contact to send payment notifications to
        </Text>
      </Box>
      <HFlex alignItems="start" gap="4" w="100%">
        <VendorEmails
          vendorEmails={emails}
          setVendorEmails={(emails: string[]) => {
            onClick(emails)
          }}
        />
      </HFlex>
    </VFlex>
  )
}

import { Button, HFlex, VFlex } from 'ui'
import { useBillStore } from '../../stores/billStore'
import { Success } from '../components/ReviewBillOverlay'
import { Box, Divider } from '@chakra-ui/react'

export function RecurringBillSuccess({
  setOpen,
}: {
  setOpen: (open: boolean) => void
}) {
  const { reset, setRecurringPage } = useBillStore((state) => ({
    reset: state.reset,
    setRecurringPage: state.setRecurringPage,
  }))

  return (
    <VFlex flex={1} w="100%" gap={4}>
      <Success scheduled={true} />
      <Box mt="auto" w="100%">
        <Divider />
        <HFlex justifyContent="flex-end" m="4" gap={4}>
          <Button
            label="View Recurring Bills"
            variant="outline"
            onClick={() => {
              reset()
              setRecurringPage('overview')
            }}
          />
          <Button
            label="Return to Dashboard"
            onClick={() => {
              setOpen(false)
              reset()
            }}
          />
        </HFlex>
      </Box>
    </VFlex>
  )
}

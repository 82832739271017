import {
  HStack,
  Link,
  Text,
  VStack,
  Box,
  Input,
  useToast,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom'
import { Button, Column, Header, Overlay, Row, LARGE_CELL_PX } from 'ui'
import {
  ManualSyncQboDocument,
  QuestionFormSortOrder,
  QuestionFormsQueryVariables,
  QuestionFormStatus,
  User,
} from '../../operations-types'
import { PageLayout } from '../layout/PageLayout'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import PaymentLinkSuccessOverlay from './PaymentLinkSuccessOverlay'
import CreatePaymentLinkOverlay from './CreatePaymentLinkOverlay'
import PaymentLinkTable from './PaymentLinkTable'
import QueryString from 'qs'
import { fetchPortalURL } from '../../lib/urls'
import NickelPay from '../settings/nickelpay/NickelPay'
import AddFilter from 'ui/src/components/Transactions/AddFilter'
import { omit } from 'lodash'
import { PaymentLinksFilterSchema } from 'ui/src/components/PaymentLinks/PaymentLinksFilter'
import currency from 'currency.js'
import { useMutation } from '@apollo/client'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { useCodatLinkedConnection } from '../utils/CodatUtils'

function PaymentLinks() {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const availableSpace = window.screen.availHeight - 500
  const [pageSize, setPageSize] = useState(
    Math.ceil(availableSpace / LARGE_CELL_PX),
  )
  const [pageValue, setPageValue] = useState(pageSize.toString())

  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState(QuestionFormSortOrder.CreatedDateDesc)

  const [queryOpts, setQueryOpts] = useState<
    NonNullable<QuestionFormsQueryVariables>
  >({
    page: page,
    pageSize: pageSize,
    status:
      (searchParams.get('tab') as QuestionFormStatus) ||
      ('ACTIVE' as QuestionFormStatus),
    sortOrder: sortBy,
  })

  const onPageChange = async (page: number) => {
    await setPage(page)
    await setQueryOpts({
      ...queryOpts,
      page: page,
    })
  }

  const [success, setSuccess] = useState(false)
  const [open, setOpen] = useState(false)
  const focusRef = useRef(null)

  const { organization } = useOutletContext() as User

  const [selectedTab, setSelectedTab] = useState<
    QuestionFormStatus | 'SETTINGS'
  >(QuestionFormStatus.Active)

  const [newPaymentLinkId, setNewPaymentLinkId] = useState('')

  const onClose = (
    label: string,
    value: string | Array<string>,
    key: string,
  ) => {
    setQueryOpts(omit(queryOpts, key) as unknown as QuestionFormsQueryVariables)
  }

  const onCustomFilter = (values?: PaymentLinksFilterSchema) => {
    setQueryOpts(Object.assign({}, queryOpts, values, { page: 1 }))
    setPage(1)
  }

  const currentFilters = [
    queryOpts.name
      ? {
          label: 'Name',
          value: queryOpts.name,
          key: 'name',
        }
      : null,
    queryOpts.minCreatedDate
      ? {
          label: 'Created At Start Date',
          value: queryOpts.minCreatedDate,
          key: 'minCreatedDate',
        }
      : null,
    queryOpts.maxCreatedDate
      ? {
          label: 'Created At End Date',
          value: queryOpts.maxCreatedDate,
          key: 'maxCreatedDate',
        }
      : null,
    queryOpts.minRequestedAmount
      ? {
          label: 'Minimum Requested Amount',
          value: currency(queryOpts.minRequestedAmount, {
            fromCents: true,
          }).format(),
          key: 'minRequestedAmount',
        }
      : null,
    queryOpts.maxRequestedAmount
      ? {
          label: 'Maximum Requested Amount',
          value: currency(queryOpts.minRequestedAmount || 0, {
            fromCents: true,
          }).format(),
          key: 'maxRequestedAmount',
        }
      : null,
  ].filter((e) => e !== null)

  const [manualSyncQboLoading, setManualSyncQboLoading] = useState(false)

  const [manualSyncQbo] = useMutation(ManualSyncQboDocument)

  const toast = useToast()

  const loggedInUser = useLoggedInStore((state) => state.user)
  const { hasLinkedConnection } = useCodatLinkedConnection(
    loggedInUser.organization.accountInfo ?? null,
  )

  return (
    <PageLayout>
      <Column
        className="w-full bg-white rounded-md min-h-[calc(100vh-2rem)]"
        grow
        wGrow
      >
        <Row className="w-full" between>
          <VStack className="p-4" spacing="0" gap="0" alignItems="left">
            <Header variant="page" className="text-[20px]">
              Get Paid
            </Header>
            <HStack gap={2} alignItems={'center'}>
              <Text color="gray.500" className="text-sm">
                Start accepting payments by creating a payment link or using
                your dedicated{' '}
                <Link
                  color="purple.500"
                  isExternal
                  href={fetchPortalURL(
                    organization?.accountInfo?.subdomain || 'nickel',
                  )}
                >
                  Payments Portal
                </Link>
              </Text>
              <ExternalLinkIcon
                cursor="pointer"
                onClick={() =>
                  window.open(
                    fetchPortalURL(
                      organization?.accountInfo?.subdomain || 'nickel',
                    ),
                  )
                }
                boxSize={3.5}
                color="purple.500"
              />
            </HStack>
          </VStack>
          <HStack spacing="4" className="p-4">
            <Button
              label="Request Payment"
              iconName="plusIcon"
              className="!text-sm !h-[30px]"
              iconPosition="left"
              onClick={() => {
                setSuccess(false)
                setOpen(true)
              }}
            />
          </HStack>
        </Row>
        <div className="w-full">
          <Overlay open={open} setOpen={setOpen} focusRef={focusRef}>
            {success ? (
              <PaymentLinkSuccessOverlay
                id={newPaymentLinkId}
                organizationSubdomain={organization?.accountInfo?.subdomain}
                onClose={() => {
                  setOpen(false)
                  setSuccess(false)
                }}
              />
            ) : (
              <CreatePaymentLinkOverlay
                setSuccess={setSuccess}
                onClose={() => setOpen(false)}
                setNewPaymentLinkId={setNewPaymentLinkId}
                focusRef={focusRef}
              />
            )}
          </Overlay>
          <TransactionTabsV2
            hasLinkedConnection={hasLinkedConnection}
            buttonLoading={manualSyncQboLoading}
            onQbSync={async () => {
              setManualSyncQboLoading(true)
              const response = await manualSyncQbo()
              if (response.data?.manualSyncQbo?.error?.message) {
                toast({
                  title: 'Error',
                  description: response.data.manualSyncQbo.error.message,
                  status: 'error',
                })
              } else {
                await new Promise((resolve) => setTimeout(resolve, 1000))
                toast({
                  title: 'Success',
                  description: 'Sync initiated successfully',
                  status: 'success',
                })
              }
              setManualSyncQboLoading(false)
            }}
            tabs={[
              { label: 'Active', value: 'ACTIVE' },
              { label: 'Completed', value: 'COMPLETED' },
              { label: 'Archived', value: 'ARCHIVED' },
              { label: 'Settings', value: 'SETTINGS' },
            ]}
            selected={selectedTab}
            onSelect={(tab) => {
              setSearchParams({ tab })
              setQueryOpts({
                ...queryOpts,
                ...(tab === 'COMPLETED'
                  ? { sortOrder: QuestionFormSortOrder.CompletedAtDesc }
                  : { sortOrder: QuestionFormSortOrder.CreatedDateDesc }),
                status: tab as QuestionFormStatus,
              })

              if (tab === 'COMPLETED') {
                setSortBy(QuestionFormSortOrder.CompletedAtDesc)
              } else {
                setSortBy(QuestionFormSortOrder.CreatedDateDesc)
              }

              setSelectedTab(tab as QuestionFormStatus)
              navigate(
                `/dashboard/get-paid?${QueryString.stringify({
                  type: tab,
                })}`,
              )
            }}
          />
          <Box w="100%" pt="4">
            {selectedTab !== 'SETTINGS' && (
              <>
                <Row grow className="p-2 pt-0 justify-between items-center">
                  <AddFilter
                    {...{
                      currentFilters: currentFilters,
                      onFormClick: onCustomFilter,
                      onClose: onClose,
                      formValues: queryOpts,
                      type: 'paymentLinks',
                      funnel: true,
                    }}
                  />
                  <HStack>
                    <Text color="gray.600" fontSize="xs">
                      Rows Per Page
                    </Text>
                    <Input
                      htmlSize={2}
                      width="auto"
                      size="xs"
                      value={pageValue}
                      onChange={(e) => {
                        if (isNaN(parseInt(e.target.value))) {
                          setPageValue('')
                          return
                        }
                        setPageValue(e.target.value)
                        setPageSize(parseInt(e.target.value))
                        setQueryOpts({
                          ...queryOpts,
                          pageSize: parseInt(e.target.value),
                        })
                      }}
                    />
                  </HStack>
                </Row>
                <PaymentLinkTable
                  queryOpts={queryOpts}
                  onPageChange={onPageChange}
                  pageSize={pageSize}
                  onSortBy={(sortOrder) => {
                    setQueryOpts({
                      ...queryOpts,
                      sortOrder: sortOrder,
                    })
                    setSortBy(sortOrder)
                  }}
                />
              </>
            )}
            {selectedTab === 'SETTINGS' && <NickelPay />}
          </Box>
        </div>
      </Column>
    </PageLayout>
  )
}

export default PaymentLinks

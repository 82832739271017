import { Box } from '@chakra-ui/react'
import { tw } from '@nickeltech/brise'
import React from 'react'
import { Button, Column, Row } from 'ui'
import ContainerProps from 'ui/src/types'

export type Tab = {
  label: string
  value: string
}

type TransactionTabsProps = {
  tabs: Array<Tab>
  selected?: string
  onSelect?: (tab: string) => void
  hasLinkedConnection?: boolean
  onQbSync?: () => void
  buttonLoading?: boolean
}

const TabComponent = tw.div<{ active: boolean } & ContainerProps>`
  text-sm
  text-gray-400
  font-medium
  cursor-pointer
  border-b-2
  whitespace-nowrap
  flex
  pb-[10px]

  ${({ active }) => (active ? `!text-gray-800 !border-b-gray-800` : '')}
`

const Filler = tw.div<ContainerProps>`
  h-[32px]
  border-b-2
  border-gray-200
  w-[40px]
`

const TransactionTabs = (props: TransactionTabsProps) => {
  return (
    <Row grow>
      <Column gap="small">
        <Row grow>
          {props.tabs.map((tab, index) => (
            <React.Fragment key={index}>
              <TabComponent
                key={tab.value}
                active={props.selected === tab.value}
                onClick={() => props.onSelect && props.onSelect(tab.value)}
              >
                {tab.label}
              </TabComponent>
              <Filler />
            </React.Fragment>
          ))}
        </Row>
      </Column>
      <Filler className="!w-full" />
    </Row>
  )
}

export const TransactionTabsV2 = (props: TransactionTabsProps) => {
  return (
    <Row grow>
      <Column gap="small">
        <Row grow y="bottom">
          <div className="h-[2px] !w-[20px] !bg-gray-200" />
          {props.tabs.map((tab, index) => (
            <React.Fragment key={index}>
              <TabComponent
                key={tab.value}
                active={props.selected === tab.value}
                onClick={() => props.onSelect && props.onSelect(tab.value)}
              >
                {tab.label}
              </TabComponent>
              <Filler />
            </React.Fragment>
          ))}
        </Row>
      </Column>
      <Filler className="!w-full" />
      {props.hasLinkedConnection && (
        <Box
          pe="10px"
          h="32px"
          borderStyle="solid"
          borderBottom="2px solid"
          borderBottomColor="gray.200"
        >
          <Button
            label="Sync"
            onClick={() => props.onQbSync && props.onQbSync()}
            isLoading={props.buttonLoading}
            pb="10px"
            size="sm"
            variant="ghost"
            iconName="quickbooks"
            iconPosition="left"
            borderTop="none"
            borderLeft="none"
            borderRight="none"
            _active={{
              borderBottomColor: 'gray.800',
              borderBottom: '2px solid',
              borderBottomRadius: '0px',
            }}
            _hover={{
              borderBottomColor: 'gray.800',
              borderBottomRadius: '0px',
            }}
            _loading={{
              borderBottomColor: 'gray.800',
              borderBottomRadius: '0px',
              border: '0px solid',
              borderBottom: '2px solid',
              bgColor: 'white',
            }}
          />
        </Box>
      )}
    </Row>
  )
}

export default TransactionTabs

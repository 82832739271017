import { Text, VStack } from '@chakra-ui/react'
import { Button, InfoCard, Separator } from 'ui'

type AddVendorOptionsProps = {
  onManualClick: () => void
  onRequestClick: () => void
  onCancel: () => void
}
export function AddVendorOptions({
  onManualClick,
  onRequestClick,
  onCancel,
}: AddVendorOptionsProps) {
  return (
    <VStack alignItems="start" height="100%">
      <VStack spacing={4} px={8} alignItems="flex-start">
        <VStack spacing={0} alignItems="start">
          <Text fontSize="lg" fontWeight="semibold" color="gray.800">
            Do you know their details?
          </Text>
          <Text fontSize="sm" color="gray.500">
            Enter their payment details and preferences yourself or invite them
            to fill out their own information with a secure onboarding link
          </Text>
        </VStack>
        <InfoCard
          icon="pencil"
          title="Yes, I can enter their information"
          subtitle="Enter the vendor's information to create a new vendor profile"
          onClick={onManualClick}
        />
        <InfoCard
          icon="envelope"
          title="No, ask vendor to fill it out"
          subtitle="Nickel will send them a secure link to enter their payment details."
          onClick={onRequestClick}
        />
      </VStack>
      <VStack spacing={0} mt="auto" w="100%" alignItems="start">
        <Separator orientation="horizontal" />
        <Button
          label="Cancel"
          variant="outline"
          onClick={onCancel}
          mx="8"
          mt="4"
        />
      </VStack>
    </VStack>
  )
}

export default AddVendorOptions

import { useState } from 'react'
import { SendCodeComponent } from './EmailTwoFactor'
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useAuth } from '../lib/auth'
import { useMutation } from '@apollo/client'
import { SendNickelVerificationCodeViaEmailDocument } from '../operations-types'
import { createSearchParams, Navigate, useLocation } from 'react-router-dom'

function LoginTwoFactor() {
  const { verifyTwoFactorCodeForLogin } = useAuth()

  const toaster = useToast()

  const [sendTwoFactorCode] = useMutation(
    SendNickelVerificationCodeViaEmailDocument,
    {
      onError: (e) => {
        setError(e.message)
        toaster({
          status: 'error',
          title: 'Error sending one-time code.',
        })
      },
    },
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const email = localStorage.getItem('email')

  const location = useLocation()

  if (!email) {
    if (location) {
      return (
        <Navigate
          to={{
            pathname: '/login',
            search: createSearchParams({
              redirect: location.pathname,
            }).toString(),
          }}
        />
      )
    } else {
      return <Navigate to={{ pathname: '/login' }} />
    }
  }

  return (
    <Flex
      height="100vh"
      alignItems="center"
      flexDirection="column"
      py="32"
      bgColor="brand.50"
      bgImage="nickel-mask.png"
      bgPosition="right bottom"
      bgSize="contain"
      bgRepeat="no-repeat"
      w="100%"
    >
      <Card>
        <CardHeader alignItems="center" justifyItems="center">
          <img alt="nickel logo" src="/logo500.png" className="w-32" />
        </CardHeader>
        <CardBody>
          <Text fontSize="lg" fontWeight="semibold" pb="3">
            Two-Factor Authentication
          </Text>
          <Text fontSize="sm" pb="3">
            Welcome! You have two-factor authentication set-up on your account
            for added security.
          </Text>

          <SendCodeComponent
            {...{
              identifier: email!,
              disabled: loading,
              setDisabled: setLoading,
              error: error || '',
              setSentCode: () => {},
              enrollment: false,
              validateVerificationCode: async (
                code: string,
                identifier: string,
              ) => {
                const response = await verifyTwoFactorCodeForLogin({
                  email,
                  code,
                })

                if (response?.error) {
                  setError(response.error)
                }
              },
              sendVerificationCode: async (identifier: string) => {
                try {
                  await sendTwoFactorCode({
                    variables: { email: identifier },
                  })
                } catch (e: any) {
                  setError(e.message)
                }
              },
            }}
          />
        </CardBody>
      </Card>
    </Flex>
  )
}

export default LoginTwoFactor

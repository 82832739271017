import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { TRANSACTIONS_URL } from '../../lib/urls'
import PaymentOverlayRefund from '../payments/PaymentOverlayRefund'
import {
  GetPaymentDocument,
  PaymentActivitiesDocument,
} from '../../operations-types'
import { useToast } from '@chakra-ui/react'
import currency from 'currency.js'
import PaymentInnerOverlay from './PaymentInnerOverlay'
import RefundInnerOverlay from './RefundInnerOverlay'
import ReturnInnerOverlay from './ReturnInnerOverlay'
import PayableInnerOverlay from './PayableInnerOverlay'
import { QueryOptsActivityNote } from '../ap/ActivityNoteModal'
import { BillPayOverlay } from '../ap/BillPayOverlay'
import VendorReturnInnerOverlay from './VendorReturnInnerOverlay'
import { ChargebackInnerOverlay } from './ChargebackInnerOverlay'
import LoanAdvanceInnerOverlay from './LoanAdvanceInnerOverlay'

type TransactionOverlayContext = {
  type: string
  queryOpts: QueryOptsActivityNote
}

type OVERLAY_STATES =
  | 'payment'
  | 'refund'
  | 'refundOverlay'
  | 'return'
  | 'vendorReturn'
  | 'payable'
  | 'bill'
  | 'chargeback'
  | 'advance'

const typeToOverlayState = (type: string): OVERLAY_STATES => {
  switch (type) {
    case 'receivable':
      return 'payment'
    case 'refund':
      return 'refund'
    case 'payable':
      return 'payable'
    case 'return':
      return 'return'
    case 'vendorReturn':
      return 'vendorReturn'
    case 'chargeback':
      return 'chargeback'
    case 'advance':
      return 'advance'
    default:
      return 'payment'
  }
}

export function TransactionOverlay() {
  const { transactionId } = useParams() as { transactionId: string }

  const { type, queryOpts } = useOutletContext() as TransactionOverlayContext
  const navigate = useNavigate()
  const [refundId, setRefundId] = useState<string | null>(
    type === 'refund' ? transactionId : null,
  )
  const [paymentId, setPaymentId] = useState<string | null>(
    type === 'receivable' ? transactionId : null,
  )
  const [returnId, setReturnId] = useState<string | null>(
    type === 'return' ? transactionId : null,
  )
  const [payableId, setPayableId] = useState<string | null>(
    type === 'payable' ? transactionId : null,
  )

  const [vendorReturnId, setVendorReturnId] = useState<string | null>(
    type === 'vendorReturn' ? transactionId : null,
  )

  const [chargebackId, setChargebackId] = useState<string | null>(
    type === 'chargeback' ? transactionId : null,
  )

  const [loanId, setLoanId] = useState<string | null>(
    type === 'advance' ? transactionId : null,
  )

  const [billId, setBillId] = useState<string | null>(null)

  const [state, setState] = useState<OVERLAY_STATES>(typeToOverlayState(type))

  const toast = useToast()

  const STATE_TO_ELEMENT: { [state in OVERLAY_STATES]: JSX.Element } = {
    payment: (
      <>
        {paymentId && (
          <PaymentInnerOverlay
            queryOpts={queryOpts}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            paymentId={paymentId}
            onRefund={() => setState('refundOverlay')}
            onRefundItem={(refundId) => {
              setRefundId(refundId)
              setState('refund')
            }}
            onReturnItem={(returnId) => {
              setReturnId(returnId)
              setState('return')
            }}
            onChargebackItem={(chargebackId) => {
              setChargebackId(chargebackId)
              setState('chargeback')
            }}
            onLoanItem={(loanId) => {
              setLoanId(loanId)
              setState('advance')
            }}
          />
        )}
      </>
    ),
    chargeback: (
      <>
        {chargebackId && (
          <ChargebackInnerOverlay
            queryOpts={queryOpts}
            chargebackId={chargebackId}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            onPaymentClick={(id) => {
              setPaymentId(id)
              setState('payment')
            }}
            onBack={() => setState('payment')}
          />
        )}
      </>
    ),
    return: (
      <>
        {returnId && (
          <ReturnInnerOverlay
            queryOpts={queryOpts}
            returnId={returnId}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            onPaymentClick={(id) => {
              setPaymentId(id)
              setState('payment')
            }}
            onBack={() => setState('payment')}
          />
        )}
      </>
    ),
    vendorReturn: (
      <>
        {vendorReturnId && (
          <>
            <VendorReturnInnerOverlay
              queryOpts={queryOpts}
              returnId={vendorReturnId}
              exitOverlay={() => navigate(TRANSACTIONS_URL)}
              onPaymentClick={(id) => {
                setPayableId(id)
                setState('payable')
              }}
              onBack={() => setState('payment')}
            />
          </>
        )}
      </>
    ),
    refund: (
      <>
        {refundId && (
          <RefundInnerOverlay
            refundId={refundId}
            queryOpts={queryOpts}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            onPaymentClick={(id) => {
              setPaymentId(id)
              setState('payment')
            }}
            onBack={() => setState('payment')}
          />
        )}
      </>
    ),
    payable: (
      <>
        {payableId && (
          <PayableInnerOverlay
            billPaymentId={payableId}
            queryOpts={queryOpts}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            onPayableClick={(id) => {
              setBillId(id)
              setState('bill')
            }}
            onReturnItem={(id) => {
              setVendorReturnId(id)
              setState('vendorReturn')
            }}
          />
        )}
      </>
    ),
    bill: (
      <>
        {billId && (
          <BillPayOverlay
            billId={billId}
            onBack={() => setState('payable')}
            onClose={() => setState('payable')}
            breadcrumbs={[
              {
                label: 'Transactions',
                onClick: () => setState('payable'),
              },
              {
                label: 'Bill',
                isActive: true,
              },
            ]}
          />
        )}
      </>
    ),
    refundOverlay: (
      <>
        {transactionId && (
          <PaymentOverlayRefund
            onRefunded={(paymentId, amount) => {
              toast({
                title: 'Success!',
                description: `${paymentId} refunded for ${currency(
                  amount,
                ).format()} to customer.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'bottom-left',
              })
              setState('payment')
            }}
            onError={(paymentId, error) => {
              toast({
                title: 'Error!',
                description: `Error refunding ${paymentId}: ${error}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom-left',
              })
              setState('payment')
            }}
            refetchQueries={[
              {
                query: PaymentActivitiesDocument,
                variables: {
                  ...queryOpts,
                },
              },
              {
                query: GetPaymentDocument,
                variables: {
                  paymentId: transactionId,
                },
              },
            ]}
            onBack={() => setState('payment')}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            paymentId={transactionId}
          />
        )}
      </>
    ),
    advance: (
      <>
        {loanId && (
          <LoanAdvanceInnerOverlay
            loanId={loanId}
            exitOverlay={() => navigate(TRANSACTIONS_URL)}
            onBack={() => setState('payment')}
            queryOpts={queryOpts}
          />
        )}
      </>
    ),
  }
  return <>{STATE_TO_ELEMENT[state]}</>
}

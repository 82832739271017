import { create } from 'zustand'

import { PaymentMethod } from '../ap/components/SendPaymentRouter'
import _ from 'lodash'
import { Bill, Vendor } from './batchStoreTypes'
import { BillWithPaymentData } from './BillWithPaymentData'
import { ApprovalPolicy, VendorPayoutMethod } from '../../operations-types'
import currency from 'currency.js'

type BillStoreData = {
  bills: Bill[]
  billsWithPaymentData: Record<string, BillWithPaymentData>
  billsByVendorId: Record<string, Bill[]>
  vendors: Vendor[]
  savedPaymentMethods: PaymentMethod[]
}

type BillStoreFunctions = {
  setSavedPaymentMethods: (paymentMethods: PaymentMethod[]) => void
  setBills: (bills: Bill[]) => void
  getBillsByVendorId: (vendorId: string) => Bill[]
  getBillsGroupedByVendorId: () => Record<string, Bill[]>
  getVendorById: (vendorId: string) => Vendor | undefined
  setBillPaymentMethod: (billId: string, paymentMethod: PaymentMethod) => void
  setBillDeliveryMethod: (
    billId: string,
    deliveryMethod: VendorPayoutMethod,
  ) => void
  setBillWithdrawalDate: (billId: string, withdrawalDate: Date) => void
  setVendorEmails: (billId: string, emails: string[]) => void
  setBillVendorMemo: (billId: string, memo: string) => void
  getBillWithPaymentData: (billId: string) => BillWithPaymentData
  getVendorTotalAmountWithoutFees: (vendorId: string) => number
  getVendorTotalFeeAmount: (vendorId: string) => number
  getTotalAmountCharged: () => number
  getTotalFees: () => number
  getTotalAmountChargedMinusFees: () => number
  setBillApprovalPolicies: (
    billId: string,
    approvalPolicies: ApprovalPolicy[],
  ) => void
  reset: () => void
}

type BillStore = BillStoreData & BillStoreFunctions

const initialState: BillStoreData = {
  savedPaymentMethods: [],
  bills: [],
  billsByVendorId: {},
  vendors: [],
  billsWithPaymentData: {},
}

export const useBatchBillStore = create<BillStore>((set) => ({
  ...initialState,
  setSavedPaymentMethods: (paymentMethods: PaymentMethod[]) =>
    set({ savedPaymentMethods: paymentMethods }),
  setBills: (bills: Bill[]) => {
    const billsByVendorId = _.groupBy(bills, (bill) => bill.vendor?.id) || {}
    const vendors = bills
      .map((bill) => bill.vendor)
      .filter((x): x is Vendor => !!x)

    const billsWithPaymentMethodAndPayoutMethod = bills.map(
      (bill) => new BillWithPaymentData(bill),
    )

    const billsWithPaymentData = Object.fromEntries(
      billsWithPaymentMethodAndPayoutMethod.map((bill) => [bill.bill.id, bill]),
    )

    set({
      bills,
      billsByVendorId,
      vendors,
      billsWithPaymentData,
    })
  },
  getVendorTotalAmountWithoutFees: (vendorId: string): number => {
    return useBatchBillStore
      .getState()
      .billsByVendorId[vendorId].reduce(
        (acc, bill) => acc + currency(bill.billData?.amountDue || 0).intValue,
        0,
      )
  },
  getVendorTotalFeeAmount: (vendorId: string): number => {
    const bills = useBatchBillStore.getState().billsByVendorId[vendorId]

    const billsWithPaymentData =
      useBatchBillStore.getState().billsWithPaymentData

    return bills.reduce((acc, bill) => {
      const billWithPaymentData = billsWithPaymentData[bill.id]

      return acc + billWithPaymentData.getPaymentSummary().feeTotalCents()
    }, 0)
  },
  getTotalAmountCharged: (): number => {
    return useBatchBillStore.getState().bills.reduce((acc, bill) => {
      const billWithPaymentData =
        useBatchBillStore.getState().billsWithPaymentData[bill.id]
      return acc + billWithPaymentData.getPaymentSummary().amountCents()
    }, 0)
  },
  getTotalFees: (): number => {
    return useBatchBillStore.getState().bills.reduce((acc, bill) => {
      const billWithPaymentData =
        useBatchBillStore.getState().billsWithPaymentData[bill.id]
      return acc + billWithPaymentData.getPaymentSummary().feeTotalCents()
    }, 0)
  },
  getTotalAmountChargedMinusFees: (): number => {
    return useBatchBillStore.getState().bills.reduce((acc, bill) => {
      const billWithPaymentData =
        useBatchBillStore.getState().billsWithPaymentData[bill.id]
      return (
        acc + billWithPaymentData.getPaymentSummary().amountWithoutFeeCents()
      )
    }, 0)
  },
  getBillsByVendorId: (vendorId: string): Bill[] => {
    return useBatchBillStore.getState().billsByVendorId[vendorId] || []
  },
  getBillsGroupedByVendorId: (): Record<string, Bill[]> => {
    return useBatchBillStore.getState().billsByVendorId
  },
  getVendorById: (vendorId: string): Vendor | undefined => {
    return useBatchBillStore
      .getState()
      .vendors.find((vendor) => vendor.id === vendorId)
  },
  setBillPaymentMethod: (billId: string, paymentMethod: PaymentMethod) => {
    set({
      billsWithPaymentData: {
        ...useBatchBillStore.getState().billsWithPaymentData,
        [billId]: useBatchBillStore
          .getState()
          .billsWithPaymentData[billId].setPaymentMethod(paymentMethod),
      },
    })
  },
  setBillDeliveryMethod: (
    billId: string,
    deliveryMethod: VendorPayoutMethod,
  ) => {
    set({
      billsWithPaymentData: {
        ...useBatchBillStore.getState().billsWithPaymentData,
        [billId]: useBatchBillStore
          .getState()
          .billsWithPaymentData[billId].setDeliveryMethod(deliveryMethod),
      },
    })
  },
  setBillWithdrawalDate: (billId: string, withdrawalDate: Date) => {
    set({
      billsWithPaymentData: {
        ...useBatchBillStore.getState().billsWithPaymentData,
        [billId]: useBatchBillStore
          .getState()
          .billsWithPaymentData[billId].setWithdrawalDate(withdrawalDate),
      },
    })
  },
  setBillApprovalPolicies: (
    billId: string,
    approvalPolicies: ApprovalPolicy[],
  ) => {
    set({
      billsWithPaymentData: {
        ...useBatchBillStore.getState().billsWithPaymentData,
        [billId]: useBatchBillStore
          .getState()
          .billsWithPaymentData[billId].setApprovalPolicies(approvalPolicies),
      },
    })
  },

  setBillVendorMemo: (billId: string, memo: string) => {
    set({
      billsWithPaymentData: {
        ...useBatchBillStore.getState().billsWithPaymentData,
        [billId]: useBatchBillStore
          .getState()
          .billsWithPaymentData[billId].setVendorMemo(memo),
      },
    })
  },
  setVendorEmails: (billId: string, emails: string[]) => {
    set({
      billsWithPaymentData: {
        ...useBatchBillStore.getState().billsWithPaymentData,
        [billId]: useBatchBillStore
          .getState()
          .billsWithPaymentData[billId].setVendorEmails(emails),
      },
    })
  },
  getBillWithPaymentData: (billId): BillWithPaymentData => {
    return useBatchBillStore.getState().billsWithPaymentData[billId]
  },
  reset: () => {
    set(initialState)
  },
}))

import { useQuery } from '@apollo/client'
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { Button, Column, HFlex, Row } from 'ui'
import { OrganizationTradeAccountsDocument } from '../../../operations-types'
import { v4 as uuidv4 } from 'uuid'

import moment from 'moment'

type Project = {
  projectName: string
  id: string
  tradeAccountName: string
}

type ProjectOption = {
  value: Project
  label: string
}

type TradeAccountSelectorProps = {
  onProjectSelect: (project: Project) => void
}

function TradeAccountSelector({ onProjectSelect }: TradeAccountSelectorProps) {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const { data, loading } = useQuery(OrganizationTradeAccountsDocument, {})

  const [options, setOptions] = useState<ProjectOption[]>([])

  useEffect(() => {
    if (!loading && data?.organization?.organization?.tradeAccounts) {
      const projectOptions: ProjectOption[] =
        data?.organization?.organization?.tradeAccounts
          .filter(
            (tradeAccount) => tradeAccount.underwriteStatus === 'APPROVED',
          )
          ?.flatMap((tradeAccount) => ({
            value: {
              tradeAccountName: tradeAccount.name,
              id: tradeAccount.id,
            } as Project,
            label: `${tradeAccount.name}`,
          }))
          .filter((projects) => projects !== undefined) || []
      setOptions(projectOptions)
    }
  }, [data, loading])

  const loadOptions = (inputValue: string): Promise<ProjectOption[]> => {
    return new Promise((resolve) => {
      const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      )
      resolve(filteredOptions)
    })
  }

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      defaultOptions={options}
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(false)}
      onMenuOpen={() => setMenuIsOpen(true)}
      onBlur={() => setMenuIsOpen(true)}
      openMenuOnClick={true}
      openMenuOnFocus={true}
      inputValue={inputValue}
      placeholder="Type to search for a customer"
      styles={{
        noOptionsMessage: (base) => ({
          ...base,
          color: 'gray.800',
          cursor: 'pointer',
        }),
      }}
      components={{
        Option: (props) => (
          <Column gap="small">
            {props.innerProps.id?.includes('option-0') &&
            (inputValue?.trim().length === 0 ||
              inputValue === undefined ||
              inputValue === null) ? (
              <Column grow y="center" x="left" className="pb-0" wGrow>
                <Row grow spacing="small">
                  <Text className="text-uppercase text-sm text-gray-500">
                    Recent Recipients
                  </Text>
                </Row>
                <Divider />
              </Column>
            ) : (
              ''
            )}
            <Row
              grow
              y="center"
              x="left"
              spacing="small"
              className="cursor-pointer hover:bg-blue-100"
              {...{
                onClick: () => {
                  setInputValue(props.children as string)
                  setMenuIsOpen(false)
                  onProjectSelect(props.data.value)
                },
              }}
            >
              {props.label}
            </Row>
          </Column>
        ),
      }}
      onInputChange={(value) => {
        setInputValue(value)
        setMenuIsOpen(true)
      }}
      noOptionsMessage={(inputValue) => inputValue.inputValue}
      onChange={(option) => {
        if (!option) {
          return
        }
        onProjectSelect(option.value)
        setMenuIsOpen(false)
      }}
    />
  )
}

type TradeAccountSelectProps = {
  setTradeAccountId(tradeAccountId: string): void
  tradeAccountId: string
  setTermsSelected(termsSelected: Date | null): void
  termsSelected: Date | null
  setQuestionFormId(questionFormId: string): void
  amount?: string
}

export function TradeAccountSelect(props: TradeAccountSelectProps) {
  const [tradeAccountName, setTradeAccountName] = useState<string | undefined>()
  const questionFormId = uuidv4()

  return (
    <Flex
      flexDirection="column"
      gap={4}
      border="1px solid"
      borderRadius="8px"
      backgroundColor="dark.600"
      p={4}
    >
      <Box>
        <Text fontSize="lg" fontWeight="bold" color="white">
          Select a customer to extend terms
        </Text>
        <Text fontSize="sm" color="white">
          Nickel will extend net 30-60 day terms and advance payment if your
          customer accepts the terms.
        </Text>
      </Box>

      <Divider />
      {!props.tradeAccountId ? (
        <TradeAccountSelector
          onProjectSelect={(tradeAccount) => {
            props.setTradeAccountId(tradeAccount.id)
            setTradeAccountName(tradeAccount.tradeAccountName)
            props.setQuestionFormId(questionFormId)
          }}
        />
      ) : (
        <HFlex alignItems="center">
          <HFlex gap={6}>
            <VStack gap="0" spacing="0" alignItems="Start" p="0">
              <FormLabel color="white" p="0" m="0" fontSize="xs">
                Trade Account
              </FormLabel>
              <Text p="0" color="white" fontWeight="500">
                {tradeAccountName}
              </Text>
            </VStack>
          </HFlex>
          <Spacer />
          <Button
            size="xs"
            label="Remove"
            variant="ghost"
            status="warning"
            onClick={() => {
              props.setTermsSelected(null)
              props.setTradeAccountId('')
            }}
          />
        </HFlex>
      )}

      {props.amount && (
        <>
          {props.termsSelected && (
            <HFlex alignItems="center">
              <HFlex gap={6}>
                <VStack gap="0" spacing="0" alignItems="Start" p="0">
                  <FormLabel color="white" p="0" m="0" fontSize="xs">
                    Term Duration
                  </FormLabel>
                  <Text p="0" color="white" fontWeight="500">
                    {moment(props.termsSelected).diff(moment(), 'days') + 1}{' '}
                    days
                  </Text>
                </VStack>
                <Spacer />
                <VStack gap="0" spacing="0" alignItems="Start" p="0">
                  <FormLabel color="white" p="0" m="0" fontSize="xs">
                    Due Date
                  </FormLabel>
                  <Text p="0" color="white" fontWeight="500">
                    {moment(props.termsSelected).format('MMM DD, YYYY')}
                  </Text>
                </VStack>
              </HFlex>
              <Spacer />
              <Button
                size="xs"
                label="Remove"
                variant="ghost"
                status="warning"
                onClick={() => {
                  props.setTermsSelected(null)
                }}
              />
            </HFlex>
          )}
        </>
      )}
    </Flex>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import {
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  useToast,
  Card as ChakraCard,
} from '@chakra-ui/react'
import currency from 'currency.js'
import moment from 'moment'
import { useEffect } from 'react'
import {
  Button,
  Card,
  Icon,
  PaymentSummary as UIPaymentSummary,
  VFlex,
} from 'ui'

import {
  AddBillPayableDocument,
  ApprovalPolicy,
  CheckApprovalDocument,
  CreateApprovalDocument,
  PayBillDocument,
  PaymentMethod,
  SelfDocument,
} from '../../../operations-types'
import { getRoleLabel } from '../../settings/Team'
import {
  RecipientDetailsValidationSchemaType,
  SendMoneyAmountResult,
  useSendPaymentStore,
} from './SendPaymentRouter'

type Props = {}

export const ReviewItem = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <Grid templateColumns="1fr 1fr">
      <GridItem w="100%">
        <Text color="gray.500" fontWeight="semibold" fontSize="sm">
          {label}
        </Text>
      </GridItem>
      <GridItem w="100%">
        <Text color="gray.800" fontWeight="medium">
          {value}
        </Text>
      </GridItem>
    </Grid>
  )
}

const ReviewAddress = ({
  label,
  street,
  city,
  state,
  zip,
}: {
  label: string
  street: string
  city: string
  state: string
  zip: string
}) => {
  return (
    <Grid templateColumns="1fr 1fr">
      <GridItem w="100%">
        <Text color="gray.500" fontWeight="semibold" fontSize="sm">
          {label}
        </Text>
      </GridItem>
      <GridItem w="100%">
        <Text color="gray.800" fontWeight="medium">
          {street}
        </Text>
        <Text color="gray.800" fontWeight="medium">
          {city}, {state}, {zip}
        </Text>
      </GridItem>
    </Grid>
  )
}

export const PaymentSummaryItem = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <Flex flexDirection="row" justifyContent={'space-between'}>
      <Text color="gray.500" fontWeight="semibold" fontSize="sm">
        {label}
      </Text>
      <Text color="gray.800" fontWeight="medium">
        {value}
      </Text>
    </Flex>
  )
}

export const TransactionDetails = (props?: {
  selectedSavedPaymentMethod?: Partial<PaymentMethod> | null | undefined
  sendMoneyAmountResult?: SendMoneyAmountResult
  recipientDetails?: RecipientDetailsValidationSchemaType
  name?: string
}) => {
  let {
    selectedSavedPaymentMethod: fromDashboardSelectedSavedPaymentMethod,
    sendMoneyAmountResult,
    recipientDetails,
  } = useSendPaymentStore()

  let selectedSavedPaymentMethod =
    fromDashboardSelectedSavedPaymentMethod || props?.selectedSavedPaymentMethod
  sendMoneyAmountResult = sendMoneyAmountResult || props?.sendMoneyAmountResult
  recipientDetails = recipientDetails || props?.recipientDetails

  return (
    <Card className="py-8 px-6 w-[540px] max-w-full">
      <Flex flexDirection="column" gap={6}>
        <HStack>
          <Icon name="arrowsRightLeft" className="stroke-2" />
          <Text fontSize="lg" fontWeight="semibold">
            Transaction Details
          </Text>
        </HStack>
        <VFlex gap={2}>
          <Flex flexDirection="column" gap={2}>
            <ReviewItem label="From" value={props?.name || ''} />
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <ReviewItem
              label="Reason for payment"
              value={sendMoneyAmountResult?.reason || ''}
            />
          </Flex>
        </VFlex>
        <Divider orientation="horizontal" />
        <Flex flexDirection="column" gap={2}>
          {fromDashboardSelectedSavedPaymentMethod ? (
            <ReviewItem
              label="Payment Method"
              value={`${
                selectedSavedPaymentMethod?.card?.brand
                  ? `${selectedSavedPaymentMethod?.card?.brand} ·· ${selectedSavedPaymentMethod?.card?.last4}`
                  : `${selectedSavedPaymentMethod?.achDebit?.bankName} ·· ${selectedSavedPaymentMethod?.achDebit?.last2}`
              }`}
            />
          ) : (
            ''
          )}
          <ReviewItem
            label="Payment Date"
            value={moment().format('MM/DD/YYYY')}
          />
        </Flex>
        <Divider orientation="horizontal" />
        <Flex flexDirection="column" gap={2}>
          <ReviewItem label="Recipient" value={recipientDetails.vendorName} />
          <ReviewItem
            label="Delivery Method"
            value={
              recipientDetails.paymentVia === 'ACH' ? 'ACH' : 'Physical Check'
            }
          />
          {recipientDetails.paymentVia === 'ACH' ? (
            <>
              <ReviewItem
                label="Bank Name"
                value={recipientDetails.achDetails.bankName}
              />
              <ReviewItem
                label="Account Number"
                value={recipientDetails.achDetails.accountNumber}
              />
            </>
          ) : (
            <ReviewAddress
              label="Delivery Address"
              street={
                recipientDetails.achDetails.recipientAddress.streetAddress
              }
              city={recipientDetails.achDetails.recipientAddress.city}
              state={recipientDetails.achDetails.recipientAddress.state}
              zip={recipientDetails.achDetails.recipientAddress.zipCode}
            />
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

type ApprovalPolicyProps = {
  approvalPolicy: ApprovalPolicy
}

const ApprovalPolicy = ({ approvalPolicy }: ApprovalPolicyProps) => {
  // TODO(claudio.wilson): Support multiple approval steps and conditions and different steps and conditions
  const approvalStep = approvalPolicy.approvalSteps?.at(0)
  const approvalCondition = approvalPolicy?.approvalConditions?.at(0)

  return (
    <ChakraCard w="540px" py={8} px={6}>
      <Flex flexDirection="column" gap={6}>
        <HStack>
          <Icon name="exclamationCircle" className="h-5 w-5 stroke-1.5" />
          <Text fontSize="lg" fontWeight="semibold">
            Approval Needed
          </Text>
        </HStack>
        <Text color="gray.800" fontWeight="normal">
          {`This payment requires approval from ${
            approvalStep?.approver?.email ||
            `any ${getRoleLabel(approvalStep?.approverRole || '')}`
          } because it is greater than ${currency(
            approvalCondition?.paymentAmountCents || 0,
            { fromCents: true },
          ).format()}`}
        </Text>
      </Flex>
    </ChakraCard>
  )
}

const PaymentSummary = (props?: {
  sendMoneyAmountResult?: SendMoneyAmountResult
  paymentSummary?: UIPaymentSummary
}) => {
  let { sendMoneyAmountResult, paymentSummary } = useSendPaymentStore()

  sendMoneyAmountResult = sendMoneyAmountResult || props?.sendMoneyAmountResult
  paymentSummary = paymentSummary || props?.paymentSummary

  return (
    <Card className="py-8 px-6 w-[540px] max-w-full">
      <Flex flexDirection="column" gap={6}>
        <HStack>
          <Icon name="currencyDollar" className="h-5 w-5 stroke-1.5" />
          <Text fontSize="lg" fontWeight="semibold">
            Payment Summary
          </Text>
        </HStack>
        <Flex flexDirection="column" gap={2}>
          <PaymentSummaryItem
            label="Amount to send"
            value={`${currency(
              sendMoneyAmountResult?.submittedAmountCents || 0,
              {
                fromCents: true,
              },
            ).format()}`}
          />
          {paymentSummary
            ?.feePresenters()
            .map((fee, idx) => (
              <PaymentSummaryItem
                key={idx}
                label={fee.name}
                value={fee.amount}
              />
            ))}
          <Divider orientation="horizontal" />
          <PaymentSummaryItem
            label="You'll be charged"
            value={`
            ${currency(sendMoneyAmountResult?.submittedAmountCents || 0, {
              fromCents: true,
            })
              .add(
                currency(
                  paymentSummary?.fees.reduce(
                    (acc, fee) => acc + fee.amountCents,
                    0,
                  ) || 0,
                  { fromCents: true },
                ),
              )
              .format()}
          `}
          />
        </Flex>
      </Flex>
    </Card>
  )
}

const ReviewSendPayment = (props: Props) => {
  const [payBill, { error, loading }] = useMutation(PayBillDocument, {
    refetchQueries: ['paymentActivities', 'bills'],
  })
  const [addBillPayable] = useMutation(AddBillPayableDocument)
  const [createApproval, { loading: createApprovalLoading }] = useMutation(
    CreateApprovalDocument,
    {
      refetchQueries: ['approvals'],
    },
  )

  const { data: selfData } = useQuery(SelfDocument)

  const orgName = selfData?.user?.user?.organization?.name

  const {
    selectedSavedPaymentMethod,
    sendMoneyAmountResult,
    paymentSummary,
    vendorId,
    vendorPayoutMethodId,
    recipientDetails,
    setPage,
    setBillPaymentId,
    bill,
    setApprovalPolicy,
  } = useSendPaymentStore()

  const { data: approvalData } = useQuery(CheckApprovalDocument, {
    variables: {
      submittedAmountCents: sendMoneyAmountResult?.submittedAmountCents!,
      paymentMethodId: selectedSavedPaymentMethod?.id!,
      vendorId: vendorId!,
    },
    onCompleted: (data) => {
      const policy = data.checkApproval?.approvalPolicies?.at(0)
      if (policy) {
        setApprovalPolicy(policy)
      }
    },
  })

  const toast = useToast()

  useEffect(() => {
    if (error) {
      toast({
        title: error.message,
        status: 'error',
      })
    }
  }, [error])

  const totalAmount = paymentSummary?.amountWithoutFeeCents()
  // For now just get the first approval policy, we can support multiple later
  const approvalPolicy = approvalData?.checkApproval?.approvalPolicies?.at(0)

  return (
    <Flex flexDirection="column" gap={8} className="px-4">
      <TransactionDetails
        {...{
          name: orgName || '',
        }}
      />
      <PaymentSummary />
      {approvalPolicy && <ApprovalPolicy approvalPolicy={approvalPolicy} />}
      <Button
        label={
          approvalPolicy
            ? 'Send for Approval'
            : `Confirm and Pay ${paymentSummary?.totalString()}`
        }
        width="100%"
        iconName={approvalPolicy ? 'envelope' : 'wallet'}
        iconPosition="right"
        isLoading={loading || createApprovalLoading}
        onClick={async () => {
          if (!vendorPayoutMethodId || !selectedSavedPaymentMethod?.id) {
            toast({
              title: 'Please select a payment method',
              status: 'error',
            })
            return
          }

          const billPayableResponse = await addBillPayable({
            variables: {
              amountDueCents: sendMoneyAmountResult?.submittedAmountCents || 0,
              invoiceDate: new Date().getTime(),
              dueDate: new Date().getTime(),
              vendorId: vendorId || '',
              billType: bill?.externalId ? 'BILL' : 'ONE_OFF',
              externalId: bill?.externalId ? bill.externalId : null,
              vendorEmails: recipientDetails?.vendorEmails,
              vendorPayoutMethodId: vendorPayoutMethodId,
              merchantPaymentMethodId: selectedSavedPaymentMethod?.id,
            },
          })

          const billPayableId =
            billPayableResponse?.data?.addBillPayable?.billPayable?.id

          if (
            billPayableResponse.data?.addBillPayable?.error ||
            !billPayableId
          ) {
            toast({
              title:
                billPayableResponse.data?.addBillPayable?.error?.message ||
                'Something went wrong',
              status: 'error',
            })
            return
          }

          if (!selectedSavedPaymentMethod || !vendorPayoutMethodId) {
            toast({
              title: 'Please select a payment method',
              status: 'error',
            })
            return
          }

          if (approvalPolicy) {
            const { data } = await createApproval({
              variables: {
                billPayableId: billPayableId,
                paymentMethodId: selectedSavedPaymentMethod.id,
                vendorPayoutMethodId: vendorPayoutMethodId,
              },
            })

            if (data?.createApproval?.approval?.id) {
              setPage('approval')
              return
            } else {
              toast({
                title: data?.createApproval?.error?.message,
                status: 'error',
              })
              return
            }
          }

          if (billPayableId) {
            const billPayment = await payBill({
              variables: {
                billId: billPayableId,
                amountCents: totalAmount || 0,
                merchantPaymentMethodId: selectedSavedPaymentMethod?.id || '',
                vendorPayoutMethodId: vendorPayoutMethodId || '',
                notificationEmails: recipientDetails.vendorEmails,
              },
            })

            if (billPayment.data?.payBill?.error) {
              toast({
                title: billPayment.data?.payBill?.error.message,
                status: 'error',
              })
              return
            }

            await setBillPaymentId(
              billPayment.data?.payBill?.billPayment?.id || '',
            )
          }

          setPage('notify')
        }}
      />
    </Flex>
  )
}

export default ReviewSendPayment

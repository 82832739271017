import { useToast } from '@chakra-ui/react'
import { BillOverlayHeader, Overlay, Separator, VFlex } from 'ui'
import {
  AddVendorDocument,
  RequestVendorDeliveryDetailsDocument,
  UpdateVendorCheckPayoutMethodDocument,
  UpdateVendorPayoutMethodDocument,
  Vendor,
  VendorsDocument,
} from '../../../operations-types'
import { useState } from 'react'
import { ManualVendorDetails, useAddVendorStore } from './AddVendorStore'
import { AddVendorPage } from './types'

import { useMutation } from '@apollo/client'
import AddVendorOptions from './AddVendorOptions'
import ManualVendorDetailsForm from './ManualVendorDetailsForm'
import {
  RequestVendorDetailsForm,
  RequestVendorFormData,
} from './RequestVendorDetailsForm'

export type AddVendorOverlay = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  vendor?: Vendor | null
}

type AddVendorOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onVendor?: (vendor: Vendor | null) => void
}

export function AddVendorOverlay({
  isOpen,
  setOpen,
  onVendor,
}: AddVendorOverlayProps) {
  const {
    getPage,
    setPage,
    manualVendorDetails,
    setManualVendorDetails,
    reset,
    getManualVendorDetails,
  } = useAddVendorStore((state) => ({
    getPage: state.getPage,
    setPage: state.setPage,
    manualVendorDetails: state.manualVendorDetails,
    setManualVendorDetails: state.setManualVendorDetails,
    reset: state.reset,
    getManualVendorDetails: state.getManualVendorDetails,
  }))
  const [loading, setLoading] = useState(false)

  const [addVendor] = useMutation(AddVendorDocument, {
    refetchQueries: [VendorsDocument],
  })

  const [updateVendorPayoutMethod] = useMutation(
    UpdateVendorPayoutMethodDocument,
    {},
  )

  const [updateVendorCheckPayoutMethod] = useMutation(
    UpdateVendorCheckPayoutMethodDocument,
    {},
  )

  const [requestVendorDetails] = useMutation(
    RequestVendorDeliveryDetailsDocument,
    {
      refetchQueries: [VendorsDocument],
    },
  )

  const toast = useToast()

  const handleRequestSubmit = async (values: RequestVendorFormData) => {
    setLoading(true)
    try {
      const { data } = await addVendor({
        variables: {
          name: values.vendorName,
          emails: values.vendorEmails.filter(
            (email): email is string => !!email,
          ),
        },
      })

      const vendorId = data?.addVendor?.vendor?.id
      if (!vendorId) {
        throw new Error('Failed to create vendor')
      }

      await requestVendorDetails({
        variables: {
          vendorId,
          emails: values.vendorEmails,
          taxRequested: values.requireTaxDocuments,
        },
      })

      toast({
        title: 'Vendor details requested successfully',
        description: 'An email has been sent to the vendor',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      setOpen(false)
      reset()
    } catch (error) {
      toast({
        title: 'Error requesting vendor details',
        description:
          error instanceof Error ? error.message : 'Unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleManualSubmit = async (values: ManualVendorDetails | null) => {
    setLoading(true)
    if (!values) {
      toast({
        title: 'Error creating vendor',
        description: 'No vendor details provided',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    try {
      const { data } = await addVendor({
        variables: {
          name: values.vendorName,
          emails: values.vendorEmails,
          vendorAddress: {
            street1: values.recipientAddress?.street1 || '',
            city: values.recipientAddress?.city || '',
            state: values.recipientAddress?.state || '',
            zip: values.recipientAddress?.zipCode || '',
          },
          type: values.vendorType,
        },
      })

      const vendorId = data?.addVendor?.vendor?.id
      if (!vendorId) {
        throw new Error('Failed to create vendor')
      }

      if (
        values.vendorAchPayoutMethod?.routingNumber &&
        values.vendorAchPayoutMethod?.accountNumber
      ) {
        await updateVendorPayoutMethod({
          variables: {
            vendorId,
            routingNumber: values.vendorAchPayoutMethod.routingNumber,
            accountNumber: values.vendorAchPayoutMethod.accountNumber,
            accountType: values.vendorAchPayoutMethod.accountType || 'checking',
          },
        })
      }

      if (values.vendorCheckPayoutMethod?.recipientAddress) {
        const address = values.vendorCheckPayoutMethod.recipientAddress
        await updateVendorCheckPayoutMethod({
          variables: {
            vendorId,
            street: address.street1 || '',
            city: address.city || '',
            state: address.state || '',
            zip: address.zipCode || '',
          },
        })
      }

      setLoading(false)
      toast({
        title: 'Vendor created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      if (onVendor) {
        onVendor(data?.addVendor?.vendor || null)
      }
      setOpen(false)
      reset()
    } catch (error) {
      toast({
        title: 'Error creating vendor',
        description:
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const components: Record<AddVendorPage, React.ReactNode> = {
    options: (
      <AddVendorOptions
        onManualClick={() => setPage('manual')}
        onRequestClick={() => setPage('request')}
        onCancel={() => setOpen(false)}
      />
    ),
    manual: (
      <ManualVendorDetailsForm
        onCancel={() => setPage('options')}
        loading={loading}
        onSubmit={async (values) => {
          await setLoading(true)
          await setManualVendorDetails({
            ...manualVendorDetails,
            vendorName: values.vendorName,
            vendorEmails:
              values?.vendorEmails?.filter(
                (email): email is string => !!email,
              ) || [],
            recipientAddress: {
              street1: values.recipientAddress.streetAddress || '',
              city: values.recipientAddress.city || '',
              state: values.recipientAddress.state || '',
              zipCode: values.recipientAddress.zipCode || '',
            },
            vendorType: values.vendorType,
          })
          await handleManualSubmit(getManualVendorDetails())
          setLoading(false)
        }}
      />
    ),
    request: (
      <RequestVendorDetailsForm
        onSubmit={handleRequestSubmit}
        loading={loading}
      />
    ),
  }
  return (
    <Overlay
      open={isOpen}
      setOpen={(open) => {
        setOpen(open)
        if (!open) {
          reset()
        }
      }}
    >
      <VFlex h="100%" w="100%">
        <BillOverlayHeader
          type="vendor"
          onClose={() => {
            setOpen(false)
            reset()
          }}
          title={
            getPage() === 'request'
              ? 'Request vendor details'
              : 'Create a new vendor'
          }
          breadcrumbs={[
            { label: 'Bill Pay' },
            { label: 'Vendors' },
            { label: 'Add Vendor', isActive: true },
          ]}
        />
        <Separator orientation="horizontal" />
        <VFlex w="100%" h="100%" pt="8" pb="4">
          {components[getPage()]}
        </VFlex>
      </VFlex>
    </Overlay>
  )
}

import {
  Card,
  Center,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Icon as ChakraIcon,
} from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Icon, IconName } from 'ui'

type PaymentOptionProps = {
  title: string
  description: string
  icon: IconName
  feeString: string
  onClick?: () => void
  selected?: boolean
}

export function PaymentOption({
  title,
  description,
  icon,
  onClick,
  feeString,
  selected,
}: PaymentOptionProps) {
  return (
    <Card
      p={4}
      w="100%"
      cursor="pointer"
      borderColor={selected ? 'black' : 'gray.200'}
      boxShadow={selected ? '0px 0px 0px 1px black' : 'none'}
      onClick={onClick}
      _hover={{
        background: 'gray.50',
        borderColor: 'black',
        boxShadow: '0px 0px 0px 1px black',
      }}
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <HStack gap={1.5}>
          <Icon name={icon} />
          <Text fontSize="sm" color="gray.800" fontWeight="medium">
            {title}
          </Text>
        </HStack>
        <HStack>
          <Text
            fontSize="xs"
            fontWeight="medium"
            color={feeString === 'No Fees' ? 'purple.600' : 'yellow.600'}
          >
            {feeString}
          </Text>
        </HStack>
      </Stack>
      <Text fontSize="sm" color="gray.500">
        {description}
      </Text>
    </Card>
  )
}

type SavedPaymentOptionProps = {
  title: string
  description: string
  icon: IconName
  feeString: string
  onClick?: () => void
  onRemove?: () => void
  selected?: boolean
}

export function SavedPaymentOption({
  title,
  description,
  icon,
  onClick,
  onRemove,
  feeString,
  selected,
}: SavedPaymentOptionProps) {
  return (
    <Card
      px={4}
      py={2}
      w="100%"
      cursor="pointer"
      borderColor={selected ? 'black' : 'gray.200'}
      boxShadow={selected ? '0px 0px 0px 1px black' : 'none'}
      onClick={onClick}
      _hover={{
        background: 'gray.50',
      }}
    >
      <Stack flexDirection={'row'} justifyContent="space-between">
        <HStack gap={1.5}>
          <Icon name={icon} className="h-[14px]" />
          <Text
            fontSize="sm"
            color="gray.800"
            fontWeight="medium"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
        </HStack>
        <HStack>
          <Text
            fontSize="xs"
            fontWeight="medium"
            color={feeString === 'No Fee' ? 'purple.600' : 'yellow.600'}
          >
            {feeString}
          </Text>
          {onRemove && (
            <Menu offset={[-80, -20]}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                style={{ width: '10px' }}
                icon={<ChakraIcon as={EllipsisVerticalIcon} boxSize="5" />}
                variant="unstyled"
                size="savedPaymentIcon"
                color="gray.500"
                onClick={(event) => {
                  event.stopPropagation()
                }}
              />
              <MenuList minW="0" w="85px" zIndex={999} position="absolute">
                <Center>
                  <MenuItem
                    color="red.500"
                    fontSize="sm"
                    alignSelf="center"
                    onClick={(event) => {
                      event.stopPropagation()
                      onRemove && onRemove()
                    }}
                  >
                    Remove
                  </MenuItem>
                </Center>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Stack>
      <Text fontSize="sm" color="gray.500">
        {description}
      </Text>
    </Card>
  )
}

type GoBackButtonProps = {
  onClick: () => void
}

export function GoBackButton({ onClick }: GoBackButtonProps) {
  return (
    <HStack justifyContent="center">
      <HStack cursor="pointer" onClick={onClick}>
        <Icon
          name="leftArrow"
          hoverable
          className="!stroke-gray-500 h-[14px]"
        />
        <Text
          fontWeight="medium"
          color="gray.500"
          fontSize="13px"
          _hover={{
            color: 'gray.600',
          }}
        >
          Go Back
        </Text>
      </HStack>
    </HStack>
  )
}

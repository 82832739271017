import { useMutation } from '@apollo/client'
import { Box, Divider, Flex, useToast } from '@chakra-ui/react'
import currency from 'currency.js'
import { sum } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, VFlex } from 'ui'
import 'popstate-direction'
import ContainerProps from 'ui/src/types'
import {
  ResendPaymentLinkToRecipientDocument,
  UpdateQuestionFormDocument,
} from '../../operations-types'

import { GoBack } from '../ap/components/send-money/SendMoneyHeader'
import { usePaymentLinkStore } from '../layout/PaymentLinkStore'
import BulkPaymentLinkElement, {
  AddRecipientDialog,
  BulkPaymentValidationSchema,
  BulkPaymentValidationSchemaType,
} from './BulkPaymentLinkElement'
import { BatchBillPayFooter } from '../bills/batch/BatchBillPay'
import { BatchLayout } from '../BatchLayout'

type BulkPaymentHeaderProps = {
  onExit: () => void
}

function BulkPaymentHeader({ onExit }: BulkPaymentHeaderProps) {
  return (
    <Box>
      <Box px={10} py={5}>
        <Flex justify="space-between" align="center" height="40px">
          <div className="text-sm font-medium">Request payments in bulk</div>
          {onExit && <GoBack onClick={onExit} />}
        </Flex>
      </Box>
      <Divider orientation="horizontal" />
    </Box>
  )
}

export function BulkPaymentLayout(props: ContainerProps) {
  const navigate = useNavigate()
  const { setPaymentLinks, paymentLinks } = usePaymentLinkStore()

  const onExit = () => {
    navigate('/dashboard/get-paid')
    setPaymentLinks([])
  }

  const [updateQuestionForm] = useMutation(UpdateQuestionFormDocument)

  const [resendPaymentLinktoRecipient] = useMutation(
    ResendPaymentLinkToRecipientDocument,
    {
      onCompleted: () => {},
    },
  )

  const toast = useToast()

  window.addEventListener('back', () => {
    setPaymentLinks([])
  })

  const [loading, setLoading] = useState(false)
  const onPaymentRequests = async (values: BulkPaymentValidationSchemaType) => {
    setLoading(true)

    await Promise.all(
      (values.paymentLinks || []).map((pl) =>
        updateQuestionForm({
          variables: {
            questionFormId: pl.id || '',
            memo: pl.message || '',
            recipients: (pl.recipients || []).map((r) => {
              return {
                name: r.name,
                email: r.email,
                phoneNumber: r.phoneNumber,
              }
            }),
          },
        }),
      ),
    )
    await Promise.all(
      (values.paymentLinks || [])
        .filter((g) => (g.recipients || [])?.length > 0)
        .map((pl) => {
          return resendPaymentLinktoRecipient({
            variables: {
              questionFormId: pl.id!,
              recipient: {
                name: pl.recipients?.[0].name || '',
                email: pl.recipients?.[0].email || '',
                phoneNumber: pl.recipients?.[0].phoneNumber || '',
              },
            },
          })
        }),
    )

    setLoading(false)

    toast({
      title: 'Payment requests sent successfully!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })

    onExit()
  }

  const totalBeingPaid = sum(
    paymentLinks.map(
      (pl) =>
        currency(pl.requestedAmount || '$0.00').subtract(
          currency(pl.completedAmountInCents || '$0.00'),
        ).value,
    ),
  )

  return (
    <Form
      validationSchema={BulkPaymentValidationSchema}
      initialValues={
        {
          modalOpen: false,
          selectedIndex: undefined,
          paymentLinks: paymentLinks.map((pl) => ({
            id: pl.id,
            invoiceAmount: pl.requestedAmount || '$0.00',
            alreadyPaid: pl.completedAmountInCents || '$0.00',
            message: pl.memo,
            title: pl.name,
            recipients:
              pl.recipients?.length === 0 &&
              pl.invoice?.customerRef?.emailAddress
                ? [
                    {
                      name: pl.invoice?.customerRef?.customerName || '',
                      email: pl.invoice?.customerRef?.emailAddress || '',
                      phoneNumber: '',
                    },
                  ]
                : pl.recipients,
            youRequest: currency(pl.requestedAmount || '$0.00')
              .subtract(currency(pl.completedAmountInCents || '$0.00'))
              .format(),
          })),
          currentCount: paymentLinks.length,
          newContact: {
            name: '',
            email: '',
            phoneNumber: '',
          },
        } as BulkPaymentValidationSchemaType
      }
    >
      {(props) => (
        <>
          <AddRecipientDialog
            {...{
              isOpen: props.values.modalOpen,
              onCloseAction: () => {
                props.setFieldValue('modalOpen', false)
                props.setFieldValue('selectedIndex', null)
              },
            }}
          />
          <BatchLayout
            header={<BulkPaymentHeader onExit={onExit} />}
            footer={
              <BatchBillPayFooter
                footerItems={[
                  {
                    label: 'Payment Request Links',
                    value: paymentLinks.length.toString(),
                  },
                  {
                    label: 'Total Amount Requested',
                    value: currency(totalBeingPaid).format(),
                  },
                ]}
                onClick={async () => await onPaymentRequests(props.values)}
                isDisabled={
                  props.values.paymentLinks?.length === 0 || !props.isValid
                }
                loading={loading}
                label="Send Requests"
                iconName="envelope"
                iconPosition="left"
              />
            }
          >
            <VFlex gap={6} pt={10}>
              {props.values.paymentLinks?.map((pL, idx) => (
                <BulkPaymentLinkElement {...pL} key={idx} index={idx} />
              ))}
            </VFlex>
          </BatchLayout>
        </>
      )}
    </Form>
  )
}

import { Header, Row, LARGE_CELL_PX } from 'ui'
import Column from 'ui/src/components/Layout/Column'
import { PageLayout } from '../layout/PageLayout'
import { Box, Text } from '@chakra-ui/react'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import { useState } from 'react'
import { ApprovalsTable } from './ApprovalsTable'
import { ApprovalsQueryVariables } from '../../operations-types'
import { Workflow } from './Workflow'

type Tabs = 'PENDING' | 'ALL' | 'WORKFLOW'

export function Approvals() {
  const [selectedTab, setSelectedTab] = useState<Tabs>('PENDING')
  const availableSpace = window.screen.availHeight - 420
  const pageSize = Math.floor(availableSpace / LARGE_CELL_PX)
  const [page, setPage] = useState(1)

  const [queryOpts, setQueryOpts] = useState<ApprovalsQueryVariables>({
    approvalStatus: 'PENDING',
    page: page,
    pageSize: pageSize,
  })

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem) min-h-[calc(100vh-2rem)]"
      >
        <Row className="justify-between p-4 h-[64px]">
          <Box>
            <Header variant="page" className="text-[20px]">
              Approvals
            </Header>
            <Text color="gray.500" fontSize="13px">
              Review and manage all submitted vendor payments
            </Text>
          </Box>
        </Row>
        <Box w="100%" pt={4}>
          <TransactionTabsV2
            tabs={[
              { label: 'Pending Approval', value: 'PENDING' },
              { label: 'All Requests', value: 'ALL' },
              { label: 'Workflow', value: 'WORKFLOW' },
            ]}
            selected={selectedTab}
            onSelect={(tab) => {
              setQueryOpts({
                approvalStatus: tab === 'PENDING' ? 'PENDING' : undefined,
              })
              setSelectedTab(tab as Tabs)
            }}
          />
        </Box>
        {['PENDING', 'ALL'].includes(selectedTab) && (
          <ApprovalsTable
            queryOpts={queryOpts}
            onPage={(page) => {
              setPage(page)
              setQueryOpts({
                ...queryOpts,
                page,
              })
            }}
          />
        )}
        {selectedTab === 'WORKFLOW' && <Workflow />}
      </Column>
    </PageLayout>
  )
}

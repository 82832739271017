import { HFlex } from 'ui'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Text } from '@chakra-ui/react'

type AmountArrowProps = {
  color?: string
  size?: number
  amount: string
  onClick?: () => void
  textColor?: string
  strokeWidth?: number
  strokeColor?: string
  iconWidth?: string
  iconClassName?: string
  fontSize?: string
}

export function AmountArrow({
  color = 'purple',
  fontSize = 'sm',
  amount,
  onClick,
  textColor,
  strokeWidth = 2,
  strokeColor,
  iconWidth = 'w-3',
  iconClassName,
}: AmountArrowProps) {
  const defaultTextColor = `${color}.500`
  const defaultStrokeColor = `stroke-${color}-500`

  return (
    <HFlex gap={1} alignItems="center" cursor="pointer" onClick={onClick}>
      <Text
        fontSize={fontSize}
        color={textColor ?? defaultTextColor}
        fontWeight="medium"
      >
        {amount}
      </Text>
      <ArrowRightIcon
        className={`${iconWidth} ${iconClassName ?? ''} stroke-${strokeWidth} ${
          strokeColor ?? defaultStrokeColor
        }`}
      />
    </HFlex>
  )
}

export default AmountArrow

import {
  CheckIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { ActionMeta } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Row } from 'ui'
import { Recipient } from '../../../operations-types'

type Vendor = {
  label: string
  value: string | Recipient
}

type VendorSelectProps = {
  vendors: Array<Vendor>
  onAddVendor: (e?: string) => void
  onValueSelect?: (
    e: {
      label: string
      value: string | Recipient
    },
    actionMeta: ActionMeta<unknown>,
  ) => Promise<void>
  value?: string
  className?: string
}

const VendorSelect = (props: VendorSelectProps) => {
  const [active, setActive] = useState(false)

  const [localVendors, setLocalVendors] = useState(props.vendors)

  return (
    <Row grow className="relative">
      <MagnifyingGlassIcon className="w-4 absolute !left-[8px] !top-[11px] z-10 stroke-gray-400" />
      <CreatableSelect
        {...{
          options: localVendors,
          placeholder: "Type the recipient's name",
          value:
            localVendors.find(
              (v) => (v.value as Recipient).name === props.value,
            ) || '',
          onChange(newValue, actionMeta) {
            if (props.onValueSelect) {
              props.onValueSelect(
                newValue as {
                  label: string
                  value: Recipient | string
                },
                actionMeta,
              )
            }
          },
          onCreateOption: (e) => {
            props.onAddVendor(e)
            setLocalVendors([...localVendors, { label: e, value: { name: e } }])
          },
          onFocus: () => setActive(true),
          onBlur: () => setActive(false),
          menuPosition: 'fixed',
          formatOptionLabel: (option: any) => (
            <Row y="center" gap="small" grow between>
              <span>{option.label}</span>
              {option === props.value && (
                <CheckIcon className="w-4 h-4 stroke-green-500 stroke-2" />
              )}
            </Row>
          ),
          formatCreateLabel: (inputLabel) => (
            <Row y="center" gap="small" className="cursor-pointer">
              <PlusCircleIcon className="w-4 stroke-gray-400" />
              <span>{`Add "${inputLabel}"`}</span>
            </Row>
          ),
          isValidNewOption: (inputValue) =>
            inputValue.length > 0 &&
            !localVendors
              .map((e) => (e.value as Recipient).name)
              .find((g) => g?.includes(inputValue)),
          styles: {
            option: (provided, state) => ({
              ...provided,
              fontSize: '12px',
              color: state.isSelected ? '#444' : '#000',
              background: state.isSelected ? '#e6e6e6' : '#fff',
              '&:hover': {
                background: state.isSelected ? '#e6e6e6' : '#F8F8F8',
              },
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              rotate: active ? '180deg' : '0deg',
            }),
            control: (provided) => ({
              ...provided,
              width: '100%',
              border: '1px solid #D7D7D7',
              boxShadow: 'none',
              '&:hover': {
                outline: 'none',
                border: '1px solid #D7D7D7',
              },
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#A8A291',
              fontSize: '14px',
            }),
            valueContainer: (provided) => ({
              ...provided,
              paddingLeft: '25px',
              fontSize: '14px',
            }),
            container: (provided) => ({
              ...provided,
              width: '100%',
              '&:focus': {
                outline: 'none',
                border: 'none',
              },
            }),
          },
        }}
      />
    </Row>
  )
}

export default VendorSelect

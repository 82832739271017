import {
  Badge,
  Box,
  Center,
  ColorProps,
  Flex,
  HStack,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useClipboard,
  Icon as ChakraIcon,
  Checkbox,
} from '@chakra-ui/react'
import moment from 'moment'
import { cloneElement, useState } from 'react'
import styled, { CSSObject } from 'styled-components'
import {
  BodyDescription,
  Button,
  HFlex,
  LARGE_CELL_PX,
  Notification,
  PaymentProgress,
  StatusBadge,
  TypeTransactionStatus,
  VFlex,
  XLARGE_CELL_PX,
} from 'ui'
import { Upload } from 'antd/lib/index'
import { FiCopy } from 'react-icons/fi'
import formatter from '../../../formatter'
import ContainerProps, { Datum } from '../../../types'
import Icon, { IconName, PaperPlaneIcon } from '../../Icons'
import StatusPill from '../../Indicators/StatusPill'
import CreditCardDetails from '../../Payment/CreditCardDetails'
import Text from '../../Typography/Text'
import { Text as ChakraText } from '@chakra-ui/react'
import Row from '../Row'
import { TableCellButton } from './CellButton'
import { TableHeaderProps } from './Header'
import SkeletonLoader from '../../Loaders/SkeletonLoader'
import {
  EllipsisVerticalIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
  DocumentTextIcon,
  UserCircleIcon,
  CheckCircleIcon,
  LinkIcon,
  WindowIcon,
  BuildingStorefrontIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import Dropdown from '../../Dropdown'
import Column from '../Column'
import Quickbooks from '../../Icons/Quickbooks'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'

export type CellType =
  | 'creditCard'
  | 'date'
  | 'datetime'
  | 'currency'
  | 'description'
  | 'syncedDescription'
  | 'status'
  | 'button'
  | 'checkbox'
  | 'notification'
  | 'payout'
  | 'paymentMethod'
  | 'dateSeen'
  | 'number'
  | 'badge'
  | 'textDate'
  | 'dateHuman'
  | 'buttonOptions'
  | 'buttonOptionsLink'
  | 'currencyProgress'
  | 'methodWithName'
  | 'direction'
  | 'reference'
  | 'note'
  | 'transactionStatus'
  | 'colorText'
  | 'copyLink'
  | 'paymentLinkStatus'
  | 'documentUpload'
  | 'contactTooltip'
  | 'payableStatus'
  | 'qboLabel'
  | 'paymentRequest'
  | 'methodForAccount'
  | 'colorTextWithDirection'
  | 'paymentRequest'
  | 'syncedDescription'
  | 'multiSelect'
  | 'paymentSource'
  | 'factorInvoice'
  | 'twoLines'
  | 'approveDeny'
  | 'vendor'
  | 'paymentInProgress'
  | 'billingAddress'
  | 'approvalMultiSelect'

export type SortOrder = 'asc' | 'desc'

export type TableCellContainerProps = {
  grow?: number
  center?: boolean
}

export type CellSize = 'xlarge' | 'large'

export type OptionClickMap = {
  label: string
  onClick: (key: Datum, value: Datum) => void
  condition?: (key?: Datum, value?: Datum) => boolean
}

export type CellBaseValue = {
  label: string
  value?: string
}

export type TransactionStatusCell = {
  status: TypeTransactionStatus
  tooltipText?: string
}

export type ColorTextCell = {
  label: string
  color: ColorProps['color']
}

type CellWidth = 'variable' | 'icon' | 'base' | 'small' | 'medium' | 'large'

const CellSizeToWidthMap: Record<CellWidth, string> = {
  variable: 'auto',
  icon: '50px',
  base: '100px',
  small: '120px',
  medium: '160px',
  large: '180px',
}

export type TableCellProps<T> = {
  type?: CellType
  onClick?: (key: Datum, value: T, e?: unknown) => void
  onOptionsClick?: (key: Datum, value: T) => void
  data?: Datum
  optionClickMap?: Array<OptionClickMap>
  value?: Datum
  keyName?: string
  additionalKeyName?: string | Array<string>
  label?: string
  selected?: Array<string>
  size?: number
  loading?: boolean
  className?: string
  width?: CellWidth
  grow?: number
  center?: boolean
  headerCenter?: boolean
  right?: boolean
  cursorDefault?: boolean
  style?: CSSObject
  cellSize?: CellSize
  subdomain?: string
  fit?: boolean
  sortable?: boolean
  headerSelected?: boolean
  headerOnCheck?: (e: boolean) => void
  onCheck?: (data: Datum, e: boolean) => void
}

export const CellContainer = styled.div<TableCellProps<Datum>>`
  display: flex;
  flex-direction: row;
  items-start: top;
  flex-grow: ${(props) => props.grow ?? 0};
  height: ${(props) =>
    props.cellSize == 'xlarge'
      ? `${XLARGE_CELL_PX}px`
      : props.cellSize == 'large'
      ? `${LARGE_CELL_PX}px`
      : '26px'};
  align-items: center;
  justify-content: ${(props) =>
    props.center ? 'center' : props.right ? 'flex-end' : 'flex-start'};
  width: ${(props) => CellSizeToWidthMap[props.width ?? 'base']};
  text-overflow: ellipsis;
`
function capitalizeWords(label: string) {
  return label
    .split(' ')
    .map((word) => capitalize(lowerCase(word)))
    .join(' ')
}

export type FileUploadDraggerProps = {
  onFile: (e: any) => void
} & ContainerProps

export const FileUploadDraggerHidden = (props: FileUploadDraggerProps) => {
  return (
    <Upload
      {...props}
      className="w-3 h-3"
      customRequest={() => {}}
      beforeUpload={async (file) =>
        await new Promise(() => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            props.onFile({
              data: reader.result,
              name: file.name,
            })
          }
        })
      }
    >
      {props.children}
    </Upload>
  )
}

export const TableCell = <T extends object>(
  props: TableCellProps<T> & Pick<TableHeaderProps<T>, 'headers'>,
) => {
  let component

  const [optionsVisible, setOptionsVisible] = useState(false)

  const localOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    props.onClick && props.onClick(props.keyName, props.value as T)
  }

  const localOptionsClick = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation()
    props.onOptionsClick &&
      props.onOptionsClick(props.keyName, props.value as T)
    setOptionsVisible(!optionsVisible)
  }

  const { onCopy, hasCopied } = useClipboard(props.value?.url || '')

  if (props.loading) {
    component = <SkeletonLoader className="h-[18px] px-2" count={1} />

    return (
      <Row grow x={props.center ? 'center' : props.right ? 'right' : 'left'}>
        {cloneElement(component ? component : <div />, {
          style: props.style,
        })}
      </Row>
    )
  }

  if (props.type === 'creditCard') {
    component = (
      <div
        {...{
          onClick: localOnClick,
          className: props.className,
        }}
      >
        <CreditCardDetails {...{ cardLastFour: props.value as string }} />
      </div>
    )
  } else if (props.type === 'currency') {
    component = (
      <Text
        {...{
          variant: 'tertiary',
          onClick: localOnClick,
          className: `${props.className} !text-gray-800 !font-medium text-[13px]`,
        }}
      >
        {formatter.format(props.value)}
      </Text>
    )
  } else if (props.type === 'colorText' && props.value) {
    const value = props.value as ColorTextCell
    component = (
      <Row y="center" gap="small">
        <ChakraText
          className={
            ['PAYABLE', 'RETURN', 'CHARGEBACK', 'REFUND'].includes(
              props.data.label.label,
            )
              ? 'text-yellow-600'
              : 'text-gray-600'
          }
          fontSize="small"
        >
          {value.label.toUpperCase()}
        </ChakraText>
      </Row>
    )
  } else if (props.type === 'colorTextWithDirection' && props.value) {
    const value = props.value as ColorTextCell
    component = (
      <Row y="center" gap="small">
        {value.label.toUpperCase() === 'PAYABLE' ||
        value.label.toUpperCase() === 'RETURN' ||
        value.label.toUpperCase() === 'CHARGEBACK' ||
        value.label.toUpperCase() === 'REFUND' ? (
          <ArrowRightIcon className="stroke-yellow-600 h-3 w-3 stroke-1.5" />
        ) : (
          <ArrowLeftIcon className="stroke-gray-600 h-3 w-3 stroke-1.5" />
        )}
        <ChakraText
          className={
            ['PAYABLE', 'RETURN', 'CHARGEBACK', 'REFUND'].includes(
              value.label.toUpperCase(),
            )
              ? 'text-yellow-600'
              : 'text-gray-600'
          }
          fontSize="small"
        >
          {capitalizeWords(lowerCase(value.label))}
        </ChakraText>
      </Row>
    )
  } else if (props.type === 'currencyProgress') {
    component = (
      <PaymentProgress
        {...{
          totalAmount: props.value.amoutDueInCents,
          paidAmount: props.value.amountPaidInCents,
        }}
      />
    )
  } else if (props.type == 'dateHuman') {
    component = (
      <Text
        {...{
          onClick: localOnClick,
          className: props.className,
          variant: 'tertiary',
        }}
      >
        {props.value ? moment(props.value).format('MMM DD, YYYY') : ''}
      </Text>
    )
  } else if (props.type === 'date') {
    component = (
      <Text
        {...{
          onClick: localOnClick,
          className: `${props.className} !text-[13px]`,
          variant: 'tertiary',
        }}
      >
        {props.value ? moment(props.value).format('MM/DD/YY') : ''}
      </Text>
    )
  } else if (props.type === 'datetime') {
    component = (
      <Text
        {...{
          onClick: localOnClick,
          className: props.className,
          variant: 'tertiary',
        }}
      >
        {moment(props.value).format('MMM DD YYYY, h:mm a')}
      </Text>
    )
  } else if (props.type === 'qboLabel') {
    component = (
      <HFlex justifyContent="space-between" w="100%">
        <Tooltip label={props.value.label}>
          <ChakraText fontSize="sm" noOfLines={1}>
            {props.value.label ?? 'Unnamed Bill'}
          </ChakraText>
        </Tooltip>
        <Box>{props.value.source === 'QUICKBOOKS' ? <Quickbooks /> : ''}</Box>
      </HFlex>
    )
  } else if (props.type === 'transactionStatus' && props.value) {
    component = (
      <Tooltip
        hasArrow
        label={props.value.tooltipText}
        bgColor="gray.500"
        p={3}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            left: '8px',
            justifyContent: props.center
              ? 'center'
              : props.right
              ? 'flex-end'
              : 'flex-start',
            flexGrow: 1,
            maxWidth: 'fit-content',
          }}
        >
          <StatusBadge
            {...{
              status: props.value.status as TypeTransactionStatus,
            }}
          />
        </Box>
      </Tooltip>
    )
  } else if (props.type === 'status') {
    component = props.value?.tooltipText ? (
      <Tooltip
        hasArrow
        label={props.value?.tooltipText}
        bgColor="gray.500"
        p={3}
      >
        <Box>
          <StatusPill
            status={props.value?.status ?? 'info'}
            spacing="xsmallNarrow"
            className="whitespace-nowrap"
          >
            <span className="truncate">
              {startCase(camelCase(props.value?.label))}
            </span>
          </StatusPill>
        </Box>
      </Tooltip>
    ) : (
      <StatusPill
        status={props.value?.status ?? 'info'}
        spacing="xsmallNarrow"
        className="whitespace-nowrap"
      >
        <span className="truncate">
          {startCase(camelCase(props.value?.label))}
        </span>
      </StatusPill>
    )
  } else if (props.type === 'paymentLinkStatus') {
    const checkoutActive = props.value.checkoutActive

    const formattedLabel = checkoutActive
      ? props.value.label.charAt(0).toUpperCase() +
        props.value.label.slice(1).toLowerCase()
      : 'Inactive'

    component = (
      <StatusPill
        status={
          props.value.completedCount === 0 &&
          props.value.label !== 'COMPLETED' &&
          checkoutActive
            ? 'success'
            : props.value.label === 'COMPLETED'
            ? 'success'
            : 'warning'
        }
        spacing="xsmallNarrow"
      >
        {props.value.completedCount === 0
          ? formattedLabel
          : `${props.value.completedCount} Payments`}
      </StatusPill>
    )
  } else if (props.type === 'payableStatus') {
    component = (
      <StatusPill
        status={
          props.value.label === 'PAID'
            ? 'success'
            : props.value.label === 'VOIDED'
            ? 'error'
            : 'action'
        }
        spacing="xsmallNarrow"
      >
        {props.value.label}
      </StatusPill>
    )
  } else if (props.type === 'badge') {
    component = (
      <Badge
        px="4px"
        py="2px"
        colorScheme={props.value.color ?? 'info'}
        variant="outline"
        borderRadius="100px"
        color="black"
      >
        <ChakraText fontSize="xs" fontWeight="medium">
          {props.value.label}
        </ChakraText>
      </Badge>
    )
  } else if (props.type === 'button') {
    component = (
      <TableCellButton
        disabled={props.value.disabled}
        onClick={(e) => {
          if (props.value.disabled) {
            e.stopPropagation()
          } else {
            localOnClick(e)
          }
        }}
      >
        {props.value.label}
      </TableCellButton>
    )
  } else if (props.type === 'twoLines') {
    component = (
      <Flex flexDirection="column" alignItems="flex-end">
        <ChakraText
          {...{
            onClick: localOnClick,
            className: props.className,
            color: 'gray.800',
            fontWeight: 'semibold',
            fontSize: '13px',
          }}
        >
          {props.value.line1}
        </ChakraText>
        <ChakraText
          {...{
            onClick: localOnClick,
            className: props.className,
            fontSize: '11px',
            color: 'gray.500',
          }}
        >
          {props.value.line2}
        </ChakraText>
      </Flex>
    )
  } else if (props.type === 'buttonOptionsLink') {
    if (props.value.amountDue === 0) {
      component = (
        <Row y="center" className="gap-[5px] relative left-[3px]">
          <div className="text-xs text-green-600 hover:text-gray-800">Paid</div>
          <CheckCircleIcon className="w-3 stroke-green-600 stroke-2" />
        </Row>
      )
    } else {
      component = (
        <Row y="center" className="gap-[5px] relative left-[3px]">
          <a
            className="text-[13px] text-gray-800 font-medium hover:text-purple-600"
            onClick={(e) => {
              if (props.value.disabled) {
                e.stopPropagation()
              } else {
                localOnClick(e)
              }
            }}
          >
            {props.value.label}
          </a>
          <ArrowRightIcon className="w-3 stroke-gray-800" />
        </Row>
      )
    }
  } else if (props.type === 'buttonOptions') {
    component = (
      <Row y="center" gap="small">
        <TableCellButton
          disabled={props.value.disabled}
          onClick={(e) => {
            if (props.value.disabled) {
              e.stopPropagation()
            } else {
              localOnClick(e)
            }
          }}
        >
          {props.value.label}
        </TableCellButton>
        {(props.optionClickMap || [])
          .map((g) => ({
            label: g.label,
            onClick: g.onClick,
            condition: g.condition,
          }))
          .filter((g) => g.condition && g.condition()).length > 0 ? (
          <Column>
            <Dropdown
              position="bottom-start"
              className="!absolute mt-[20px] !z-[100]"
              options={(props.optionClickMap || [])
                .map((g) => ({
                  label: g.label,
                  onClick: (e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation()

                    g.onClick(props.keyName, props.value as T)
                  },
                  condition: g.condition,
                }))
                .filter((g) => g.condition && g.condition())}
              onVisible={() => setOptionsVisible(true)}
              visible={optionsVisible}
            >
              <EllipsisVerticalIcon
                className="w-5 stroke-gray-400 stroke-2 relative left-[2px]"
                {...{
                  onClick: (e) => {
                    if (props.value.disabled) {
                      e.stopPropagation()
                    } else {
                      localOptionsClick(e)
                    }
                  },
                }}
              />
            </Dropdown>
          </Column>
        ) : (
          ''
        )}
      </Row>
    )
  } else if (props.type === 'checkbox') {
    component = <PaperPlaneIcon success={!!props.value} />
  } else if (props.type === 'multiSelect') {
    component = (
      <Checkbox
        {...{
          isChecked: props.data.isSelected,
          colorScheme: 'gray',
          onChange: (e) => {
            e.stopPropagation()
            e.preventDefault()

            if (props.onCheck) {
              props.onCheck(props.data, e.target.checked)
            }
          },
        }}
      />
    )
  } else if (props.type === 'approvalMultiSelect') {
    component = props.data.status.label === 'Pending' && (
      <Checkbox
        {...{
          isChecked: props.selected?.includes(props.data.id),
          colorScheme: 'gray',
          onChange: (e) => {
            e.stopPropagation()
            e.preventDefault()

            if (props.onCheck) {
              props.onCheck(props.data, e.target.checked)
            }
          },
        }}
      />
    )
  } else if (props.type === 'payout') {
    const payoutStatusToIconMap: Record<string, React.ReactNode> = {
      Scheduled: <Icon name="payoutScheduled" />,
      Returned: <Icon name="payoutReversed" />,
      Sent: <Icon name="payoutSent" />,
      Processing: <Icon name="payoutProcessing" />,
    }

    component = props.value ? (
      <Row gap="xsmall" y="center">
        {payoutStatusToIconMap[props.value.status]}
        <Text variant="tertiary">{props.value.status}</Text>
        {props.value.availableOn && (
          <BodyDescription className="text-stone-400 text-xs">
            ({moment(props.value.availableOn).format('MM/DD/YY')})
          </BodyDescription>
        )}
      </Row>
    ) : null
  } else if (
    props.type === 'methodWithName' ||
    props.type === 'methodForAccount'
  ) {
    const iconToTypeMap: Record<string, IconName> = {
      creditCard: 'creditCard',
      bankAccount: 'buildingLibrary',
      checkDeposit: 'paycheck',
    }
    component = (
      <Row
        onClick={localOnClick}
        cursorDefault={props.cursorDefault}
        gap="small"
        y="center"
        className="overflow-hidden"
      >
        <Tooltip
          hasArrow
          bg={'gray.500'}
          placement="top"
          label={props.value.identifier}
          fontSize="xs"
          width={'fit-content'}
          minW={'fit-content'}
        >
          <div>
            <Icon
              name={iconToTypeMap[props?.value?.type || '']}
              className={`!stroke-gray-500 ${
                props.value.type === 'checkDeposit' ? '!stroke-[1px]' : ''
              }`}
            />
          </div>
        </Tooltip>
        <ChakraText
          color="gray.800"
          whiteSpace="nowrap"
          className="text-ellipsis text-[13px]"
        >
          {props?.value?.name || ''}
        </ChakraText>
      </Row>
    )
  } else if (props.type === 'paymentMethod') {
    const iconToTypeMap: Record<string, IconName> = {
      creditCard: 'creditCard',
      bankAccount: 'buildingLibrary',
      checkDeposit: 'moneyCheck',
    }

    component = (
      <Row
        onClick={localOnClick}
        cursorDefault={props.cursorDefault}
        gap="small"
        y="center"
      >
        <Icon name={iconToTypeMap[props.value.type]} />
        <BodyDescription>{props.value.identifier}</BodyDescription>
      </Row>
    )
  } else if (props.type === 'direction') {
    if (props.value == 'left') {
      component = (
        <ArrowLeftIcon className="stroke-green-600 h-5 w-5 stroke-1.5" />
      )
    } else {
      component = (
        <ArrowRightIcon className="stroke-red-600 w-5 h-5 stroke-1.5" />
      )
    }
  } else if (props.type === 'dateSeen') {
    component = (
      <Row
        onClick={localOnClick}
        cursorDefault={props.cursorDefault}
        gap="small"
        y="center"
      >
        <BodyDescription>
          {moment(props.value.createdAt).format('MM/DD/YY')}
        </BodyDescription>
      </Row>
    )
  } else if (props.type === 'notification') {
    component = (
      <Notification variant="primary" size="small">
        {props.value}
      </Notification>
    )
  } else if (props.type === 'textDate') {
    component = (
      <Flex flexDirection="column">
        <ChakraText
          {...{
            onClick: localOnClick,
            className: props.className,
            fontSize: 'small',
            color: 'gray.800',
            fontWeight: 'medium',
          }}
        >
          {props.value.text}
        </ChakraText>
        <ChakraText
          {...{
            onClick: localOnClick,
            className: props.className,
            fontSize: 'xs',
            color: 'gray.400',
            fontWeight: 'medium',
          }}
        >
          {moment(props.value.date).format('MMM DD, YYYY')}
        </ChakraText>
      </Flex>
    )
  } else if (props.type === 'reference') {
    component = (
      <Row
        cursorDefault={props.cursorDefault}
        gap="xsmall"
        y="center"
        className="overflow-hidden"
      >
        {props.value.paymentSource !== '' && (
          <div>
            <Tooltip
              hasArrow
              fontSize="xs"
              label={
                props.value.paymentSource !== 'Portal' ? 'Go to Request' : ''
              }
              bgColor="gray.500"
            >
              <ChakraIcon
                _hover={{
                  bgColor: props.value.paymentSource !== 'Portal' && 'gray.300',
                }}
                boxSize="6"
                p="1"
                borderRadius="4px"
                as={
                  props.value.paymentSource !== 'Portal' ? LinkIcon : WindowIcon
                }
                className={`!stroke-gray-500 ${'!stroke-[2px]'}`}
                onClick={() => {
                  if (props.value.paymentSource !== 'Portal') {
                    window.open(
                      `/dashboard/get-paid/${props.value.paymentSource}`,
                    )
                  }
                }}
              />
            </Tooltip>
          </div>
        )}

        <Tooltip hasArrow label={props.value.notes} bgColor="gray.500" p={3}>
          <ChakraText
            color="gray.600"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            fontSize="12px"
          >
            {props.value.notes}
          </ChakraText>
        </Tooltip>
      </Row>
    )
  } else if (props.type === 'note') {
    const isEmptyObj = (obj: {
      note: string
      reconciled: boolean
      flagged: boolean
    }) => {
      return (
        obj.note === '' && obj.reconciled === false && obj.flagged === false
      )
    }

    const className =
      props.value && !isEmptyObj(props.value)
        ? 'flex items-center justify-center relative left-[5px]'
        : 'rounded-full bg-gray-100 flex items-center justify-center relative left-[5px]'

    component = (
      <div
        className={`${className} ${
          !(!props.value || isEmptyObj(props.value)) ? 'p-0' : 'p-1'
        }`}
        {...{
          onClick: localOnClick,
        }}
      >
        {!props.value || isEmptyObj(props.value) ? (
          <PlusIcon className="w-4 h-4 stroke-gray-800" />
        ) : (
          <Row className="w-[24px] h-[24px]" y="center" x="center">
            <DocumentTextIcon className="w-4 h-4 stroke-gray-800" />
          </Row>
        )}
      </div>
    )
  } else if (props.type === 'documentUpload') {
    const className =
      props.value && props.value.length > 0
        ? 'flex items-center justify-center p-2 relative left-[5px]'
        : 'rounded-full bg-gray-100 flex items-center justify-center p-2 relative left-[5px]'

    component = (
      <div className={className} onClick={(e) => e.stopPropagation()}>
        {!props.value || props.value.length === 0 ? (
          <FileUploadDraggerHidden
            {...{
              onFile: async (e) => {
                props.onClick &&
                  props.onClick(props.keyName, props.value as T, e)
              },
            }}
          >
            <PlusIcon className="w-3 h-3 stroke-gray-800 relative bottom-[4px]" />
          </FileUploadDraggerHidden>
        ) : (
          <FileUploadDraggerHidden
            {...{
              onFile: async (e) => {
                props.onClick &&
                  props.onClick(props.keyName, props.value as T, e)
              },
            }}
          >
            <DocumentTextIcon className="w-4 h-4 stroke-gray-800 relative bottom-[3px] right-[1px]" />
          </FileUploadDraggerHidden>
        )}
      </div>
    )
  } else if (props.type === 'copyLink') {
    component = (
      <HStack spacing="0" gap="0" borderRadius="4px">
        <InputGroup
          maxWidth="150px"
          borderRadius="4px"
          px="0"
          size="xs"
          color="gray.500"
          bgColor="gray.100"
          className="rounded-md relative left-[10px]"
        >
          <InputRightAddon
            border="none"
            ps="1"
            color="gray.500"
            bgColor="gray.10"
            fontSize="10px"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onCopy()
            }}
            _hover={{
              cursor: 'pointer',
              bgColor: 'gray.200',
            }}
          >
            <div className="font-semibold text-gray-600">
              {hasCopied ? 'Copied!' : 'COPY'}
            </div>
            <Box ps="1">
              <FiCopy className="stroke-gray-600" />
            </Box>
          </InputRightAddon>
        </InputGroup>
      </HStack>
    )
  } else if (props.type === 'factorInvoice') {
    component = (
      <>
        {props.value && props.value?.status ? (
          <Box display={!props.value ? 'none' : ''}>
            <StatusPill status={'success'} spacing="xsmallNarrow">
              {startCase(camelCase(props.value?.status))}
            </StatusPill>
          </Box>
        ) : (
          ''
        )}
      </>
    )
  } else if (props.type === 'contactTooltip') {
    component = (
      <>
        {props.value && props.value.length > 0 && (
          <Flex flexDirection="row" w="100%" maxW="50px">
            <Center>
              <span>
                <HStack ps="1">
                  <Popover
                    placement="top-start"
                    trigger="hover"
                    openDelay={0}
                    closeDelay={0}
                  >
                    <PopoverTrigger>
                      <Center>
                        <HStack>
                          <ChakraIcon as={UserCircleIcon} boxSize="6" />
                          <ChakraText fontSize="2xs">
                            {props.value &&
                              props.value.length > 0 &&
                              `${props.value?.[0].name}`}
                          </ChakraText>
                        </HStack>
                      </Center>
                    </PopoverTrigger>
                    <PopoverContent minW={{ base: '100%', lg: 'max-content' }}>
                      <PopoverHeader fontWeight="semibold">
                        Notification Recipients
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverBody>
                        {props.value &&
                          props.value.length > 0 &&
                          props.value.map((customer: any) => {
                            return (
                              <HStack key={customer.id}>
                                <ChakraText fontSize="sm" noOfLines={1}>
                                  {customer.name} | {customer.email}{' '}
                                  {customer.phoneNumber}
                                </ChakraText>
                              </HStack>
                            )
                          })}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </HStack>
              </span>
            </Center>
          </Flex>
        )}
      </>
    )
  } else if (props.type === 'paymentRequest') {
    if (props.value.length === 0) {
      component = (
        <HStack
          className="gap-[5px] px-2 py-1 bg-gray-200 rounded"
          onClick={localOnClick}
          verticalAlign="center"
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'gray.300',
          }}
        >
          <div className="text-xs font-medium text-gray-800 whitespace-nowrap">
            Send a request
          </div>
          <ArrowRightIcon className="w-2 h-2 stroke-[2px] stroke-gray-800" />
        </HStack>
      )
    } else {
      component = (
        <Row y="center" className="gap-[5px]">
          <ChakraText className="text-xs text-gray-800">
            {props.value[0].name}{' '}
            {props.value[0].sentAt
              ? `on ${moment(parseInt(props.value[0].sentAt)).format(
                  'MMM DD, YYYY',
                )}`
              : ''}
          </ChakraText>
        </Row>
      )
    }
  } else if (props.type === 'syncedDescription') {
    component = (
      <Row y="center" gap="small" className="overflow-hidden">
        {props.data.externalId ? <Quickbooks /> : ''}
        <ChakraText
          {...{
            onClick: localOnClick,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'gray.800',
            fontSize: 'sm',
            className: `${props.className || ''}`,
          }}
        >
          {props.value}
        </ChakraText>
      </Row>
    )
  } else if (props.type === 'approveDeny') {
    if (props.value === 'PAYMENT') {
      component = (
        <Button
          label="Go to Payment"
          variant="outline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation()
            props.onClick &&
              props.onClick(props.keyName, props.value as T, undefined)
          }}
        />
      )
    } else if (props.value === 'BILL') {
      component = (
        <Button
          label="See Queued Bills"
          variant="outline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation()
            props.onClick &&
              props.onClick(props.keyName, props.value as T, undefined)
          }}
        />
      )
    } else if (props.value === 'ACTION') {
      component = (
        <HStack>
          <Button
            label="Deny"
            variant="outline"
            size="xs"
            status="warning"
            onClick={(e) => {
              e.stopPropagation()
              props.onClick &&
                props.onClick(props.keyName, props.value as T, 'REJECTED')
            }}
          />
          <Button
            label="Approve"
            variant="outline"
            size="xs"
            status="success"
            onClick={(e) => {
              e.stopPropagation()
              props.onClick &&
                props.onClick(props.keyName, props.value as T, 'APPROVED')
            }}
          />
        </HStack>
      )
    } else if (props.value === 'DELETE') {
      component = (
        <Button
          label="Delete"
          variant="outline"
          size="xs"
          status="warning"
          onClick={(e) => {
            e.stopPropagation()
            props.onClick &&
              props.onClick(props.keyName, props.value as T, 'DELETED')
          }}
        />
      )
    }
  } else if (props.type === 'vendor') {
    component = (
      <VFlex alignItems="flex-start" className="py-2">
        <HFlex gap={3} alignItems="center">
          <Box
            borderRadius="full"
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgColor="gray.200"
          >
            <ChakraIcon
              as={
                props.data?.vendor?.methods?.length > 0
                  ? BuildingStorefrontIcon
                  : ExclamationCircleIcon
              }
              boxSize={4}
              className="text-gray-600"
            />
          </Box>
          <VFlex alignItems="flex-start" gap={0}>
            <ChakraText
              fontSize="sm"
              fontWeight="medium"
              color="gray.800"
              noOfLines={1}
            >
              {props.data?.vendor?.name}
            </ChakraText>
            <ChakraText fontSize="xs" color="gray.500">
              {props.data?.vendor?.methods?.length > 0
                ? 'Ready to pay'
                : 'Missing details'}
            </ChakraText>
          </VFlex>
        </HFlex>
      </VFlex>
    )
  } else if (props.type === 'paymentInProgress') {
    const statusToIconMap: Record<string, React.ElementType> = {
      declined: ExclamationTriangleIcon,
      failed: ExclamationTriangleIcon,
    }

    const statusToColorMap: Record<string, string> = {
      declined: 'yellow.600',
      failed: 'red.600',
    }
    if (props.value.paymentAmount) {
      component = (
        <VFlex gap="4px" alignItems="end">
          <ChakraText
            fontWeight="medium"
            fontSize="13px"
            color="gray.800"
            _hover={{
              color: 'purple.800',
            }}
          >
            {props.value.paymentAmount}
          </ChakraText>
          {props.value.subLine && (
            <HFlex gap="5px" alignItems="center" justifyContent="start">
              {props.value.status && (
                <ChakraIcon
                  as={statusToIconMap[props.value.status]}
                  boxSize={3}
                  color={statusToColorMap[props.value.status]}
                />
              )}
              <ChakraText
                color={statusToColorMap[props.value.status] || 'gray.500'}
                fontSize="xs"
                whiteSpace="nowrap"
              >
                {props.value.subLine}
              </ChakraText>
            </HFlex>
          )}
        </VFlex>
      )
    } else {
      component = (
        <Row y="center" className="gap-[5px] relative left-[3px]">
          <a
            className="text-[13px] text-gray-800 font-medium hover:text-purple-800"
            onClick={(e) => {
              if (props.value.disabled) {
                e.stopPropagation()
              } else {
                localOnClick(e)
              }
            }}
          >
            {props.value.label}
          </a>
          <ArrowRightIcon className="w-3 stroke-gray-800" />
        </Row>
      )
    }
  } else if (props.type === 'billingAddress') {
    component = (
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        <ChakraText
          {...{
            onClick: localOnClick,
            fontWeight: 'medium',
            fontSize: '13px',
            color: 'gray.800',
            className: props.className,
          }}
        >
          {props.value.line1}
        </ChakraText>
        <ChakraText
          {...{
            onClick: localOnClick,
            className: props.className,
            color: 'gray.800',
            fontWeight: 'medium',
            fontSize: '13px',
          }}
        >
          {props.value.line2}
        </ChakraText>
      </Flex>
    )
  } else {
    component = (
      <ChakraText
        {...{
          onClick: localOnClick,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'gray.800',
          fontSize: 'sm',
          className: `${props.className}`,
        }}
      >
        {props.value}
      </ChakraText>
    )
  }

  return (
    <Row
      grow
      x={props.center ? 'center' : props.right ? 'right' : 'left'}
      className={props.right ? 'px-4' : 'px-4'}
    >
      {cloneElement(component ? component : <div />, {
        style: props.style,
      })}
    </Row>
  )
}

export default TableCell

import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  useToast,
  VStack,
  Text,
  StackDivider,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { tw } from '@nickeltech/brise'
import { Fragment, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Button, Entity, EntityField, Overlay, RemoveModal } from 'ui'
import ContainerProps from 'ui/src/types'
import {
  AddTeammateDocument,
  Invite,
  OrganizationEmployeesDocument,
  OrganizationTier,
  RemoveInviteDocument,
  RemoveTeammateDocument,
  SelfDocument,
  UnlinkOrganizationFromAccountantDocument,
  UserRole,
} from '../../operations-types'
import {
  Organization,
  OrganizationUser as User,
  User as OutletUser,
} from '../../types'
import RoleSelectDropdown from '../ap/components/permissions/RoleSelectDropdown'
import UpgradeBanner from '../ap/components/permissions/UpgradeBanner'
import { useLoggedInStore } from '../layout/LoggedInStore'
import ErrorNotification from '../utils/ErrorNotification'
import Loading from '../utils/Loading'
import Notification from '../utils/Notification'
import NotificationArea from '../utils/NotificationArea'
import { InfoCardItem } from './InfoCardItem'
import TeammateOverlay, { PERMISSIONS_URL } from './TeammateOverlay'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { useAuth } from '../../lib/auth'

type DeactivateProps = {
  open: boolean
  setOpen: (bool: boolean) => void
  teammate?: User
  name?: string
  setShowError?: Function
  setShowNotif?: Function
  orgId?: string
}

export const getRoleLabel = (role: string) => {
  return {
    USER: 'Member',
    USER_ADMIN: 'Administrator',
    ADMIN: 'Nickel Administrator',
    MANAGER: 'Manager',
  }[role]
}

export function Deactivate({
  open,
  setOpen,
  teammate = {} as User,
  name,
  setShowError,
  setShowNotif,
}: DeactivateProps) {
  const toaster = useToast()
  const [removeTeammate] = useMutation(RemoveTeammateDocument, {
    onCompleted: (data) => {
      if (data?.removeTeammate?.error) {
        if (setShowError) {
          setShowError({
            message: data.removeTeammate.error.message,
            show: true,
          })
        } else {
          toaster({
            title: 'Error',
            description: data.removeTeammate.error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      } else {
        if (setShowNotif) {
          setShowNotif({
            message: `Successfully removed ${teammate.firstName} ${teammate.lastName}`,
            header: 'Success',
            show: true,
          })
        } else {
          toaster({
            title: 'Success',
            description: `Successfully removed ${teammate.firstName} ${teammate.lastName}`,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        }
      }
    },
    refetchQueries: [
      {
        query: OrganizationEmployeesDocument,
      },
    ],
  })

  const onClick = () => {
    removeTeammate({ variables: { teammateId: teammate.id } })
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Remove account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to remove {teammate?.firstName}{' '}
                        {teammate?.lastName} from {name}? They will no longer be
                        able to see the organization&apos;s data on Nickel.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onClick}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

type AddTeammateProps = {
  open: boolean
  setOpen: Function
  organizationName: string
  setShowNotif: Function
  setShowError: Function
  orgId: String
}

function AddTeammate({
  open,
  setOpen,
  setShowNotif,
  setShowError,
}: AddTeammateProps) {
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(UserRole.User)

  const [addTeammate] = useMutation(AddTeammateDocument, {
    onCompleted: (data) => {
      if (data?.newTeammate?.token) {
        if (data?.newTeammate?.token === 'NoToken') {
          setShowNotif({
            message: 'They now have access to your organization on Nickel',
            header: 'Successfully invited!',
            show: true,
          })
        } else {
          setShowNotif({
            message: 'Their inbox has a link to join your team',
            header: 'Successfully invited!',
            show: true,
          })
        }
      }

      if (data?.newTeammate?.error) {
        setShowError({ message: data.newTeammate.error.message, show: true })
      }
    },
    refetchQueries: [
      {
        query: OrganizationEmployeesDocument,
      },
    ],
  })

  const onInviteClick = () => {
    addTeammate({ variables: { email: email, role: role } })
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-inherit shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <VStack gap="3">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 w-full"
                    >
                      Invite a member
                    </Dialog.Title>
                    <FormControl>
                      <FormLabel variant="small">Email</FormLabel>
                      <Input
                        placeholder="name@email.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel variant="small">Role</FormLabel>
                      <RoleSelectDropdown
                        defaultValue={UserRole.User}
                        onChange={(val: UserRole) => setRole(val)}
                      />
                    </FormControl>
                    <Box>
                      <Text fontSize="sm">
                        Have questions about permissions and which roles can
                        perform what actions?
                      </Text>
                      <HStack spacing="0" gap="0">
                        <Text fontSize="sm">
                          Read our documentation to{' '}
                          <Link
                            fontSize="sm"
                            href={PERMISSIONS_URL}
                            color="purple.600"
                            target="_blank"
                          >
                            learn more
                            <ExternalLinkIcon m="1" mb="2" />
                          </Link>{' '}
                        </Text>
                      </HStack>
                    </Box>
                    <HStack gap="3" w="100%">
                      <Button
                        size="md"
                        variant="outline"
                        label="Cancel"
                        onClick={() => setOpen(false)}
                        w="100%"
                      />
                      <Button
                        size="md"
                        label="Invite"
                        isDisabled={!email}
                        onClick={onInviteClick}
                        w="100%"
                      />
                    </HStack>
                  </VStack>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

type InviteStatusProps = {
  employee: User | null
  invites: Array<Invite>
} & ContainerProps

const InviteStatus = tw((props: InviteStatusProps) => {
  const isAccepted =
    props.invites.length === 0 ||
    props.invites.find((e) => e.email === props.employee?.email)?.status ===
      'ACCEPTED'

  const isInvited = props.employee === null

  return (
    <div
      {...{
        className: `${props.className} ${
          isAccepted
            ? 'bg-green-100 text-green-600'
            : 'bg-gray-100 text-gray-600'
        }`,
      }}
    >
      {isInvited ? 'Invited' : isAccepted ? 'Active' : ''}
    </div>
  )
})`
  text-sm
  font-medium
  py-1
  px-2
  rounded-md
`

type TeamListProps = {
  employees: Array<User>
  organizationName: string
  organizationId: string
  invites: Array<Invite>
  tier: OrganizationTier | null
}

function TeamList({
  employees,
  organizationName,
  tier,
  invites,
  organizationId,
}: TeamListProps) {
  const data = useDashboardOutletContext()
  const { getAccountantUserId } = useAuth()
  const { getUser } = useLoggedInStore()

  const IS_PERMITTED =
    getUser().isUserPermitted(UserRole.UserAdmin) || !!getAccountantUserId()

  const [open, setOpen] = useState(false)
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [openDeactivate, setOpenDeactivate] = useState(false)
  const [showError, setShowError] = useState({ message: '', show: false })
  const [showNotif, setShowNotif] = useState({
    message: '',
    show: false,
    header: '',
  })

  const [removeEmployee, setRemoveEmployee] = useState<User | undefined>(
    undefined,
  )
  const toaster = useToast()

  const [removeInvite] = useMutation(RemoveInviteDocument, {
    onCompleted: () => {
      setShowNotif({
        message: 'Successfully removed invite',
        header: 'Success',
        show: true,
      })
    },
    onError: (error) => {
      setShowError({ message: error.message, show: true })
    },
    refetchQueries: [
      {
        query: OrganizationEmployeesDocument,
        variables: { id: organizationId },
      },
    ],
  })

  const onRemoveClick = (employee: User) => () => {
    setRemoveEmployee(employee)
    setOpenDeactivate(true)
  }

  const onRemoveInviteClick = (invite: Invite) => () => {
    removeInvite({
      variables: { email: invite.email || '', organizationId: organizationId },
    })
  }
  const [removeClient] = useMutation(UnlinkOrganizationFromAccountantDocument, {
    onCompleted: async () => {
      toaster({
        status: 'success',
        title: 'Client removed successfully',
      })
    },
    refetchQueries: [
      {
        query: SelfDocument,
      },
    ],
  })

  const [selectedEmployee, setSelectedEmployee] = useState<User | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigate = useNavigate()
  const accountant = data?.organization?.accountant
  const validEmployees = employees.filter((e) => !e.shadowRemoved)
  return (
    <div>
      <RemoveModal
        removeString={`${accountant?.organization?.name} from ${organizationName}`}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        removeOnClick={async () => {
          if (!data?.organization?.id) return
          await removeClient({
            variables: {
              clientOrgId: data.organization?.id,
            },
          })
        }}
      />
      <NotificationArea>
        <Notification
          show={showNotif['show']}
          onClose={() => setShowNotif({ message: '', show: false, header: '' })}
          header={showNotif['header']}
          message={showNotif['message']}
        />
        <ErrorNotification
          show={showError['show']}
          onClose={() => setShowError({ message: '', show: false })}
          errorMessage={showError['message']}
        />
      </NotificationArea>
      <Box py={{ base: '4', md: '8' }}>
        <Stack spacing="8" divider={<StackDivider />}>
          <InfoCardItem
            label="Team"
            sublabel="Invite your team to collaborate on Nickel."
          >
            <VStack alignItems="flex-end" spacing="4" width="100%">
              {tier === 'FREE_TIER' ? (
                <UpgradeBanner
                  {...{
                    employeeCount: employees.length,
                    onClick: () => {
                      navigate('/dashboard/nickel-plans')
                    },
                    isAdmin: IS_PERMITTED,
                  }}
                />
              ) : (
                ''
              )}
              {validEmployees.map((employee) => {
                return (
                  <Entity
                    key={employee.id}
                    onClick={() => {
                      if (
                        getUser().isUserPermitted(
                          employee.role || UserRole.Admin,
                        )
                      ) {
                        setSelectedEmployee(employee)
                        setOverlayOpen(true)
                      }
                    }}
                    w="100%"
                    actions={
                      IS_PERMITTED && (
                        <TrashIcon
                          className="h-5 w-5 cursor-pointer stroke-gray-500 hover:stroke-gray-700"
                          onClick={onRemoveClick(employee)}
                        />
                      )
                    }
                  >
                    <EntityField
                      title={`${employee.firstName} ${employee.lastName}`}
                      titleSize="sm"
                    />
                    <EntityField
                      title={employee.email || ''}
                      titleSize="sm"
                      titleTextColor="gray.500"
                    />
                    <EntityField
                      title={getRoleLabel(employee.role || 'USER')}
                      titleSize="sm"
                      titleTextColor="gray.500"
                    />
                    <InviteStatus
                      {...{
                        employee: employee,
                        invites: invites.filter(
                          (e) => e.email === employee.email,
                        ),
                      }}
                    />
                  </Entity>
                )
              })}

              <Overlay open={overlayOpen} setOpen={setOverlayOpen}>
                <TeammateOverlay
                  status={
                    invites.length === 0 ||
                    data.email === selectedEmployee?.email ||
                    invites.find((e) => e.email === selectedEmployee?.email)
                      ?.status === 'ACCEPTED'
                      ? 'Active'
                      : 'Invited'
                  }
                  employee={selectedEmployee!}
                  onClose={() => setOverlayOpen(false)}
                />
              </Overlay>

              {invites
                .filter((e) => e.status === 'PENDING')
                .map((invite) => {
                  return (
                    <Entity
                      key={invite.url}
                      w="100%"
                      actions={
                        IS_PERMITTED && (
                          <TrashIcon
                            className="h-5 w-5 cursor-pointer stroke-gray-500 hover:stroke-gray-700"
                            onClick={onRemoveInviteClick(invite)}
                          />
                        )
                      }
                    >
                      <EntityField />
                      <EntityField title={invite.email || ''} titleSize="sm" />
                      <EntityField
                        title={getRoleLabel(invite.role || 'USER')}
                        titleSize="sm"
                        titleTextColor="gray.500"
                      />
                      <InviteStatus
                        {...{
                          employee: null,
                          invites: invites.filter(
                            (e) => e.email === invite.email,
                          ),
                        }}
                      />
                    </Entity>
                  )
                })}
              {IS_PERMITTED &&
                !(employees.length >= 3 && tier === 'FREE_TIER') && (
                  <Button label="Add Teammate" onClick={() => setOpen(true)} />
                )}
            </VStack>
          </InfoCardItem>
          {accountant && (
            <InfoCardItem
              label="Accounting Firm"
              sublabel="The accounting firm with access to your organization on Nickel."
            >
              <Entity
                w="100%"
                actions={
                  <TrashIcon
                    className="h-5 w-5 cursor-pointer stroke-gray-500 hover:stroke-gray-700"
                    onClick={onOpen}
                  />
                }
              >
                <EntityField
                  title={accountant.organization?.name || ''}
                  titleSize="sm"
                />
                <EntityField
                  title={accountant.user?.email || ''}
                  titleSize="sm"
                  titleTextColor="gray.500"
                />
                <EntityField />
                <Box
                  bg="green.100"
                  color="green.600"
                  fontSize="sm"
                  fontWeight="medium"
                  py={1}
                  px={2}
                  borderRadius="4px"
                >
                  Active
                </Box>
              </Entity>
            </InfoCardItem>
          )}
        </Stack>
      </Box>
      <AddTeammate
        open={open}
        setOpen={setOpen}
        organizationName={organizationName}
        setShowNotif={setShowNotif}
        setShowError={setShowError}
        orgId={organizationId}
      />
      <Deactivate
        open={openDeactivate}
        teammate={removeEmployee}
        name={organizationName}
        setOpen={setOpenDeactivate}
        setShowNotif={setShowNotif}
        setShowError={setShowError}
        orgId={organizationId}
      />
    </div>
  )
}

export default function Team() {
  const outletData = useOutletContext()
  const { organization = {} as Organization } = outletData as OutletUser

  const { loading, data } = useQuery(OrganizationEmployeesDocument)

  const employees = data?.organization?.organization?.employees
  const invites = data?.organization?.organization?.invites
  const id = data?.organization?.organization?.id

  return (
    <div>
      {loading && <Loading additionalClasses={{ 'mt-8': true }} />}
      {employees && (
        <TeamList
          invites={invites as Invite[]}
          employees={employees as User[]}
          organizationName={organization?.name || ''}
          organizationId={id as string}
          tier={data.organization?.organization?.accountInfo?.tier || null}
        />
      )}
    </div>
  )
}

export const MAX_PRICE = 999999

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputProps,
  InputLeftAddon,
  Card,
  HStack,
  InputLeftElement,
  InputRightElement,
  Input,
} from '@chakra-ui/react'
import CurrencyInput from 'react-currency-input-field'

export type SelectAmountInputProps = {
  isInvalid?: boolean
  id?: string
  label?: string
  error?: string
  validated?: boolean
  isRequired?: boolean
  labelColor?: string
  formik?: any
  onBlur?: (e: any) => void
  onChange?: (e: string) => void
  leftAddon?: React.ReactNode
  rightAddon?: React.ReactNode
  value: string
} & Omit<InputProps, 'onChange' | 'onBlur' | 'value'>

function SelectAmountInput({
  isInvalid,
  label,
  id,
  error,
  validated,
  isRequired,
  leftAddon,
  onChange,
  onBlur,
  autoFocus,
  isReadOnly,
  value,
  ...other
}: SelectAmountInputProps) {
  return (
    <Card
      w="100%"
      bgColor={other.bgColor || 'gray.50'}
      boxShadow="0"
      border={other.border || '1px'}
      borderColor={other.borderColor || 'gray.200'}
    >
      <FormControl
        isInvalid={isInvalid && validated}
        id={id}
        isRequired={isRequired}
      >
        <HStack
          justifyContent="space-between"
          px={3}
          pt={2}
          alignItems="center"
        >
          <FormLabel
            noOfLines={1}
            fontSize={{ base: '2xs', md: 'xs' }}
            color={other.labelColor || 'gray.500'}
            m={0}
            htmlFor="currencyInput"
          >
            {label}
          </FormLabel>
          <FormErrorMessage fontSize={{ base: '2xs', md: 'xs' }} m={0}>
            {error && validated ? error : ''}
          </FormErrorMessage>
        </HStack>

        <InputGroup size={other.size || 'md'} px={3} pb={2}>
          {leftAddon ? (
            <InputLeftAddon pointerEvents="none" className="text-gray-500">
              {leftAddon}
            </InputLeftAddon>
          ) : (
            ''
          )}
          <CurrencyInput
            prefix="$"
            id="currencyInput"
            name={other.name}
            style={{
              width: '100%',
              background: 'transparent',
              fontSize: other?.fontSize
                ? `var(--chakra-fontSizes-${other.fontSize})`
                : `var(--chakra-fontSizes-3xl)`,
              outline: 'none',
              fontWeight: '500',
            }}
            readOnly={isReadOnly}
            value={value}
            placeholder={other.placeholder}
            onValueChange={(value) => onChange && onChange(value || '')}
            maxLength={MAX_PRICE.toString().length + 3}
            onBlur={onBlur}
            autoFocus={autoFocus}
          />
          {other.rightAddon && <>{other.rightAddon}</>}
        </InputGroup>
      </FormControl>
    </Card>
  )
}

type PriceInputProps = {
  value?: string
  label: string
  error?: string
  onPriceChange?: (price: string) => void
  leftElementText?: string
  rightElement?: React.ReactNode
  inputFontSize?: string
} & InputProps

export function PriceInput({
  value,
  onPriceChange,
  label,
  ...props
}: PriceInputProps) {
  const { leftElementText, fontWeight, rightElement, error } = props

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel
          fontSize={props.fontSize || 'md'}
          fontWeight={fontWeight}
          htmlFor="currencyInput"
        >
          {label}
        </FormLabel>
      )}
      <InputGroup size={props.size || 'md'}>
        {leftElementText && (
          <InputLeftElement pointerEvents="none" color="gray.400">
            {leftElementText}
          </InputLeftElement>
        )}
        <Input
          as={CurrencyInput}
          id="currencyInput"
          name={props.name}
          prefix="$"
          style={{
            width: '100%',
            background: 'transparent',
            fontSize:
              props?.inputFontSize || props?.fontSize
                ? `var(--chakra-fontSizes-${
                    props.inputFontSize || props.fontSize
                  })`
                : `var(--chakra-fontSizes-3xl)`,
            outline: '2px solid transparent',
            fontWeight: '400',
            color: error ? 'var(--chakra-colors-red-600)' : undefined,
            border: '1px solid var(--chakra-colors-gray.200)',
            padding: '0px 16px 0px 16px',
            borderRadius: 'var(--chakra-radii-md)',
            height: 'var(--chakra-sizes-10)',
          }}
          readOnly={props.isReadOnly}
          value={value}
          placeholder={props.placeholder}
          onValueChange={(value: any) =>
            onPriceChange && onPriceChange(value || '')
          }
          maxLength={MAX_PRICE.toString().length + 3}
          onBlur={props.onBlur}
          autoFocus={props.autoFocus}
        />
        {rightElement && (
          <InputRightElement width="5rem">{rightElement}</InputRightElement>
        )}
      </InputGroup>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : ''}
    </FormControl>
  )
}

export type PriceSelectorProps = {
  value?: number | string
  name: string
  placeholder?: string
  min?: number
  max?: number
  autoFocus?: boolean
} & SelectAmountInputProps

export function PriceSelector({
  value,
  name,
  placeholder,
  min,
  max,
  autoFocus,
  isRequired,
  error,
  isReadOnly,
  ...other
}: PriceSelectorProps) {
  return (
    <SelectAmountInput
      {...{
        ...other,
        name,
        value,
        placeholder,
        min,
        max,
        autoFocus,
        error,
      }}
      label="Enter a payment amount"
      leftAddon={other.leftAddon}
      isInvalid={other.isInvalid}
      validated={other.validated}
      error={error}
      isRequired={isRequired}
      autoFocus={autoFocus}
      isReadOnly={isReadOnly}
    />
  )
}

export default PriceSelector

import { useMutation, useQuery } from '@apollo/client'
import { Box, useToast } from '@chakra-ui/react'
import currency from 'currency.js'
import { useNavigate } from 'react-router-dom'
import { TableCellProps, TableV2 } from 'ui'
import { useDashboardOutletContext } from '../../lib/outletContext'
import { getPaymentMethodPayoutIdentifier } from '../../lib/utils'
import {
  ApprovalsDocument,
  ApprovalsQuery,
  ApprovalsQueryVariables,
  ApprovalStatus,
  ChangeApprovalStatusDocument,
  UserRole,
  VendorPayoutMethod,
} from '../../operations-types'
import { User } from '../../types'

import { useAuth } from '../../lib/auth'
import { useEffect, useState } from 'react'
import { Datum } from 'ui/src/types'
import { isActionPermitted } from '../layout/LoggedInUser'
import { getMultiSelectToastOptions } from '../MultiSelectToast'
import { IconName } from 'ui'

type ApprovalsTableProps = {
  queryOpts: ApprovalsQueryVariables
  onPage: (page: number) => void
}

type Approval = NonNullable<
  NonNullable<ApprovalsQuery['approvals']>['approvals']
>[0]

type ApprovalTableData = {
  id: string
  vendor: string
  reference: {
    source: string
    label: string
  }
  createdAt: number
  status: {
    status: string
    label: string
  }
  paymentMethod: {
    name: string
    type: string
    identifier: string
  }
  payoutMethod: {
    name: string
    type: string
    identifier: string
  }
  paymentAmount: number
  showAction: string
  billPaymentId?: string
  canUserApprove: boolean
}

const APPROVAL_STATUS_MAP: Record<ApprovalStatus, string> = {
  [ApprovalStatus.Pending]: 'info',
  [ApprovalStatus.Approved]: 'success',
  [ApprovalStatus.Rejected]: 'warning',
  [ApprovalStatus.Deleted]: 'danger',
}

const APPROVAL_LABEL_MAP: Record<ApprovalStatus, string> = {
  [ApprovalStatus.Pending]: 'Pending',
  [ApprovalStatus.Approved]: 'Approved',
  [ApprovalStatus.Rejected]: 'Rejected',
  [ApprovalStatus.Deleted]: 'Deleted',
}

const getShowAction = (
  status: ApprovalStatus,
  loggedInUser: User,
  isAccountant: boolean,
  approverId?: string,
  approverRole?: UserRole,
  approvalCreatedById?: string,
  billPaymentId?: string,
) => {
  // Also check who is the appropriate approver
  if (status === ApprovalStatus.Approved && billPaymentId) {
    return 'PAYMENT'
  }

  if (status === ApprovalStatus.Approved && !billPaymentId) {
    return 'BILL'
  }

  if (status === ApprovalStatus.Pending) {
    if (isAccountant) {
      return 'DELETE'
    }

    if (loggedInUser.id === approvalCreatedById) {
      return 'DELETE'
    }

    if (loggedInUser.id === approverId) {
      return 'ACTION'
    }

    if (loggedInUser.role === approverRole || loggedInUser.role === 'ADMIN') {
      return 'ACTION'
    }
  }

  return ''
}

export function getVendorPayoutMethodIdentifier(
  vendorPayoutMethod: VendorPayoutMethod,
) {
  if (!vendorPayoutMethod) {
    return 'Unknown'
  }

  if (vendorPayoutMethod.type === 'CHECK') {
    return `${vendorPayoutMethod.city}, ${vendorPayoutMethod.state}` || ''
  }
  return `${vendorPayoutMethod.bankName} ·· ${
    vendorPayoutMethod.accountNumber?.slice(-2) || ''
  }`
}

function canApprove(approval: Approval, user: User) {
  const step = approval?.approvalPolicy?.approvalSteps?.at(0)
  return isActionPermitted(step?.approverRole as UserRole, user.role!)
}

function transformApprovalIntoTableData(
  approval: Approval[],
  loggedInUser: User,
  isAccountant: boolean,
): ApprovalTableData[] {
  return approval.map((approval) => ({
    id: approval.id,
    vendor:
      approval.billPayable?.vendor?.name ||
      approval.recurringBill?.vendor?.name ||
      'N/A',
    reference: {
      source: approval?.billPayable?.bill?.billSource || 'N/A',
      label:
        approval.billPayable?.bill?.billData?.reference ||
        `${approval.recurringBill
          ?.reason} - Recurring ${approval.recurringBill?.frequency.toLocaleLowerCase()}` ||
        'N/A',
    },
    createdAt: parseInt(approval.createdAt || '') || 0,
    paymentMethod: getPaymentMethodPayoutIdentifier(approval.paymentMethod!),
    payoutMethod: {
      type:
        approval?.vendorPayoutMethod?.type === 'ACH'
          ? 'bankAccount'
          : 'checkDeposit',
      identifier: getVendorPayoutMethodIdentifier(
        approval?.vendorPayoutMethod!,
      ),
      name:
        approval?.billPayable?.vendor?.name ||
        approval?.recurringBill?.vendor?.name ||
        '',
    },
    paymentAmount: currency(approval.submittedAmountInCents || 0, {
      fromCents: true,
    }).value,
    //TODO(claudio.wilson): Have to change this when we support more than just a single approval mechanism
    showAction: getShowAction(
      approval.status!,
      loggedInUser,
      isAccountant,
      approval.approvalPolicy?.approvalSteps?.at(0)?.approver?.id,
      approval.approvalPolicy?.approvalSteps?.at(0)?.approverRole ?? undefined,
      approval.createdBy?.id,
      approval.billPayable?.billPayments?.[0]?.id,
    ),
    status: {
      status: APPROVAL_STATUS_MAP[approval.status!],
      label: APPROVAL_LABEL_MAP[approval.status!],
    },
    billPaymentId: approval.billPayable?.billPayments?.[0]?.id,
    canUserApprove: canApprove(approval, loggedInUser),
  }))
}

export function ApprovalsTable({ queryOpts, onPage }: ApprovalsTableProps) {
  const { data, loading } = useQuery(ApprovalsDocument, {
    variables: queryOpts,
    fetchPolicy: 'network-only',
  })

  const user = useDashboardOutletContext()
  const { getAccountantUserId } = useAuth()

  const accountantIsLoggedIn = !!getAccountantUserId()

  const navigate = useNavigate()
  const [updateApproval, { loading: approvalLoading }] = useMutation(
    ChangeApprovalStatusDocument,
    {
      refetchQueries: ['approvals', 'paymentActivities'],
      onCompleted: (data, error) => {
        if (
          data?.changeApprovalStatus?.error?.message ||
          !data?.changeApprovalStatus?.approvals?.length
        ) {
          toaster({
            title: 'Error',
            description:
              data?.changeApprovalStatus?.error?.message || 'An error occurred',
            status: 'error',
            duration: 5000,
          })
          return
        }

        const approvals = data.changeApprovalStatus.approvals

        if (approvals.length === 0) {
          return
        }

        for (const approval of approvals) {
          if (approval.status === ApprovalStatus.Approved) {
            toaster({
              title: 'Success',
              description: `${
                approval.billPayable?.vendor?.name || 'Vendor'
              } will be paid ${currency(approval?.submittedAmountInCents || 0, {
                fromCents: true,
              }).format()}.`,
              status: 'success',
              duration: 5000,
            })
          } else if (approval.status === ApprovalStatus.Deleted) {
            toaster({
              title: 'Success',
              description: `Payment approval to ${
                approval.billPayable?.vendor?.name || 'Vendor'
              } has been deleted.`,
              status: 'success',
              duration: 5000,
            })
          } else {
            toaster({
              title: 'Success',
              description: `Payment to ${
                approval.billPayable?.vendor?.name || 'Vendor'
              } has been denied.`,
              status: 'success',
              duration: 5000,
            })
          }
        }
      },
    },
  )

  const totalPages = Math.ceil(
    (data?.approvals?.totalResults || 0) / (queryOpts.pageSize || 20),
  )

  const toaster = useToast()
  const [headerSelected, setHeaderSelected] = useState(false)

  const [approvalIds, setApprovalIds] = useState<string[]>()

  const [currentToast, setCurrentToast] = useState('')

  const toastOptions = getMultiSelectToastOptions({
    buttons: [
      {
        icon: 'arrowPath' as IconName,
        label: 'Unselect',
        onClick: () => {
          setApprovalIds([])
          setHeaderSelected(false)
        },
      },
      ...(accountantIsLoggedIn ||
      !data?.approvals?.approvals?.some((approval) =>
        canApprove(approval, user),
      )
        ? [
            {
              icon: 'trash' as IconName,
              label: `Delete ${approvalIds?.length} payments`,
              onClick: () => {
                updateApproval({
                  variables: {
                    approvalIds: approvalIds,
                    status: ApprovalStatus.Deleted,
                  },
                })
                setApprovalIds([])
              },
            },
          ]
        : [
            {
              icon: 'xMark' as IconName,
              label: `Deny ${approvalIds?.length} payments`,
              onClick: () => {
                updateApproval({
                  variables: {
                    approvalIds: approvalIds,
                    status: ApprovalStatus.Rejected,
                  },
                })
                setApprovalIds([])
              },
            },
            {
              icon: 'checkCircle' as IconName,
              label: `Approve ${approvalIds?.length} payments`,
              onClick: () => {
                updateApproval({
                  variables: {
                    approvalIds: approvalIds,
                    status: ApprovalStatus.Approved,
                  },
                })
                setApprovalIds([])
              },
              primary: true,
            },
          ]),
    ],
  })

  useEffect(() => {
    if (approvalIds?.length === 0) {
      toaster.close(currentToast)
      setHeaderSelected(false)
    } else {
      if (!toaster.isActive(currentToast)) {
        let t = toaster(toastOptions)

        setCurrentToast(t as string)
      } else {
        toaster.update(currentToast, toastOptions)
      }
    }
  }, [approvalIds, approvalLoading])

  useEffect(() => {
    setApprovalIds([])
    toaster.closeAll()
  }, [])

  const table = (
    <TableV2
      loading={loading || approvalLoading}
      rowSize="large"
      cellSize="large"
      headers={
        [
          data?.approvals?.approvals?.some(
            (approval) =>
              canApprove(approval, user) &&
              approval.status === ApprovalStatus.Pending,
          )
            ? {
                type: 'approvalMultiSelect',
                keyName: 'id',
                width: 'icon',
                headerCenter: true,
                center: true,
                selected: approvalIds?.map((approvalId) => approvalId),
                headerSelected: headerSelected,
                headerOnCheck: (e: boolean) => {
                  setHeaderSelected(e)
                  setApprovalIds(
                    e
                      ? data?.approvals?.approvals
                          ?.filter(
                            (approval) =>
                              canApprove(approval, user) &&
                              approval.status === ApprovalStatus.Pending,
                          )
                          .map((approval) => approval.id)
                      : [],
                  )
                },
                onCheck: (data: Datum, e: boolean) => {
                  if (!(data as ApprovalTableData).canUserApprove) {
                    return
                  }
                  if (e) {
                    setApprovalIds([...(approvalIds ?? []), data.id])
                  } else {
                    setApprovalIds(approvalIds?.filter((id) => id !== data.id))
                  }
                },
              }
            : null,
          { label: 'Vendor', keyName: 'vendor', type: 'description', grow: 1 },
          {
            type: 'qboLabel',
            label: 'Bill / Reason for Payment',
            keyName: 'reference',
            width: 'large',
            grow: 1,
          },
          queryOpts?.approvalStatus !== ApprovalStatus.Pending
            ? {
                type: 'status',
                label: 'Status',
                keyName: 'status',
              }
            : null,
          {
            type: 'date',
            label: 'Submitted On',
            keyName: 'createdAt',
          },
          {
            type: 'methodWithName',
            keyName: 'paymentMethod',
            label: 'Payment Method',
            width: 'large',
          },
          {
            type: 'methodWithName',
            keyName: 'payoutMethod',
            label: 'Delivery Method',
            grow: 1,
          },
          {
            type: 'currency',
            keyName: 'paymentAmount',
            label: 'Payment',
          },
          {
            type: 'approveDeny',
            label: 'Action',
            keyName: 'showAction',
            width: 'large',
            onClick: (
              keyName: string,
              approval: ApprovalTableData,
              newStatus: string,
            ) => {
              if (newStatus === 'APPROVED') {
                updateApproval({
                  variables: {
                    approvalIds: [approval.id],
                    status: ApprovalStatus.Approved,
                  },
                })
              } else if (newStatus === 'REJECTED') {
                updateApproval({
                  variables: {
                    approvalIds: [approval.id],
                    status: ApprovalStatus.Rejected,
                  },
                })
              } else if (newStatus === 'DELETED') {
                updateApproval({
                  variables: {
                    approvalIds: [approval.id],
                    status: ApprovalStatus.Deleted,
                  },
                })
              } else if (approval.billPaymentId) {
                navigate(
                  `/dashboard/transactions/payable/${approval.billPaymentId}`,
                )
              } else {
                navigate(`/dashboard/accounts-payable/bills/?type=QUEUED`)
              }
            },
          },
        ].filter(Boolean) as TableCellProps<object>[]
      }
      data={transformApprovalIntoTableData(
        data?.approvals?.approvals || [],
        user,
        accountantIsLoggedIn,
      )}
      page={queryOpts.page || 1}
      perPage={queryOpts.pageSize || 10}
      totalPages={totalPages || 1}
      onPage={onPage}
    />
  )

  return (
    <Box w="100%" pt="4">
      {table}
    </Box>
  )
}

import { Text } from '@chakra-ui/react'
import { Overlay } from 'ui'
import {
  Approval,
  RecurringBillFrequency,
  RecurringBillsDocument,
  Vendor,
} from '../../operations-types'

import { RecurringBillsOverview } from '../bills/recurring/RecurringBillsOverview'
import CreateRecurringBill from '../bills/recurring/CreateRecurringBill'
import { RecurringBillPayment } from '../bills/recurring/RecurringBillPayment'
import { RecurringBillPage, useBillStore } from '../stores/billStore'
import { RecurringBillReview } from '../bills/recurring/RecurringBillReview'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { RecurringBillSuccess } from '../bills/recurring/RecurringBillSuccess'
export type NewBillOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  vendor?: Vendor | null
}

export type OverlayHeaderType =
  | 'transactions'
  | 'bills'
  | 'payments'
  | 'vendor'
  | 'recurring'

export function RecurringBillSectionHeader({ title }: { title: string }) {
  return (
    <Text fontSize="xl" fontWeight="semibold" color="gray.800" mb={4}>
      {title}
    </Text>
  )
}

export function RecurringBillsOverlay({
  isOpen,
  setOpen,
}: NewBillOverlayProps) {
  const { recurringDetails, reset } = useBillStore((state) => ({
    recurringDetails: state.recurringDetails,
    reset: state.reset,
  }))

  const { data } = useQuery(RecurringBillsDocument)

  const activeRecurringBillsResponse =
    data?.recurringBills?.recurringBills?.filter(
      (recurringBill) =>
        recurringBill.active ||
        (!recurringBill.active &&
          recurringBill.approvals?.some(
            (approval) => approval.status === 'PENDING',
          )),
    ) ?? []

  const inactiveRecurringBillsResponse =
    data?.recurringBills?.recurringBills?.filter(
      (recurringBill) =>
        !recurringBill.active &&
        !recurringBill.approvals?.some(
          (approval) => approval.status === 'PENDING',
        ),
    ) ?? []

  const approvalStatusToText = (
    approvals: Approval[],
    status: 'active' | 'inactive',
  ) => {
    if (approvals?.find((approval) => approval.status === 'PENDING'))
      return 'Pending Approval'
    if (approvals?.find((approval) => approval.status === 'REJECTED'))
      return 'Rejected'
    return status === 'active' ? 'Active' : 'Inactive'
  }

  const activeBills = activeRecurringBillsResponse.map((recurringBill) => ({
    id: recurringBill.id,
    vendorName: recurringBill.vendor?.name ?? '',
    description: recurringBill.reason ?? '',
    amountCents: recurringBill.amountCents ?? 0,
    schedule: recurringBill.frequency ?? RecurringBillFrequency.Monthly,
    nextPaymentDate: moment(
      parseInt(recurringBill.nextPaymentDate ?? ''),
    ).toDate(),
    statusText: approvalStatusToText(recurringBill?.approvals ?? [], 'active'),
  }))

  const inactiveBills = inactiveRecurringBillsResponse.map((recurringBill) => ({
    id: recurringBill.id,
    vendorName: recurringBill.vendor?.name ?? '',
    description: recurringBill.reason ?? '',
    amountCents: recurringBill.amountCents ?? 0,
    schedule: recurringBill.frequency ?? RecurringBillFrequency.Monthly,
    nextPaymentDate: undefined,
    statusText: approvalStatusToText(
      recurringBill?.approvals ?? [],
      'inactive',
    ),
  }))

  const components: Record<RecurringBillPage, React.ReactNode> = {
    overview: (
      <RecurringBillsOverview
        activeBills={activeBills}
        inactiveBills={inactiveBills}
        setOpen={setOpen}
      />
    ),
    recurringCreateBill: <CreateRecurringBill setOpen={setOpen} />,
    recurringPaymentPayout: <RecurringBillPayment setOpen={setOpen} />,
    recurringReview: <RecurringBillReview setOpen={setOpen} />,
    recurringSuccess: <RecurringBillSuccess setOpen={setOpen} />,
  }

  return (
    <Overlay
      open={isOpen}
      setOpen={(boolean) => {
        if (!boolean) {
          reset()
        }
        setOpen(boolean)
      }}
    >
      {components[recurringDetails?.page ?? 'overview']}
    </Overlay>
  )
}

import { Flex, HStack, Skeleton, Text } from '@chakra-ui/react'
import { IconName, Icon } from '../Icons'

export type LineItemProps = {
  icon?: IconName
  title: string
  subtitle: string
  bold?: boolean
  subIcon?: IconName
  loading?: boolean
}

export function LineItem({
  title,
  icon,
  subIcon,
  subtitle,
  bold,
  loading,
}: LineItemProps) {
  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <HStack gap={1}>
        {icon && (
          <Icon
            name={icon}
            className={bold ? 'stroke-gray-800' : 'stroke-gray-500'}
          />
        )}
        <Text
          fontSize="sm"
          color={bold ? 'gray.800' : 'gray.500'}
          fontWeight={bold ? 'semibold' : 'normal'}
        >
          {title}
        </Text>
      </HStack>
      <Skeleton isLoaded={!loading}>
        <HStack gap={2}>
          {subIcon && <Icon name={subIcon} className="h-[14px]" />}
          <Text
            fontSize="sm"
            color="gray.800"
            fontWeight={bold ? 'semibold' : 'normal'}
          >
            {subtitle}
          </Text>
        </HStack>
      </Skeleton>
    </Flex>
  )
}

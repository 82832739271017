import { Flex, Card, Skeleton, Text } from '@chakra-ui/react'
import { InputField, Button } from 'ui'
import { useSendPaymentStore } from '../SendPaymentRouter'
import { useCallback, useEffect, useState } from 'react'
import currency from 'currency.js'
import { PriceInput } from 'ui'

type PaymentAmountProps = {
  loading: boolean
}
export function PaymentAmount({ loading }: PaymentAmountProps) {
  const { setSendMoneyAmountResult, sendMoneyAmountResult, setPage, bill } =
    useSendPaymentStore((state) => ({
      sendMoneyAmountResult: state.sendMoneyAmountResult,
      setSendMoneyAmountResult: state.setSendMoneyAmountResult,
      setPage: state.setPage,
      bill: state.bill,
    }))

  const [amountToSend, setAmountToSend] = useState(
    bill?.billData?.amountDue
      ? currency(bill.billData?.amountDue || 0).value.toString()
      : sendMoneyAmountResult?.submittedAmountCents
      ? currency(sendMoneyAmountResult?.submittedAmountCents, {
          fromCents: true,
        }).value.toString()
      : '',
  )
  const [paymentReason, setPaymentReason] = useState(
    bill?.billData?.reference || sendMoneyAmountResult?.reason || '',
  )

  const isDisabled = !paymentReason || isNaN(parseFloat(amountToSend))

  const onClick = useCallback(() => {
    setSendMoneyAmountResult({
      submittedAmountCents: currency(amountToSend || '0').intValue,
      reason: paymentReason,
    })
    setPage('paymentMethod')
  }, [amountToSend, setSendMoneyAmountResult, setPage, paymentReason])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !isDisabled) {
        onClick()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [onClick, isDisabled])

  useEffect(() => {
    if (bill?.billData?.amountDue) {
      setAmountToSend(currency(bill.billData?.amountDue).value.toString())
    }

    if (bill?.billData?.reference) {
      setPaymentReason(bill.billData?.reference)
    }
  }, [bill])

  const approvals =
    bill?.billPayables
      ?.flatMap((x) => x.approvals)
      .filter((x) => x?.status === 'PENDING') || []

  const pendingPaymentAmount = currency(
    (approvals ?? []).reduce(
      (acc, x) => acc + (x?.submittedAmountInCents ?? 0),
      0,
    ),
    { fromCents: true },
  ).format()

  return (
    <Flex width="540px" gap={8} flexDirection="column">
      <Card py={8} px={6}>
        <Flex flexDirection="column" gap={6}>
          {approvals?.length > 0 && (
            <Flex
              bg="dark.100"
              p={4}
              borderRadius="md"
              justifyContent="space-between"
            >
              <Text color="dark.600" fontSize="sm" fontWeight="semibold">
                {approvals?.length === 1
                  ? 'There is 1 payment pending approval, totaling: '
                  : `There are {count} payments pending approval, totaling: `}
                {pendingPaymentAmount}
              </Text>
            </Flex>
          )}
          {loading ? (
            <Skeleton height="40px" />
          ) : (
            <PriceInput
              label="Amount to send"
              placeholder="$100.00"
              value={amountToSend}
              fontWeight="medium"
              fontSize="sm"
              onPriceChange={(price) => setAmountToSend(price)}
            />
          )}
          {loading ? (
            <Skeleton height="40px" />
          ) : (
            <InputField
              label="Reason for payment"
              placeholder="Invoice #12345"
              fontWeight="medium"
              fontSize="sm"
              value={paymentReason}
              onChange={(e) => setPaymentReason(e.target.value)}
            />
          )}
        </Flex>
      </Card>
      <Button
        label="Next"
        width="100%"
        iconName="wallet"
        iconPosition="right"
        type="submit"
        isDisabled={isDisabled}
        onClick={onClick}
      />
    </Flex>
  )
}

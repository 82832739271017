import * as yup from 'yup'
import { US_STATES } from './src'
import moment from 'moment'
import { NickelBillStatus } from './types'

export const formatOrderRef = (val: string, hasClicked: boolean) => {
  if (val && val.length >= 1) {
    return val
  } else if (hasClicked) {
    return '#'
  }
}

export const parseOrderRef = (val: string) => {
  val = val.replace(/,/g, '').replace(/^\#/g, '')
  return val
}

export const websiteRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const STAGING_ORG_IDS = [
  'cm2xf0c7k0001u8ztqitzrwi8',
  'cm1jpz8ca0001t9021wjddo3k',
  'cm1jqheed000bt902ak0aidsv',
]

export const BaseVendorPayoutDetailsValidationSchema = yup.object().shape({
  paymentVia: yup.string().required(),
  email: yup.string(),
  achDetails: yup
    .object()
    .when('paymentVia', {
      is: 'ACH',
      then: yup
        .object({
          routingNumber: yup.string().required().length(9),
          accountNumber: yup.string().required(),
          bankName: yup.string(),
          confirmedAccountNumber: yup
            .string()
            .required()
            .oneOf([yup.ref('accountNumber')]),
        })
        .required(),
      otherwise: undefined,
    })
    .when('paymentVia', {
      is: 'check',
      then: yup.object({
        recipientAddress: yup.object().shape({
          zipCode: yup
            .string()
            .required()
            .length(5)
            .matches(/^[0-9]{5}/)
            .required(),
          state: yup
            .string()
            .required()
            .oneOf(US_STATES.states.map((x) => x.name)),
          streetAddress: yup.string().required(),
          city: yup.string().required(),
        }),
      }),
      otherwise: undefined,
    }),
})

export type VendorPayoutDetails = yup.InferType<
  typeof BaseVendorPayoutDetailsValidationSchema
>

export const LARGE_CELL_PX = 40
export const XLARGE_CELL_PX = 60

export const dateIsFutureAndNotWeekend = (
  date: Date,
  paymentMethod: 'ach_debit' | 'card',
) => {
  const isACH = paymentMethod === 'ach_debit'

  const isFutureAndWithinYear =
    moment(date).isSameOrAfter(moment(), 'days') &&
    moment(date).isBefore(moment().add(1, 'year'))

  if (isACH) {
    return (
      isFutureAndWithinYear &&
      moment(date).day() !== 0 &&
      moment(date).day() !== 6
    )
  }

  return isFutureAndWithinYear
}

export function getBusinessDay(time: Date, Tplus = 0) {
  const nextDay = moment(time).add(Tplus, 'days')

  if (nextDay.day() === 0) {
    return moment(nextDay).add(1, 'days').toDate()
  }

  if (nextDay.day() === 6) {
    return moment(nextDay).add(2, 'days').toDate()
  }

  return nextDay.toDate()
}

function addOrSubtractBusinessDays(
  startDate: moment.Moment | Date,
  days = 0,
  add = true,
) {
  const date = moment(startDate)
  let remainingDays = days

  while (remainingDays > 0) {
    if (add) {
      date.add(1, 'days')
    } else {
      date.subtract(1, 'days')
    }

    // Only count day if it's not a weekend
    if (date.day() !== 0 && date.day() !== 6) {
      remainingDays -= 1
    }
  }

  return moment(getBusinessDay(date.toDate()))
}

export function addBusinessDays(
  startDate: moment.Moment | Date,
  days = 0,
): moment.Moment {
  return addOrSubtractBusinessDays(startDate, days, true)
}

export function subtractBusinessDays(
  startDate: moment.Moment | Date,
  days = 0,
): moment.Moment {
  return addOrSubtractBusinessDays(startDate, days, false)
}

export const filterDeliveryDate = (
  date: Date,
  estimatedDeliveryDays: number,
) => {
  // Check that the delivery date minus the delivery date is the same or after today, otherwise it would have been in the past
  return (
    subtractBusinessDays(date, estimatedDeliveryDays).isSameOrAfter(
      moment(),
      'days',
    ) &&
    moment(date).isBefore(moment().add(1, 'year')) &&
    moment(date).day() !== 0 &&
    moment(date).day() !== 6
  )
}

export const billStatusToLabel = (status?: string | null) => {
  if (status === 'Paid') {
    return 'Paid in Full'
  } else if (status === 'Open') {
    return 'Outstanding'
  } else if (status === 'PartiallyPaid') {
    return 'Partially Paid'
  } else if (status === 'Paid outside Nickel') {
    return 'Paid outside Nickel'
  } else if (status === 'Queued') {
    return 'Queued'
  }
}

export const billStatusToType = (status?: string | null) => {
  if (status === 'Paid') {
    return 'success'
  } else if (status === 'Open') {
    return 'info'
  } else if (status === 'PartiallyPaid') {
    return 'progress'
  } else if (status === 'Paid outside Nickel') {
    return 'success'
  } else if (status === 'Queued') {
    return 'warning'
  }
}

export function getNickelBillStatus(
  billStatus: string,
  billPayableId: string | undefined,
  billPayment: any | undefined,
  pendingApprovalsLength: number,
  withdrawalDate: number | undefined,
): NickelBillStatus | undefined {
  if (billStatus === 'Open' || billStatus === 'PartiallyPaid') {
    if (withdrawalDate || pendingApprovalsLength > 0) {
      return 'Queued'
    }
  } else if (billStatus === 'Paid') {
    if (billPayableId && !billPayment) {
      return 'Queued'
    } else if (!billPayment) {
      return 'Paid outside Nickel'
    }
  }
  return undefined
}

export type RequireKeys<T, K extends keyof T> = T & { [P in K]-?: T[P] }

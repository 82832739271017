import { useMutation, useQuery } from '@apollo/client'
import {
  Card,
  CardBody,
  Fade,
  Flex,
  HStack,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Column,
  HFlex,
  Row,
  Separator,
  StatusPill,
  OverlayElements,
  BillOverlayHeader,
  InfoCard,
  SlimVendorCard,
  VFlex,
  Breadcrumb,
  OverlayHeaderNotes,
  billStatusToLabel,
  billStatusToType,
  getNickelBillStatus,
} from 'ui'
import SkeletonLoader from 'ui/src/components/Loaders/SkeletonLoader'

import {
  BillDocument,
  BillQuery,
  CancelScheduledPaymentDocument,
  DeactivateRecurringBillDocument,
  DeleteBillDocument,
  RecurringBillDocument,
  RecurringBillsDocument,
  UpdateBillDocument,
  UserRole,
} from '../../operations-types'
import { capitalize, lowerCase } from 'lodash'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { AlertDialogWithCancelButton } from '../bills/components/ScheduleBillOverlay'
import { VendorOverlay } from './vendor/VendorOverlay'
import { ScrollableColumn } from 'ui'

type BillPayOverlayProps = {
  billId: string
  onClose?: () => void
  onBack?: () => void
  onMakePayment?: () => void
  onVendorClick?: () => void
  breadcrumbs?: Breadcrumb[]
}

export const getBillLink = (billId: string) => {
  if (import.meta.env.MODE === 'production') {
    return `https://app.qbo.intuit.com/app/bill?txnId=${billId}`
  } else {
    return `https://app.sandbox.qbo.intuit.com/app/bill?txnId=${billId}`
  }
}

type BillDetailsProps = {
  totalAmount: number
  issueDate: string
  amountDue: number
  dueDate: string
}

const BillDetails = ({
  totalAmount,
  issueDate,
  amountDue,
  dueDate,
}: BillDetailsProps) => {
  return (
    <HFlex w="100%" alignItems="start" justifyContent="space-between">
      <VFlex w="100%" gap={4}>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Amount
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {currency(totalAmount || 0, {
              fromCents: false,
            }).format()}
          </Text>
        </VFlex>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Issued on
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {moment(issueDate || '').format('MM/DD/YYYY')}
          </Text>
        </VFlex>
      </VFlex>
      <VFlex w="100%" gap={4}>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Balance
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {currency(amountDue || 0, {
              fromCents: false,
            }).format()}
          </Text>
        </VFlex>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Due on
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {moment(dueDate || '').format('MM/DD/YYYY')}
          </Text>
        </VFlex>
      </VFlex>
    </HFlex>
  )
}

type PaymentsNodeProps = {
  bill: NonNullable<BillQuery['bill']>['bill']
}

const PaymentsNode = ({ bill }: PaymentsNodeProps) => {
  const navigate = useNavigate()
  const toaster = useToast()
  const [isCancelPaymentOpen, setIsCancelPaymentOpen] = useState(false)
  const [isDeactivateRecurringBillOpen, setIsDeactivateRecurringBillOpen] =
    useState(false)
  const [cancelScheduledPayment, { loading: cancelScheduledPaymentLoading }] =
    useMutation(CancelScheduledPaymentDocument, {
      refetchQueries: [BillDocument],
      onCompleted: (data) => {
        if (data?.cancelScheduledPayment?.error?.message) {
          toaster({
            status: 'error',
            title: 'Error',
            description: data?.cancelScheduledPayment?.error?.message,
          })
          return
        }

        toaster({
          status: 'success',
          title: `Success`,
          description: `Scheduled payment has been cancelled`,
        })
        setIsCancelPaymentOpen(false)
      },
    })

  const [deactivateRecurringBill, { loading: deactivateRecurringBillLoading }] =
    useMutation(DeactivateRecurringBillDocument, {
      refetchQueries: [
        BillDocument,
        RecurringBillsDocument,
        RecurringBillDocument,
      ],
      onCompleted: (data) => {
        if (data?.deactivateRecurringBill?.error?.message) {
          toaster({
            status: 'error',
            title: 'Error',
            description: data?.deactivateRecurringBill?.error?.message,
          })
          return
        }

        toaster({
          status: 'success',
          title: `Success`,
          description: `Recurring bill has been deactivated`,
        })
        setIsDeactivateRecurringBillOpen(false)
      },
    })
  const payments = (
    bill?.billPayables?.flatMap((x) => x.billPayments) || []
  ).map((x) => {
    return {
      to: {
        accountName: `${x?.vendorPayoutMethod?.bankName}`,
        accountNumber: x?.vendorPayoutMethod?.accountNumber || '',
        type: x?.vendorPayoutMethod?.accountType || '',
        street: x?.vendorPayoutMethod?.street || '',
        city: x?.vendorPayoutMethod?.city || '',
        state: x?.vendorPayoutMethod?.state || '',
        zip: x?.vendorPayoutMethod?.zip || '',
      },
      amount: x?.submittedAmountInCents || 0,
      date: x?.createdAt,
      paymentId: x?.id,
      status: x?.activity?.status,
    }
  })

  const queuedForPayments = bill?.billPayables
    ?.filter(
      (x) =>
        x.approvals?.some((approval) => approval.status === 'PENDING') ||
        x.status === 'SCHEDULED' ||
        (x.status === 'FAILED' && x.withdrawalDate),
    )
    .map((x) => {
      return {
        to: {
          accountName: `${x?.vendorPayoutMethod?.bankName}`,
          accountNumber: x?.vendorPayoutMethod?.accountNumber || '',
          type: x?.vendorPayoutMethod?.accountType || '',
          street: x?.vendorPayoutMethod?.street || '',
          city: x?.vendorPayoutMethod?.city || '',
          state: x?.vendorPayoutMethod?.state || '',
          zip: x?.vendorPayoutMethod?.zip || '',
        },
        id: x?.id,
        amount: x?.amountDueCents || 0,
        withdrawalDate:
          x?.status === 'SCHEDULED' || x?.status === 'FAILED'
            ? x?.withdrawalDate
            : '',
        approvals: x?.approvals?.filter(
          (approval) => approval.status === 'PENDING',
        ),
        status: x?.status,
        recurringBillId: x?.currentOnRecurringBill?.id,
      }
    })

  return (
    <Column wGrow className="pb-10 px-8" gap="medium">
      {!!queuedForPayments && queuedForPayments.length > 0 && (
        <div className="text-xl font-semibold">Queued for Payment</div>
      )}

      {queuedForPayments?.map((x, index) => {
        const statusText =
          x.approvals?.length && x.approvals?.length > 0
            ? 'Pending Approval'
            : x.status === 'SCHEDULED'
            ? 'Scheduled'
            : 'Failed'

        return (
          <Card w="100%" borderColor="gray.300" key={index}>
            <CardBody w="100%" p="14px">
              <Flex flexDirection="row" w="100%">
                <Flex flexDirection="row" w="60%">
                  <VFlex gap="1">
                    <Text fontSize="xs" color="gray.500">
                      Amount
                    </Text>
                    <Text className="text-md text-gray-900 text-semibold">
                      {currency(x.amount || 0, {
                        fromCents: true,
                      }).format()}
                    </Text>
                  </VFlex>
                  <Spacer />
                  <VFlex gap="1">
                    <div className="text-xs text-gray-500">Withdrawal Date</div>
                    <div className="text-md text-gray-900 text-semibold">
                      {x?.withdrawalDate
                        ? moment(x?.withdrawalDate).format('MM/DD/YYYY')
                        : 'ASAP'}
                    </div>
                  </VFlex>
                  <Spacer />
                  <VFlex gap="1">
                    <div className="text-xs text-gray-500">Status</div>
                    <Text fontSize="md">{statusText}</Text>
                  </VFlex>
                </Flex>
                <Spacer />
                {x?.approvals?.length && x?.approvals?.length > 0 ? (
                  <Row
                    gap="small"
                    y="center"
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/dashboard/accounts-payable/approvals`)
                    }}
                  >
                    <div className="text-sm whitespace-nowrap">
                      Go to Approvals
                    </div>
                    <ArrowRightIcon className="h-4 w-4 text-gray-800" />
                  </Row>
                ) : x?.status === 'SCHEDULED' ? (
                  <HStack>
                    <Button
                      label="Cancel"
                      variant="ghost"
                      verticalAlign="center"
                      size="xs"
                      isLoading={
                        cancelScheduledPaymentLoading ||
                        deactivateRecurringBillLoading
                      }
                      onClick={() => {
                        if (x?.recurringBillId) {
                          setIsDeactivateRecurringBillOpen(true)
                        } else {
                          setIsCancelPaymentOpen(true)
                        }
                      }}
                    />
                    <AlertDialogWithCancelButton
                      title="Cancel Scheduled Payment"
                      description="Are you sure you want to cancel this scheduled payment?"
                      onConfirm={async () => {
                        await cancelScheduledPayment({
                          variables: {
                            billPayableId: x.id,
                          },
                        })
                      }}
                      isOpen={isCancelPaymentOpen}
                      onClose={() => setIsCancelPaymentOpen(false)}
                      confirmLabel={`Yes, cancel payment`}
                      cancelLabel="No, do not cancel"
                    />
                    <AlertDialogWithCancelButton
                      title="Deactivate Recurring Bill"
                      description="Are you sure you want to deactivate this recurring bill?"
                      onConfirm={async () => {
                        if (!x.recurringBillId) {
                          return
                        }

                        await deactivateRecurringBill({
                          variables: {
                            recurringBillId: x.recurringBillId,
                          },
                        })
                      }}
                      isOpen={isDeactivateRecurringBillOpen}
                      onClose={() => setIsDeactivateRecurringBillOpen(false)}
                      confirmLabel="Yes, deactivate recurring bill"
                      cancelLabel="No, do not cancel"
                    />
                  </HStack>
                ) : (
                  ''
                )}
              </Flex>
            </CardBody>
          </Card>
        )
      })}
      <div className="text-xl font-semibold">Payments</div>
      {bill?.billPayables?.flatMap((x) => x.billPayments).length === 0 ? (
        <Row
          grow
          spacing="medium"
          className="text-sm text-black bg-gray-100 rounded py-4"
        >
          You have not made any payments
        </Row>
      ) : (
        ''
      )}
      {payments.map((x, index) => {
        return (
          <Card w="100%" borderColor="gray.300" key={index}>
            <CardBody w="100%" p="14px">
              <Flex flexDirection="row" w="100%">
                <Flex flexDirection="row" w="60%">
                  <VFlex alignItems="start" gap="1">
                    <Text fontSize="xs" color="gray.500">
                      Amount
                    </Text>
                    <Text className="text-md text-gray-900 text-semibold">
                      {currency(x.amount || 0, {
                        fromCents: true,
                      }).format()}
                    </Text>
                  </VFlex>
                  <Spacer />
                  <VFlex gap="1">
                    <div className="text-xs text-gray-500">Initiated On</div>
                    <div className="text-md text-gray-900 text-semibold">
                      {moment(parseInt(x?.date || '')).format('MM/DD/YYYY') ??
                        ''}
                    </div>
                  </VFlex>
                  <Spacer />
                  <VFlex gap="1">
                    <div className="text-xs text-gray-500">Status</div>
                    <StatusPill
                      status={
                        x?.status === 'SUCCEEDED'
                          ? 'success'
                          : x?.status === 'FAILED'
                          ? 'error'
                          : 'info'
                      }
                      spacing="xsmallNarrow"
                    >
                      {capitalize(
                        lowerCase(
                          x?.status === 'PENDING'
                            ? 'Processing'
                            : x?.status || '',
                        ),
                      )}
                    </StatusPill>
                  </VFlex>
                </Flex>
                <Spacer />
                <Row
                  gap="small"
                  y="center"
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(`/dashboard/transactions/payable/${x?.paymentId}`)
                  }}
                >
                  <div className="text-xs whitespace-nowrap">View Payment</div>
                  <ArrowRightIcon className="h-4 w-4 text-gray-800" />
                </Row>
              </Flex>
            </CardBody>
          </Card>
        )
      })}
    </Column>
  )
}

type OverviewNodeProps = {
  bill: NonNullable<BillQuery['bill']>['bill']
  onVendorClick?: () => void
}

const OverviewNode = ({ bill, onVendorClick }: OverviewNodeProps) => {
  const vendorContact = (bill?.vendor?.emails || []).join(', ')
  const vendorPayoutMethods = bill?.vendor?.vendorPayoutMethods || []
  const vendorPayoutMethod = vendorPayoutMethods?.at(0)

  return (
    <Column wGrow className="py-6" gap="xlarge">
      <VFlex w="100%" gap="6" px={8}>
        <Text fontSize="xl" fontWeight="semibold">
          Vendor
        </Text>
        <SlimVendorCard
          vendorName={bill?.vendor?.name || ''}
          vendorId={bill?.vendor?.id || ''}
          vendorPayoutMethod={vendorPayoutMethod ?? undefined}
          vendorEmail={vendorContact || ''}
          onVendorClick={onVendorClick}
        />
      </VFlex>
      <Separator orientation="horizontal" />
      <Column wGrow gap="large" className="px-8">
        <Text fontSize="xl" fontWeight="semibold">
          Bill Details
        </Text>
        <BillDetails
          totalAmount={bill?.billData?.totalAmount || 0}
          issueDate={bill?.billData?.issueDate || ''}
          amountDue={bill?.billData?.amountDue || 0}
          dueDate={bill?.billData?.dueDate || ''}
        />
      </Column>

      {bill?.billSource === 'QUICKBOOKS' && (
        <>
          <Separator orientation="horizontal" />

          <Column wGrow gap="large" className="px-8">
            <Text fontSize="xl" fontWeight="semibold">
              Accounting Sync
            </Text>

            <InfoCard
              title={bill?.billData?.reference || ''}
              subtitle={
                bill?.billData?.reference ||
                bill?.billData?.supplierRef?.supplierName ||
                bill?.vendor?.name ||
                ''
              }
              icon="quickbooks"
              onButtonClick={() => {
                window.open(getBillLink(bill?.externalId || ''))
              }}
              buttonLabel="View in Quickbooks"
            />
          </Column>
        </>
      )}
    </Column>
  )
}

export const BillPayOverlay = (props: BillPayOverlayProps) => {
  const toaster = useToast()

  const { data } = useQuery(BillDocument, {
    variables: { billId: props.billId },
  })

  const [deleteBill, { loading: deleteBillLoading }] = useMutation(
    DeleteBillDocument,
    {
      refetchQueries: ['Bills'],
      onCompleted: () => {
        toaster({
          status: 'success',
          title: `Success`,
          description: `Bill has been deleted`,
        })
        props.onClose && props.onClose()
      },
    },
  )

  const [updateBill] = useMutation(UpdateBillDocument, {
    refetchQueries: [BillDocument],
  })

  const bill = data?.bill?.bill

  const vendorPayoutMethods = bill?.vendor?.vendorPayoutMethods || []
  const vendorPayoutMethod = vendorPayoutMethods?.at(0)

  const payments = (
    bill?.billPayables?.flatMap((x) => x.billPayments) || []
  ).map((x) => {
    return {
      to: {
        accountName: `${vendorPayoutMethod?.bankName}`,
        accountNumber: vendorPayoutMethod?.accountNumber || '',
        type: vendorPayoutMethod?.accountType || '',
        street: vendorPayoutMethod?.street || '',
        city: vendorPayoutMethod?.city || '',
        state: vendorPayoutMethod?.state || '',
        zip: vendorPayoutMethod?.zip || '',
      },
      amount: x?.submittedAmountInCents || 0,
      date: x?.createdAt,
      paymentId: x?.id,
      status: x?.status,
    }
  })

  const queuedForPayments = bill?.billPayables
    ?.filter(
      (x) =>
        x.approvals?.some((approval) => approval.status === 'PENDING') ||
        x.status === 'SCHEDULED' ||
        (x.status === 'FAILED' && x.withdrawalDate),
    )
    .map((x) => {
      return {
        to: {
          accountName: `${vendorPayoutMethod?.bankName}`,
          accountNumber: vendorPayoutMethod?.accountNumber || '',
          type: vendorPayoutMethod?.accountType || '',
          street: vendorPayoutMethod?.street || '',
          city: vendorPayoutMethod?.city || '',
          state: vendorPayoutMethod?.state || '',
          zip: vendorPayoutMethod?.zip || '',
        },
        id: x?.id,
        amount: x?.amountDueCents || 0,
        withdrawalDate:
          x?.status === 'SCHEDULED' || x?.status === 'FAILED'
            ? x?.withdrawalDate
            : '',
        approvals: x?.approvals?.filter(
          (approval) => approval.status === 'PENDING',
        ),
        status: x?.status,
      }
    })

  const { getUser } = useLoggedInStore()
  const user = getUser()

  const IS_PERMITTED =
    user?.isUserPermitted(UserRole.Manager) ||
    !!user?.organization?.approvalPolicies?.length

  type TabType = 'overview' | 'payments'
  const [tab, setTab] = useState<TabType>('overview')

  const overviewNode = (
    <OverviewNode bill={bill!} onVendorClick={props.onVendorClick} />
  )

  if (!bill) {
    return null
  }

  const paymentsNode = <PaymentsNode bill={bill} />

  const hasAtleastOneButton =
    (IS_PERMITTED && (bill?.billData?.amountDue || 0) > 0) ||
    bill?.billSource !== 'QUICKBOOKS'

  const isQueued = (queuedForPayments?.length || 0) > 0
  const hasPendingPayment =
    (payments.filter((x) => x.status === 'PENDING')?.length || 0) > 0

  const badgeCount = (queuedForPayments?.length || 0) + (payments?.length || 0)

  const billPayable = bill?.billPayables?.[0]
  const pendingApprovals =
    billPayable?.approvals?.filter((x) => x?.status === 'PENDING') || []
  const billPayment = billPayable?.billPayments?.[0]

  const nickelBillStatus = getNickelBillStatus(
    bill?.billData?.status || '',
    billPayable?.id,
    billPayment,
    pendingApprovals.length,
    billPayable?.withdrawalDate || undefined,
  )

  return (
    <>
      {/* //TODO: Add edit bill button to Bill Overlay Header */}
      <BillOverlayHeader
        onClose={props.onClose!}
        title={
          bill?.billData?.reference ||
          bill?.billData?.supplierRef?.supplierName ||
          bill?.vendor?.name ||
          ''
        }
        breadcrumbs={props.breadcrumbs}
        type="bills"
        renderHeader={true}
        statusText={billStatusToLabel(
          nickelBillStatus || bill?.billData?.status || '',
        )}
        statusType={billStatusToType(
          nickelBillStatus || bill?.billData?.status || '',
        )}
      />
      <OverlayHeaderNotes
        onSave={(note) => {
          updateBill({
            variables: {
              billId: bill?.id,
              internalNote: note,
            },
          })
        }}
        note={bill?.internalNote || ''}
      />
      <ScrollableColumn wGrow grow>
        <Fade
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <ScrollableColumn className="w-full">
            <Separator orientation="horizontal" />
            <OverlayElements
              tabIndex={tab === 'overview' ? 0 : 1}
              elementClassName="px-0"
              onClick={(idx) => {
                if (idx === 0) {
                  setTab('overview')
                } else if (idx === 1) {
                  setTab('payments')
                }
              }}
              nodes={[
                {
                  label: 'Overview',
                  node: overviewNode,
                },
                {
                  label: 'Payments',
                  node: paymentsNode,
                  badgeCount: badgeCount > 0 ? badgeCount : undefined,
                },
              ]}
            />
          </ScrollableColumn>
        </Fade>
      </ScrollableColumn>
      {props.onBack && (
        <>
          <Separator orientation="horizontal" />
          <HStack w="100%" px={10} py={6} justifyContent="flex-end">
            <Button label="Back" onClick={props.onBack} />
          </HStack>
        </>
      )}
      {hasAtleastOneButton && !isQueued && !hasPendingPayment && (
        <>
          <Separator orientation="horizontal" />
          <HStack w="100%" px={10} py={6} justifyContent="flex-end">
            {bill?.billSource !== 'QUICKBOOKS' && (
              <Button
                label="Delete"
                variant="ghost"
                isLoading={deleteBillLoading}
                onClick={() =>
                  deleteBill({
                    variables: {
                      billId: bill.id,
                    },
                  })
                }
              />
            )}
            {IS_PERMITTED && (bill?.billData?.amountDue || 0) > 0 && (
              <Button
                label="Make Payment"
                {...{
                  onClick: props.onMakePayment,
                }}
              />
            )}
          </HStack>
        </>
      )}
    </>
  )
}

type BillPayStates = 'vendor' | 'bill'

type BillPayMasterOverlayProps = {
  billId: string
  onClose?: () => void
  onBack?: () => void
  onMakePayment?: () => void
  breadcrumbs?: Breadcrumb[]
}

export const BillPayMasterOverlay = ({
  billId,
  onClose,
  onBack,
  onMakePayment,
  breadcrumbs,
}: BillPayMasterOverlayProps) => {
  const { data, loading: billLoading } = useQuery(BillDocument, {
    variables: { billId: billId },
  })

  const bill = data?.bill?.bill
  const vendor = bill?.vendor

  const [state, setState] = useState<BillPayStates>('bill')
  const components: Record<BillPayStates, React.ReactNode> = {
    vendor: (
      <VendorOverlay
        vendorIdProp={vendor?.id}
        onBack={() => setState('bill')}
        breadcrumbs={breadcrumbs}
        isSecondaryOverlay
        onVendorClose={onClose}
      />
    ),
    bill: (
      <BillPayOverlay
        billId={billId}
        onClose={onClose}
        onBack={onBack}
        onMakePayment={onMakePayment}
        breadcrumbs={breadcrumbs}
        onVendorClick={() => {
          setState('vendor')
        }}
      />
    ),
  }

  if (!bill || !vendor) {
    return null
  }

  return (
    <>
      {billLoading ? (
        <SkeletonLoader className="!w-[200px]" />
      ) : (
        components[state]
      )}
    </>
  )
}

import { Card, CardBody, HStack, Icon, Spacer, Text } from '@chakra-ui/react'
import {
  ArrowRightIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/outline'
import currency from 'currency.js'
import moment from 'moment'
import { HFlex, VFlex } from 'ui'
import { Bill } from '../../../operations-types'

export type VendorBillsProps = {
  unpaidBills: Bill[]
  onBillClick: (billId: string) => void
}

export default function VendorBills(props: VendorBillsProps) {
  const { unpaidBills } = props

  return (
    <>
      {unpaidBills && unpaidBills?.length > 0 ? (
        <VFlex px={8} w="100%" gap="4" py="4">
          <div className="text-xl font-semibold">Unpaid Bills</div>
          <VFlex gap="4" w="100%">
            {unpaidBills?.map((bill) => (
              <Card w="100%" borderColor="gray.300" key={bill.id}>
                <CardBody w="100%" p="14px">
                  <HFlex w="100%">
                    <HFlex w="60%">
                      <VFlex alignItems="start" gap="1">
                        <Text fontSize="xs" color="gray.500">
                          Reference
                        </Text>
                        <Text className="text-md text-gray-900 text-semibold">
                          {bill.billData?.reference}
                        </Text>
                      </VFlex>
                      <Spacer />
                      <VFlex gap="1">
                        <Text fontSize="xs" color="gray.500">
                          Due Date
                        </Text>
                        <Text className="text-md text-gray-900 text-semibold">
                          {moment(bill.billData?.dueDate).format('MM/DD/YYYY')}
                        </Text>
                      </VFlex>
                      <Spacer />
                      <VFlex gap="1">
                        <Text fontSize="xs" color="gray.500">
                          Amount Due
                        </Text>
                        <Text className="text-md text-gray-900 text-semibold">
                          {currency(bill.billData?.amountDue || 0, {
                            fromCents: false,
                          }).format()}
                        </Text>
                      </VFlex>
                    </HFlex>
                    <Spacer />
                    <HStack
                      as="button"
                      cursor="pointer"
                      onClick={() => props.onBillClick(bill.id)}
                    >
                      <Text
                        fontSize="sm"
                        color="purple.600"
                        fontWeight="medium"
                        _hover={{ color: 'purple.800' }}
                      >
                        Pay Bill
                      </Text>
                      <ArrowRightIcon
                        width={12}
                        height={12}
                        color="purple.600"
                      />
                    </HStack>
                  </HFlex>
                </CardBody>
              </Card>
            ))}
          </VFlex>
        </VFlex>
      ) : (
        <VFlex px={8} w="100%" gap="4" py="4">
          <Card borderColor="gray.300">
            <CardBody>
              <HFlex gap="3">
                <Icon
                  as={BuildingLibraryIcon}
                  borderRadius="full"
                  bgColor="gray.200"
                  color="gray.800"
                  boxSize="12"
                  p="3"
                />
                <VFlex>
                  <Text fontSize="xl" fontWeight="semibold">
                    No unpaid bills
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    This vendor has no outstanding bills to pay
                  </Text>
                </VFlex>
              </HFlex>
            </CardBody>
          </Card>
        </VFlex>
      )}
    </>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import {
  Button,
  Column,
  DatePickerCardInput,
  HFlex,
  Row,
  STAGING_ORG_IDS,
} from 'ui'
import {
  GetFactoringFeeDocument,
  OrganizationTradeAccountsDocument,
} from '../../../operations-types'

import moment from 'moment'
import { useLoggedInStore } from '../../layout/LoggedInStore'
import currency from 'currency.js'

type Project = {
  projectName: string
  id: string
  tradeAccountName: string
}

type ProjectOption = {
  value: Project
  label: string
}

type TradeAccountSelectorProps = {
  onProjectSelect: (project: Project) => void
}

function StagingTradeAccountSelector({
  onProjectSelect,
}: TradeAccountSelectorProps) {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const { data, loading } = useQuery(OrganizationTradeAccountsDocument, {})

  const [options, setOptions] = useState<ProjectOption[]>([])

  useEffect(() => {
    if (!loading && data?.organization?.organization?.tradeAccounts) {
      const projectOptions: ProjectOption[] =
        data?.organization?.organization?.tradeAccounts
          .filter(
            (tradeAccount) => tradeAccount.underwriteStatus === 'APPROVED',
          )
          ?.flatMap((tradeAccount) =>
            (tradeAccount.projects || []).map((project) => ({
              value: {
                projectName: project.projectName,
                tradeAccountName: tradeAccount.name,
                id: project.id,
              } as Project,
              label: `${project.projectName} | ${tradeAccount.name}`,
            })),
          )
          .filter((projects) => projects !== undefined) || []
      setOptions(projectOptions)
    }
  }, [data, loading])

  const loadOptions = (inputValue: string): Promise<ProjectOption[]> => {
    return new Promise((resolve) => {
      const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      )
      resolve(filteredOptions)
    })
  }

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      defaultOptions={options}
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(false)}
      onMenuOpen={() => setMenuIsOpen(true)}
      onBlur={() => setMenuIsOpen(true)}
      openMenuOnClick={true}
      openMenuOnFocus={true}
      inputValue={inputValue}
      placeholder="Type to search for a project"
      styles={{
        noOptionsMessage: (base) => ({
          ...base,
          color: 'gray.800',
          cursor: 'pointer',
        }),
      }}
      components={{
        Option: (props) => (
          <Column gap="small">
            {props.innerProps.id?.includes('option-0') &&
            (inputValue?.trim().length === 0 ||
              inputValue === undefined ||
              inputValue === null) ? (
              <Column grow y="center" x="left" className="pb-0" wGrow>
                <Row grow spacing="small">
                  <Text className="text-uppercase text-sm text-gray-500">
                    Recent Recipients
                  </Text>
                </Row>
                <Divider />
              </Column>
            ) : (
              ''
            )}
            <Row
              grow
              y="center"
              x="left"
              spacing="small"
              className="cursor-pointer hover:bg-blue-100"
              {...{
                onClick: () => {
                  setInputValue(props.children as string)
                  setMenuIsOpen(false)
                  onProjectSelect(props.data.value)
                },
              }}
            >
              {props.label}
            </Row>
          </Column>
        ),
      }}
      onInputChange={(value) => {
        setInputValue(value)
        setMenuIsOpen(true)
      }}
      noOptionsMessage={(inputValue) => inputValue.inputValue}
      onChange={(option) => {
        if (!option) {
          return
        }
        onProjectSelect(option.value)
        setMenuIsOpen(false)
      }}
    />
  )
}

type TradeAccountSelectProps = {
  setProjectId(projectId: string): void
  projectId: string
  setTermsSelected(termsSelected: Date | null): void
  termsSelected: Date | null
  setQuestionFormId(questionFormId: string): void
  amount?: string
}

export function StagingTradeAccountSelect(props: TradeAccountSelectProps) {
  const user = useLoggedInStore((state) => state.getUser())
  const [tradeAccountName, setTradeAccountName] = useState<string | undefined>()
  const [projectName, setProjectName] = useState<string | undefined>()

  const [getFactoringFee] = useMutation(GetFactoringFeeDocument)

  const [feeLoading, setFeeLoading] = useState<boolean>(false)

  const [factoringFee, setFactoringFee] = useState<string | undefined>()

  const [dueDate, setDueDate] = useState<Date>(new Date())

  return (
    <Flex
      flexDirection="column"
      gap={4}
      border="1px solid"
      borderRadius="8px"
      backgroundColor="dark.600"
      p={4}
    >
      {STAGING_ORG_IDS.includes(user.organization.id) ? (
        props.amount ? (
          <Box>
            <Text fontSize="lg" fontWeight="bold" color="white">
              Select a project to factor this invoice
            </Text>
            <Text fontSize="sm" color="white">
              Nickel will advance 95% of the invoice when your customer accepts
              terms. The remaining less fees will be sent after your customer
              completes payment before the due date.
            </Text>
          </Box>
        ) : (
          <Box>
            <Text fontSize="lg" fontWeight="bold" color="white">
              Enter an amount to see terms
            </Text>
            <Text fontSize="sm" color="white">
              Nickel will advance 95% of the invoice when your customer accepts
              terms. The remaining less fees will be sent after your customer
              completes payment before the due date.
            </Text>
          </Box>
        )
      ) : (
        <Box>
          <Text fontSize="lg" fontWeight="bold" color="white">
            Select a project to extend terms
          </Text>
          <Text fontSize="sm" color="white">
            Nickel will extend net 30-60 day terms and advance payment if your
            customer accepts the terms.
          </Text>
        </Box>
      )}

      <Divider />
      {props.amount &&
        (!props.projectId ? (
          <StagingTradeAccountSelector
            onProjectSelect={(project) => {
              props.setProjectId(project.id)
              setTradeAccountName(project.tradeAccountName)
              setProjectName(project.projectName)
            }}
          />
        ) : (
          <HFlex alignItems="center">
            <HFlex gap={6}>
              <VStack gap="0" spacing="0" alignItems="Start" p="0">
                <FormLabel color="white" p="0" m="0" fontSize="xs">
                  Project Name
                </FormLabel>
                <Text p="0" color="white" fontWeight="500">
                  {projectName}
                </Text>
              </VStack>
              <Spacer />
              <VStack gap="0" spacing="0" alignItems="Start" p="0">
                <FormLabel color="white" p="0" m="0" fontSize="xs">
                  Trade Account
                </FormLabel>
                <Text p="0" color="white" fontWeight="500">
                  {tradeAccountName}
                </Text>
              </VStack>
            </HFlex>
            <Spacer />
            <Button
              size="xs"
              label="Remove"
              variant="ghost"
              status="warning"
              onClick={() => {
                props.setProjectId('')
                props.setTermsSelected(null)
                setFactoringFee(undefined)
              }}
            />
          </HFlex>
        ))}

      {STAGING_ORG_IDS.includes(user.organization.id) && props.amount && (
        <>
          {!props.termsSelected && props.projectId && (
            <>
              <DatePickerCardInput
                label="Select the due date to see terms"
                className="text-sm"
                selected={dueDate}
                includeDateIntervals={[
                  {
                    start: moment(new Date()).add(14, 'days').toDate(),
                    end: moment(new Date()).add(60, 'days').toDate(),
                  },
                ]}
                onChange={async (d) => {
                  if (!d) {
                    return
                  }
                  setDueDate(d)
                  setFeeLoading(true)
                  props.setTermsSelected(d)
                  const response = await getFactoringFee({
                    variables: {
                      projectId: props.projectId,
                      dueDateEpochMillis: d.getTime(),
                      amount: currency(props.amount || 0).intValue,
                    },
                  })

                  if (
                    response.data &&
                    response.data.getFactoringFee?.feeAmountInCents &&
                    response.data.getFactoringFee?.questionFormId
                  ) {
                    const fee = currency(
                      response.data?.getFactoringFee?.feeAmountInCents,
                      { fromCents: true },
                    ).format()
                    setFactoringFee(fee)
                    props.setQuestionFormId(
                      response.data.getFactoringFee.questionFormId,
                    )
                    setFeeLoading(false)
                  }

                  if (response.data?.getFactoringFee?.error) {
                    setFactoringFee('Not approved for terms')
                    setFeeLoading(false)
                  }
                }}
              />
            </>
          )}

          {props.termsSelected && (
            <HFlex alignItems="center">
              <HFlex gap={6}>
                <VStack gap="0" spacing="0" alignItems="Start" p="0">
                  <FormLabel color="white" p="0" m="0" fontSize="xs">
                    Term Duration
                  </FormLabel>
                  <Text p="0" color="white" fontWeight="500">
                    {moment(props.termsSelected).diff(moment(), 'days') + 1}{' '}
                    days
                  </Text>
                </VStack>
                <Spacer />
                <VStack gap="0" spacing="0" alignItems="Start" p="0">
                  <FormLabel color="white" p="0" m="0" fontSize="xs">
                    Due Date
                  </FormLabel>
                  <Text p="0" color="white" fontWeight="500">
                    {moment(props.termsSelected).format('MMM DD, YYYY')}
                  </Text>
                </VStack>
                <VStack gap="0" spacing="0" alignItems="Start" p="0">
                  <FormLabel color="white" p="0" m="0" fontSize="xs">
                    Fees
                  </FormLabel>
                  {feeLoading ? (
                    <Spinner />
                  ) : (
                    <Text p="0" color="white" fontWeight="500">
                      {factoringFee}
                    </Text>
                  )}
                </VStack>
              </HFlex>
              <Spacer />
              <Button
                size="xs"
                label="Remove"
                variant="ghost"
                status="warning"
                onClick={() => {
                  props.setTermsSelected(null)
                  setFactoringFee(undefined)
                }}
              />
            </HFlex>
          )}
        </>
      )}
    </Flex>
  )
}

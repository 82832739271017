import { Spacer, Text, Tooltip } from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Status } from '../../types'
import StatusPill from '../Indicators/StatusPill'
import { UnstandardizedColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import { HFlex } from '../v3/FlexUtils'

export type OverlayTitleProps = {
  title: string
  subtitle: string
  className?: string
  quickbooksLink?: string
  statusText?: string
  titleRight?: React.ReactNode
  statusType?: Status
}
export function OverlayTitle({
  title,
  subtitle,
  className,
  quickbooksLink,
  titleRight,
  statusType,
  statusText,
}: OverlayTitleProps) {
  return (
    <UnstandardizedColumn className={className || 'mx-8 py-4 w-full'}>
      <HFlex
        alignItems="center"
        gap="small"
        justifyContent="space-between"
        w="100%"
        verticalAlign="center"
        pb="2"
      >
        <Tooltip
          label={title}
          openDelay={100}
          fontSize="xs"
          hasArrow
          bg={'gray.500'}
        >
          <Text
            fontSize="3xl"
            color="gray.800"
            fontWeight="semibold"
            noOfLines={1}
          >
            {title}
          </Text>
        </Tooltip>
        {titleRight}
      </HFlex>
      <div className="text-gray-500">{subtitle}</div>
      <HFlex justifyContent="space-between" alignContent="center" w="100%">
        {statusText && (
          <StatusPill status={statusType || 'info'} spacing="xsmallNarrow">
            {statusText}
          </StatusPill>
        )}
        <Spacer />
        {quickbooksLink && (
          <Row y="center" className="gap-[5px] relative left-[3px]">
            <a
              className="text-purple-600 text-sm cursor-pointer"
              target="_blank"
              rel="noreferrer"
              href={quickbooksLink}
            >
              View in Quickbooks
            </a>
            <ArrowRightIcon className="w-4 stroke-purple-600" />
          </Row>
        )}
      </HFlex>
    </UnstandardizedColumn>
  )
}

export default OverlayTitle

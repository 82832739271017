import {
  Box,
  HStack,
  Text,
  Icon,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  Breadcrumb,
  OverlayHeaderBreadcrumbs,
} from './OverlayHeaderBreadcrumbs'
import { HFlex } from '../v3/FlexUtils'
import { Status } from '../../types'
import StatusPill from '../Indicators/StatusPill'
import { ReceiptPercentIcon } from '../Icons'
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'
import { DollarReceipt } from '../Icons/DollarReceipt'
import { useEffect, useState } from 'react'

type OverlayHeaderProps = {
  onClose: () => void
  title: string
  subtitle?: string
  breadcrumbs?: Breadcrumb[]
  editButton?: React.ReactNode
  status?: string
  statusText?: string
  statusType?: Status
  type: OverlayHeaderType
  renderHeader?: boolean
}

export type OverlayHeaderType =
  | 'transactions'
  | 'bills'
  | 'payments'
  | 'vendor'
  | 'recurring'

type OverlayHeaderNotesProps = {
  onSave: (note: string) => void
  note: string
}

export function OverlayHeaderNotes({ onSave, note }: OverlayHeaderNotesProps) {
  const [localNote, setLocalNote] = useState(note)

  useEffect(() => {
    setLocalNote(note)
  }, [note])

  return (
    <HStack px={8} pb={4} w="100%">
      <Textarea
        value={localNote}
        placeholder="Add an internal note"
        rows={1}
        border="none"
        color="gray.700"
        p={0}
        width="100%"
        resize="none"
        onBlur={() => {
          if (localNote !== note) {
            onSave(localNote)
          }
        }}
        onChange={(e) => setLocalNote(e.target.value)}
      />
    </HStack>
  )
}

type BoxIconProps = {
  type: OverlayHeaderType
}

export function BoxIcon({ type }: BoxIconProps) {
  const typeToIcon: Record<OverlayHeaderType, React.ElementType> = {
    transactions: ReceiptPercentIcon,
    bills: DollarReceipt,
    payments: ReceiptPercentIcon,
    vendor: BuildingStorefrontIcon,
    recurring: ReceiptPercentIcon,
  }
  const typeToColor: Record<OverlayHeaderType, string> = {
    transactions: 'blue',
    bills: 'blue',
    payments: 'blue',
    vendor: 'purple',
    recurring: 'blue',
  }

  const iconColorScheme = typeToColor[type]

  return (
    <Box bg={`${iconColorScheme}.100`} p={3} borderRadius="md">
      <Icon
        as={typeToIcon[type]}
        boxSize={6}
        color={`${iconColorScheme}.600`}
      />
    </Box>
  )
}

export function BillOverlayHeader({
  onClose,
  title,
  breadcrumbs,
  subtitle,
  editButton,
  statusText,
  statusType,
  type,
  renderHeader = true,
}: OverlayHeaderProps) {
  return (
    <VStack width="100%" px={8} pt={6} pb={5} alignItems="start" gap="4">
      {breadcrumbs && (
        <OverlayHeaderBreadcrumbs breadcrumbs={breadcrumbs} onClose={onClose} />
      )}
      {renderHeader && (
        <HFlex justifyContent="space-between" alignItems="center" width="100%">
          <HFlex alignItems="center" gap={4}>
            <BoxIcon type={type} />
            <VStack gap={0} alignItems="start" spacing={0}>
              {statusText && (
                <StatusPill
                  status={statusType || 'info'}
                  spacing="xsmallNarrow"
                >
                  {statusText}
                </StatusPill>
              )}
              <Tooltip label={title}>
                <Text
                  fontSize="xl"
                  fontWeight="semibold"
                  color="gray.800"
                  noOfLines={1}
                >
                  {title}
                </Text>
              </Tooltip>
              {subtitle && (
                <Text fontSize="sm" color="gray.500">
                  {subtitle}
                </Text>
              )}
            </VStack>
          </HFlex>
          {editButton && editButton}
        </HFlex>
      )}
    </VStack>
  )
}

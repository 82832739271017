import { HStack, Icon, Text } from '@chakra-ui/react'
import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { HFlex } from '../v3/FlexUtils'

export type Breadcrumb = {
  label: string
  onClick?: () => void
  isActive?: boolean
}

type OverlayHeaderBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[]
  onClose: () => void
}

export function OverlayHeaderBreadcrumbs(props: OverlayHeaderBreadcrumbsProps) {
  return (
    <HFlex justifyContent="space-between" alignItems="center" width="100%">
      <HStack
        divider={<ArrowRightIcon className="h-3 w-3 text-gray-400" />}
        width="100%"
      >
        {props.breadcrumbs.map((breadcrumb, idx) => (
          <Text
            fontSize="xs"
            px={idx === 0 ? undefined : '2'}
            paddingRight={idx === 0 ? '2' : undefined}
            color={breadcrumb.isActive ? 'gray.800' : 'gray.400'}
            key={idx}
            cursor={
              breadcrumb.onClick && !breadcrumb.isActive ? 'pointer' : undefined
            }
            _hover={
              breadcrumb.onClick
                ? {
                    color: 'gray.800',
                  }
                : undefined
            }
            onClick={breadcrumb.onClick}
          >
            {breadcrumb.label}
          </Text>
        ))}
      </HStack>

      <Icon
        as={XMarkIcon}
        boxSize={4}
        className="stroke-gray-400"
        onClick={props.onClose}
        cursor="pointer"
      />
    </HFlex>
  )
}

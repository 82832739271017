import { FieldArray } from 'formik'
import { CategoryLineRow, ItemLineRow } from './LineItemsCard'
import {
  VStack,
  Popover,
  Center,
  PopoverContent,
  PopoverTrigger,
  Box,
  Divider,
  Text,
  CardBody,
  Icon,
  Spacer,
  Card,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button as ChakraButton,
} from '@chakra-ui/react'
import {
  InformationCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { ArrayHelpers, useFormikContext } from 'formik'
import { HFlex, VFlex } from 'ui'
import { Category, Item, LineItem } from 'ui/src/types'
import { RecurringBillFormData } from './CreateRecurringBill'

export function calculateItemAmountCents(quantity: string, rate: string) {
  return currency(rate || 0, { fromCents: false }).multiply(quantity || 0)
}

export function calculateTotalAmountCents(lineItems: LineItem[]) {
  return lineItems?.reduce(
    (acc, lineItem) =>
      acc +
      (currency(lineItem?.lineDetails?.amountCents || 0, { fromCents: true })
        .intValue || 0),
    0,
  )
}

function updateLineItemAndTotal(
  arrayHelpers: ArrayHelpers,
  index: number,
  updatedLineItem: LineItem | null,
  setFieldValue: (field: string, value: any) => void,
  allLineItems: LineItem[],
) {
  const updatedLineItems = [...(allLineItems || [])]

  if (updatedLineItem) {
    updatedLineItems[index] = updatedLineItem
  } else {
    updatedLineItems.splice(index, 1)
  }

  const newTotal = calculateTotalAmountCents(updatedLineItems)
  arrayHelpers.replace(index, updatedLineItem)
  setFieldValue('amountCents', newTotal || 0)
}

type CategoriesAndItemsSectionProps = {
  categoryLines: Category[]
  itemLines: Item[]
  getAvailableOptions: (
    currentLineItem: LineItem,
    allLineItems: LineItem[],
  ) => {
    availableCategories: Category[]
    availableItems: Item[]
  }
}

export function CategoriesAndItemsSection({
  categoryLines,
  itemLines,
  getAvailableOptions,
}: CategoriesAndItemsSectionProps) {
  const { setFieldValue, values } = useFormikContext<RecurringBillFormData>()

  return (
    <>
      <Divider />

      <VFlex px="8" py="4" gap="6" w="100%">
        <Box>
          <HFlex>
            <Center>
              <HFlex>
                <Text
                  fontSize="xl"
                  fontWeight="semibold"
                  color="gray.800"
                  mb="6"
                >
                  Category and Item Details
                  <Box ps="2" display="inline-block">
                    <Popover trigger="hover" placement="right">
                      <PopoverTrigger>
                        <span>
                          <Icon
                            as={InformationCircleIcon}
                            boxSize={4}
                            cursor="pointer"
                          />
                        </span>
                      </PopoverTrigger>
                      <PopoverContent>
                        <Card>
                          <CardBody>
                            <Text fontSize="sm" color="gray.500">
                              You must create a category or item in quickbooks
                              to add it to your bill.
                            </Text>
                          </CardBody>
                        </Card>
                      </PopoverContent>
                    </Popover>
                  </Box>
                </Text>
              </HFlex>
            </Center>
            <Spacer />
            <Text fontSize="md" color="gray.800">
              Total:{' '}
              {currency(calculateTotalAmountCents(values.lineItems || []), {
                fromCents: true,
              }).format()}
            </Text>
          </HFlex>
          <VStack spacing={4} align="stretch">
            <VFlex gap="2">
              <FieldArray
                name="lineItems"
                render={(arrayHelpers) => (
                  <>
                    {values.lineItems?.map((lineItem, index) => {
                      if (!lineItem) return null

                      const { availableCategories, availableItems } =
                        getAvailableOptions(lineItem, values.lineItems || [])

                      return lineItem?.type === 'category' ? (
                        <CategoryLineRow
                          key={lineItem.id}
                          index={index}
                          category={lineItem}
                          availableCategories={availableCategories}
                          availableItems={availableItems}
                          onChangeType={(type: string) => {
                            if (type === 'item') {
                              arrayHelpers.replace(index, {
                                id: '',
                                type: 'item',
                                lineDetails: {
                                  amountCents: currency('0'),
                                  name: '',
                                  description: '',
                                  rate: '',
                                  quantity: '',
                                },
                              })
                            }
                          }}
                          onChangeCategoryId={(categoryId: string) => {
                            arrayHelpers.replace(index, {
                              ...lineItem,
                              id: categoryId,
                            })
                          }}
                          onChangeAmount={(amount) => {
                            setFieldValue(
                              `lineItems.${index}.lineDetails.amountCents`,
                              currency(amount, {
                                fromCents: false,
                              }).intValue,
                            )
                            const newTotal = calculateTotalAmountCents(
                              values.lineItems || [],
                            )
                            setFieldValue('amountCents', newTotal || 0)
                          }}
                          onDelete={() => {
                            updateLineItemAndTotal(
                              arrayHelpers,
                              index,
                              null,
                              setFieldValue,
                              values.lineItems || [],
                            )
                          }}
                          onChangeDescription={(description) => {
                            arrayHelpers.replace(index, {
                              ...lineItem,
                              lineDetails: {
                                ...lineItem?.lineDetails,
                                description,
                              },
                            })
                          }}
                        />
                      ) : (
                        <ItemLineRow
                          key={lineItem?.id}
                          index={index}
                          item={lineItem}
                          availableItems={availableItems}
                          onChangeType={(type: string) => {
                            if (type === 'category') {
                              arrayHelpers.replace(index, {
                                id: '',
                                type: 'category',
                                lineDetails: {
                                  amountCents: currency('0'),
                                  name: '',
                                  description: '',
                                },
                              })
                            }
                          }}
                          onChangeItemId={(itemId: string) => {
                            arrayHelpers.replace(index, {
                              ...lineItem,
                              id: itemId,
                              lineDetails: {
                                ...lineItem?.lineDetails,
                                rate: itemLines.find(
                                  (item) => item.id === itemId,
                                )?.lineDetails.rate,
                              },
                            })

                            const newItemAmount = calculateItemAmountCents(
                              lineItem?.lineDetails?.quantity || '0',
                              lineItem?.lineDetails?.rate || '0',
                            )

                            setFieldValue(
                              `lineItems.${index}.lineDetails.amountCents`,
                              newItemAmount.intValue,
                            )
                            const newTotal = calculateTotalAmountCents(
                              values.lineItems || [],
                            )
                            setFieldValue('amountCents', newTotal || 0)
                          }}
                          onChangeQuantity={(quantity: string) => {
                            const updatedLineItem = {
                              ...lineItem,
                              lineDetails: {
                                ...lineItem.lineDetails,
                                quantity,
                                amountCents: calculateItemAmountCents(
                                  quantity,
                                  lineItem?.lineDetails?.rate || '0',
                                ).intValue,
                              },
                            }
                            updateLineItemAndTotal(
                              arrayHelpers,
                              index,
                              updatedLineItem,
                              setFieldValue,
                              values.lineItems || [],
                            )
                          }}
                          onChangeRate={(rate: string) => {
                            const newItemAmount = calculateItemAmountCents(
                              lineItem.lineDetails.quantity || '0',
                              rate,
                            )
                            const updatedLineItem = {
                              ...lineItem,
                              lineDetails: {
                                ...lineItem.lineDetails,
                                rate,
                                amountCents: newItemAmount.intValue,
                              },
                            }
                            updateLineItemAndTotal(
                              arrayHelpers,
                              index,
                              updatedLineItem,
                              setFieldValue,
                              values.lineItems || [],
                            )
                          }}
                          onDelete={() => {
                            updateLineItemAndTotal(
                              arrayHelpers,
                              index,
                              null,
                              setFieldValue,
                              values.lineItems || [],
                            )
                          }}
                          onChangeDescription={(description: string) => {
                            arrayHelpers.replace(index, {
                              ...lineItem,
                              lineDetails: {
                                ...lineItem.lineDetails,
                                description,
                              },
                            })
                          }}
                        />
                      )
                    })}
                  </>
                )}
              />

              <Box>
                <Menu>
                  <MenuButton
                    as={ChakraButton}
                    size="sm"
                    variant="ghost"
                    leftIcon={<Icon as={PlusCircleIcon} boxSize={4} />}
                    iconSpacing={1}
                    px="0"
                  >
                    Add Another
                  </MenuButton>

                  <MenuList>
                    {categoryLines?.length > 0 &&
                      categoryLines.length >
                        (values.lineItems?.filter(
                          (item) => item?.type === 'category',
                        )?.length || 0) && (
                        <MenuItem
                          onClick={() => {
                            setFieldValue('lineItems', [
                              ...(values.lineItems || []),
                              {
                                id: '',
                                type: 'category',
                                lineDetails: {
                                  amountCents: currency('0'),
                                  name: '',
                                  description: '',
                                },
                              },
                            ])
                          }}
                        >
                          Add Category
                        </MenuItem>
                      )}
                    {itemLines?.length > 0 &&
                      itemLines.length >
                        (values.lineItems?.filter(
                          (item) => item?.type === 'item',
                        )?.length || 0) && (
                        <MenuItem
                          onClick={() => {
                            setFieldValue('lineItems', [
                              ...(values.lineItems || []),
                              {
                                id: '',
                                type: 'item',
                                lineDetails: {
                                  amountCents: currency('0'),
                                  name: '',
                                  description: '',
                                  rate: '',
                                  quantity: '',
                                },
                              },
                            ])
                          }}
                        >
                          Add Item
                        </MenuItem>
                      )}
                  </MenuList>
                </Menu>
              </Box>
            </VFlex>
          </VStack>
        </Box>
      </VFlex>
    </>
  )
}

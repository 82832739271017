import { tw } from '@nickeltech/brise'
import { FieldProps, useFormikContext } from 'formik'
import { Ref, useEffect, useState } from 'react'
import { VStack } from '@chakra-ui/react'
import ReactSelect, { MenuPlacement, Props } from 'react-select'
import Label from '../Typography/Label'
import { CheckIcon } from '../Icons'
import get from 'lodash/get'

export type Option = {
  label: string
  value: any
}

export type OptionProps = {
  options: Array<Option>
  abbreviations?: Record<string, Option>
}

export type SelectProps = {
  label?: string
  forwadedRef?: Ref<any>
  onChange?: (...args: any) => void
  hasSuccess?: boolean
  initialValue?: string
  onInputChange?: (e: string) => void
  formatOptionLabel?: (...args: any) => any
  noIndicator?: boolean
  containerHeight?: any
  valueContainer?: any
  indicatorsContainer?: any
  withValidator?: boolean
  loadingMessage?: any
  noOptionsMessage?: () => any
  onCreateOption?: (...args: any) => void
  onMenuClose?: (...args: any) => void
  isLoading?: boolean
  filterOption?: (e: any) => boolean
  formatLabel?: (value: unknown) => string
  menuIsOpen?: boolean
  selectId?: string
  isClearable?: boolean
  validated?: boolean
  menuPlacement?: MenuPlacement
  value?: Option
  overflowable?: boolean
  noBorder?: boolean
  disabled?: boolean
  className?: string
} & Partial<FieldProps> &
  Props<Option> &
  OptionProps

export const SelectComponent = tw(
  ({ field, form, isClearable = true, ...props }: SelectProps) => {
    const [dom, setDom] = useState<HTMLDocument | null>(null)
    const [menuIsOpen, setMenuIsOpen] = useState<undefined | boolean>(undefined)

    const [touchedMenuOpen, setTouchedMenuOpen] = useState(false)
    const [initClick, setInitClick] = useState(false)

    useEffect(() => {
      if (typeof window !== 'undefined' && window) {
        setDom(window.document)
      }
    }, [typeof window !== 'undefined'])

    return (
      <ReactSelect
        menuIsOpen={menuIsOpen || touchedMenuOpen}
        closeMenuOnSelect={props.closeMenuOnSelect || undefined}
        ref={props.forwadedRef}
        instanceId={props.selectId ? `__${props.selectId}` : '__react_select_1'}
        name={field?.name}
        value={props.value}
        isSearchable={true}
        isClearable={isClearable}
        loadingMessage={props.loadingMessage}
        openMenuOnFocus={props.openMenuOnFocus}
        isLoading={props.isLoading}
        noOptionsMessage={props.noOptionsMessage}
        onChange={(e) => {
          props.onChange && props.onChange(e || '')
        }}
        onInputChange={(e, meta) => {
          // This is from autofill
          if (meta.prevInputValue == '' && e.length > 1) {
            const value = getValue(props.options, e, props.abbreviations ?? {})
            if (!value) {
              setMenuIsOpen(true)
              props.onInputChange && props.onInputChange(e)
            } else {
              setMenuIsOpen(false)
              props.onInputChange && props.onInputChange(value.value)
            }
          } else if (
            meta.action != 'set-value' &&
            meta.action != 'menu-close'
          ) {
            setMenuIsOpen(true)
          }
        }}
        onFocus={(e) => {
          if (props.onFocus) {
            props.onFocus(e)
          } else {
            setTouchedMenuOpen(true)
            setMenuIsOpen(true)
          }
        }}
        onBlur={() => {
          setTouchedMenuOpen(false)
        }}
        onMenuOpen={() => {
          if (!initClick) {
            setTouchedMenuOpen(false)
            setInitClick(true)
          } else {
            setTouchedMenuOpen(true)
          }
        }}
        onMenuClose={() => {
          setMenuIsOpen(false)
          setTouchedMenuOpen(false)
        }}
        options={(props.options as any) || []}
        placeholder={props.placeholder}
        menuPortalTarget={
          props.overflowable && dom
            ? dom.querySelector('body')
            : props.menuPortalTarget
        }
        menuPlacement={props.menuPlacement || 'auto'}
        styles={{
          menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999,
          }),
          container: (provided, state) => ({
            ...provided,
            minWidth: 'fit-content',
            position: 'relative',
            width: '100%',
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: props.hasSuccess ? '#292524' : '#e7e5e4',
          }),
          placeholder: (provided) => ({
            ...provided,
            color: '#a8a29e',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            padding: '0px 0px 5px 0px',
            ...props.indicatorsContainer,
            display:
              props.withValidator && props.value ? 'none' : provided.display,
          }),
          control: (provided, state) => ({
            ...provided,
            border: props.hasSuccess
              ? 'none'
              : props.validated
              ? provided.border
              : 'none',
            borderColor: props.hasSuccess
              ? provided.borderColor
              : props.validated
              ? '#FF0000'
              : provided.borderColor,
            outline: props.noBorder ? 'none' : provided.outline,
            boxShadow: 'none',
            borderRadius: '0px',
            fontSize: '1rem',
            minWidth: 'fit-content',
            cursor: 'pointer',
            ...props.containerHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '39px',
            '&:hover': {
              border: 'none',
            },
            '&:focus': {
              border: 'none',
            },
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            paddingLeft: '10px',
            ...props.valueContainer,
          }),
          input: (provided, state) => ({
            ...provided,
          }),
          menu: (provided, state) => ({
            ...provided,
            transform: 'translateY(6px)',
          }),
          menuList: (provided, state) => ({
            fontSize: '0.875rem',
            maxHeight: 'unset',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            ...provided,
          }),
        }}
      />
    )
  },
)``

const getValue = (
  options: Option[],
  value: string,
  abbreviations: Record<string, Option>,
) => {
  const potentialValue = options.find((e) => e.value === value)
  if (potentialValue) {
    return potentialValue
  }

  return abbreviations[value] ?? null
}

export const SelectInput = (props: SelectProps) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext()

  useEffect(() => {
    if (props.initialValue) {
      setFieldValue(props.name || '', props.initialValue)
    }
  }, [props.initialValue])

  return (
    <VStack
      gap="0"
      {...{ disabled: props.disabled }}
      w="100%"
      className={props.className}
      borderBottom={props.noBorder ? undefined : '1px solid'}
      borderColor="gray.300"
      position={'relative'}
    >
      {props.label && props.label.length > 0 ? (
        <Label variant="secondary">{props.label}</Label>
      ) : (
        ''
      )}
      <SelectComponent
        {...props}
        forwadedRef={props.forwadedRef}
        value={getValue(
          props.options,
          get(values, props.name || '', props.initialValue || ''),
          props.abbreviations ?? {},
        )}
        validated={props.validated}
        isLoading={props.isLoading}
        noOptionsMessage={props.noOptionsMessage}
        loadingMessage={props.loadingMessage}
        onCreateOption={props.onCreateOption}
        isClearable={props.isClearable}
        onBlur={props.onBlur}
        filterOption={props.filterOption}
        formatOptionLabel={props.formatOptionLabel}
        onMenuClose={props.onMenuClose}
        openMenuOnFocus={props.openMenuOnFocus}
        menuPortalTarget={props.menuPortalTarget}
        formatLabel={props.formatLabel}
        menuIsOpen={props.menuIsOpen}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e)
          } else {
            setFieldTouched(props.name || '', true)
            setFieldValue(props.name || '', e.value)
          }
        }}
        onInputChange={(e) => {
          setFieldTouched(props.name || '', true)
          setFieldValue(props.name || '', e)
        }}
      />
      {props.withValidator && props.hasSuccess ? (
        <CheckIcon
          className="absolute right-[13px] top-[12px] w-4 h-4 stroke-green-800 stroke-2"
          name="check"
        />
      ) : (
        ''
      )}
    </VStack>
  )
}

export default SelectInput

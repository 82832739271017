import { useState } from 'react'
import { useAuth } from '../lib/auth'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from 'ui'
import {
  Card,
  CardBody,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  Icon,
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

export default function Login() {
  const [errorMsg, setErrorMsg] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { signIn } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const onSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { error = null, twoFactorRequired } = await signIn({
      email,
      password,
    })
    const redirect = new URLSearchParams(location.search).get('redirect')

    if (twoFactorRequired) {
      navigate(
        `/two-factor-authentication${redirect ? `?redirect=${redirect}` : ''}`,
      )
    } else if (location.search.includes('redirect') && !error) {
      if (redirect) {
        navigate(redirect)
      }
    }
    if (error) {
      setErrorMsg(error)
    }
  }

  const inputStyle =
    'py-4 px-6 rounded-md border-2 text-lg border-slate-400 focus:border-slate-500 focus:ring-slate-500'

  return (
    <>
      <div className="w-full max-w-sm">
        <div className="md:mt-24 mt-16">
          <form onSubmit={onSubmit}>
            <p
              className={classNames(
                'mb-6',
                'text-md',
                'justify-center',
                'flex',
                'text-red-600',
                'font-light',
                { invisible: !errorMsg },
              )}
              id="name-error"
            >
              {errorMsg}
            </p>

            <Card h="100%" w="100%" bgColor="#ECEAE3">
              <CardBody h="100%" w="100%" p="8">
                <VStack w="100%" spacing="0" gap="6">
                  <Text
                    fontSize="xl"
                    color="dark.600"
                    alignSelf="center"
                    fontWeight="medium"
                  >
                    Welcome back!
                  </Text>
                  <VStack w="100%" gap="4">
                    <VStack w="100%" spacing="0" gap="0" alignItems="left">
                      <FormLabel>Email</FormLabel>
                      <Input
                        className={inputStyle}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        id="email"
                        name="email"
                        placeholder="name@business.com"
                        autoFocus
                        bgColor="white"
                      />
                    </VStack>
                    {import.meta.env.VITE_NODE_ENV === 'demo' ? (
                      ''
                    ) : (
                      <VStack w="100%" spacing="0" gap="0" alignItems="left">
                        <FormLabel>Password</FormLabel>
                        <Input
                          className={inputStyle}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          id="password"
                          name="password"
                          autoComplete="current-password"
                          autoCapitalize="none"
                          spellCheck="false"
                          required
                          placeholder="Your Password"
                          bgColor="white"
                        />
                      </VStack>
                    )}
                  </VStack>

                  <Button
                    label="Log In"
                    variant="solid"
                    status="accent"
                    color="dark.600"
                    type="submit"
                    size="lg"
                    w="100%"
                    _hover={{ bg: 'accent.500' }}
                  />
                </VStack>
                {import.meta.env.VITE_NODE_ENV === 'demo' ? (
                  ''
                ) : (
                  <HStack
                    verticalAlign="center"
                    pt="4"
                    w="100%"
                    justifyContent="end"
                  >
                    <Link to="/forgot-password">
                      <HStack role="group">
                        <Text
                          color="gray.500"
                          fontSize="sm"
                          fontWeight="light"
                          _groupHover={{ color: 'gray.700' }}
                        >
                          Forgot Password
                        </Text>
                        <Icon
                          as={ArrowRightIcon}
                          boxSize="4"
                          color="gray.500"
                          _groupHover={{ color: 'gray.700' }}
                        />
                      </HStack>
                    </Link>
                  </HStack>
                )}
              </CardBody>
            </Card>
          </form>
        </div>
      </div>
    </>
  )
}

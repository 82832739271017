import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import React from 'react'
import { HFlex, Row } from 'ui'

type OverlayElement = {
  node: React.ReactNode
  label: string
  badgeCount?: number
}

type OverlayElementsProps = {
  nodes: OverlayElement[]
  onClick?: (idx: number) => void
  tabIndex?: number
  elementClassName?: string
}

export const OverlayElements = (props: OverlayElementsProps) => {
  const currentIndex = props.tabIndex || 0

  return (
    <Row between className="w-full px-0">
      <Tabs className="w-full" onChange={props.onClick} index={currentIndex}>
        <TabList className="px-8">
          {props.nodes.map((node, idx) => (
            <HFlex key={idx}>
              <Tab
                color={currentIndex === idx ? 'dark.800' : 'dark.300'}
                paddingX={0}
                marginRight={5}
                fontSize="sm"
              >
                {node.label}
                {node.badgeCount && (
                  <Text
                    bg="yellow.400"
                    color="dark.800"
                    borderRadius="sm"
                    fontSize="xs"
                    minW="16px"
                    h="16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginLeft={1}
                  >
                    {node.badgeCount}
                  </Text>
                )}
              </Tab>
            </HFlex>
          ))}
        </TabList>
        <Row className={props.elementClassName || 'px-8'}>
          <TabPanels>
            {props.nodes.map((node, idx) => (
              <TabPanel key={idx} paddingX={0}>
                {node.node}
              </TabPanel>
            ))}
          </TabPanels>
        </Row>
      </Tabs>
    </Row>
  )
}

export default OverlayElements

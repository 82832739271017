import {
  BoxProps,
  Card,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
  Text,
} from '@chakra-ui/react'
import { forwardRef } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import styled from 'styled-components'
import Icon from '../Icons'
import { VFlex } from '../..'

type CardInputProps = {
  label: string
  isInvalid?: boolean
  validated?: boolean
  isRequired?: boolean
  error?: string
  labelColor?: string
  inputGroupSize?: string
} & InputProps

type DatePickerCardInputProps = {
  className?: string
  label?: string
  onChange: (date: Date) => void
  selected: Date
  disabled?: boolean
  px?: Pick<BoxProps, 'px'>['px']
  py?: Pick<BoxProps, 'py'>['py']
  labelColor?: string
  gap?: Pick<BoxProps, 'gap'>['gap']
} & ReactDatePickerProps

type DatePickerWrapperProps = {
  className?: string
  onChange: (date: Date) => void
  selected: Date
} & ReactDatePickerProps

const DatePickerWrapper = styled(
  ({ className, ...props }: DatePickerWrapperProps) => (
    <ReactDatePicker
      className={className}
      wrapperClassName={'w-full'}
      {...props}
    />
  ),
)`
  outline-color: transparent;
  width: 100%;
  cursor: pointer;
`

export function DatePickerCardInputSmall({
  label,
  onChange,
  selected,
  labelColor,
  gap,
  px,
  py,
  ...rest
}: DatePickerCardInputProps) {
  const CardInput = forwardRef<
    HTMLDivElement,
    { value?: string; onClick?: () => void; className?: string }
  >(({ value, onClick, className }, ref) => (
    <Card
      border="1px"
      borderColor="gray.200"
      px={px || 3}
      py={py || '7px'}
      w="100%"
      _hover={{ borderColor: 'gray.300' }}
      borderRadius="4px"
      bgColor="gray.100"
      className={className}
      onClick={onClick}
      ref={ref}
    >
      <HStack justifyContent="space-between">
        <VFlex gap={gap ?? 2.5}>
          {label && (
            <FormLabel
              noOfLines={1}
              fontSize={{ base: '2xs', md: 'xs' }}
              color={labelColor || 'gray.500'}
              _hover={{ borderColor: 'gray.300', cursor: 'pointer' }}
              m={0}
            >
              {label}
            </FormLabel>
          )}
          <Text color={'gray.900'} fontSize="sm" fontWeight="normal">
            {value}
          </Text>
        </VFlex>
        <Icon name="pencil" className="stroke-gray-800" size="xsmall" />
      </HStack>
    </Card>
  ))

  CardInput.displayName = 'CardInput'

  return (
    <DatePickerWrapper
      selected={selected}
      onChange={onChange}
      enableTabLoop={false}
      customInput={<CardInput />}
      {...rest}
    />
  )
}

export function DatePickerCardInput({
  label,
  onChange,
  selected,
  labelColor,
  gap,
  px,
  py,
  ...rest
}: DatePickerCardInputProps) {
  const CardInput = forwardRef<
    HTMLDivElement,
    { value?: string; onClick?: () => void; className?: string }
  >(({ value, onClick, className }, ref) => (
    <Card
      border="1px"
      borderColor="gray.200"
      px={px || 3}
      py={py || 2}
      w="100%"
      className={className}
      onClick={onClick}
      ref={ref}
    >
      <HStack justifyContent="space-between">
        <VFlex gap={gap ?? 2.5}>
          {label && (
            <FormLabel
              noOfLines={1}
              fontSize={{ base: '2xs', md: 'xs' }}
              color={labelColor || 'gray.500'}
              _hover={{ borderColor: 'gray.300', cursor: 'pointer' }}
              m={0}
            >
              {label}
            </FormLabel>
          )}
          <Text color={'gray.900'} fontSize="md" fontWeight="medium">
            {value}
          </Text>
        </VFlex>
        <Icon name="pencil" className="stroke-gray-500" />
      </HStack>
    </Card>
  ))

  CardInput.displayName = 'CardInput'

  return (
    <DatePickerWrapper
      selected={selected}
      onChange={onChange}
      enableTabLoop={false}
      customInput={<CardInput />}
      {...rest}
    />
  )
}

export function CardInput({
  label,
  value,
  isInvalid,
  validated,
  isRequired,
  error,
  placeholder,
  ...other
}: CardInputProps) {
  return (
    <Card
      w="100%"
      bgColor={other.bgColor || 'gray.50'}
      border="1px"
      borderColor="gray.200"
      px={3}
      py={2}
    >
      <FormControl isInvalid={isInvalid && validated} isRequired={isRequired}>
        <HStack justifyContent="space-between" alignItems="center">
          <FormLabel
            noOfLines={1}
            fontSize={{ base: '2xs', md: 'xs' }}
            color={other.labelColor || 'gray.500'}
            m={0}
          >
            {label}
          </FormLabel>
          <FormErrorMessage fontSize={{ base: '2xs', md: 'xs' }} m={0}>
            {error && validated ? error : ''}
          </FormErrorMessage>
        </HStack>

        <InputGroup size={other.inputGroupSize || 'md'}>
          <Input
            {...other}
            value={value}
            border="0"
            paddingInlineStart={0}
            variant="selectAmountInput"
            placeholder={placeholder}
          />
        </InputGroup>
      </FormControl>
    </Card>
  )
}

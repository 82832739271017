import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Bill,
  BillsDocument,
  BillsQueryVariables,
  ManualSyncQboDocument,
  PayableStatus,
  SortOrder,
  UserRole,
} from '../../operations-types'
import { useMutation, useQuery } from '@apollo/client'
import { PageLayout } from '../layout/PageLayout'
import {
  billStatusToLabel,
  billStatusToType,
  Button,
  Column,
  getNickelBillStatus,
  Header,
  Row,
  TableCellProps,
  TableV2,
  XLARGE_CELL_PX,
} from 'ui'
import { Box, VStack, Text, useToast } from '@chakra-ui/react'
import { TransactionTabsV2 } from '../ap/TransactionTabs'
import QueryString from 'qs'
import { FilterSchema } from 'ui/src/components/Bills/BillsFilterDropdown'
import { omit } from 'lodash'
import AddFilter from 'ui/src/components/Transactions/AddFilter'
import TableSearchInput from 'ui/src/components/TableSearchInput'
import currency from 'currency.js'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { BillOverlay, BillOverlayStates } from './components/BillOverlay'
import { RecurringBillsOverlay } from '../ap/RecurringBillsOverlay'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import moment from 'moment'
import { useAddBillStore } from '../stores/addBillStore'
import { Datum } from 'ui/src/types'
import { useCodatLinkedConnection } from '../utils/CodatUtils'
import { getMultiSelectToastOptions } from '../MultiSelectToast'
import { BATCH_BILL_PAY_URL } from '../../lib/urls'

export function Bills() {
  const availableSpace = window.screen.availHeight - 420
  const pageSize = Math.floor(availableSpace / XLARGE_CELL_PX)

  const toast = useToast()

  const [page, setPage] = useState(1)

  const [params, setParams] = useSearchParams()

  const [searchQuery, setSearchQuery] = useState(
    params.get('searchQuery') || '',
  )

  const [vendorName] = useState(params.get('vendorName') || '')
  const [billReferenceId] = useState(params.get('billReferenceId') || '')
  const [dueDateStartDate] = useState(params.get('dueDateStartDate') || '')
  const [dueDateEndDate] = useState(params.get('dueDateEndDate') || '')
  const [billAmountStartValue] = useState(
    params.get('billAmountStartValue') || '',
  )
  const [billAmountEndValue] = useState(params.get('billAmountEndValue') || '')
  const [paidAmountEndValue] = useState(params.get('paidAmountEndValue') || '')
  const [paidAmountStartValue] = useState(
    params.get('paidAmountStartValue') || undefined,
  )

  const [queryOpts, setQueryOpts] = useState<BillsQueryVariables>({
    page: page,
    pageSize: pageSize,
    payableQuery:
      !params.get('type') || params.get('type') === 'ALL'
        ? undefined
        : (params.get('type') as PayableStatus),
    searchQuery,
    vendorName,
    billReferenceId,
    dueDateStartDate,
    dueDateEndDate,
    billAmountStartValue,
    billAmountEndValue,
    paidAmountStartValue,
    issuedOnStartDate: params.get('issuedOnStartDate') || '',
    issuedOnEndDate: params.get('issuedOnEndDate') || '',
    paidAmountEndValue,
    orderBy: (params.get('orderBy') || 'dueDate') as string,
    sortOrder: params.get('sortBy') || 'asc',
    nickelBillStatus: null,
  })

  const { data: billsData, loading: billsPayableLoading } = useQuery(
    BillsDocument,
    {
      variables: {
        ...queryOpts,
      },
      pollInterval: 3000,
      fetchPolicy: 'cache-and-network',
    },
  )

  const [headerSelected, setHeaderSelected] = useState(false)
  const [selectedBills, setSelectedBills] = useState<string[]>([])

  const totalPages = Math.ceil((billsData?.bills?.totalResults || 0) / pageSize)

  const [selectedTab, setSelectedTab] = useState<string>(
    params.get('type') || 'Open',
  )

  const billPayablesConverted = billsData?.bills?.bills?.map((g) => {
    const billPayable = g.billPayables?.[0]
    const pendingApprovals =
      billPayable?.approvals?.filter((x) => x?.status === 'PENDING') || []
    const billPayment = billPayable?.billPayments?.[0]

    const nickelBillStatus = getNickelBillStatus(
      g.billData?.status || '',
      billPayable?.id,
      billPayment,
      pendingApprovals.length,
      billPayable?.withdrawalDate || undefined,
    )
    const rejectedApproval =
      billPayable?.approvals?.find((x) => x?.status === 'REJECTED') || null

    function getPaymentAmountAndSubline() {
      if (rejectedApproval) {
        return {
          paymentAmount: currency(
            rejectedApproval.submittedAmountInCents || 0,
            {
              fromCents: true,
            },
          ).format(),
          subLine: 'Declined by Approver',
          status: 'declined',
        }
      } else if (pendingApprovals.length > 0) {
        const totalPending = pendingApprovals.reduce(
          (acc, x) => acc + (x?.submittedAmountInCents || 0),
          0,
        )
        return {
          paymentAmount: `${currency(totalPending || 0, {
            fromCents: true,
          }).format()}`,
          subLine: `Awaiting Approval`,
        }
      } else if (
        billPayable?.withdrawalDate &&
        billPayable.status === 'SCHEDULED'
      ) {
        return {
          paymentAmount: `${currency(billPayable.amountDueCents || 0, {
            fromCents: true,
          }).format()}`,
          subLine: `Scheduled for ${moment(billPayable.withdrawalDate).format(
            'MM/DD/YYYY',
          )}`,
        }
      } else {
        return {
          paymentAmount: '',
          subLine: '',
        }
      }
    }

    return {
      ...g,
      nickelBillStatus,
      isSelected: selectedBills.includes(g.id),
      status: {
        label: billStatusToLabel(nickelBillStatus || g?.billData?.status || ''),
        status: billStatusToType(nickelBillStatus || g?.billData?.status || ''),
      },
      vendor: {
        name: g.billData?.supplierRef?.supplierName,
        source: g.billSource,
        methods: g.vendor?.vendorPayoutMethods || [],
      },
      reference: {
        label: g.billData?.reference,
        source: g.billSource,
      },
      dueDate: g.billData?.dueDate,
      balanceDue: currency(g.billData?.amountDue || 0).format(),
      totalAmount: currency(g.billData?.totalAmount || 0).format(),
      action: {
        label: 'Make Payment',
        amountDue: g.billData?.amountDue || 0,
        ...getPaymentAmountAndSubline(),
      },
    }
  })

  const [open, setOpen] = useState(false)
  const [recurringBillOpen, setRecurringBillOpen] = useState(false)
  const [currentInvoice, setCurrentInvoice] = useState<string | null>(null)
  const [currentToast, setCurrentToast] = useState('')
  const navigate = useNavigate()

  const { getUser } = useLoggedInStore((state) => ({
    getUser: state.getUser,
  }))

  const IS_PERMITTED =
    getUser().isUserPermitted(UserRole.Manager) ||
    !!getUser()?.organization?.approvalPolicies?.length

  useEffect(() => {
    if (!params.get('type') || selectedTab === 'Open') {
      setQueryOpts({
        ...queryOpts,
        payableQuery: ['Open', 'PartiallyPaid'] as PayableStatus[],
      })
      setParams({ type: 'Open', page: params.get('page') || '1' })
    } else if (params.get('type') === 'QUEUED') {
      setQueryOpts({
        ...queryOpts,
        nickelBillStatus: 'Queued',
        payableQuery: undefined,
      })
    }
  }, [])

  useEffect(() => {
    setParams({
      page: (queryOpts.page || 1).toString(),
      type:
        queryOpts.nickelBillStatus === 'Queued'
          ? 'QUEUED'
          : queryOpts.payableQuery || 'Open',
      ...(queryOpts.orderBy ? { orderBy: queryOpts.orderBy || '' } : {}),
      ...(queryOpts.issuedOnStartDate
        ? { issuedStartDate: queryOpts.issuedOnStartDate || '' }
        : {}),
      ...(queryOpts.issuedOnEndDate
        ? { issuedEndDate: queryOpts.issuedOnEndDate || '' }
        : {}),
      ...(queryOpts.paidAmountStartValue
        ? { paidAmountStartValue: queryOpts.paidAmountStartValue || '' }
        : {}),
      ...(queryOpts.paidAmountEndValue
        ? { paidAmountEndValue: queryOpts.paidAmountEndValue || '' }
        : {}),
      ...(queryOpts.billAmountStartValue
        ? { billAmountStartValue: queryOpts.billAmountStartValue || '' }
        : {}),
      ...(queryOpts.billAmountEndValue
        ? { billAmountEndValue: queryOpts.billAmountEndValue || '' }
        : {}),
      ...(queryOpts.vendorName
        ? { vendorName: queryOpts.vendorName || '' }
        : {}),
      ...(queryOpts.billReferenceId
        ? { billReferenceId: queryOpts.billReferenceId || '' }
        : {}),
      ...(queryOpts.searchQuery
        ? { searchQuery: queryOpts.searchQuery || '' }
        : {}),
      ...(queryOpts.dueDateStartDate
        ? { dueDateStartDate: queryOpts.dueDateStartDate || '' }
        : {}),
      ...(queryOpts.dueDateEndDate
        ? { dueDateEndDate: queryOpts.dueDateEndDate || '' }
        : {}),
      ...(queryOpts.sortOrder ? { sortBy: queryOpts.sortOrder || '' } : {}),
    })
  }, [queryOpts])

  const currentFilters = [
    queryOpts.billReferenceId
      ? {
          label: 'Bill Reference ID',
          value: queryOpts.billReferenceId,
          key: 'billReferenceId',
        }
      : null,
    queryOpts.vendorName
      ? {
          label: 'Vendor Name',
          value: queryOpts.vendorName,
          key: 'vendorName',
        }
      : null,
    queryOpts.issuedOnStartDate
      ? {
          label: 'Issued Start Date',
          value: queryOpts.issuedOnStartDate,
          key: 'issuedOnStartDate',
        }
      : null,
    queryOpts.issuedOnEndDate
      ? {
          label: 'Issued End Date',
          value: queryOpts.issuedOnEndDate,
          key: 'issuedOnEndDate',
        }
      : null,
    queryOpts.dueDateStartDate
      ? {
          label: 'Due Start Date',
          value: queryOpts.dueDateStartDate,
          key: 'dueDateStartDate',
        }
      : null,

    queryOpts.dueDateEndDate
      ? {
          label: 'Due End Date',
          value: queryOpts.dueDateEndDate,
          key: 'dueDateEndDate',
        }
      : null,
    queryOpts.billAmountStartValue
      ? {
          label: 'Bill Amount Start Value',
          value: queryOpts.billAmountStartValue,
          key: 'billAmountStartValue',
        }
      : null,
    queryOpts.billAmountEndValue
      ? {
          label: 'Bill Amount End Value',
          value: queryOpts.billAmountEndValue,
          key: 'billAmountEndValue',
        }
      : null,
    queryOpts.paidAmountStartValue
      ? {
          label: 'Paid Amount Start Value',
          value: queryOpts.paidAmountStartValue,
          key: 'paidAmountStartValue',
        }
      : null,
    queryOpts.paidAmountEndValue
      ? {
          label: 'Paid Amount End Value',
          value: queryOpts.paidAmountEndValue,
          key: 'paidAmountEndValue',
        }
      : null,
    queryOpts.searchQuery
      ? {
          label: 'Search Query',
          value: queryOpts.searchQuery,
          key: 'searchQuery',
        }
      : null,
  ].filter((e) => e !== null)

  const toastOptions = getMultiSelectToastOptions({
    buttons: [
      {
        icon: 'arrowPath',
        label: 'Unselect',
        onClick: () => {
          toast.closeAll()
          setSelectedBills([])
          setHeaderSelected(false)
        },
      },
      {
        icon: 'checkCircle',
        label: `Pay ${selectedBills?.length} bills`,
        onClick: () => {
          toast.closeAll()
          navigate(`${BATCH_BILL_PAY_URL}?billIds=${selectedBills.join(',')}`)
          setSelectedBills([])
        },
        primary: true,
      },
    ],
  })

  const onCustomFilter = (values?: FilterSchema) => {
    setQueryOpts(
      Object.assign(queryOpts, values, {
        page: 1,
      }),
    )
    setPage(1)
  }

  const onClose = (
    label: string,
    value: string | Array<string>,
    key: string,
  ) => {
    setQueryOpts(omit(queryOpts, key) as unknown as BillsQueryVariables)
  }

  const [localSearchQuery, setLocalSearchQuery] = useState('')
  const [billOverlayState, setBillOverlayState] =
    useState<BillOverlayStates>('view')

  const recurringBillsEnabled = useFeatureFlagEnabled('recurringBillsEnabled')
  const batchPaymentsEnabled = useFeatureFlagEnabled('batchBillPayEnabled')

  const { setNewBillVendorId } = useAddBillStore((state) => ({
    setNewBillVendorId: state.setVendorId,
  }))

  useEffect(() => {
    if (selectedBills?.length === 0) {
      toast.close(currentToast)
      setHeaderSelected(false)
    } else {
      if (!toast.isActive(currentToast)) {
        let t = toast(toastOptions)

        setCurrentToast(t as string)
      } else {
        toast.update(currentToast, toastOptions)
      }
    }
  }, [selectedBills])

  const [manualSyncQbo] = useMutation(ManualSyncQboDocument)

  const [manualSyncQboLoading, setManualSyncQboLoading] = useState(false)

  const { hasLinkedConnection } = useCodatLinkedConnection(
    getUser().organization.accountInfo ?? null,
  )

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem)] min-h-[calc(100vh-2rem)]"
      >
        <Row className="rounded-md" between grow>
          <VStack
            spacing="0"
            gap="0"
            alignItems="left"
            className="w-full h-full"
          >
            <Row className="p-4 w-full gap-4" between>
              <Row grow y="center" between>
                <Column>
                  <Header variant="page" className="text-[20px]">
                    Bills
                  </Header>
                  <Text color="gray.500" className="text-sm">
                    Track, manage and pay your bills in one place
                  </Text>
                </Column>
              </Row>
              {recurringBillsEnabled && (
                <Button
                  label="Recurring Bills"
                  size="sm"
                  variant="outline"
                  onClick={() => setRecurringBillOpen(true)}
                />
              )}
              <Button
                label="New Bill"
                size="sm"
                onClick={() => {
                  setBillOverlayState('newBill')
                  setNewBillVendorId(null)
                  setOpen(true)
                }}
              />
            </Row>
            <Column wGrow className="!pb-0" gap="small">
              <TransactionTabsV2
                hasLinkedConnection={hasLinkedConnection}
                buttonLoading={manualSyncQboLoading}
                onQbSync={async () => {
                  setManualSyncQboLoading(true)
                  const response = await manualSyncQbo()
                  if (response.data?.manualSyncQbo?.error?.message) {
                    toast({
                      title: 'Error',
                      description: response.data.manualSyncQbo.error.message,
                      status: 'error',
                    })
                  } else {
                    await new Promise((resolve) => setTimeout(resolve, 1000))
                    toast({
                      title: 'Success',
                      description: 'Sync initiated successfully',
                      status: 'success',
                    })
                  }
                  setManualSyncQboLoading(false)
                }}
                tabs={[
                  {
                    label: 'Open',
                    value: 'Open',
                  },
                  {
                    label: 'Queued for Payment',
                    value: 'QUEUED',
                  },
                  {
                    label: 'Paid in Full',
                    value: 'Paid',
                  },
                  {
                    label: 'All',
                    value: 'ALL',
                  },
                ]}
                selected={selectedTab}
                onSelect={(tab) => {
                  setPage(1)
                  setParams({ tab })
                  setQueryOpts({
                    ...queryOpts,
                    nickelBillStatus: tab === 'QUEUED' ? 'Queued' : null,
                    page: 1,
                    payableQuery: (tab === 'ALL' || tab === 'QUEUED'
                      ? undefined
                      : tab === 'Open'
                      ? ['Open', 'PartiallyPaid']
                      : tab) as PayableStatus,
                  })
                  setSelectedTab(tab as string)
                  navigate(
                    `/dashboard/accounts-payable/bills?${QueryString.stringify({
                      type: tab,
                    })}`,
                  )
                }}
              />
              <Column className="h-full" wGrow grow>
                <Column wGrow>
                  <Row className="py-2 px-4" grow between>
                    <AddFilter
                      {...{
                        currentFilters: currentFilters,
                        onFormClick: onCustomFilter,
                        onClose: onClose,
                        formValues: queryOpts,
                        type: 'bills',
                        funnel: false,
                      }}
                    />
                    <TableSearchInput
                      placeholder="Search bills"
                      {...{
                        value: localSearchQuery,
                        onChange: (e) => {
                          setLocalSearchQuery(e.target.value)
                        },
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            setSearchQuery(localSearchQuery)
                            setQueryOpts({
                              ...queryOpts,
                              searchQuery: localSearchQuery,
                              page: 1,
                            })
                            setPage(1)

                            setLocalSearchQuery('')
                          }
                        },
                      }}
                    />
                  </Row>
                  <Box w="100%" pt="4" py={2} className="!pb-0">
                    <TableV2
                      {...{
                        onClick: (inv) => {
                          setCurrentInvoice(inv.id)
                          setOpen(true)
                        },
                        loading: billsPayableLoading,
                        rowSize: 'large',
                        cellSize: 'xlarge',
                        sortBy: queryOpts.orderBy || '',
                        sortOrder:
                          (queryOpts.sortOrder as any) || ('desc' as SortOrder),
                        onSort: (sortBy, sortOrder) => {
                          setQueryOpts({
                            ...queryOpts,
                            orderBy: sortBy as string,
                            sortOrder: sortOrder,
                          })
                        },
                        headers: [
                          batchPaymentsEnabled && selectedTab === 'Open'
                            ? {
                                type: 'multiSelect',
                                keyName: 'isSelected',
                                width: 'icon',
                                headerCenter: true,
                                center: true,
                                headerSelected: headerSelected,
                                sortable: false,
                                orderable: false,
                                headerOnCheck: (e: boolean) => {
                                  setHeaderSelected(e)
                                  setSelectedBills(
                                    e
                                      ? billsData?.bills?.bills?.map(
                                          (x) => x.id,
                                        ) || []
                                      : [],
                                  )
                                },
                                onCheck: (data: Datum, e: boolean) => {
                                  if (e) {
                                    setSelectedBills([
                                      ...selectedBills,
                                      data.id,
                                    ])
                                  } else {
                                    setSelectedBills(
                                      selectedBills.filter(
                                        (id) => id !== data.id,
                                      ),
                                    )
                                  }
                                },
                              }
                            : null,
                          {
                            type: 'vendor',
                            keyName: 'vendor',
                            label: 'Vendor',
                            grow: 4,
                            width: 'large',
                          },
                          {
                            type: 'status',
                            keyName: 'status',
                            label: 'Status',
                            width: 'medium',
                          },
                          {
                            type: 'qboLabel',
                            label: 'Bill #',
                            keyName: 'reference',
                            width: 'large',
                            grow: 1,
                          },

                          {
                            type: 'date',
                            keyName: 'dueDate',
                            label: 'Due Date',
                            sortable: true,
                          },
                          {
                            type: 'description',
                            keyName: 'totalAmount',
                            label: 'Amount',
                            className: 'font-medium',
                            width: 'large',
                            right: true,
                          },
                          {
                            type: 'description',
                            label: 'Balance',
                            keyName: 'balanceDue',
                            className: 'font-medium',
                            width: 'large',
                            right: true,
                          },
                          {
                            type: 'paymentInProgress',
                            disabled: false,
                            keyName: 'action',
                            label: 'Payment In Progress',
                            width: 'large',
                            right: true,
                            onClick: async (x: string, y: Bill) => {
                              if (IS_PERMITTED) {
                                if (y.billData?.status !== 'Paid') {
                                  setCurrentInvoice(y.id)
                                  setBillOverlayState('schedule')
                                  setOpen(true)
                                }
                              }
                            },
                          },
                        ].filter(Boolean) as TableCellProps<object>[],
                        data: billPayablesConverted || [],
                        page: queryOpts.page || 1,
                        perPage: queryOpts.pageSize || 10,
                        totalPages: totalPages || 1,
                        onChange: () => {},
                        onPage: async (page: number) => {
                          await setPage(page)
                          await setQueryOpts({
                            ...queryOpts,
                            page: page,
                          })
                        },
                      }}
                    />

                    <BillOverlay
                      open={open}
                      setOpen={(bool) => {
                        setOpen(bool)
                        setBillOverlayState('view')
                      }}
                      setBillId={setCurrentInvoice}
                      billId={currentInvoice ?? ''}
                      state={billOverlayState}
                      setState={setBillOverlayState}
                    />

                    <RecurringBillsOverlay
                      setOpen={setRecurringBillOpen}
                      isOpen={recurringBillOpen}
                    />
                  </Box>
                </Column>
              </Column>
            </Column>
          </VStack>
        </Row>
      </Column>
    </PageLayout>
  )
}

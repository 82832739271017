import { useQuery } from '@apollo/client'
import {
  Badge,
  Card,
  CardBody,
  HStack,
  Text,
  VStack,
  Icon,
  Flex,
  Spacer,
  Tabs,
  Tab,
  TabList,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  Button,
  calculatePaymentSummaryForMerchant,
  PaymentSummary,
  Separator,
} from 'ui'
import { OrganizationBillingDocument } from '../../../../operations-types'
import { NickelPlansPage } from './types'
import currency from 'currency.js'
import { SendMoneyAmountResult } from '../../../ap/components/SendPaymentRouter'
import { AmountResult, useNickelPlansStore } from './NickelPlansStore'
import { useEffect, useState } from 'react'

const AchFeatures = {
  NICKEL_FREE: ['Unlimited free ACHs', 'Two day settlement'],
  NICKEL_PLUS: ['Unlimited free ACHs', 'Next day settlement'],
  NICKEL_PRO: ['Unlimited free ACHs', 'Next day settlement'],
}

const CardFeatures = {
  NICKEL_FREE: ['2.9% flat rate', 'Next day settlement'],
  NICKEL_PLUS: ['2.9% flat rate', 'Same day settlement'],
  NICKEL_PRO: ['2.9% flat rate', 'Same day settlement'],
}

const NickelFeatures = {
  NICKEL_FREE: [
    '3 active users',
    '$25,000 limit per transaction',
    'Customer payment portal',
    'Get paid via link',
    'Bill Pay',
    'Permissions and approvals workflow',
    'Live chat and email support',
  ],
  NICKEL_PLUS: [
    'Unlimited active users',
    'No hard transaction limit',
    'Schedule payments in advance',
    'Recurring payments',
    'Remote check deposit',
  ],
  NICKEL_PRO: [
    'Digital credit application',
    'Customer credit insights',
    'Decision workflow',
    'Offer Net 60 Terms to buyers',
  ],
}

type NickelPlanCardProps = {
  title: string
  price: number
  description: string
  cta: string
  achFeatures: string[]
  cardFeatures: string[]
  nickelFeatures: string[]
  nickelFeaturesText: string
  badgeLabel?: string
  isDisabled?: boolean
  onClick: () => void
  isAnnual: boolean
}

const NickelPlanCard = (props: NickelPlanCardProps) => {
  return (
    <Card maxW="350px" h="850px">
      <CardBody>
        <VStack w="100%" py="6" px="4">
          <Flex flexDirection="row" w="100%" alignItems="center">
            <Text fontWeight="semibold" color="gray.800">
              {props.title}
            </Text>
            <Spacer />
            {props.badgeLabel && (
              <Badge
                colorScheme="purple" //TODO: Change colorScheme according to current plan
                fontWeight="medium"
                fontSize="2xs"
                p="1"
              >
                {props.badgeLabel}
              </Badge>
            )}
          </Flex>
          <Text fontSize="sm" color="gray.500" alignSelf="start">
            {props.description}
          </Text>
          <VStack
            w="100%"
            alignItems="start"
            py="2"
            spacing="0"
            gap="0"
            h="70px"
          >
            {props.price > 0 && (
              <>
                <Text fontSize="xl" fontWeight="medium">
                  {props.title === 'Nickel Enterprise' && 'Starting at '}
                  {currency(props.price, { precision: 0 }).format()} / month
                </Text>
                {props.isAnnual && (
                  <Text fontSize="sm" color="gray.500">
                    {currency(props.price * 12, { precision: 0 }).format()}{' '}
                    {props.title === 'Nickel Enterprise' && 'or more'} billed
                    annually
                  </Text>
                )}
              </>
            )}

            {props.price === 0 && (
              <Text fontSize="xl" fontWeight="medium">
                Free
              </Text>
            )}
          </VStack>
          <Button
            w="100%"
            label={props.cta}
            isDisabled={props.isDisabled}
            onClick={props.onClick}
          />
          <Separator orientation="horizontal" />

          <Text alignSelf="start" fontSize="md" fontWeight="medium" py="2">
            ACH Bank Transfer
          </Text>
          {props.achFeatures.map((feature, index) => (
            <HStack
              key={index}
              w="100%"
              alignItems="center"
              justifyItems="start"
            >
              <Icon as={CheckCircleIcon} color="accent.700" />
              <Text key={index}>{feature}</Text>
            </HStack>
          ))}
          <Separator orientation="horizontal" />
          <Text alignSelf="start" fontSize="md" fontWeight="medium" py="2">
            Credit Card
          </Text>
          {props.cardFeatures.map((feature, index) => (
            <HStack
              key={index}
              w="100%"
              alignItems="center"
              justifyItems="start"
            >
              <Icon as={CheckCircleIcon} color="accent.700" />
              <Text key={index}>{feature}</Text>
            </HStack>
          ))}
          <Separator orientation="horizontal" />
          <Text alignSelf="start" fontSize="md" fontWeight="medium" py="2">
            {props.nickelFeaturesText}
          </Text>
          {props.nickelFeatures.map((feature, index) => (
            <HStack
              key={index}
              w="100%"
              alignItems="center"
              justifyItems="start"
            >
              <Icon as={CheckCircleIcon} color="accent.700" />
              <Text key={index}>{feature}</Text>
            </HStack>
          ))}
        </VStack>
      </CardBody>
    </Card>
  )
}

type NickelPricingProps = {
  setPage: (page: NickelPlansPage) => void
  setPaymentSummary: (_: PaymentSummary) => void
  sendMoneyAmountResult: SendMoneyAmountResult | AmountResult
}

type MonthlyOrAnnualPricingProps = {
  onMonthlyClicked?: () => void
  onAnnualClicked?: () => void
}

function MonthlyOrAnnualPricing({
  onMonthlyClicked,
  onAnnualClicked,
}: MonthlyOrAnnualPricingProps) {
  return (
    <Tabs colorScheme={'dark'}>
      <TabList>
        <Tab>
          <HStack gap={2} onClick={onAnnualClicked}>
            <Text>Annual Pricing</Text>
            <Badge variant="outline" colorScheme="gray">
              Save 20%
            </Badge>
          </HStack>
        </Tab>
        <Tab onClick={onMonthlyClicked}>Monthly Pricing</Tab>
      </TabList>
    </Tabs>
  )
}

export function NickelPricing(props: NickelPricingProps) {
  const { data: organizationBillingData } = useQuery(
    OrganizationBillingDocument,
  )

  useEffect(() => {
    const executePrevPayment = async () => {
      const paymentSummary = calculatePaymentSummaryForMerchant(
        props.sendMoneyAmountResult?.submittedAmountCents,
        'Credit Card',
        'ACH',
      )
      props.setPaymentSummary(paymentSummary)
    }
    executePrevPayment()
  }, [])

  const activated =
    organizationBillingData?.organization?.organization?.accountInfo
      ?.stagedSubscription?.stagedSubscription?.initiated || false

  const { setSelectedPlan } = useNickelPlansStore((state) => ({
    setSelectedPlan: state.setSelectedPlan,
  }))

  const [isAnnual, setIsAnnual] = useState(true)

  return (
    <>
      <HStack justifyContent="center">
        <MonthlyOrAnnualPricing
          onAnnualClicked={() => setIsAnnual(true)}
          onMonthlyClicked={() => setIsAnnual(false)}
        />
      </HStack>
      <HStack spacing="8" gap="8" w="100%">
        <NickelPlanCard
          title="Nickel"
          isAnnual={isAnnual}
          price={0}
          description="Our basic plan to send and receive payments" //TODO: Change description accdng to design
          cta={activated ? 'Included' : 'Current Plan'} //TODO: Change CTA according to current plan
          achFeatures={AchFeatures.NICKEL_FREE}
          cardFeatures={CardFeatures.NICKEL_FREE}
          nickelFeatures={NickelFeatures.NICKEL_FREE}
          nickelFeaturesText="Features Included"
          isDisabled={true}
          onClick={() => {
            setSelectedPlan(
              isAnnual ? 'NICKEL_PLUS_ANNUAL' : 'NICKEL_PLUS_MONTHLY',
            )
          }}
        />
        <NickelPlanCard
          title="Nickel Plus"
          price={isAnnual ? 35 : 45}
          isAnnual={isAnnual}
          description="Everything you need to upgrade your payment system" //TODO: Change description accdng to design
          cta={activated ? `Current Plan` : `Upgrade to Plus`}
          achFeatures={AchFeatures.NICKEL_PLUS}
          cardFeatures={CardFeatures.NICKEL_PLUS}
          nickelFeatures={NickelFeatures.NICKEL_PLUS}
          nickelFeaturesText="Everything in Basic, plus:"
          badgeLabel="Most Popular"
          isDisabled={activated}
          onClick={() => {
            setSelectedPlan(
              isAnnual ? 'NICKEL_PLUS_ANNUAL' : 'NICKEL_PLUS_MONTHLY',
            )
            props.setPage('paymentMethod')
          }}
        />
        <NickelPlanCard
          title="Nickel Pro"
          isAnnual={isAnnual}
          price={isAnnual ? 300 : 375}
          description="For ambitious companies looking to grow quickly" //TODO: Change description accdng to design
          cta="Contact Us" //TODO: Change CTA according to current plan
          achFeatures={AchFeatures.NICKEL_PRO}
          cardFeatures={CardFeatures.NICKEL_PRO}
          nickelFeatures={NickelFeatures.NICKEL_PRO}
          nickelFeaturesText="Everything in Plus, plus Trade Credit"
          onClick={() => {
            window.open('https://calendly.com/nickelhq/nickel-discovery-call')
          }}
        />
      </HStack>
    </>
  )
}

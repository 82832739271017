import { useMutation, useQuery } from '@apollo/client'
import { Fade, HStack, Text, useToast } from '@chakra-ui/react'
import currency from 'currency.js'
import moment from 'moment'
import { useState } from 'react'
import {
  Button,
  Column,
  HFlex,
  Row,
  Separator,
  OverlayElements,
  BillOverlayHeader,
  SlimVendorCard,
  VFlex,
  Breadcrumb,
  OverlayHeaderNotes,
  addBusinessDays,
} from 'ui'
import SkeletonLoader from 'ui/src/components/Loaders/SkeletonLoader'

import {
  RecurringBillFrequency,
  RecurringBillDocument,
  RecurringBillQuery,
  OrganizationTier,
  DeactivateRecurringBillDocument,
  RecurringBillsDocument,
} from '../../../operations-types'
import { capitalize } from 'lodash'
import { VendorOverlay } from '../../ap/vendor/VendorOverlay'
import { PaymentAndDeliveryMethod } from '../components/ReviewBillOverlay'
import { BillCard } from './BillCard'
import { getEstimatedDaysToDelivery } from '../../../lib/utils'

type RecurringBillOverlayProps = {
  recurringBillId: string
  onClose?: () => void
  onBack?: () => void
  onVendorClick: () => void
  onEditRecurringBill?: () => void
  breadcrumbs?: Breadcrumb[]
  onBillClick?: (billId: string) => void
}

type RecurringBillDetailsProps = {
  amountCents: number
  startDate: string
  frequency?: RecurringBillFrequency
  nextPaymentDate: string
  endDate: string
}

const RecurringBillDetails = ({
  amountCents,
  startDate,
  frequency,
  nextPaymentDate,
  endDate,
}: RecurringBillDetailsProps) => {
  return (
    <HFlex w="100%" alignItems="start" justifyContent="space-between">
      <VFlex w="100%" gap={4}>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Amount
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {currency(amountCents || 0, {
              fromCents: true,
            }).format()}
          </Text>
        </VFlex>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Start Date
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {moment(parseInt(startDate)).format('MM/DD/YYYY')}
          </Text>
        </VFlex>
      </VFlex>
      <VFlex w="100%" gap={4}>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Frequency
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {capitalize(frequency?.toLowerCase() || '')}
          </Text>
        </VFlex>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            Next Payment
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {moment(parseInt(nextPaymentDate)).format('MM/DD/YYYY')}
          </Text>
        </VFlex>
      </VFlex>
      <VFlex w="100%" gap={4}>
        <VFlex gap={0}>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            End Date
          </Text>
          <Text fontSize="lg" fontWeight="500">
            {moment(parseInt(endDate)).format('MM/DD/YYYY')}
          </Text>
        </VFlex>
      </VFlex>
    </HFlex>
  )
}

type PaymentsNodeProps = {
  recurringBill: NonNullable<
    RecurringBillQuery['recurringBill']
  >['recurringBill']
  onBillClick?: (billId: string) => void
}

const PaymentsNode = ({ recurringBill, onBillClick }: PaymentsNodeProps) => {
  const pastBills = recurringBill?.bills || []
  const currentBillPayable = recurringBill?.currentBillPayable

  return (
    <Column wGrow className="pb-10 px-8" gap="medium">
      {recurringBill?.approvals?.some(
        (approval) => approval.status === 'PENDING',
      ) && (
        <>
          <div className="text-xl font-semibold">Open Bill</div>
          <Row
            grow
            spacing="medium"
            className="text-sm text-black bg-gray-100 rounded py-4"
          >
            <Text>
              Next payment on{' '}
              {moment(parseInt(recurringBill?.nextPaymentDate || '')).format(
                'MM/DD/YYYY',
              )}{' '}
              if approved
            </Text>
          </Row>
        </>
      )}
      {currentBillPayable &&
        currentBillPayable.status !== 'PAID' &&
        currentBillPayable.status !== 'VOIDED' && (
          <>
            <div className="text-xl font-semibold">Open Bill</div>
            <BillCard
              reference={currentBillPayable.bill?.billData?.reference || ''}
              dueDate={currentBillPayable.dueDate || ''}
              status={currentBillPayable.status || ''}
              amount={currentBillPayable.amountDueCents || 0}
              amountInCents={true}
              id={currentBillPayable.bill?.id || ''}
              onBillClick={onBillClick}
            />
          </>
        )}

      <div className="text-xl font-semibold">Past Bills</div>
      {pastBills.length === 0 ? (
        <Row
          grow
          spacing="medium"
          className="text-sm text-black bg-gray-100 rounded py-4"
        >
          No past bills have been generated yet
        </Row>
      ) : (
        pastBills
          .filter((bill) =>
            currentBillPayable?.status === 'SCHEDULED'
              ? bill.id !== currentBillPayable?.bill?.id
              : true,
          )
          .map((bill, index) => (
            <BillCard
              key={index}
              reference={bill.billData?.reference || ''}
              dueDate={bill.billData?.dueDate || ''}
              status={bill.billData?.status || ''}
              amount={bill.billData?.totalAmount || 0}
              amountInCents={false}
              id={bill.id}
              onBillClick={onBillClick}
            />
          ))
      )}
    </Column>
  )
}

type RecurringBill = NonNullable<
  RecurringBillQuery['recurringBill']
>['recurringBill']

type OverviewNodeProps = {
  recurringBill: RecurringBill
  onVendorClick: () => void
}

const OverviewNode = ({ recurringBill, onVendorClick }: OverviewNodeProps) => {
  const vendorContact = (recurringBill?.vendor?.emails || []).join(', ')
  const vendorPayoutMethod = recurringBill?.vendorPayoutMethod
  const deliveryMethodToDays = getEstimatedDaysToDelivery(
    (vendorPayoutMethod?.type as 'ACH' | 'CHECK') || 'ACH',
    (recurringBill?.paymentMethod?.type as 'ach_debit' | 'card') || 'ach_debit',
    OrganizationTier.TeamTier,
    moment(recurringBill?.nextPaymentDate).isSame(moment(), 'day'),
  )

  const paymentMethodDetails =
    recurringBill?.paymentMethod?.type === 'ach_debit'
      ? {
          iconName: 'wallet',
          type: 'ach_debit',
          displayString: `ACH ···· ${recurringBill?.paymentMethod?.achDebit?.last2}`,
        }
      : {
          iconName: 'creditCard',
          type: 'card',
          displayString: `Credit Card ···· ${recurringBill?.paymentMethod?.card?.last4}`,
        }

  const deliveryMethodDetails =
    vendorPayoutMethod?.type === 'ACH'
      ? {
          iconName: 'wallet',
          type: 'ACH',
          displayString: `ACH ···· ${vendorPayoutMethod?.accountNumber?.slice(
            -2,
          )}`,
        }
      : {
          iconName: 'paycheck',
          type: 'CHECK',
          displayString: `${vendorPayoutMethod?.street} ${vendorPayoutMethod?.city} ${vendorPayoutMethod?.state} ${vendorPayoutMethod?.zip}`,
        }

  return (
    <Column wGrow className="py-6">
      <VFlex w="100%" gap="6" px={8} pb={6}>
        <Text fontSize="xl" fontWeight="semibold">
          Vendor
        </Text>
        <SlimVendorCard
          vendorName={recurringBill?.vendor?.name || ''}
          vendorId={recurringBill?.vendor?.id || ''}
          vendorPayoutMethod={vendorPayoutMethod ?? undefined}
          vendorEmail={vendorContact || ''}
          onVendorClick={onVendorClick}
        />
      </VFlex>
      <Separator orientation="horizontal" />
      <Column wGrow gap="large" className="px-8 py-6">
        <Text fontSize="xl" fontWeight="semibold">
          Recurring Bill Details
        </Text>
        <RecurringBillDetails
          amountCents={recurringBill?.amountCents || 0}
          startDate={recurringBill?.startDate || ''}
          frequency={recurringBill?.frequency}
          nextPaymentDate={recurringBill?.nextPaymentDate || ''}
          endDate={recurringBill?.endDate || ''}
        />
      </Column>

      <Separator orientation="horizontal" />

      <PaymentAndDeliveryMethod
        paymentMethod={
          paymentMethodDetails as {
            iconName: string
            type: 'ach_debit' | 'card'
            displayString: string
          }
        }
        withdrawalDate={moment(
          parseInt(recurringBill?.nextPaymentDate || ''),
        ).toDate()}
        deliveryMethod={
          deliveryMethodDetails as {
            type: 'ACH' | 'CHECK'
            displayString: string
          }
        }
        estimatedDeliveryDate={addBusinessDays(
          moment(parseInt(recurringBill?.nextPaymentDate || '')).toDate(),
          deliveryMethodToDays,
        ).toDate()}
      />
    </Column>
  )
}

export const RecurringBillOverlay = (props: RecurringBillOverlayProps) => {
  const { data } = useQuery(RecurringBillDocument, {
    variables: { recurringBillId: props.recurringBillId },
  })

  const [deactivateRecurringBill] = useMutation(
    DeactivateRecurringBillDocument,
    {
      refetchQueries: [RecurringBillsDocument, RecurringBillDocument],
    },
  )

  const toast = useToast()

  const recurringBill = data?.recurringBill?.recurringBill

  type TabType = 'overview' | 'bills'
  const [tab, setTab] = useState<TabType>('overview')

  const overviewNode = (
    <OverviewNode
      recurringBill={recurringBill}
      onVendorClick={props.onVendorClick}
    />
  )

  if (!recurringBill) {
    return null
  }

  const paymentsNode = (
    <PaymentsNode
      recurringBill={recurringBill}
      onBillClick={props.onBillClick}
    />
  )

  const statusText = recurringBill?.active
    ? 'Active'
    : recurringBill?.approvals?.some(
        (approval) => approval.status === 'PENDING',
      )
    ? 'Pending Approval'
    : 'Inactive'

  return (
    <>
      <BillOverlayHeader
        onClose={props.onClose!}
        title={`Recurring Bill - ${recurringBill?.vendor?.name || ''}`}
        breadcrumbs={props.breadcrumbs}
        type="bills"
        renderHeader={true}
        statusText={statusText}
        statusType={
          recurringBill.active
            ? 'success'
            : recurringBill.approvals?.some(
                (approval) => approval.status === 'PENDING',
              )
            ? 'warning'
            : 'info'
        }
      />
      <OverlayHeaderNotes
        onSave={(note) => {
          // TODO: Implement note saving
        }}
        note={recurringBill?.reason || ''}
      />
      <Column wGrow grow overflowScrollable>
        <Fade
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%' }}
        >
          <Column className="w-full overflow-y-scroll">
            <Separator orientation="horizontal" />
            <OverlayElements
              tabIndex={tab === 'overview' ? 0 : 1}
              elementClassName="px-0"
              onClick={(idx) => {
                if (idx === 0) {
                  setTab('overview')
                } else if (idx === 1) {
                  setTab('bills')
                }
              }}
              nodes={[
                {
                  label: 'Overview',
                  node: overviewNode,
                },
                {
                  label: 'Bills',
                  node: paymentsNode,
                  badgeCount: recurringBill.bills?.length || undefined,
                },
              ]}
            />
          </Column>
        </Fade>
      </Column>

      {props.onBack && (
        <>
          <Separator orientation="horizontal" />
          <HStack w="100%" px={10} py={6} justifyContent="space-between">
            {(recurringBill.active ||
              (!recurringBill.active && !recurringBill.bills?.length)) && (
              <>
                <Button
                  label={
                    recurringBill.bills?.length &&
                    recurringBill.bills?.length > 1
                      ? 'Deactivate'
                      : 'Delete'
                  }
                  variant="outline"
                  onClick={async () => {
                    const resp = await deactivateRecurringBill({
                      variables: { recurringBillId: recurringBill.id },
                    })
                    if (resp.data?.deactivateRecurringBill?.recurringBill) {
                      toast({
                        title: 'Recurring bill deactivated',
                        description: 'The recurring bill has been deactivated',
                        status: 'success',
                      })
                    } else {
                      toast({
                        title: 'Error deactivating recurring bill',
                        description:
                          resp.data?.deactivateRecurringBill?.error?.message,
                        status: 'error',
                      })
                    }
                  }}
                />
              </>
            )}

            <Button label="Back" onClick={props.onBack} />
          </HStack>
        </>
      )}
    </>
  )
}

type RecurringBillStates = 'vendor' | 'recurringBill'

type RecurringBillMasterOverlayProps = {
  recurringBillId: string
  onClose?: () => void
  onBack?: () => void
  onEditRecurringBill?: () => void
  breadcrumbs?: Breadcrumb[]
  onBillClick?: (billId: string) => void
}

export const RecurringBillMasterOverlay = ({
  recurringBillId,
  onClose,
  onBack,
  onEditRecurringBill,
  breadcrumbs,
  onBillClick,
}: RecurringBillMasterOverlayProps) => {
  const { data, loading: recurringBillLoading } = useQuery(
    RecurringBillDocument,
    {
      variables: { recurringBillId: recurringBillId },
    },
  )

  const recurringBill = data?.recurringBill?.recurringBill
  const vendor = recurringBill?.vendor

  const [state, setState] = useState<RecurringBillStates>('recurringBill')

  const components: Record<RecurringBillStates, React.ReactNode> = {
    vendor: (
      <VendorOverlay
        vendorIdProp={vendor?.id}
        onBack={() => setState('recurringBill')}
        breadcrumbs={breadcrumbs}
        isSecondaryOverlay
        onVendorClose={onClose}
      />
    ),
    recurringBill: (
      <RecurringBillOverlay
        recurringBillId={recurringBillId}
        onClose={onClose}
        onBack={onBack}
        onEditRecurringBill={onEditRecurringBill}
        breadcrumbs={breadcrumbs}
        onVendorClick={() => {
          setState('vendor')
        }}
        onBillClick={onBillClick}
      />
    ),
  }

  if (!recurringBill || !vendor) {
    return null
  }

  return (
    <>
      {recurringBillLoading ? (
        <SkeletonLoader className="!w-[200px]" />
      ) : (
        components[state]
      )}
    </>
  )
}

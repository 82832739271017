import { useLazyQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import {
  GetBankRoutingNumberDocument,
  GetBankRoutingNumberQuery,
  UpdateVendorCheckPayoutMethodDocument,
  UpdateVendorPayoutMethodDocument,
  Vendor,
  VendorDocument,
  VendorPayoutMethod,
} from '../../../operations-types'
import {
  Card,
  VStack,
  useToast,
  Collapse,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import {
  ACHForm,
  AddressInput,
  Button,
  Form,
  HFlex,
  US_STATES,
  VendorPayoutMethodCard,
  BaseVendorPayoutDetailsValidationSchema,
} from 'ui'
import { PayoutMethodBase } from 'ui/src/types'

export const mapToPayoutMethodBase = (
  method: VendorPayoutMethod,
): PayoutMethodBase => ({
  bankName: method.bankName ?? null,
  accountNumber: method.accountNumber ?? null,
  accountType: method.accountType ?? null,
  street: method.street ?? null,
  city: method.city ?? null,
  state: method.state ?? null,
  zip: method.zip ?? null,
})

interface VendorPayoutDetailsV2Props {
  vendorId: string
  vendorPayoutMethods: Vendor['vendorPayoutMethods']
  vendorEmail?: string
  paymentMethod: 'ACH' | 'check'
  internalButtons?: boolean
  onChangeRoutingNumber?: (number: string, bankName?: string) => void
  onChangeAccountNumber?: (number: string) => void
  onChangeAccountType?: (type: string) => void
  onChangeRecipientAddress?: (address: string) => void
  onChangeCity?: (city: string) => void
  onChangeState?: (state: string) => void
  onChangeZip?: (zip: string) => void
}

export default function VendorPayoutDetailsV2({
  vendorId,
  vendorPayoutMethods,
  vendorEmail,
  paymentMethod,
  internalButtons = true,
  onChangeRoutingNumber,
  onChangeAccountNumber,
  onChangeAccountType,
  onChangeRecipientAddress,
  onChangeCity,
  onChangeState,
  onChangeZip,
}: VendorPayoutDetailsV2Props) {
  const [activeMethod, setActiveMethod] = useState<'ACH' | 'check' | null>(null)
  const toast = useToast()

  const [updateVendorPayoutMethod] = useMutation(
    UpdateVendorPayoutMethodDocument,
    {
      onCompleted: () => {
        setActiveMethod(null)
        onToggle()
      },
      refetchQueries: [VendorDocument],
    },
  )

  const [updateVendorCheckPayoutMethod] = useMutation(
    UpdateVendorCheckPayoutMethodDocument,
    {
      onCompleted: () => {
        setActiveMethod(null)
        onToggle()
      },
      refetchQueries: [VendorDocument],
    },
  )

  const [getBankName, { loading: getBankNameLoading }] =
    useLazyQuery<GetBankRoutingNumberQuery>(GetBankRoutingNumberDocument)

  const { isOpen, onToggle } = useDisclosure()

  const vendorAchPayoutMethod =
    vendorPayoutMethods?.find((method) => method?.type === 'ACH') || null

  const vendorCheckPayoutMethod =
    vendorPayoutMethods?.find((method) => method?.type === 'CHECK') || null

  // Create a submit function that can be called externally
  const handleSubmit = async (values: any) => {
    try {
      if (values.paymentVia === 'ACH') {
        await updateVendorPayoutMethod({
          variables: {
            vendorId,
            accountNumber: values.achDetails.accountNumber,
            routingNumber: values.achDetails.routingNumber,
            accountType: values.achDetails.accountType,
          },
        })
      } else if (values.paymentVia === 'check') {
        await updateVendorCheckPayoutMethod({
          variables: {
            vendorId,
            street: values.achDetails.recipientAddress.streetAddress,
            city: values.achDetails.recipientAddress.city,
            state: values.achDetails.recipientAddress.state,
            zip: values.achDetails.recipientAddress.zipCode,
          },
        })
      }
      toast({
        status: 'success',
        title: 'Success',
        description: 'Payment method updated successfully',
      })
    } catch (error) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to update payment method',
      })
      throw error // Re-throw to handle in parent component if needed
    }
  }

  return (
    <Form
      validationSchema={BaseVendorPayoutDetailsValidationSchema}
      initialValues={{
        paymentVia: '',
        email: vendorEmail || '',
        achDetails: {
          accountNumber: vendorAchPayoutMethod?.accountNumber || '',
          routingNumber: vendorAchPayoutMethod?.routingNumber || '',
          bankName: vendorAchPayoutMethod?.bankName || '',
          confirmedAccountNumber: '',
          accountType: vendorAchPayoutMethod?.accountType || 'checking',
          recipientAddress: {
            streetAddress: vendorCheckPayoutMethod?.street || '',
            city: vendorCheckPayoutMethod?.city || '',
            state:
              US_STATES.states.find(
                (x) =>
                  x.abbreviation === vendorCheckPayoutMethod?.state ||
                  x.name === vendorCheckPayoutMethod?.state,
              )?.name || '',
            zipCode: vendorCheckPayoutMethod?.zip || '',
          },
        },
      }}
      onSubmit={async (values) => {
        await handleSubmit(values)
      }}
    >
      {(formik) => {
        return (
          <VStack spacing={0} align="stretch" w="100%">
            {paymentMethod === 'ACH' && (
              <>
                <VendorPayoutMethodCard
                  isActive={activeMethod === 'ACH'}
                  vendorPayoutMethod={
                    vendorAchPayoutMethod
                      ? mapToPayoutMethodBase(vendorAchPayoutMethod)
                      : null
                  }
                  type="ACH"
                  setEditMethod={() => {
                    formik.setFieldValue('paymentVia', 'ACH')
                    if (activeMethod !== 'ACH') {
                      setActiveMethod('ACH')
                      onToggle()
                    } else {
                      setActiveMethod(null)
                      onToggle()
                    }
                  }}
                />
              </>
            )}
            {paymentMethod === 'check' && (
              <VendorPayoutMethodCard
                isActive={activeMethod === 'check'}
                vendorPayoutMethod={
                  vendorCheckPayoutMethod
                    ? mapToPayoutMethodBase(vendorCheckPayoutMethod)
                    : null
                }
                type="check"
                setEditMethod={() => {
                  formik.setFieldValue('paymentVia', 'check')
                  if (activeMethod !== 'check') {
                    setActiveMethod('check')
                    onToggle()
                  } else {
                    setActiveMethod(null)
                    onToggle()
                  }
                }}
              />
            )}

            <Collapse
              in={isOpen}
              animateOpacity
              transition={{ exit: { duration: 0.5 }, enter: { duration: 0.5 } }}
            >
              {activeMethod === 'ACH' && paymentMethod === 'ACH' && (
                <Card
                  p={4}
                  bg="gray.50"
                  borderTopLeftRadius="0"
                  borderTopRightRadius="0"
                  borderTop="none"
                  w="100%"
                >
                  <ACHForm
                    isDemo={false}
                    loading={getBankNameLoading}
                    routingNumber={
                      formik.values.achDetails?.routingNumber || ''
                    }
                    accountNumber={
                      formik.values.achDetails?.accountNumber || ''
                    }
                    confirmedAccountNumber={
                      formik.values.achDetails?.confirmedAccountNumber || ''
                    }
                    bankName={formik.values.achDetails?.bankName || ''}
                    accountType={formik.values.achDetails?.accountType || ''}
                    accountSelectInputName="achDetails.accountType"
                    onChangeRoutingNumber={async (number) => {
                      formik.setFieldValue('achDetails.routingNumber', number)
                      formik.setFieldValue('achDetails.bankName', '')
                      if (number.length === 9) {
                        const response = await getBankName({
                          variables: { bankRoutingNumber: number },
                        })
                        if (
                          response.data?.bankRoutingNumber?.bankRoutingNumber
                            ?.bankName
                        ) {
                          formik.setFieldValue(
                            'achDetails.bankName',
                            response.data.bankRoutingNumber.bankRoutingNumber
                              .bankName,
                          )
                        }
                        onChangeRoutingNumber?.(
                          number,
                          response?.data?.bankRoutingNumber?.bankRoutingNumber
                            ?.bankName || '',
                        )
                      }
                    }}
                    onChangeAccountNumber={(number) => {
                      formik.setFieldValue('achDetails.accountNumber', number)
                      onChangeAccountNumber?.(number)
                    }}
                    onChangeConfirmAccountNumber={(number) => {
                      formik.setFieldValue(
                        'achDetails.confirmedAccountNumber',
                        number,
                      )
                    }}
                    onChangeAccountType={(type) => {
                      formik.setFieldValue('achDetails.accountType', type)
                      onChangeAccountType?.(type)
                    }}
                  />
                  {internalButtons && (
                    <HFlex justifyContent="end" w="100%" gap="4" pt="4">
                      <Button
                        size="sm"
                        onClick={() => {
                          setActiveMethod(null)
                          onToggle()
                        }}
                        label="Cancel"
                        variant="outline"
                      />
                      <Button
                        size="sm"
                        label="Save"
                        isDisabled={Object.keys(formik.errors).length > 0}
                        isLoading={formik.isSubmitting}
                        onClick={() => {
                          formik.submitForm()
                        }}
                      />
                    </HFlex>
                  )}
                </Card>
              )}

              {activeMethod === 'check' && paymentMethod === 'check' && (
                <Card
                  p={4}
                  borderTopLeftRadius="0"
                  borderTopRightRadius="0"
                  borderTop="none"
                  bgColor="gray.50"
                >
                  <Box w="100%" bgColor="white">
                    <AddressInput
                      {...{
                        valueKey: 'achDetails.recipientAddress',
                        apiKey: import.meta.env.VITE_SMARTY_STREETS_KEY,
                        onChangeAddress: onChangeRecipientAddress || undefined,
                        onChangeCity: onChangeCity || undefined,
                        onChangeState: onChangeState || undefined,
                        onChangeZip: onChangeZip || undefined,
                      }}
                    />
                  </Box>

                  {internalButtons && (
                    <HFlex justifyContent="end" w="100%" gap="4" pt="4">
                      <Button
                        size="sm"
                        onClick={() => {
                          setActiveMethod(null)
                          onToggle()
                        }}
                        label="Cancel"
                        variant="outline"
                      />
                      <Button
                        size="sm"
                        label="Save"
                        isDisabled={Object.keys(formik.errors).length > 0}
                        isLoading={formik.isSubmitting}
                        onClick={() => {
                          formik.submitForm()
                        }}
                      />
                    </HFlex>
                  )}
                </Card>
              )}
            </Collapse>
          </VStack>
        )
      }}
    </Form>
  )
}

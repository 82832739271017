import { useMutation, useQuery } from '@apollo/client'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
  Text,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  HStack,
  useToast,
  Fade,
} from '@chakra-ui/react'
import currency from 'currency.js'
import { useEffect, useState } from 'react'
import {
  Button,
  VFlex,
  Row,
  Separator,
  OverlayElements,
  BillOverlayHeader,
  Breadcrumb,
} from 'ui'
import {
  RemoveVendorDocument,
  RequestVendorDeliveryDetailsDocument,
  UpdateVendorDocument,
  VendorDocument,
  VendorsDocument,
} from '../../../operations-types'

import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom'
import { PAY_VENDORS_VENDORS_URL } from '../../../lib/urls'
import SkeletonLoader from 'ui/src/components/Loaders/SkeletonLoader'
import EditVendorDetails from './EditVendorDetails'
import VendorTransactionSummary from './VendorTransactionsSummary'
import VendorBills from './VendorBills'
import VendorDetails from './VendorDetails'
import VendorBillingContact from './VendorBillingContact'
import VendorOverlayFooter from './VendorOverlayFooter'
import { VendorDeliveryMethod } from '../../bills/components/ScheduleBillOverlay'
import { AddDeliveryMethodOverlay } from '../../bills/components/AddDeliveryMethodOverlay'
import { useAddBillStore } from '../../stores/addBillStore'
import {
  BillOverlay,
  BillOverlayStates,
} from '../../bills/components/BillOverlay'
import { ScrollableColumn } from 'ui'

type TabType =
  | 'vendor'
  | 'editVendor'
  | 'overview'
  | 'bills'
  | 'tax'
  | 'newBill'

type VendorOverlayProps = {
  vendorIdProp?: string
  onBack?: () => void
  isSecondaryOverlay?: boolean
  onVendorClose?: () => void
  breadcrumbs?: Breadcrumb[]
}
export function VendorOverlay({
  vendorIdProp,
  onBack,
  isSecondaryOverlay,
  breadcrumbs,
  onVendorClose,
}: VendorOverlayProps) {
  const { vendorId: contextVendorId } = useOutletContext() as {
    vendorId: string | undefined
  }

  const vendorId = vendorIdProp || contextVendorId

  const { data, loading } = useQuery(VendorDocument, {
    variables: {
      vendorId: vendorIdProp || contextVendorId,
    },
    skip: !vendorIdProp && !contextVendorId,
  })

  const { setVendorId } = useAddBillStore((state) => {
    return {
      setVendorId: state.setVendorId,
    }
  })
  const [removeVendor] = useMutation(RemoveVendorDocument, {
    refetchQueries: [VendorsDocument],
  })

  const [updateVendor] = useMutation(UpdateVendorDocument, {
    refetchQueries: [VendorDocument],
  })

  const [requestVendorDeliveryDetails] = useMutation(
    RequestVendorDeliveryDetailsDocument,
    {
      refetchQueries: [VendorsDocument],
    },
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [params] = useSearchParams()

  const payVendor = params.get('payVendor')

  const [newBillOpen, setNewBillOpen] = useState(false)

  const vendor = data?.vendor?.vendor

  const navigate = useNavigate()

  const [tab, setTab] = useState<TabType>(
    payVendor === 'true' ? 'newBill' : 'overview',
  )
  const toast = useToast()

  const [addDeliveryMethodOpen, setAddDeliveryMethodOpen] = useState(false)

  const [addDeliveryMethodType, setAddDeliveryMethodType] = useState<
    'ACH' | 'Check' | null
  >(null)
  useEffect(() => {
    if (payVendor === 'true') {
      setTab('newBill')
      setNewBillOpen(true)
    }
  }, [payVendor])

  const [currentInvoice, setCurrentInvoice] = useState<string | null>(null)

  const [billOverlayState, setBillOverlayState] =
    useState<BillOverlayStates>('newBill')

  if (!vendorId) {
    console.error('Vendor ID is undefined')
    return null
  }

  const editVendorNode = (
    <EditVendorDetails
      name={vendor?.name || ''}
      email={vendor?.emails?.join(', ') || ''}
      taxId={vendor?.taxId || ''}
      vendorId={vendorId}
      setTab={setTab}
    />
  )

  const addDeliveryMethodNode = (
    <AddDeliveryMethodOverlay
      onClose={() => setAddDeliveryMethodOpen(false)}
      onBack={() => setAddDeliveryMethodOpen(false)}
      vendorId={vendorId}
      type={addDeliveryMethodType || 'ACH'}
      onSave={() => {
        setAddDeliveryMethodOpen(false)
      }}
    />
  )
  const addBillNode = (
    <BillOverlay
      open={newBillOpen}
      setOpen={(bool) => {
        if (!bool) {
          navigate(PAY_VENDORS_VENDORS_URL)
        }
        setNewBillOpen(bool)
      }}
      setBillId={setCurrentInvoice}
      billId={currentInvoice ?? ''}
      state={billOverlayState}
      setState={setBillOverlayState}
      skipOverlay={true}
    />
  )

  const billsNode = (
    <VendorBills
      unpaidBills={vendor?.unpaidBills || []}
      onBillClick={(billId) => {
        setBillOverlayState('schedule')
        setCurrentInvoice(billId)
        setTab('newBill')
        setNewBillOpen(true)
      }}
    />
  )

  const vendorStatus = vendor?.vendorPayoutMethods?.[0]
    ? 'Ready to pay'
    : 'Missing details'

  const overviewNode = (
    <>
      <VendorDetails
        vendorStatus={vendorStatus}
        taxId={vendor?.taxId || ''}
        address={vendor?.address || ''}
        city={vendor?.city || ''}
        state={vendor?.state || ''}
        zip={vendor?.zip || ''}
      />

      <Separator orientation="horizontal" />

      <VFlex w="100%" gap="6" px={8} py={8}>
        <Text fontSize="xl" fontWeight="semibold">
          Spend
        </Text>

        <VendorTransactionSummary
          setTab={setTab}
          remainingBalance={currency(
            vendor?.transactionsSummary?.remainingBalanceInCents || 0,
            { fromCents: true },
          ).format()}
          totalPaid={currency(
            vendor?.transactionsSummary?.totalPaidInCents || 0,
            { fromCents: true },
          ).format()}
          unpaidBills={vendor?.unpaidBills?.length || 0}
        />
      </VFlex>
      <Separator orientation="horizontal" />
      <VendorDeliveryMethod
        vendorId={vendorId}
        onAddDeliveryMethod={(type) => {
          setAddDeliveryMethodType(type)
          setAddDeliveryMethodOpen(true)
        }}
      />

      <Separator orientation="horizontal" />

      <VendorBillingContact
        emails={vendor?.emails || []}
        onClick={async (emails: string[]) => {
          const response = await updateVendor({
            variables: {
              vendorId: vendorId,
              emails: emails,
            },
          })

          if (response.data?.updateVendor?.error?.message) {
            toast({
              title: 'Error',
              description: response.data.updateVendor.error.message,
              status: 'error',
            })
          } else {
            toast({
              title: 'Contact updated',
              description: 'Contact updated successfully',
              status: 'success',
            })
          }
        }}
      />

      <VFlex w="100%">
        <VFlex w="100%" gap="6" px={8}>
          <Button
            label="Request Payment Delivery Information"
            isDisabled={vendor?.emails?.length === 0}
            onClick={async () => {
              const response = await requestVendorDeliveryDetails({
                variables: {
                  vendorId: vendorId,
                  emails: vendor?.emails || [],
                },
              })

              if (response.data?.requestVendorDeliveryDetails?.error) {
                toast({
                  title: 'Error',
                  description:
                    response.data.requestVendorDeliveryDetails.error.message,
                  status: 'error',
                })
              } else {
                toast({
                  title: 'Delivery information requested',
                  description:
                    'We sent a request to your vendor to provide us with their payment information.',
                  status: 'success',
                })
              }
            }}
          />
        </VFlex>
      </VFlex>
    </>
  )

  return (
    <>
      {tab !== 'editVendor' && tab !== 'newBill' && !addDeliveryMethodOpen && (
        <>
          {loading ? (
            <Row spacing="large">
              <SkeletonLoader className="!w-[200px]" />
            </Row>
          ) : (
            <BillOverlayHeader
              onClose={
                onVendorClose
                  ? onVendorClose
                  : () => {
                      navigate(PAY_VENDORS_VENDORS_URL)
                    }
              }
              title={vendor?.name || ''}
              breadcrumbs={
                breadcrumbs || [
                  { label: 'Bill Pay' },
                  { label: 'Vendors' },
                  { label: vendor?.name || '', isActive: true },
                ]
              }
              type="vendor"
            />
          )}
          <ScrollableColumn className="w-full">
            <Fade
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ width: '100%' }}
            >
              <Separator orientation="horizontal" />
              <OverlayElements
                elementClassName="px-0"
                tabIndex={tab === 'overview' ? 0 : 1}
                onClick={(idx) => {
                  if (idx === 0) {
                    setTab('overview')
                  } else if (idx === 1) {
                    setTab('bills')
                  }
                }}
                nodes={[
                  {
                    label: 'Overview',
                    node: overviewNode,
                  },
                  {
                    label: 'Bills',
                    node: billsNode,
                  },
                ]}
              />
            </Fade>
          </ScrollableColumn>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Are you sure you want to remove this vendor?
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  This action cannot be undone. This will remove the vendor from
                  your account and you will no longer be able to pay them.
                </Text>
              </ModalBody>
              <ModalFooter>
                <HStack gap={4} w="100%" justifyContent="space-between">
                  <Button
                    label="Yes, delete this vendor"
                    variant="outline"
                    onClick={async () => {
                      const response = await removeVendor({
                        variables: {
                          vendorId: vendorId,
                        },
                      })

                      if (response.data?.removeVendor?.error?.message) {
                        toast({
                          title: 'Error',
                          description: response.data.removeVendor.error.message,
                          status: 'error',
                        })
                      } else {
                        toast({
                          title: 'Vendor removed',
                          description: 'Vendor removed successfully',
                          status: 'success',
                        })
                        navigate(PAY_VENDORS_VENDORS_URL)
                      }
                    }}
                  />
                  <Button label="Cancel" variant="ghost" onClick={onClose} />
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <VendorOverlayFooter
            onBack={onBack}
            isExternalVendor={!!vendor?.externalVendorId || false}
            onDeleteVendor={() => {
              onOpen()
            }}
            onPayVendor={
              isSecondaryOverlay
                ? undefined
                : () => {
                    setTab('newBill')
                    setVendorId(vendor?.id || null)
                    setCurrentInvoice(null)
                    setBillOverlayState('newBill')
                    setNewBillOpen(true)
                  }
            }
          />
        </>
      )}

      {addDeliveryMethodOpen && addDeliveryMethodNode}

      {tab === 'editVendor' && editVendorNode}

      {tab === 'newBill' && addBillNode}
    </>
  )
}

import { useState } from 'react'
import { Box, Divider, HStack, Text } from '@chakra-ui/react'
import Select, { components, OptionProps, SingleValueProps } from 'react-select'
import { Icon, IconName, VFlex } from '../..'

export type PaymentSelectOption<T> = {
  label: string
  iconName: IconName
  subtitle: string
  rightText?: string
  rightTextColor?: string
  rightTextIcon?: IconName
  value: T | null
}

type PaymentSelectProps<T> = {
  label: string
  options: PaymentSelectOption<T>[]
  value: PaymentSelectOption<T> | null
  onClick: (
    value: PaymentSelectOption<T>,
    e?: React.MouseEvent<HTMLDivElement>,
  ) => void
  onOptionsClick?: (
    data: PaymentSelectOption<T>,
    e: React.MouseEvent<HTMLDivElement>,
  ) => void
  errorBorder?: boolean
}

type ValueContentProps<T> = {
  data: PaymentSelectOption<T>
  onOptionsClick?: (
    data: PaymentSelectOption<T>,
    e: React.MouseEvent<HTMLDivElement>,
  ) => void
}

type PaymentSelectContentProps = {
  iconName: IconName
  label: string
  subtitle: string
  rightText?: string
  rightTextColor?: string
  rightTextIcon?: IconName
  onOptionsClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}
export function PaymentSelectContent({
  iconName,
  label,
  subtitle,
  rightText,
  rightTextColor,
  rightTextIcon,
  onOptionsClick,
}: PaymentSelectContentProps) {
  return (
    <HStack gap={2}>
      <Box
        bgColor="gray.100"
        borderRadius="full"
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon name={iconName} size="large" />
      </Box>
      <VFlex w="100%">
        <HStack justifyContent={'space-between'}>
          <Text fontSize="13px" noOfLines={1}>
            {label}
          </Text>
          <HStack>
            {rightText && (
              <Text fontSize="xs" fontWeight="medium" color={rightTextColor}>
                {rightText}
              </Text>
            )}
            {onOptionsClick && rightTextIcon && (
              <Icon
                name={rightTextIcon}
                size="small"
                hoverable
                onClick={(e) => {
                  onOptionsClick && onOptionsClick(e)
                }}
              />
            )}
          </HStack>
        </HStack>
        <Text color="gray.500" fontSize="xs" noOfLines={1}>
          {subtitle}
        </Text>
      </VFlex>
    </HStack>
  )
}

export function PaymentSelect<T>({
  label,
  options,
  value,
  onClick,
  onOptionsClick,
  errorBorder,
}: PaymentSelectProps<T>) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const ValueContent = (props: ValueContentProps<T>) => {
    return (
      <PaymentSelectContent
        iconName={props.data.iconName}
        label={props.data.label}
        subtitle={props.data.subtitle}
        rightText={props.data.rightText}
        rightTextColor={props.data.rightTextColor}
        rightTextIcon={props.data.rightTextIcon}
        onOptionsClick={
          onOptionsClick
            ? (e) => {
                e.stopPropagation()
                setMenuIsOpen(true)
                onOptionsClick(props.data, e)
              }
            : undefined
        }
      />
    )
  }

  const SingleValue = (props: SingleValueProps<PaymentSelectOption<T>>) => {
    return (
      <Box gridArea={'1/1/2/3'} py={2} px={4} w="100%" borderRadius="4px">
        <ValueContent data={props.data} />
      </Box>
    )
  }

  const Option = (props: OptionProps<PaymentSelectOption<T>>) => {
    return (
      <VFlex>
        <Box
          py={2}
          px={4}
          _hover={{ bg: 'gray.100' }}
          cursor="pointer"
          onClick={(e) => {
            setMenuIsOpen(false)
            onClick(props.data, e)
          }}
        >
          <ValueContent data={props.data} onOptionsClick={onOptionsClick} />
        </Box>
        {/* Only show divider if the option is not the last one */}
        {!props.innerProps.id?.includes(`option-0-${options.length - 1}`) && (
          <Divider />
        )}
      </VFlex>
    )
  }

  return (
    <Select<PaymentSelectOption<T>>
      menuPortalTarget={document.body}
      isClearable={false}
      isSearchable={false}
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(false)}
      onMenuOpen={() => setMenuIsOpen(true)}
      value={value}
      formatGroupLabel={(data) => {
        return (
          <Text fontSize="xs" fontWeight="medium">
            {data.label}
          </Text>
        )
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          border: errorBorder ? '1px solid red' : '1px solid #E5E5E5',
          borderRadius: '4px',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#78716C',
            cursor: 'pointer',
            background: '#f5f5f4',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        }),
        noOptionsMessage: (base) => ({
          ...base,
          color: 'gray.800',
          cursor: 'pointer',
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
      }}
      components={{
        Option,
        SingleValue,
        Control: ({ children, ...rest }) => {
          return <components.Control {...rest}>{children}</components.Control>
        },
      }}
      options={[
        {
          label: label,
          options: options,
        },
      ]}
    />
  )
}

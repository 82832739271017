import { Text, VStack } from '@chakra-ui/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { HFlex } from 'ui'
import { TabType } from './types'

type VendorTransactionSummaryProps = {
  remainingBalance: string
  totalPaid: string
  unpaidBills: number
  setTab: (tab: TabType) => void
}

export default function VendorTransactionSummary(
  props: VendorTransactionSummaryProps,
) {
  return (
    <HFlex minW="320px" alignItems="start" gap="32">
      <VStack alignItems="start">
        <Text fontSize="xs" fontWeight="400" color="gray.500">
          Total Spend
        </Text>
        <Text fontSize="lg" fontWeight="500">
          {props.totalPaid}
        </Text>
      </VStack>
      <VStack alignItems="start">
        <Text fontSize="xs" fontWeight="400" color="gray.500">
          Unpaid Balance
        </Text>
        <Text fontSize="lg" fontWeight="500">
          {props.remainingBalance}
        </Text>
      </VStack>
      <VStack
        alignItems="start"
        cursor="pointer"
        onClick={() => {
          props.setTab('bills')
        }}
      >
        <Text fontSize="xs" fontWeight="400" color="gray.500">
          Unpaid bills
        </Text>
        <Text fontSize="lg" fontWeight="500">
          <HFlex alignItems="center" gap="2">
            {props.unpaidBills}
            <ArrowRightIcon className="h-4 w-4 stroke-2" />
          </HFlex>
        </Text>
      </VStack>
    </HFlex>
  )
}

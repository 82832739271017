import { calculatePaymentSummaryForMerchant, PaymentSummary } from 'ui'
import {
  ApprovalPolicy,
  PaymentMethod,
  VendorPayoutMethod,
} from '../../operations-types'
import { Bill } from './batchStoreTypes'
import currency from 'currency.js'

export class BillWithPaymentData {
  bill: Bill
  paymentMethod?: PaymentMethod
  deliveryMethod?: VendorPayoutMethod
  withdrawalDate: Date
  vendorMemo?: string
  vendorEmails: string[]
  approvalPolicies: ApprovalPolicy[]

  constructor(bill: Bill) {
    this.bill = bill
    this.withdrawalDate = new Date()
    this.vendorEmails = bill.vendor?.emails || []
    this.approvalPolicies = []
  }

  setApprovalPolicies(approvalPolicies: ApprovalPolicy[]): BillWithPaymentData {
    this.approvalPolicies = approvalPolicies
    return this
  }

  setPaymentMethod(paymentMethod: PaymentMethod): BillWithPaymentData {
    this.paymentMethod = paymentMethod
    return this
  }

  getAmountDueCents(): number {
    return currency(this.bill.billData?.amountDue || 0).intValue
  }

  setDeliveryMethod(deliveryMethod: VendorPayoutMethod): BillWithPaymentData {
    this.deliveryMethod = deliveryMethod
    return this
  }

  getPaymentSummary(): PaymentSummary {
    if (!this.deliveryMethod || !this.paymentMethod) {
      // Use ACH as defaults because there aren't any fees
      return calculatePaymentSummaryForMerchant(
        this.getAmountDueCents(),
        'None',
        'ACH',
      )
    }

    return calculatePaymentSummaryForMerchant(
      this.getAmountDueCents(),
      this.paymentMethod?.card?.brand ? 'Credit Card' : 'ACH Transfer',
      this.deliveryMethod?.type === 'ACH' ? 'ACH' : 'Check',
    )
  }

  setWithdrawalDate(withdrawalDate: Date): BillWithPaymentData {
    this.withdrawalDate = withdrawalDate
    return this
  }

  setVendorMemo(vendorMemo: string): BillWithPaymentData {
    this.vendorMemo = vendorMemo
    return this
  }

  setVendorEmails(vendorEmails: string[]): BillWithPaymentData {
    this.vendorEmails = vendorEmails
    return this
  }
}

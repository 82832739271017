import { useQuery } from '@apollo/client'
import {
  VendorsDocument,
  VendorsQueryVariables,
} from '../../../operations-types'
import { PageLayout } from '../../layout/PageLayout'
import { TableV2, Column, Header, Row, Button, HFlex, XLARGE_CELL_PX } from 'ui'
import { FilterItem } from 'ui/src/components/Transactions/AddFilter'
import { VStack, Text, Box, Divider } from '@chakra-ui/react'
import currency from 'currency.js'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AddVendorOverlay } from './AddVendorOverlay'
import TableSearchInput from 'ui/src/components/TableSearchInput'
import { VendorOutlet } from './VendorOutlet'
import { useAddBillStore } from '../../stores/addBillStore'

export function Vendors() {
  const availableSpace = window.screen.availHeight - 420
  const pageSize = Math.floor(availableSpace / XLARGE_CELL_PX)

  const [localSearchQuery, setLocalSearchQuery] = useState('')
  const navigate = useNavigate()

  const [newVendorOpen, setNewVendorOpen] = useState(false)

  const [vendorName, setVendorName] = useState('')

  const [page, setPage] = useState(1)

  const [queryOpts, setQueryOpts] = useState<VendorsQueryVariables>({
    page: page,
    pageSize: pageSize,
    vendorName: vendorName,
  })

  const { data, loading } = useQuery(VendorsDocument, {
    variables: {
      ...queryOpts,
    },
  })

  const vendors = data?.vendors?.vendors?.map((vendor) => ({
    id: vendor.id || '',
    vendor: {
      name: vendor.name,
      source: vendor.vendorSource,
      methods: vendor.vendorPayoutMethods || [],
    },
    primaryContact: vendor.emails?.at(0),
    billingAddress: {
      line1: vendor.address ? `${vendor?.address} ` : '',
      line2:
        vendor.city && vendor.state && vendor.zip
          ? `${vendor?.city}, ${vendor?.state} ${vendor?.zip}`
          : '',
    },
    remainingBalance: currency(
      vendor.transactionsSummary?.remainingBalanceInCents || 0,
      { fromCents: true },
    ).format(),
    totalPaid: currency(vendor.transactionsSummary?.totalPaidInCents || 0, {
      fromCents: true,
    }).format(),
    unpaidBills: {
      label: vendor.unpaidBills?.length || 0,
      value: vendor.name,
    },
    action: {
      label: 'Create Bill',
      value: vendor.name,
    },
    vendorPayoutMethods: vendor.vendorPayoutMethods,
  }))

  const [searchQuery, setSearchQuery] = useState('')

  const { setVendorId } = useAddBillStore((state) => ({
    setVendorId: state.setVendorId,
  }))

  const totalPages = Math.ceil((data?.vendors?.totalResults || 0) / pageSize)

  return (
    <PageLayout>
      <Column
        wGrow
        className="w-full bg-white rounded-md max-h-[calc(100vh-2rem)] min-h-[calc(100vh-2rem)]"
      >
        <Row className="rounded-md" between grow>
          <VStack
            spacing="0"
            gap="0"
            alignItems="left"
            className="w-full h-full"
          >
            <Row className="p-4 w-full" between>
              <Row grow y="center" between>
                <Column>
                  <Header variant="page" className="text-[20px]">
                    Vendors
                  </Header>
                  <Text color="gray.500" className="text-sm">
                    View and manage your vendors
                  </Text>
                </Column>
              </Row>
              <Button
                label="New Vendor"
                iconName="plusIcon"
                className="!text-sm !h-[30px]"
                iconPosition="left"
                onClick={() => setNewVendorOpen(true)}
              />
            </Row>
            <Column wGrow className="!pb-0" gap="small">
              <Column className="h-full" wGrow grow>
                <Divider my="1" />
                <Column wGrow>
                  <HFlex
                    w="100%"
                    justifyContent="start"
                    alignContent="start"
                    px="4"
                    py="2"
                    gap="4"
                  >
                    <TableSearchInput
                      placeholder="Search vendor name"
                      {...{
                        value: localSearchQuery,
                        onChange: (e) => {
                          setLocalSearchQuery(e.target.value)
                        },
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            setSearchQuery(localSearchQuery)
                            setVendorName(localSearchQuery)
                            setQueryOpts({
                              ...queryOpts,
                              vendorName: localSearchQuery,
                              page: 1,
                            })

                            setLocalSearchQuery('')
                            setPage(1)
                          }
                        },
                      }}
                    />
                    {searchQuery && (
                      <FilterItem
                        label="Name"
                        value={searchQuery}
                        onClose={() => {
                          setSearchQuery('')
                          setVendorName('')
                          setQueryOpts({
                            ...queryOpts,
                            vendorName: '',
                          })
                        }}
                      />
                    )}
                  </HFlex>
                  <Box w="100%" pt="4" py={2} className="!pb-0">
                    <TableV2
                      data={vendors || []}
                      headers={[
                        {
                          type: 'vendor',
                          keyName: 'name',
                          label: 'Vendor Name',
                          grow: 2,
                        },
                        {
                          type: 'description',
                          keyName: 'primaryContact',
                          label: 'Primary Contact',
                          grow: 1,
                        },
                        {
                          type: 'billingAddress',
                          keyName: 'billingAddress',
                          label: 'Billing Address',
                          grow: 1,
                        },

                        {
                          type: 'description',
                          keyName: 'totalPaid',
                          label: 'Total Spend',
                          grow: 1,
                        },
                        {
                          type: 'description',
                          keyName: 'remainingBalance',
                          label: 'Open Balance',
                          grow: 1,
                        },
                        {
                          type: 'buttonOptionsLink',
                          keyName: 'unpaidBills',
                          label: 'Active Bills',
                          center: true,
                          onClick: async (x: string, y) => {
                            navigate(
                              `/dashboard/accounts-payable/bills?vendorName=${y.vendor.name}`,
                            )
                          },
                        },
                        {
                          type: 'buttonOptionsLink',
                          keyName: 'action',
                          label: 'Actions',
                          right: true,
                          grow: 0.5,
                          onClick: async (x: string, y) => {
                            setVendorId(y.id)
                            navigate(
                              `/dashboard/accounts-payable/vendors/${y.id}?payVendor=true`,
                            )
                          },
                        },
                      ]}
                      loading={loading}
                      rowSize="large"
                      cellSize="xlarge"
                      onClick={(args) => {
                        navigate(
                          `/dashboard/accounts-payable/vendors/${args.id}`,
                        )
                      }}
                      {...{
                        page: queryOpts.page || 1,
                        perPage: queryOpts.pageSize || 10,
                        totalPages: totalPages || 1,
                        onPage: async (page: number) => {
                          await setPage(page)
                          await setQueryOpts({
                            ...queryOpts,
                            page: page,
                          })
                        },
                      }}
                    />
                    <VendorOutlet queryOpts={queryOpts} />

                    <AddVendorOverlay
                      isOpen={newVendorOpen}
                      setOpen={setNewVendorOpen}
                    />
                  </Box>
                </Column>
              </Column>
            </Column>
          </VStack>
        </Row>
      </Column>
    </PageLayout>
  )
}

export default Vendors

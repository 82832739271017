import { Account } from './Account'
import { BankAccount } from './BankAccount'
import { Business } from './Business'
import { Owner } from './Owner'
import {
  AlloyWebSdkResponse,
  RegistrationPage,
  useSelfServiceRegistrationStore,
} from './selfServiceRegistrationStore'
import { useFeatureFlagPayload } from 'posthog-js/react'
import alloy from '@alloyidentity/web-sdk'
import { useEffect } from 'react'

export function SelfServiceRegistration() {
  const { setStep, step, email, setIovationBlackboxId } =
    useSelfServiceRegistrationStore((state) => ({
      setStep: state.setStep,
      step: state.step,
      email: state.email,
      setIovationBlackboxId: state.setIovationBlackboxId,
    }))

  const payload = useFeatureFlagPayload('manualACHWhitelist') as
    | string[]
    | undefined
  const allowManualEntry = !!email && payload?.includes(email.toLowerCase())

  useEffect(() => {
    const initAlloy = async () => {
      if (
        import.meta.env.VITE_ALLOY_SDK_KEY &&
        import.meta.env.VITE_ALLOY_JOURNEY_TOKEN
      ) {
        const response: AlloyWebSdkResponse = await alloy.init({
          key: import.meta.env.VITE_ALLOY_SDK_KEY,
          journeyToken: import.meta.env.VITE_ALLOY_JOURNEY_TOKEN,
          production: import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production',
        })
        setIovationBlackboxId(response.iovationBlackboxId)
      }
    }

    initAlloy()
  }, [])

  const components: Record<RegistrationPage, React.ReactNode> = {
    email: <Account onNextStep={() => setStep('owner')} />,
    owner: <Owner onNextStep={() => setStep('business')} />,
    business: <Business onNextStep={() => setStep('bankAccount')} />,
    bankAccount: <BankAccount allowManualEntry={allowManualEntry} />,
  }

  return <>{components[step]}</>
}

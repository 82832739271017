import {
  AmountArrow,
  Button,
  HFlex,
  Separator,
  StatusPill,
  VFlex,
  BillOverlayHeader,
  ScrollableColumn,
} from 'ui'
import { RecurringBillSectionHeader } from '../../ap/RecurringBillsOverlay'
import { useBillStore } from '../../stores/billStore'
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline'
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import currency from 'currency.js'
import moment from 'moment'
import { RecurringBillFrequency } from '../../../operations-types'
import { useState } from 'react'
import { RecurringBillMasterOverlay } from './RecurringBillOverlay'
import { BillPayOverlay } from '../../ap/BillPayOverlay'
type RecurringBillsOverviewProps = {
  setOpen: (open: boolean) => void
  activeBills: RecurringBillRowProps[]
  inactiveBills: RecurringBillRowProps[]
}

export type RecurringBillRowProps = {
  id: string
  vendorName: string
  description: string
  amountCents: number
  schedule: RecurringBillFrequency
  nextPaymentDate: Date | undefined
  statusText?: string
  onClick?: () => void
}

function RecurringBillRow({
  vendorName,
  description,
  amountCents,
  nextPaymentDate,
  statusText,
  onClick,
}: RecurringBillRowProps) {
  return (
    <Flex
      p={4}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      w="100%"
      onClick={() => onClick && onClick()}
      cursor="pointer"
      _hover={{
        bgColor: 'gray.50',
      }}
    >
      <HFlex gap={4} alignItems="center" w="60%">
        <Box p={2} borderRadius="full" bgColor="gray.100">
          <Icon
            as={BuildingStorefrontIcon}
            boxSize={6}
            className="text-gray-400"
          />
        </Box>
        <VFlex gap={1} w="100%">
          <VFlex w="100%">
            <HFlex justifyContent="start" alignItems="center" w="100%" gap={1}>
              <Text noOfLines={1} color="gray.900" fontWeight="medium">
                <Tooltip label={vendorName}>
                  <span>{vendorName}</span>
                </Tooltip>
              </Text>
              <Box display="inline-block" whiteSpace="nowrap">
                <StatusPill
                  spacing="xsmallNarrow"
                  size="small"
                  style={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}
                  status={
                    statusText === 'Active'
                      ? 'success'
                      : statusText === 'Pending Approval'
                      ? 'warning'
                      : 'info'
                  }
                >
                  {statusText}
                </StatusPill>
              </Box>
            </HFlex>
          </VFlex>

          <Text color="gray.500">{description}</Text>
        </VFlex>
        <Box alignItems="top" justifyContent="top"></Box>
      </HFlex>

      <VFlex w="35%" alignItems="end">
        <AmountArrow
          amount={currency(amountCents, { fromCents: true }).format()}
        />

        <Text fontSize="xs" color="gray.500">
          {nextPaymentDate
            ? `Next payment on ${moment(nextPaymentDate).format('MM/DD/YYYY')}`
            : ''}
        </Text>
      </VFlex>
    </Flex>
  )
}

type RecurringBillsOverviewStates = 'recurringBill' | 'overview' | 'bill'

export function RecurringBillsOverview({
  setOpen,
  activeBills,
  inactiveBills,
}: RecurringBillsOverviewProps) {
  const { setRecurringPage } = useBillStore((state) => ({
    setRecurringPage: state.setRecurringPage,
  }))
  const [billId, setBillId] = useState<string | null>(null)

  const [state, setState] = useState<RecurringBillsOverviewStates>('overview')

  const [recurringBillId, setRecurringBillId] = useState<string | null>(null)
  const recurringBillNode = (
    <RecurringBillMasterOverlay
      recurringBillId={recurringBillId || ''}
      onClose={() => {
        setRecurringBillId(null)
        setState('overview')
      }}
      onBack={() => {
        setRecurringBillId(null)
        setState('overview')
      }}
      onEditRecurringBill={() => {
        setRecurringBillId(null)
        setState('recurringBill')
      }}
      breadcrumbs={[
        { label: 'Bill Pay' },
        { label: 'Recurring Bills', isActive: true },
      ]}
      onBillClick={(billId: string) => {
        setBillId(billId)
        setState('bill')
      }}
    />
  )

  const overviewNode = (
    <>
      <ScrollableColumn className="w-full">
        <BillOverlayHeader
          onClose={() => {
            setOpen(false)
          }}
          title="Recurring Bills"
          subtitle="Manage or create recurring bills"
          breadcrumbs={[
            { label: 'Bill Pay' },
            { label: 'Recurring Bills', isActive: true },
          ]}
          type="recurring"
        />
        <Separator orientation="horizontal" />
        <VFlex gap={4} flex={1} w="100%">
          <VFlex w="100%" gap={4} p={8}>
            <RecurringBillSectionHeader title="Active" />
            {activeBills.map((bill, index) => (
              <RecurringBillRow
                key={index}
                {...bill}
                onClick={() => {
                  setRecurringBillId(bill.id)
                  setState('recurringBill')
                }}
              />
            ))}
          </VFlex>
          <Separator orientation="horizontal" />

          <VFlex gap={4} p={8}>
            <RecurringBillSectionHeader title="Archived" />
            {inactiveBills.map((bill, index) => (
              <RecurringBillRow
                key={index}
                {...bill}
                onClick={() => {
                  setRecurringBillId(bill.id)
                  setState('recurringBill')
                }}
              />
            ))}
          </VFlex>
        </VFlex>
      </ScrollableColumn>
      <Box w="100%" mt="auto" position="sticky">
        <Separator orientation="horizontal" />
        <Flex justifyContent="flex-end" py={4} px={8} gap={4}>
          <Button
            label="Create recurring bill"
            onClick={async () => {
              // TODO: Implement recurring bill creation
              setRecurringPage('recurringCreateBill')
            }}
          />
        </Flex>
      </Box>
    </>
  )

  const billNode = (
    <>
      {billId && (
        <BillPayOverlay
          billId={billId}
          onBack={() => setState('recurringBill')}
          onClose={() => setState('recurringBill')}
        />
      )}
    </>
  )
  const components: Record<string, React.ReactNode> = {
    recurringBill: recurringBillNode,
    overview: overviewNode,
    bill: billNode,
  }
  return <>{components[state]}</>
}

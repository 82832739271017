import {
  Button,
  calculatePaymentSummaryForMerchant,
  DatePickerCardInput,
  Icon,
  PaymentSelect,
  PaymentSelectOption,
  VFlex,
  BillOverlayHeader,
  ScrollableColumn,
  dateIsFutureAndNotWeekend,
  filterDeliveryDate,
  addBusinessDays,
  subtractBusinessDays,
} from 'ui'
import { getBillTitle, useBillStore } from '../../stores/billStore'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Center,
  Divider,
  HStack,
  Spinner,
  Text,
  VStack,
  Button as ChakraButton,
  Fade,
  useToast,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import currency from 'currency.js'
import {
  DeattachMerchantPaymentDocument,
  DeleteVendorPayoutMethodDocument,
  GetAllMerchantPaymentMethodsDocument,
  OrganizationTier,
  SelfDocument,
  VendorDocument,
  VendorPayoutMethod,
} from '../../../operations-types'
import { useMutation, useQuery } from '@apollo/client'
import { getFeeString } from '../../ap/SchedulePayment'
import {
  getPaymentMethodIcon,
  getPaymentMethodString,
} from '../../ap/components/DashboardChoosePaymentMethod'
import { PaymentMethod } from '../../ap/components/SendPaymentRouter'
import { QBOChartOfAccountsModal } from '../../erp/QBOChartofAccounts'
import { NewPaymentMethodModal } from './NewPaymentMethodModal'
import { useLoggedInStore } from '../../layout/LoggedInStore'
import { useCodatLinkedConnection } from '../../utils/CodatUtils'
import {
  getEstimatedDaysToDelivery,
  vendorPayoutMethodToIdentifier,
} from '../../../lib/utils'
import moment from 'moment-timezone'
import { Breadcrumb } from 'ui'

type ScheduleBillOverlayProps = {
  onClose: () => void
  onBack: () => void
  onReview: () => void
  onAddDeliveryMethod: (type: 'ACH' | 'Check') => void
  breadcrumbs: Breadcrumb[]
}

type SavedPaymentMethodOptionsProps = {
  onAddPaymentMethod: () => void
}

function SavedPaymentMethodOptions(props: SavedPaymentMethodOptionsProps) {
  const {
    selectedSavedPaymentMethod,
    savedPaymentMethods,
    setSelectedSavedPaymentMethod,
    setPaymentSummary,
    paymentSummary,
    setSavedPaymentMethods,
    setAchWithdrawalDate,
    setCardWithdrawalDate,
    achWithdrawalDate,
    cardWithdrawalDate,
  } = useBillStore((state) => ({
    savedPaymentMethods: state.savedPaymentMethods,
    setSelectedSavedPaymentMethod: state.setSelectedSavedPaymentMethod,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    setPaymentSummary: state.setPaymentSummary,
    paymentSummary: state.paymentSummary,
    setSavedPaymentMethods: state.setSavedPaymentMethods,
    setAchWithdrawalDate: state.setAchWithdrawalDate,
    setCardWithdrawalDate: state.setCardWithdrawalDate,
    achWithdrawalDate: state.achWithdrawalDate,
    cardWithdrawalDate: state.cardWithdrawalDate,
  }))

  const toast = useToast()

  const [deattachMerchantPaymentMethod] = useMutation(
    DeattachMerchantPaymentDocument,
    {
      refetchQueries: [
        {
          query: GetAllMerchantPaymentMethodsDocument,
        },
      ],
      onCompleted: () => {
        toast({
          title: 'Payment method deleted',
          description: 'The payment method has been deleted',
          status: 'success',
        })
      },
    },
  )

  const convertPaymentMethodToPaymentSelectOption = (
    method: PaymentMethod,
    isSelected: boolean,
  ): PaymentSelectOption<PaymentMethod> => {
    return {
      label: getPaymentMethodString(method),
      iconName: getPaymentMethodIcon(method),
      subtitle: method?.card?.brand
        ? 'Your payment arrives in the next business day.'
        : 'Your payment arrives in 2 to 3 business days.',
      value: method,
      rightText: method?.card?.brand ? getFeeString() : 'No Fee',
      rightTextColor: method?.card?.brand ? 'yellow.600' : 'purple.600',
      rightTextIcon: isSelected ? undefined : 'xMark',
    }
  }

  const emptyOption: PaymentSelectOption<PaymentMethod> = {
    label: 'New Payment Method',
    iconName: 'plusIcon',
    subtitle: 'Add a payment method to your account',
    value: null,
  }

  const [isDeletePaymentMethodDialogOpen, setIsDeletePaymentMethodDialogOpen] =
    useState(false)
  const [cancelledPaymentMethod, setCancelledPaymentMethod] =
    useState<PaymentMethod | null>(null)

  return (
    <>
      <AlertDialogWithCancelButton
        isOpen={isDeletePaymentMethodDialogOpen}
        onClose={() => {
          setIsDeletePaymentMethodDialogOpen(false)
          setCancelledPaymentMethod(null)
        }}
        onConfirm={() => {
          if (!cancelledPaymentMethod) {
            return
          }

          const newPaymentMethods = savedPaymentMethods.filter(
            (g: PaymentMethod) => g.id !== cancelledPaymentMethod?.id,
          )
          setSavedPaymentMethods(newPaymentMethods)
          deattachMerchantPaymentMethod({
            variables: {
              paymentMethodId: cancelledPaymentMethod?.externalPaymentMethodId,
            },
          })
          if (selectedSavedPaymentMethod?.id === cancelledPaymentMethod?.id) {
            if (newPaymentMethods.length > 0) {
              setSelectedSavedPaymentMethod(newPaymentMethods[0])
              setPaymentSummary(
                calculatePaymentSummaryForMerchant(
                  paymentSummary?.amountWithoutFeeCents() || 0,
                  cancelledPaymentMethod?.card?.brand
                    ? 'Credit Card'
                    : 'ACH Transfer',
                  'ACH',
                ),
              )
            } else {
              setSelectedSavedPaymentMethod(null)
              setPaymentSummary(
                calculatePaymentSummaryForMerchant(
                  paymentSummary?.amountWithoutFeeCents() || 0,
                  cancelledPaymentMethod?.card?.brand
                    ? 'Credit Card'
                    : 'ACH Transfer',
                  'ACH',
                ),
              )
            }
          }
          setIsDeletePaymentMethodDialogOpen(false)
        }}
        title={`Delete ${
          cancelledPaymentMethod
            ? getPaymentMethodString(cancelledPaymentMethod)
            : 'Payment Method'
        }?`}
        description="Are you sure you want to delete this payment method?"
      />
      <PaymentSelect<PaymentMethod>
        label="Saved Payment Methods"
        errorBorder={savedPaymentMethods.length === 0}
        options={[
          ...savedPaymentMethods.map((method) =>
            convertPaymentMethodToPaymentSelectOption(method, false),
          ),
          emptyOption,
        ]}
        value={
          selectedSavedPaymentMethod
            ? convertPaymentMethodToPaymentSelectOption(
                selectedSavedPaymentMethod,
                true,
              )
            : emptyOption
        }
        onClick={(data) => {
          if (data.value === null) {
            props.onAddPaymentMethod()
            return
          }

          setPaymentSummary(
            calculatePaymentSummaryForMerchant(
              paymentSummary?.amountWithoutFeeCents() || 0,
              data.value?.card?.last4 ? 'Credit Card' : 'ACH Transfer',
              'ACH',
            ),
          )
          setSelectedSavedPaymentMethod(data.value)

          if (data.value?.achDebit) {
            setAchWithdrawalDate(
              addBusinessDays(moment(achWithdrawalDate).toDate(), 0).toDate(),
            )
          } else {
            setCardWithdrawalDate(cardWithdrawalDate)
          }
        }}
        onOptionsClick={(data) => {
          setCancelledPaymentMethod(data.value)
          setIsDeletePaymentMethodDialogOpen(true)
        }}
      />
    </>
  )
}

export function ScheduleBillOverlayMethodAmount() {
  const {
    bill,
    setSavedPaymentMethods,
    setSelectedSavedPaymentMethod,
    selectedSavedPaymentMethod,
    setPaymentSummary,
    paymentSummary,
    sendMoneyAmountResult,
    setAchWithdrawalDate,
    setCardWithdrawalDate,
  } = useBillStore((state) => ({
    bill: state.bill,
    setSavedPaymentMethods: state.setSavedPaymentMethods,
    setSelectedSavedPaymentMethod: state.setSelectedSavedPaymentMethod,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    setPaymentSummary: state.setPaymentSummary,
    paymentSummary: state.paymentSummary,
    setSendMoneyAmountResult: state.setSendMoneyAmountResult,
    sendMoneyAmountResult: state.sendMoneyAmountResult,
    recurringDetails: state.recurringDetails,
    achWithdrawalDate: state.achWithdrawalDate,
    cardWithdrawalDate: state.cardWithdrawalDate,
    setAchWithdrawalDate: state.setAchWithdrawalDate,
    setCardWithdrawalDate: state.setCardWithdrawalDate,
  }))

  const billData = bill?.bill?.billData

  const amount =
    currency(billData?.amountDue || 0).value ||
    currency(sendMoneyAmountResult?.submittedAmountCents || 0, {
      fromCents: true,
    }).value ||
    0

  const { loading } = useQuery(GetAllMerchantPaymentMethodsDocument, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setSavedPaymentMethods(
        data?.getAllMerchantPaymentMethods?.paymentMethods || [],
      )

      const paymentMethods = data?.getAllMerchantPaymentMethods?.paymentMethods

      if (
        paymentMethods &&
        paymentMethods.length > 0 &&
        !selectedSavedPaymentMethod
      ) {
        setSelectedSavedPaymentMethod(paymentMethods[0])

        if (paymentMethods[0]?.achDebit) {
          setAchWithdrawalDate(
            addBusinessDays(moment(new Date()).toDate(), 0).toDate(),
          )
        } else {
          setCardWithdrawalDate(new Date())
        }
      }
    },
  })

  const [newPaymentMethodClicked, setNewPaymentMethodClicked] = useState(false)

  const [qboChartOfAccountModalOpen, setQboChartOfAccountModalOpen] =
    useState(false)

  const loggedInUser = useLoggedInStore((state) => state.user)
  const { hasLinkedConnection } = useCodatLinkedConnection(
    loggedInUser.organization.accountInfo ?? null,
  )

  return (
    <VFlex p={8} gap={5} w="100%">
      <QBOChartOfAccountsModal
        isOpen={qboChartOfAccountModalOpen}
        setModalOpen={setQboChartOfAccountModalOpen}
        paymentMethodId={selectedSavedPaymentMethod?.id}
        type={selectedSavedPaymentMethod?.card ? 'creditCard' : 'bankAccount'}
        accountString={
          selectedSavedPaymentMethod?.achDebit?.routingNumber
            ? `your ACH payment method ${selectedSavedPaymentMethod?.achDebit?.bankName} ·· ${selectedSavedPaymentMethod?.achDebit?.last2}`
            : `your credit card payment method ${selectedSavedPaymentMethod?.card?.brand} ·· ${selectedSavedPaymentMethod?.card?.last4}`
        }
        onCompleted={() => {
          setQboChartOfAccountModalOpen(false)
          setNewPaymentMethodClicked(false)
        }}
      />
      <NewPaymentMethodModal
        isOpen={newPaymentMethodClicked}
        setModalOpen={setNewPaymentMethodClicked}
        onSuccessfulPaymentMethodAdded={(paymentMethod) => {
          setSelectedSavedPaymentMethod(paymentMethod)
          setPaymentSummary(
            calculatePaymentSummaryForMerchant(
              paymentSummary?.amountWithoutFeeCents() || 0,
              paymentMethod?.card?.brand ? 'Credit Card' : 'ACH Transfer',
              'ACH',
            ),
          )

          if (hasLinkedConnection) {
            setQboChartOfAccountModalOpen(true)
          } else {
            setNewPaymentMethodClicked(false)
          }
        }}
      />
      <Text fontSize="lg" fontWeight="semibold">
        Your Payment Details
      </Text>
      <VFlex gap={2} w="100%">
        <Text fontSize="xs" fontWeight="medium">
          Bill Amount
        </Text>
        <Text fontSize="2xl" fontWeight="semibold">
          {currency(amount).format()}
        </Text>
      </VFlex>
      <VFlex gap={2}>
        <Text fontSize="xs" fontWeight="medium">
          Payment Method
        </Text>
        {loading && (
          <Center>
            <Spinner size="lg" />
          </Center>
        )}
        {!loading && (
          <SavedPaymentMethodOptions
            onAddPaymentMethod={() => setNewPaymentMethodClicked(true)}
          />
        )}
      </VFlex>
    </VFlex>
  )
}

type VendorDeliveryMethodValue = {
  type: 'ACH' | 'Check' | 'Setup ACH' | 'Setup Check'
  label: string
  method: VendorPayoutMethod | null
}

type VendorDeliveryMethodProps = {
  vendorId: string
  onAddDeliveryMethod: (type: 'ACH' | 'Check') => void
}
export function VendorDeliveryMethod(props: VendorDeliveryMethodProps) {
  const { setSelectedVendorPayoutMethod } = useBillStore((state) => ({
    setSelectedVendorPayoutMethod: state.setSelectedVendorPayoutMethod,
  }))

  const { data, loading } = useQuery(VendorDocument, {
    variables: {
      vendorId: props.vendorId,
    },
    onCompleted: (data) => {
      setSelectedVendorPayoutMethod(
        (data?.vendor?.vendor?.vendorPayoutMethods || []).at(0) ?? null,
      )
    },
  })

  const vendor = data?.vendor
  const vendorPayoutMethods = vendor?.vendor?.vendorPayoutMethods || []

  const vendorCheckPayoutMethod = vendorPayoutMethods?.find(
    (method) => method?.type === 'CHECK',
  )
  const vendorAchPayoutMethod = vendorPayoutMethods?.find(
    (method) => method?.type === 'ACH',
  )
  return (
    <VFlex p={8} gap={5} w="100%">
      <Text fontSize="lg" fontWeight="semibold">
        Vendor Payment Delivery Details
      </Text>

      <VFlex gap={2}>
        <Text fontSize="xs" fontWeight="medium">
          Delivery Method
        </Text>
        {loading && (
          <Center>
            <Spinner size="lg" />
          </Center>
        )}
        {!loading && (
          <VendorDeliveryMethodSelect
            vendorCheckPayoutMethod={vendorCheckPayoutMethod ?? null}
            vendorAchPayoutMethod={vendorAchPayoutMethod ?? null}
            onAddDeliveryMethod={props.onAddDeliveryMethod}
          />
        )}
      </VFlex>
    </VFlex>
  )
}

type AlertDialogWithCancelButtonProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  description: string
  confirmLabel?: string
  cancelLabel?: string
}

export function AlertDialogWithCancelButton({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmLabel = 'Delete',
  cancelLabel = 'Cancel',
}: AlertDialogWithCancelButtonProps) {
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogBody>{description}</AlertDialogBody>
        <AlertDialogFooter>
          <HStack gap={2}>
            <ChakraButton ref={cancelRef} variant="ghost" onClick={onClose}>
              {cancelLabel}
            </ChakraButton>
            <ChakraButton onClick={onConfirm}>{confirmLabel}</ChakraButton>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export const getPayoutMethodString = (
  methodValue: VendorDeliveryMethodValue,
) => {
  return methodValue.label
}

type VendorDeliveryMethodSelectProps = {
  vendorCheckPayoutMethod: VendorPayoutMethod | null
  vendorAchPayoutMethod: VendorPayoutMethod | null
  onAddDeliveryMethod: (type: 'ACH' | 'Check') => void
}

function VendorDeliveryMethodSelect({
  vendorCheckPayoutMethod,
  vendorAchPayoutMethod,
  onAddDeliveryMethod,
}: VendorDeliveryMethodSelectProps) {
  const {
    setSelectedVendorPayoutMethod,
    selectedSavedPaymentMethod,
    paymentSummary,
    setPaymentSummary,
    vendorDeliveryMethod,
  } = useBillStore((state) => ({
    setSelectedVendorPayoutMethod: state.setSelectedVendorPayoutMethod,
    paymentSummary: state.paymentSummary,
    setPaymentSummary: state.setPaymentSummary,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    vendorDeliveryMethod: state.selectedVendorPayoutMethod,
  }))

  const convertVendorPayoutMethodToDeliveryMethod = (
    vendorPayoutMethod: VendorPayoutMethod | null,
  ): VendorDeliveryMethodValue | null => {
    if (!vendorPayoutMethod) {
      return null
    }

    return {
      type: vendorPayoutMethod.type === 'ACH' ? 'ACH' : 'Check',
      label: vendorPayoutMethodToIdentifier(vendorPayoutMethod) || '',
      method: vendorPayoutMethod,
    }
  }

  const convertPayoutMethodToPaymentSelectOption = (
    vendorPayoutMethod: VendorPayoutMethod | null,
    type: 'ACH' | 'Check',
    exists: boolean,
    isSelected: boolean,
  ): PaymentSelectOption<VendorDeliveryMethodValue> | null => {
    if (!vendorPayoutMethod) {
      return {
        label: type === 'ACH' ? 'Direct Deposit via ACH' : 'Send a Check',
        iconName: type === 'ACH' ? 'buildingLibrary' : 'paycheck',
        subtitle:
          type === 'ACH'
            ? 'Setup a bank account to send an ACH transfer.'
            : 'Setup an address to send a check.',
        value: {
          type: type === 'ACH' ? 'Setup ACH' : 'Setup Check',
          label: type === 'ACH' ? 'Direct Deposit via ACH' : 'Send a Check',
          method: null,
        },
      }
    }

    const deliveryMethod =
      convertVendorPayoutMethodToDeliveryMethod(vendorPayoutMethod)

    if (!deliveryMethod) {
      return null
    }

    return {
      label:
        deliveryMethod.type === 'ACH'
          ? 'Direct Deposit via ACH'
          : 'Send a Check',
      iconName: deliveryMethod.type === 'ACH' ? 'buildingLibrary' : 'paycheck',
      subtitle: deliveryMethod.label,
      rightText: deliveryMethod.type === 'ACH' ? 'No Fee' : '$2.00 Fee',
      rightTextIcon: exists && !isSelected ? 'xMark' : undefined,
      rightTextColor:
        deliveryMethod.type === 'ACH' ? 'purple.600' : 'yellow.600',
      value: {
        ...deliveryMethod,
        method: vendorPayoutMethod,
      },
    }
  }

  const emptyDeliveryMethod: PaymentSelectOption<VendorDeliveryMethodValue> = {
    label: 'Setup a Delivery Method',
    iconName: 'plusIcon',
    subtitle: 'Setup a delivery method to send a check or ACH',
    value: null,
  }

  const [isDeletePaymentMethodDialogOpen, setIsDeletePaymentMethodDialogOpen] =
    useState(false)

  const [cancelledPaymentMethod, setCancelledPaymentMethod] =
    useState<VendorDeliveryMethodValue | null>(null)

  const [deleteVendorPayoutMethod] = useMutation(
    DeleteVendorPayoutMethodDocument,
    {
      refetchQueries: [VendorDocument],
      onCompleted: () => {
        setIsDeletePaymentMethodDialogOpen(false)
        toast({
          title: 'Delivery Method Deleted',
          description: `Your vendor's delivery method has been deleted.`,
          status: 'success',
        })
      },
    },
  )

  const toast = useToast()

  return (
    <>
      <AlertDialogWithCancelButton
        isOpen={isDeletePaymentMethodDialogOpen}
        onClose={() => {
          setIsDeletePaymentMethodDialogOpen(false)
          setCancelledPaymentMethod(null)
        }}
        onConfirm={() => {
          if (!cancelledPaymentMethod) return
          deleteVendorPayoutMethod({
            variables: {
              vendorPayoutMethodId: cancelledPaymentMethod?.method?.id ?? '',
            },
          })
          setSelectedVendorPayoutMethod(null)
        }}
        title={`Delete ${
          cancelledPaymentMethod
            ? getPayoutMethodString(cancelledPaymentMethod)
            : 'Payment Method'
        }?`}
        description="Are you sure you want to delete this delivery method?"
      />
      <PaymentSelect<VendorDeliveryMethodValue>
        label="Delivery Methods"
        options={[
          convertPayoutMethodToPaymentSelectOption(
            vendorAchPayoutMethod,
            'ACH',
            !!vendorAchPayoutMethod,
            false,
          ),
          convertPayoutMethodToPaymentSelectOption(
            vendorCheckPayoutMethod,
            'Check',
            !!vendorCheckPayoutMethod,
            false,
          ),
        ].filter((x) => x !== null)}
        value={
          vendorDeliveryMethod
            ? convertPayoutMethodToPaymentSelectOption(
                vendorDeliveryMethod,
                vendorDeliveryMethod.type === 'ACH' ? 'ACH' : 'Check',
                false,
                true,
              )
            : emptyDeliveryMethod
        }
        errorBorder={!vendorDeliveryMethod}
        onOptionsClick={(data) => {
          setCancelledPaymentMethod(data.value)
          setIsDeletePaymentMethodDialogOpen(true)
        }}
        onClick={(data) => {
          if (!data.value) {
            return
          }

          if (
            data.value.type === 'Setup ACH' ||
            data.value.type === 'Setup Check'
          ) {
            onAddDeliveryMethod(
              data.value.type === 'Setup ACH' ? 'ACH' : 'Check',
            )
            return
          }

          setSelectedVendorPayoutMethod(data.value.method)
          setPaymentSummary(
            calculatePaymentSummaryForMerchant(
              paymentSummary?.amountWithoutFeeCents() || 0,
              selectedSavedPaymentMethod?.card?.brand
                ? 'Credit Card'
                : 'ACH Transfer',
              data.value.type,
            ),
          )
        }}
      />
    </>
  )
}

function BillDateScheduler() {
  const {
    vendorDeliveryMethod,
    achWithdrawalDate,
    cardWithdrawalDate,
    setAchWithdrawalDate,
    setCardWithdrawalDate,
    selectedSavedPaymentMethod,
  } = useBillStore((state) => ({
    vendorDeliveryMethod: state.selectedVendorPayoutMethod,
    achWithdrawalDate: state.achWithdrawalDate,
    cardWithdrawalDate: state.cardWithdrawalDate,
    setAchWithdrawalDate: state.setAchWithdrawalDate,
    setCardWithdrawalDate: state.setCardWithdrawalDate,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
  }))

  const { data: loggedInUser } = useQuery(SelfDocument)

  const getDeliveryType = (): 'ACH' | 'CHECK' => {
    if (vendorDeliveryMethod?.type === 'CHECK') {
      return 'CHECK'
    }

    return 'ACH'
  }

  const getPaymentMethod = (): 'ach_debit' | 'card' => {
    if (selectedSavedPaymentMethod?.card?.brand) {
      return 'card'
    }

    return 'ach_debit'
  }

  const onPremium =
    loggedInUser?.user?.user?.organization?.accountInfo?.tier !== 'FREE_TIER'
  const withdrawalDate =
    getPaymentMethod() === 'ach_debit' ? achWithdrawalDate : cardWithdrawalDate

  const deliveryMethodToDays = getEstimatedDaysToDelivery(
    getDeliveryType(),
    getPaymentMethod(),
    loggedInUser?.user?.user?.organization?.accountInfo?.tier ||
      OrganizationTier.FreeTier,
    moment(withdrawalDate).isSame(moment(), 'day'),
  )
  return (
    <VFlex p={8} gap={5} w="100%">
      <VFlex>
        <Text fontSize="lg" fontWeight="semibold">
          Payment Date
        </Text>
        <Text fontSize="sm" color="gray.500">
          Select a send or arrival date to schedule your payment.
          {!onPremium && ' Upgrade to Nickel Plus for faster payment times.'}
        </Text>
      </VFlex>
      <VFlex gap={2}>
        <HStack gap={3}>
          <DatePickerCardInput
            label="Withdrawal Date"
            className="text-sm"
            selected={withdrawalDate || new Date()}
            dateFormat="MMM dd, yyyy"
            filterDate={(date) =>
              dateIsFutureAndNotWeekend(date, getPaymentMethod())
            }
            px={4}
            py={3}
            gap={0}
            onChange={(d) => {
              if (d) {
                getPaymentMethod() === 'ach_debit'
                  ? setAchWithdrawalDate(d)
                  : setCardWithdrawalDate(d)
              }
            }}
          />
          <Icon
            name="minus"
            size="large"
            className="min-w-[24px] min-h-[24px]"
          />
          <VStack gap={0}>
            <Text fontSize="xs" color="gray.500">
              Delivery Time
            </Text>
            <Text
              fontSize="sm"
              fontWeight="medium"
              color="gray.900"
              whiteSpace="nowrap"
              minWidth="80px"
              textAlign="center"
            >
              {deliveryMethodToDays === 0
                ? 'Same Day'
                : `${deliveryMethodToDays} Business Days`}
            </Text>
          </VStack>
          <Icon
            name="rightArrow"
            size="large"
            className="min-w-[24px] min-h-[24px]"
          />
          <DatePickerCardInput
            label="Est. Arrival Date"
            className="text-sm"
            selected={addBusinessDays(
              withdrawalDate || new Date(),
              deliveryMethodToDays,
            ).toDate()}
            dateFormat="MMM dd, yyyy"
            px={4}
            py={3}
            gap={0}
            filterDate={(date) =>
              filterDeliveryDate(date, deliveryMethodToDays)
            }
            onChange={(d) => {
              if (d) {
                getPaymentMethod() === 'ach_debit'
                  ? setAchWithdrawalDate(
                      subtractBusinessDays(d, deliveryMethodToDays).toDate(),
                    )
                  : setCardWithdrawalDate(
                      moment(d).subtract(deliveryMethodToDays, 'days').toDate(),
                    )
              }
            }}
          />
        </HStack>
        <HStack gap={1}>
          <Icon name="exclamationCircle" className="stroke-gray-500" />
          <Text fontSize="xs" color="gray.500">
            ACH payments submitted after 3pm EST will be processed the next
            business day.
          </Text>
        </HStack>
      </VFlex>
    </VFlex>
  )
}

export function ScheduleBillOverlay(props: ScheduleBillOverlayProps) {
  const {
    bill,
    setPaymentSummary,
    paymentSummary,
    selectedSavedPaymentMethod,
    vendorDeliveryMethod,
  } = useBillStore((state) => ({
    bill: state.bill,
    setPaymentSummary: state.setPaymentSummary,
    paymentSummary: state.paymentSummary,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    vendorDeliveryMethod: state.selectedVendorPayoutMethod,
  }))

  const [qboChartOfAccountModalOpen, setQboChartOfAccountModalOpen] =
    useState(false)
  const { data } = useQuery(SelfDocument)
  const toast = useToast()

  return (
    <>
      <QBOChartOfAccountsModal
        isOpen={qboChartOfAccountModalOpen}
        setModalOpen={setQboChartOfAccountModalOpen}
        paymentMethodId={selectedSavedPaymentMethod?.id}
        type={selectedSavedPaymentMethod?.card ? 'creditCard' : 'bankAccount'}
        accountString={
          selectedSavedPaymentMethod?.achDebit?.routingNumber
            ? `your ACH payment method ${selectedSavedPaymentMethod?.achDebit?.bankName} ·· ${selectedSavedPaymentMethod?.achDebit?.last2}`
            : `your credit card payment method ${selectedSavedPaymentMethod?.card?.brand} ·· ${selectedSavedPaymentMethod?.card?.last4}`
        }
        onCompleted={() => {
          setQboChartOfAccountModalOpen(false)
          props.onReview()
        }}
      />
      <ScrollableColumn wGrow grow>
        <BillOverlayHeader
          onClose={props.onClose}
          title={`Pay ${getBillTitle(bill)}`}
          breadcrumbs={props.breadcrumbs}
          subtitle={bill?.bill?.vendor?.name || 'Your vendor'}
          type="bills"
        />
        <Divider />
        <Fade
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{ width: '100%', height: '100%' }}
        >
          <ScheduleBillOverlayMethodAmount />
          <Divider />
          {bill?.bill?.vendor?.id && (
            <VendorDeliveryMethod
              vendorId={bill?.bill?.vendor?.id}
              onAddDeliveryMethod={props.onAddDeliveryMethod}
            />
          )}
          <Divider />
          <BillDateScheduler />
        </Fade>
      </ScrollableColumn>
      <Divider />
      <HStack w="100%" px={10} py={6} justifyContent="space-between">
        <Button
          label="Cancel"
          variant="outline"
          onClick={() => props.onClose()}
        />
        <HStack>
          <Button label="Back" variant="ghost" onClick={() => props.onBack()} />
          <Button
            label="Review and Pay"
            isDisabled={!selectedSavedPaymentMethod || !vendorDeliveryMethod}
            onClick={() => {
              setPaymentSummary(
                calculatePaymentSummaryForMerchant(
                  paymentSummary?.amountWithoutFeeCents() || 0,
                  selectedSavedPaymentMethod?.card?.brand
                    ? 'Credit Card'
                    : 'ACH Transfer',
                  vendorDeliveryMethod?.type === 'ACH' ? 'ACH' : 'Check',
                ),
              )

              if (!paymentSummary) {
                return
              }

              if (paymentSummary?.amountWithoutFeeCents() === 0) {
                toast({
                  title: 'Payment amount cannot be 0',
                  description: 'Please enter a valid payment amount',
                  status: 'error',
                })
                return
              }
              if (
                paymentSummary?.amountWithoutFeeCents() >
                currency(bill?.bill?.billData?.amountDue || 0).intValue
              ) {
                toast({
                  title: 'Payment amount cannot be greater than the amount due',
                  description: 'Please enter a valid payment amount',
                  status: 'error',
                })
                return
              }

              if (
                data?.user?.user?.organization?.accountInfo
                  ?.quickbooksCompanyId &&
                !selectedSavedPaymentMethod?.codatChartOfAccountId
              ) {
                setQboChartOfAccountModalOpen(true)
              } else {
                props.onReview()
              }
            }}
          />
        </HStack>
      </HStack>
    </>
  )
}

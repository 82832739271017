import { useQuery } from '@apollo/client'
import { Box, Divider, Text } from '@chakra-ui/react'
import { capitalize, lowerCase } from 'lodash'
import moment from 'moment'
import { CopyInput, Entity, EntityField, Header, VFlex } from 'ui'
import { fetchDashboardURL } from '../../lib/urls'
import { ReferralsDocument } from '../../operations-types'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { BaseLayout } from '../layout/PageLayout'
import { InfoCardItem } from '../settings/InfoCardItem'
import { ScrollableColumn } from 'ui'

export function Referrals() {
  const { user } = useLoggedInStore()
  const subdomain = user?.organization?.accountInfo?.subdomain
  const { data } = useQuery(ReferralsDocument)

  const hasReferrals =
    (data?.organization?.organization?.referrals || []).length > 0
  return (
    <BaseLayout>
      <ScrollableColumn grow className="max-h-screen mx-10 my-6">
        <Box w="100%" minH="100vh">
          <Header variant="page">Referrals</Header>
          <Text color="gray.500" fontSize="md">
            Invite a business onto Nickel. You'll get $250 for a business after
            their first transaction, and $750 for an accounting or bookkeeping
            firm after they bring on their first transacting client.
          </Text>
          <Box py={6}>
            <InfoCardItem
              label="Referral Link"
              sublabel="Share this link with businesses you want to refer. Onboarded businesses will be linked to your account."
            >
              <CopyInput
                clipboardValue={`${fetchDashboardURL()}/register?r=${subdomain}`}
                formLabel="Referral URL"
              />
            </InfoCardItem>
          </Box>
          <Text fontSize="xl" fontWeight="medium">
            Your Referrals
          </Text>
          <VFlex py={4} gap={6}>
            <Divider />
            {!hasReferrals && (
              <Text color="gray.500" size="xl">
                You have no referrals yet. Businesses that use your referral
                link to sign up for Nickel will appear here.
              </Text>
            )}
            {data?.organization?.organization?.referrals?.map(
              (referral, idx) => (
                <Entity key={idx}>
                  <EntityField title={referral.name || ''} />
                  <EntityField
                    title={referral.email || ''}
                    titleTextColor="gray.500"
                  />
                  <EntityField
                    title={
                      referral.role === 'RETAILER'
                        ? 'Business'
                        : 'Accounting Firm'
                    }
                    titleTextColor="gray.500"
                  />
                  <Box
                    bg={referral.status === 'ACTIVE' ? 'green.100' : 'gray.100'}
                    py={1}
                    px={2}
                    borderRadius="md"
                  >
                    <Text
                      color={
                        referral.status === 'ACTIVE' ? 'green.600' : 'gray.600'
                      }
                      fontWeight="medium"
                    >
                      {capitalize(lowerCase(referral.status || ''))}
                    </Text>
                  </Box>
                  <EntityField
                    title={
                      moment(referral.createdAt).format('MM/DD/YYYY') || ''
                    }
                    right
                    titleTextColor="gray.500"
                  />
                </Entity>
              ),
            )}
          </VFlex>
        </Box>
      </ScrollableColumn>
    </BaseLayout>
  )
}

import Icon from '../Icons'
import Row from '../Layout/Row'
import ContainerProps from '../../types'

export type OverlayHeaderWithStatusProps = {
  onClose?: () => void
  onBack?: () => void
} & ContainerProps

export function OverlayHeaderWithStatus({
  onClose,
  onBack,
  children,
  className,
}: OverlayHeaderWithStatusProps) {
  return (
    <Row
      y="center"
      className={className || 'px-8 h-[80px] w-full min-h-[80px]'}
      between
    >
      {children}
      <Row gap="medium">
        {onBack !== undefined && (
          <Icon
            name="leftArrow"
            size="small"
            hoverable
            className="stroke-gray-400"
            onClick={onBack}
          />
        )}
        {onClose !== undefined && (
          <Icon
            name="xMark"
            size="small"
            hoverable
            className="stroke-gray-400"
            onClick={onClose}
          />
        )}
      </Row>
    </Row>
  )
}

import { create } from 'zustand'
import {
  ApprovalPolicy,
  BillQuery,
  RecurringBillFrequency,
  Vendor,
  VendorPayoutMethod,
} from '../../operations-types'
import {
  PaymentMethod,
  SendMoneyAmountResult,
} from '../ap/components/SendPaymentRouter'
import { PaymentSummary } from 'ui'
import { LineItem } from 'ui/src/types'

type Bill = BillQuery['bill']

type RecurringDetails = {
  page?: RecurringBillPage
  frequency?: RecurringBillFrequency
  startDate?: Date
  endDate?: Date
  vendor?: Vendor
  lineItems?: LineItem[]
}

export type RecurringBillPage =
  | 'overview'
  | 'recurringCreateBill'
  | 'recurringPaymentPayout'
  | 'recurringSuccess'
  | 'recurringReview'

type BillStoreData = {
  bill: Bill | null
  savedPaymentMethods: PaymentMethod[]
  selectedSavedPaymentMethod?: PaymentMethod | null
  selectedVendorPayoutMethod?: VendorPayoutMethod | null
  paymentSummary?: PaymentSummary
  sendMoneyAmountResult?: SendMoneyAmountResult
  approvalPolicy?: ApprovalPolicy
  achWithdrawalDate: Date
  cardWithdrawalDate: Date
  recurringDetails?: RecurringDetails
  recurringPage?: RecurringBillPage
  vendorEmails: string[]
}

type BillStoreFunctions = {
  setBill: (bill: Bill) => void
  setSavedPaymentMethods: (paymentMethods: PaymentMethod[]) => void
  setSelectedSavedPaymentMethod: (paymentMethod: PaymentMethod | null) => void
  setSelectedVendorPayoutMethod: (
    vendorPayoutMethod: VendorPayoutMethod | null,
  ) => void
  setPaymentSummary: (paymentSummary: PaymentSummary) => void
  setSendMoneyAmountResult: (
    sendMoneyAmountResult: SendMoneyAmountResult,
  ) => void
  setApprovalPolicy: (approvalPolicy: ApprovalPolicy) => void
  setAchWithdrawalDate: (achWithdrawalDate: Date) => void
  setCardWithdrawalDate: (cardWithdrawalDate: Date) => void
  reset: () => void
  setRecurringPage: (page: RecurringBillPage) => void
  setRecurringFrequency: (frequency: RecurringBillFrequency) => void
  setRecurringStartDate: (date: Date) => void
  setRecurringEndDate: (date: Date) => void
  setRecurringVendor: (vendor: Vendor) => void
  setVendorEmails: (emails: string[]) => void
  setLineItems: (lineItems: LineItem[]) => void
}

type BillStore = BillStoreData & BillStoreFunctions

const initialState: BillStoreData = {
  bill: null,
  savedPaymentMethods: [],
  vendorEmails: [],
  selectedSavedPaymentMethod: null,
  selectedVendorPayoutMethod: null,
  paymentSummary: undefined,
  sendMoneyAmountResult: undefined,
  approvalPolicy: undefined,
  achWithdrawalDate: new Date(),
  cardWithdrawalDate: new Date(),
  recurringDetails: undefined,
}

export const getBillTitle = (bill: Bill) => {
  const billData = bill?.bill?.billData
  return (
    billData?.reference ||
    billData?.supplierRef?.supplierName ||
    bill?.bill?.vendor?.name ||
    ''
  )
}

export const useBillStore = create<BillStore>((set) => ({
  ...initialState,
  setBill: (bill: Bill) => set({ bill }),
  setSavedPaymentMethods: (paymentMethods: PaymentMethod[]) =>
    set({ savedPaymentMethods: paymentMethods }),
  setSelectedSavedPaymentMethod: (paymentMethod: PaymentMethod | null) =>
    set({ selectedSavedPaymentMethod: paymentMethod }),
  setPaymentSummary: (paymentSummary: PaymentSummary) =>
    set({ paymentSummary }),
  reset: () => set(initialState),
  setSelectedVendorPayoutMethod: (
    vendorPayoutMethod: VendorPayoutMethod | null,
  ) => set({ selectedVendorPayoutMethod: vendorPayoutMethod }),
  setSendMoneyAmountResult: (sendMoneyAmountResult: SendMoneyAmountResult) =>
    set({ sendMoneyAmountResult }),
  setApprovalPolicy: (approvalPolicy: ApprovalPolicy) =>
    set({ approvalPolicy }),
  setAchWithdrawalDate: (achWithdrawalDate: Date) => set({ achWithdrawalDate }),
  setCardWithdrawalDate: (cardWithdrawalDate: Date) =>
    set({ cardWithdrawalDate }),
  setRecurringPage: (page: RecurringBillPage) =>
    set((state) => ({
      recurringDetails: {
        ...state.recurringDetails,
        page: page,
      },
    })),
  setRecurringFrequency: (frequency: RecurringBillFrequency) =>
    set((state) => ({
      recurringDetails: {
        ...state.recurringDetails,
        frequency: frequency,
      },
    })),
  setRecurringStartDate: (date: Date) =>
    set((state) => ({
      recurringDetails: {
        ...state.recurringDetails,
        startDate: date,
      },
    })),
  setRecurringEndDate: (date: Date) =>
    set((state) => ({
      recurringDetails: {
        ...state.recurringDetails,
        endDate: date,
      },
    })),
  setRecurringVendor: (vendor: Vendor) =>
    set((state) => ({
      recurringDetails: {
        ...state.recurringDetails,
        vendor: vendor,
      },
    })),
  setVendorEmails: (vendorEmails: string[]) => set({ vendorEmails }),
  setLineItems: (lineItems: LineItem[]) =>
    set((state) => ({
      recurringDetails: {
        ...state.recurringDetails,
        lineItems: lineItems,
      },
    })),
}))

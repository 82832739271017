import { Text } from '@chakra-ui/react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Column, Row, Separator } from 'ui'
import { AnswerFormSchema, OptionalAnswerForm } from '../../operations-types'

type SimplifiedOptionalAnswerForm = Omit<OptionalAnswerForm, 'questionForm'>

type AdditionalPaymentDetailProps = {
  answerForm?: SimplifiedOptionalAnswerForm | null
  reference: {
    paymentId?: string
    reference: string
    billPaymentId?: string
    failureReason?: string
    refundId?: string
    returnId?: string
    shortIdentifier?: string
    checkNumber?: string
    loanId?: string
  }
}

type AnswerFormAnswer = Omit<AnswerFormSchema, '__typename'>

type ReferenceDetailProps = {
  label: string
  value: string
}

const ReferenceDetail = ({ label, value }: ReferenceDetailProps) => (
  <>
    <Row grow between y="center">
      <Text className="text-gray-500 text-base">{label}</Text>
      <Text className="text-gray-800 text-base max-w-[320px] overflow-hidden whitespace-nowrap">
        {value}
      </Text>
    </Row>
    <Separator orientation="horizontal" />
  </>
)

const AdditionalPaymentDetail = (_props: AdditionalPaymentDetailProps) => {
  const schemaSteps = JSON.parse(
    _props.answerForm?.schema || '{}',
  ) as AnswerFormAnswer

  const additionalDetails = schemaSteps?.steps?.additional_information

  const termsOfService = schemaSteps?.steps?.terms_of_service || {}

  return (
    <Column gap="medium">
      <Column gap="small" wGrow>
        {additionalDetails ? (
          <>
            <Text className="text-gray-800 text-lg font-semibold">
              Additional Information
            </Text>
            {additionalDetails.map((k: any, i: number) => {
              return (
                <Column wGrow gap="small" key={i}>
                  <Separator orientation="horizontal" />
                  <Row grow between y="top">
                    <Text className="text-gray-500 text-base max-w-[50%]">
                      {k.label}
                    </Text>
                    <Text className="text-gray-800 text-base">{k.answer}</Text>
                  </Row>
                </Column>
              )
            })}
          </>
        ) : (
          ''
        )}
        {Object.keys(termsOfService).length > 0 ? (
          <>
            <Column wGrow gap="small">
              <Separator orientation="horizontal" />
              <Row grow between y="top">
                <Text className="text-gray-500 text-base max-w-[50%]">
                  Has terms of services been accepted
                </Text>
                <Text className="text-gray-800 text-base">
                  {termsOfService.answer === true ? 'Accepted' : 'Not Accepted'}
                </Text>
              </Row>
              <Row
                y="center"
                gap="small"
                {...{
                  onClick: () => {
                    if (termsOfService.fileBuffer) {
                      const link = document.createElement('a')
                      link.href = termsOfService.fileBuffer
                      link.setAttribute('download', 'file.pdf') //or any other extension
                      document.body.appendChild(link)
                      link.click()
                    }
                  },
                }}
              >
                <Text className="underline">Download File</Text>
                <ArrowTopRightOnSquareIcon width={15} />
              </Row>
            </Column>
          </>
        ) : (
          ''
        )}
      </Column>
      <Column gap="small" wGrow>
        {_props.reference.paymentId && (
          <>
            <Row grow between y="center">
              <Text className="text-gray-500 text-base">Payment ID</Text>
              <Text className="text-gray-800 text-base">
                {_props.reference.paymentId}
              </Text>
            </Row>
            <Separator orientation="horizontal" />
          </>
        )}
        {_props.reference.refundId ? (
          <ReferenceDetail
            label="Refund ID"
            value={_props.reference.refundId}
          />
        ) : null}
        {_props.reference.loanId ? (
          <ReferenceDetail label="Loan ID" value={_props.reference.loanId} />
        ) : null}
        {_props.reference.returnId ? (
          <ReferenceDetail
            label="Return Id"
            value={_props.reference.returnId}
          />
        ) : null}
        {_props.reference.billPaymentId ? (
          <ReferenceDetail
            label="Bill Payment ID"
            value={_props.reference.billPaymentId}
          />
        ) : null}
        {_props.reference.reference ? (
          <ReferenceDetail
            label="Reference"
            value={_props.reference.reference}
          />
        ) : null}
        {_props.reference.failureReason ? (
          <ReferenceDetail
            label="Failure Reason"
            value={_props.reference.failureReason}
          />
        ) : null}
        {_props.reference.shortIdentifier ? (
          <ReferenceDetail
            label="Deposit Reference"
            value={_props.reference.shortIdentifier}
          />
        ) : null}
        {_props.reference.checkNumber ? (
          <ReferenceDetail
            label="Check Number"
            value={_props.reference.checkNumber}
          />
        ) : null}
      </Column>
    </Column>
  )
}

export default AdditionalPaymentDetail

import { Card, VStack, Text, Flex, HStack, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { RegistrationLayout } from './RegistrationLayout'
import { useSelfServiceRegistrationStore } from './selfServiceRegistrationStore'
import * as yup from 'yup'
import { Formik } from 'formik'
import InputMask from 'react-input-mask'
import {
  AddressInput,
  Button,
  FieldTooltip,
  FormikStateField,
  FormikValidatingInput,
  HFlex,
  ValidatingInput,
  VFlex,
} from 'ui'
import { useLazyQuery } from '@apollo/client'
import { OrganizationPageDocument } from '../../../operations-types'

type BusinessTypeCardProps = {
  title: string
  description: string
  onClick: () => void
}

function BusinessTypeCard({
  title,
  description,
  onClick,
}: BusinessTypeCardProps) {
  return (
    <Card
      py={8}
      px={6}
      boxShadow="md"
      w="220px"
      cursor="pointer"
      _hover={{
        borderColor: 'transparent',
        boxShadow: ' 0 0 0 2px black',
      }}
      onClick={onClick}
    >
      <VStack>
        <Text fontSize="md" fontWeight="semibold" textAlign="center">
          {title}
        </Text>
        <Text color="gray.500" fontSize="sm" textAlign="center">
          {description}
        </Text>
      </VStack>
    </Card>
  )
}

type BusinessType = 'business_type' | 'businessInfo' | 'subdomain'

const OnboardingSchema = yup.object({
  legalBusinessName: yup.string().required('Business name is required'),
  dbaName: yup.string().required('DBA Name is required'),
  stateOfIncorporation: yup
    .string()
    .required('State of Incorporation is required'),
  businessIdentifier: yup
    .string()
    .length(10, 'EIN must be 9 digits')
    .required('Business Identifier is required'),
  ownerName: yup.string().required('Owner Name is required'),
  ownerSSN: yup
    .string()
    .length(11, 'SSN must be 9 digits')
    .required('Owner SSN is required'),
  website: yup
    .string()
    .url('Website must be a valid URL, including http:// or https://')
    .required('Website is required'),
  companyAddress: yup.object({
    streetAddress: yup.string().required('Street address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().min(5).max(5).required('Zipcode is required'),
  }),
})

type OnboardingValues = yup.InferType<typeof OnboardingSchema>

type OnboardingInformationProps = {
  onNextStep: () => void
}

function OnboardingInformation({ onNextStep }: OnboardingInformationProps) {
  const { setOnboardingInfo, setSubdomain, email } =
    useSelfServiceRegistrationStore((state) => ({
      setOnboardingInfo: state.setOnboardingInfo,
      setSubdomain: state.setSubdomain,
      email: state.email,
    }))

  return (
    <RegistrationLayout
      title="Your business"
      subtitle="Tell us a little about your business."
      currentPage="business"
      titleId="registration-business-info"
    >
      <Formik<OnboardingValues>
        validationSchema={OnboardingSchema}
        initialValues={{
          legalBusinessName: '',
          dbaName: '',
          stateOfIncorporation: '',
          businessIdentifier: '',
          ownerName: '',
          ownerSSN: '',
          website: '',
          companyAddress: {
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
          },
        }}
        validate={(values) => {
          let validations = {}

          if (
            values.businessIdentifier.replaceAll('-', '').replaceAll('_', '')
              .length !== 9
          ) {
            validations = {
              ...validations,
              businessIdentifier: 'EIN must be 9 digits',
            }
          }

          if (
            values.ownerSSN.replaceAll('-', '').replaceAll('_', '').length !== 9
          ) {
            validations = { ...validations, ownerSSN: 'SSN must be 9 digits' }
          }

          return validations
        }}
        onSubmit={(values) => {
          setOnboardingInfo({
            businessName: values.legalBusinessName,
            dbaName: values.dbaName,
            taxId: values.businessIdentifier,
            owners: [values.ownerName],
            ownerSSN: values.ownerSSN,
            website: values.website,
            country: 'USA',
            street: values.companyAddress.streetAddress,
            state: values.companyAddress.state,
            city: values.companyAddress.city,
            zip: values.companyAddress.zipCode,
            stateIncorporated: values.stateOfIncorporation,
            acceptTerms: true,
            email: email,
          })
          setSubdomain(values.dbaName.replace(/[^a-z0-9]/gi, '').toLowerCase())
          onNextStep()
        }}
      >
        {(formik) => {
          const onClick = () => {
            formik.setTouched({
              legalBusinessName: true,
              dbaName: true,
              stateOfIncorporation: true,
              businessIdentifier: true,
              ownerSSN: true,
              ownerName: true,
              website: true,
              companyAddress: {
                streetAddress: true,
                city: true,
                state: true,
                zipCode: true,
              },
            })

            formik.validateForm()

            if (formik.isValid) {
              formik.handleSubmit()
            }
          }

          return (
            <Flex width={['340px', '540px']} flexDirection="column" gap={8}>
              <Card py={8} px={6}>
                <VFlex gap={6}>
                  <HFlex gap={2}>
                    <ValidatingInput
                      id="legalBusinessName"
                      name="legalBusinessName"
                      label="Legal Business Name"
                      placeholder="Legal Name"
                      isInvalid={!!formik.errors.legalBusinessName}
                      validated={formik.touched.legalBusinessName}
                      value={formik.values.legalBusinessName}
                      error={formik.errors.legalBusinessName}
                      onChange={(e) => {
                        if (!formik.touched.dbaName) {
                          formik.setFieldValue('dbaName', e.target.value)
                        }
                        formik.handleChange(e)
                      }}
                    />
                    <FormikValidatingInput
                      fieldName="dbaName"
                      label="Doing business as"
                      placeholder="Name you do business as"
                    />
                  </HFlex>
                  <HFlex gap={2}>
                    <FormikStateField fieldName="stateOfIncorporation" />
                    <InputMask
                      mask="99-9999999"
                      onChange={formik.handleChange}
                      value={formik.values.businessIdentifier}
                    >
                      <ValidatingInput
                        id="businessIdentifier"
                        label="Business EIN"
                        isInvalid={!!formik.errors.businessIdentifier}
                        error={formik.errors.businessIdentifier}
                        validated={formik.touched.businessIdentifier}
                        popover={
                          <FieldTooltip text="The company's employer identification number" />
                        }
                      />
                    </InputMask>
                  </HFlex>
                  <HFlex gap={2}>
                    <FormikValidatingInput
                      fieldName="ownerName"
                      label="Beneficial Owner"
                      placeholder="Full Name"
                      popover={
                        <FieldTooltip text="The full name of the owner of this business" />
                      }
                    />
                    <InputMask
                      mask="999-99-9999"
                      onChange={formik.handleChange}
                      value={formik.values.ownerSSN}
                    >
                      <ValidatingInput
                        id="ownerSSN"
                        label="Owner SSN"
                        isInvalid={!!formik.errors.ownerSSN}
                        error={formik.errors.ownerSSN}
                        validated={formik.touched.ownerSSN}
                        popover={
                          <FieldTooltip text="The owner's social security number" />
                        }
                      />
                    </InputMask>
                  </HFlex>
                  <FormikValidatingInput
                    fieldName="website"
                    label="Website"
                    placeholder="https://yourcompany.com"
                    popover={
                      <FieldTooltip text="The website for your company" />
                    }
                  />
                  <AddressInput
                    {...{
                      valueKey: 'companyAddress',
                      apiKey: import.meta.env.VITE_SMARTY_STREETS_KEY,
                      label: 'Company Address',
                      popupContainerTarget: '.chakra-card__body',
                    }}
                  />
                </VFlex>
              </Card>

              <Button label="Next" onClick={onClick} />
            </Flex>
          )
        }}
      </Formik>
    </RegistrationLayout>
  )
}

type BusinessProps = {
  onNextStep: () => void
}

type SubdomainProps = {
  onNextStep: () => void
}

function Subdomain({ onNextStep }: SubdomainProps) {
  const { setSubdomain, subdomain } = useSelfServiceRegistrationStore(
    (state) => ({
      setSubdomain: state.setSubdomain,
      subdomain: state.subdomain,
    }),
  )
  const [organizationPage] = useLazyQuery(OrganizationPageDocument)
  const toaster = useToast()

  return (
    <RegistrationLayout
      title="Your Payment Page"
      subtitle="Pick the URL that your customers will use to access your payment page."
      currentPage="business"
      titleId="registration-subdomain"
    >
      <Flex width={['340px', '540px']} flexDirection="column" gap={8}>
        <ValidatingInput
          fontSize="sm"
          validated={!!subdomain}
          isInvalid={(subdomain?.length || 0) < 3}
          label="Payment Portal URL"
          value={subdomain}
          onChange={(e) =>
            setSubdomain(
              e.target.value.replace(/[^a-z0-9]/gi, '').toLowerCase(),
            )
          }
          error={
            (subdomain?.length || 0) < 3
              ? 'Subdomain must be at least 3 characters'
              : undefined
          }
          helperText={
            subdomain &&
            `Your customers will go to https://${subdomain}.nickelpayments.com to pay your business.`
          }
          placeholder="subdomain (e.g. businessname)"
          rightAddon=".nickelpayments.com"
          leftAddon="https://"
        />
        <Button
          label="Confirm URL"
          isDisabled={(subdomain?.length || 0) < 3}
          onClick={async () => {
            if (!subdomain || subdomain.length < 3) {
              return
            }

            const data = await organizationPage({
              variables: { subdomain: subdomain },
            })

            if (data?.data?.organizationPage?.organizationId) {
              toaster({
                title: 'Subdomain already exists',
                description: `The subdomain ${subdomain} is already in use. Please choose another.`,
                status: 'error',
              })
              return
            }

            onNextStep()
          }}
        />
      </Flex>
    </RegistrationLayout>
  )
}

export function Business({ onNextStep }: BusinessProps) {
  const [step, setStep] = useState<BusinessType>('business_type')
  const { setBusinessType } = useSelfServiceRegistrationStore((state) => ({
    setBusinessType: state.setBusinessType,
    onboardingInfo: state.onboardingInfo,
  }))

  const component: Record<BusinessType, JSX.Element> = {
    business_type: (
      <RegistrationLayout
        title="Your business"
        subtitle="Are you a business owner making payments in house or an accounting firm managing payments for clients?"
        currentPage="business"
        titleId="registration-business-type"
      >
        <Flex width={['340px', '540px']} flexDirection="column" gap={8}>
          <HStack justifyContent="center">
            <BusinessTypeCard
              title="Business Owner"
              description="Pay your vendors' bills and receive payments from your customers"
              onClick={() => {
                setBusinessType('business')
                setStep('businessInfo')
              }}
            />
            <BusinessTypeCard
              title="Accounting Firm"
              description="Managing your clients' accounts receivables and accounts payables"
              onClick={() => {
                setBusinessType('accounting')
                setStep('businessInfo')
              }}
            />
          </HStack>
        </Flex>
      </RegistrationLayout>
    ),
    businessInfo: (
      <OnboardingInformation onNextStep={() => setStep('subdomain')} />
    ),
    subdomain: <Subdomain onNextStep={onNextStep} />,
  }

  return <>{component[step]}</>
}

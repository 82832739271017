import { useState } from 'react'

import { Button } from 'ui/src/components/v3/button/Button'
import moment from 'moment'
import { Row } from 'ui'

type BillPaymentReceiptProps = {
  paymentId: string
  hidden?: boolean
  route?: string
  previewMode?: boolean
}

const downloadOnClick = async (
  setLoading: (loading: boolean) => void,
  paymentId: string,
  route: string,
) => {
  setLoading(true)

  fetch(`${route}?fileName=billPayment&paymentId=${paymentId}` || '', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((e) => e.arrayBuffer())
    .then((e) => {
      const a = document.createElement('a')
      const uintArr = new Uint8Array(e)
      const blob = new Blob([uintArr], { type: 'application/pdf' })

      a.href = URL.createObjectURL(blob)

      a.download = `${moment.now().toString()}.pdf`

      a.click()

      setLoading(false)
    })
    .finally(() => {
      setLoading(false)
    })
}

export const SmallBillPaymentReceipt = (props: BillPaymentReceiptProps) => {
  const [downloadLoading, setDownloadLoading] = useState(false)

  return (
    <Button
      variant="outline"
      label="Download Receipt"
      size="sm"
      iconName="documentArrowDown"
      iconPosition="left"
      isLoading={downloadLoading}
      w="100%"
      onClick={() =>
        downloadOnClick(setDownloadLoading, props.paymentId, props.route || '')
      }
    />
  )
}

const BillPaymentReceipt = (props: BillPaymentReceiptProps) => {
  const [downloadLoading, setDownloadLoading] = useState(false)

  return (
    <Row gap="medium" grow y="center" x="center">
      <Button
        {...{
          isLoading: downloadLoading,
          status: 'primary',
          onClick: () =>
            downloadOnClick(
              setDownloadLoading,
              props.paymentId,
              props.route || '',
            ),
          isDisabled: downloadLoading || props.previewMode,
          className: 'w-full',
        }}
        variant="solid"
        label="Receipt"
        role={'receipt_download'}
        iconPosition="left"
        iconName="documentArrowDown"
        size="lg"
      >
        Receipt
      </Button>
    </Row>
  )
}

export default BillPaymentReceipt

import { useQuery } from '@apollo/client'
import { Button, Flex } from '@chakra-ui/react'
import currency from 'currency.js'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetAllMerchantPaymentMethodsDocument } from '../../../../operations-types'
import { DashboardChoosePaymentMethod } from '../../../ap/components/DashboardChoosePaymentMethod'
import { NickelPlansLayout } from './NickelPlansLayout'
import NickelPlansReview from './NickelPlansReview'
import {
  NICKEL_PLUS_ANNUAL_PRICE_IN_CENTS,
  NICKEL_PLUS_MONTHLY_PRICE_IN_CENTS,
  useNickelPlansStore,
} from './NickelPlansStore'
import { NickelPricing } from './NickelPricing'
import { NickelPlansPage } from './types'

export function NickelPlans() {
  const navigate = useNavigate()

  const {
    savedPaymentMethods,
    sendMoneyAmountResult,
    selectedSavedPaymentMethod,
    paymentSummary,
    setPaymentMethods,
    setSelectedSavedPaymentMethod,
    setPaymentSummary,
    setPage,
    getPage,
    reset,
    selectedPlan,
  } = useNickelPlansStore((state) => ({
    sendMoneyAmountResult: state.amountResult!,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    savedPaymentMethods: state.savedPaymentMethods,
    paymentSummary: state.paymentSummary,
    setPaymentMethods: state.setPaymentMethods,
    setSelectedSavedPaymentMethod: state.setSelectedSavedPaymentMethod,
    setPaymentSummary: state.setPaymentSummary,
    clearSelectedSavedPaymentMethod: state.clearSelectedSavedPaymentMethod,
    setPage: state.setPage,
    getPage: state.getPage,
    reset: state.reset,
    selectedPlan: state.selectedPlan,
  }))

  const { data: merchantPaymentMethodsData } = useQuery(
    GetAllMerchantPaymentMethodsDocument,
  )

  useEffect(() => {
    if (
      merchantPaymentMethodsData?.getAllMerchantPaymentMethods?.paymentMethods
    ) {
      setPaymentMethods(
        merchantPaymentMethodsData.getAllMerchantPaymentMethods.paymentMethods,
      )
    }
  }, [merchantPaymentMethodsData, setPaymentMethods])

  const onGoBackClick = () => {
    navigate('/dashboard/settings/billing')
    reset()
  }

  const amount = currency(
    selectedPlan === 'NICKEL_PLUS_ANNUAL'
      ? NICKEL_PLUS_ANNUAL_PRICE_IN_CENTS
      : NICKEL_PLUS_MONTHLY_PRICE_IN_CENTS,
    {
      fromCents: true,
    },
  )

  const components: Record<NickelPlansPage, React.ReactNode> = {
    plans: (
      <NickelPlansLayout
        title="Nickel Pricing Plans"
        subtitle="Choose the best plan for your business."
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <NickelPricing
          setPage={setPage}
          setPaymentSummary={setPaymentSummary}
          sendMoneyAmountResult={sendMoneyAmountResult}
        />
      </NickelPlansLayout>
    ),
    paymentMethod: (
      <NickelPlansLayout
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
        breakdown={{
          feeCents: 0,
          amountCents: amount.intValue || 0,
        }}
        title="Choose a payment method"
        subtitle="Choose the payment method that you will use to fund this payment."
      >
        <DashboardChoosePaymentMethod
          setPage={() => setPage('review')}
          onGoBackClick={onGoBackClick}
          savedPaymentMethods={savedPaymentMethods}
          setPaymentMethods={setPaymentMethods}
          sendMoneyAmountResult={sendMoneyAmountResult}
          selectedSavedPaymentMethod={selectedSavedPaymentMethod}
          setSelectedSavedPaymentMethod={setSelectedSavedPaymentMethod}
          paymentSummary={paymentSummary}
          setPaymentSummary={setPaymentSummary}
          creditCardEnabled
          achEnabled
          cardDescription={
            "You'll pay for your Nickel subscription with a card."
          }
          bankDescription={
            "You'll pay for your Nickel subscription with a bank account."
          }
          noCardFee={true}
        />
      </NickelPlansLayout>
    ),
    review: (
      <NickelPlansLayout
        title={`Review your payment of ${amount.format()}`}
        subtitle={`Please review the details of your payment.`}
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <NickelPlansReview />
      </NickelPlansLayout>
    ),
    receipt: (
      <NickelPlansLayout
        title="Your subscription is activated!"
        subtitle="You will receive a receipt in your email shortly."
        onGoBackClick={onGoBackClick}
        currentPage={getPage()}
        onPageChange={(page) => setPage(page)}
      >
        <Flex width="540px" flexDirection="column" gap={6}>
          <Button
            variant="outline"
            onClick={async () => {
              navigate('/dashboard')
              reset()
            }}
          >
            Return to Dashboard
          </Button>
        </Flex>
      </NickelPlansLayout>
    ),
  }
  return <>{components[getPage()]}</>
}

import { Box, Divider, Spacer } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  Button,
  calculatePaymentSummaryForMerchant,
  HFlex,
  VFlex,
  BillOverlayHeader,
} from 'ui'
import {
  VendorDeliveryMethod,
  ScheduleBillOverlayMethodAmount,
} from '../components/ScheduleBillOverlay'
import { useBillStore } from '../../stores/billStore'
import { AddDeliveryMethodOverlay } from '../components/AddDeliveryMethodOverlay'
import { useToast } from '@chakra-ui/react'
type RecurringBillPaymentProps = {
  setOpen: (open: boolean) => void
}

export function RecurringBillPayment({ setOpen }: RecurringBillPaymentProps) {
  const {
    setRecurringPage,
    reset,
    sendMoneyAmountResult,
    recurringDetails,
    setPaymentSummary,
    setSelectedSavedPaymentMethod,
    savedPaymentMethods,
    paymentSummary,
    selectedSavedPaymentMethod,
    vendorDeliveryMethod,
  } = useBillStore((state) => ({
    setRecurringPage: state.setRecurringPage,
    reset: state.reset,
    sendMoneyAmountResult: state.sendMoneyAmountResult,
    recurringDetails: state.recurringDetails,
    paymentSummary: state.paymentSummary,
    setPaymentSummary: state.setPaymentSummary,
    setSelectedSavedPaymentMethod: state.setSelectedSavedPaymentMethod,
    savedPaymentMethods: state.savedPaymentMethods,
    selectedSavedPaymentMethod: state.selectedSavedPaymentMethod,
    vendorDeliveryMethod: state.selectedVendorPayoutMethod,
  }))

  useEffect(() => {
    const executePrevPayment = async () => {
      if (savedPaymentMethods && savedPaymentMethods.length > 0) {
        const defaultPaymentMethod = savedPaymentMethods[0]
        setSelectedSavedPaymentMethod(defaultPaymentMethod)

        const calculatedPaymentSummary = calculatePaymentSummaryForMerchant(
          sendMoneyAmountResult?.submittedAmountCents || 0,
          defaultPaymentMethod.card?.brand ? 'Credit Card' : 'ACH Transfer',
          'ACH',
        )
        setPaymentSummary(calculatedPaymentSummary)
      }
    }

    executePrevPayment()
  }, [
    savedPaymentMethods,
    setSelectedSavedPaymentMethod,
    sendMoneyAmountResult,
    setPaymentSummary,
  ])

  const vendor = recurringDetails?.vendor

  const [addDeliveryMethodOpen, setAddDeliveryMethodOpen] = useState(false)
  const [addDeliveryMethodType, setAddDeliveryMethodType] = useState<
    'ACH' | 'Check'
  >('ACH')

  const vendorId = recurringDetails?.vendor?.id

  const toast = useToast()

  const addDeliveryMethodNode = (
    <AddDeliveryMethodOverlay
      onClose={() => setAddDeliveryMethodOpen(false)}
      onBack={() => setAddDeliveryMethodOpen(false)}
      vendorId={vendorId ?? ''}
      type={addDeliveryMethodType || 'ACH'}
      onSave={() => {
        setAddDeliveryMethodOpen(false)
      }}
    />
  )

  const paymentDetailsNode = (
    <>
      <BillOverlayHeader
        onClose={() => {
          setOpen(false)
          reset()
        }}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Recurring Bills' },
          { label: 'Create' },
          { label: 'Pay', isActive: true },
        ]}
        title={`Pay ${sendMoneyAmountResult?.reason}`}
        subtitle={`${vendor?.name}`}
        type="recurring"
      />
      <Divider />
      <VFlex flex={1} w="100%">
        <ScheduleBillOverlayMethodAmount />
        <Divider />
        {vendor?.id && (
          <VendorDeliveryMethod
            vendorId={vendor?.id}
            onAddDeliveryMethod={(type) => {
              setAddDeliveryMethodOpen(true)
              setAddDeliveryMethodType(type)
            }}
          />
        )}
      </VFlex>
      <Box w="100%">
        <Divider />
        <HFlex gap={4} w="100%" justifyContent="flex-end" p="4">
          <Button
            label="Back"
            variant="outline"
            onClick={() => {
              setRecurringPage('recurringCreateBill')
            }}
          />
          <Spacer />

          <Button
            label="Review"
            iconName="arrowRight"
            iconPosition="right"
            isDisabled={false}
            onClick={() => {
              setPaymentSummary(
                calculatePaymentSummaryForMerchant(
                  paymentSummary?.amountWithoutFeeCents() || 0,
                  selectedSavedPaymentMethod?.card?.brand
                    ? 'Credit Card'
                    : 'ACH Transfer',
                  vendorDeliveryMethod?.type === 'ACH' ? 'ACH' : 'Check',
                ),
              )

              if (!paymentSummary) {
                return
              }

              if (paymentSummary?.amountWithoutFeeCents() === 0) {
                toast({
                  title: 'Payment amount cannot be 0',
                  description: 'Please enter a valid payment amount',
                  status: 'error',
                })
                return
              }

              setRecurringPage('recurringReview')
            }}
          />
        </HFlex>
      </Box>
    </>
  )

  return (
    <>
      {!addDeliveryMethodOpen && paymentDetailsNode}
      {addDeliveryMethodOpen && addDeliveryMethodNode}
    </>
  )
}
export default RecurringBillPayment

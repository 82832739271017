import {
  Box,
  Card,
  CardBody,
  Center,
  FormControl,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Tooltip,
} from '@chakra-ui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import currency from 'currency.js'
import { useState } from 'react'
import { Button, PriceInput, HFlex } from 'ui'
import { Category, Item } from 'ui/src/types'

type LineMenuLabelProps = {
  line: Category | Item
  onChangeType: (type: 'category' | 'item') => void
  availableItems: Item[]
}
function LineMenuLabel({
  line,
  onChangeType,
  availableItems,
}: LineMenuLabelProps) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        size="xs"
        iconName="chevronDown"
        iconPosition="right"
        label={line.type === 'category' ? 'Category' : 'Item'}
        iconBoxSize="4"
      />
      <MenuList>
        {line.type === 'item' && (
          <MenuItem onClick={() => onChangeType('category')}>Category</MenuItem>
        )}
        {line.type === 'category' && availableItems?.length > 0 && (
          <MenuItem onClick={() => onChangeType('item')}>Item</MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

type CategoryLineRowProps = {
  index: number
  availableCategories: Category[]
  availableItems: Item[]
  category: Category
  onChangeType: (type: 'category' | 'item', index: number) => void
  onChangeCategoryId: (categoryId: string, index: number) => void
  onChangeAmount: (amount: string) => void
  onDelete: () => void
  onChangeDescription?: (description: string, index: number) => void
}

export function CategoryLineRow({
  index,
  availableCategories,
  availableItems,
  category,
  onChangeType,
  onChangeCategoryId,
  onChangeAmount,
  onDelete,
  onChangeDescription,
}: CategoryLineRowProps) {
  const [amount, setAmount] = useState(
    currency(category.lineDetails?.amountCents || 0, {
      fromCents: true,
    }).value.toString(),
  )
  return (
    <Card>
      <CardBody py="2" px="3">
        <HFlex pb="1">
          <Center justifyContent="space-between" w="100%">
            <LineMenuLabel
              line={category}
              onChangeType={(type) => onChangeType(type, index)}
              availableItems={availableItems || []}
            />
            <Icon as={XMarkIcon} onClick={onDelete} cursor="pointer" />
          </Center>
        </HFlex>
        <HFlex gap={4}>
          <FormControl>
            <Select
              placeholder="Select Category"
              value={category.id}
              onChange={(e) => onChangeCategoryId(e.target.value, index)}
            >
              {availableCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.lineDetails.name}
                  {category.lineDetails.description
                    ? ` - ${category.lineDetails.description}`
                    : ''}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Tooltip label="Amount" placement="top">
              <Box>
                <PriceInput
                  label=""
                  value={amount}
                  fontWeight="medium"
                  fontSize="md"
                  onPriceChange={(amount) => {
                    setAmount(amount)
                    onChangeAmount(amount)
                  }}
                />
              </Box>
            </Tooltip>
          </FormControl>
        </HFlex>
      </CardBody>
    </Card>
  )
}

type ItemLineRowProps = {
  index: number
  item: Item
  availableItems: Item[]
  onChangeType: (type: 'category' | 'item', index: number) => void
  onChangeItemId: (itemId: string, index: number) => void
  onChangeQuantity: (quantity: string) => void
  onChangeRate: (rate: string) => void
  onDelete: () => void
  onChangeDescription?: (description: string, index: number) => void
}

export function ItemLineRow({
  index,
  item,
  availableItems,
  onChangeType,
  onChangeItemId,
  onChangeQuantity,
  onChangeRate,
  onDelete,
  onChangeDescription,
}: ItemLineRowProps) {
  return (
    <Card>
      <CardBody py="2" px="3">
        <HFlex pb="1">
          <Center justifyContent="space-between" w="100%">
            <LineMenuLabel
              line={item}
              onChangeType={(type) => onChangeType(type, index)}
              availableItems={availableItems || []}
            />
            <Icon as={XMarkIcon} onClick={onDelete} cursor="pointer" />
          </Center>
        </HFlex>
        <HStack width="full" spacing={4} align="flex-start">
          <FormControl>
            <Select
              placeholder="Select item"
              value={item.id}
              onChange={(e) => onChangeItemId(e.target.value, index)}
            >
              {availableItems.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.lineDetails.name}
                  {item.lineDetails.description
                    ? ` - ${item.lineDetails.description}`
                    : ''}
                  {` (${currency(item.lineDetails?.rate || 0, {
                    fromCents: false,
                  }).format()})`}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Tooltip label="Quantity" placement="top">
              <Input
                type="number"
                size="md"
                value={item.lineDetails?.quantity || ''}
                min={0}
                placeholder="Quantity"
                onChange={(e) => {
                  const value = Math.floor(Number(e.target.value))
                  onChangeQuantity(value.toString())
                }}
              />
            </Tooltip>
          </FormControl>
          <FormControl>
            <Tooltip label="Rate" placement="top">
              <Box>
                <PriceInput
                  label=""
                  placeholder="Rate"
                  value={item.lineDetails?.rate}
                  fontWeight="medium"
                  fontSize="md"
                  onPriceChange={(rate) => onChangeRate(rate)}
                />
              </Box>
            </Tooltip>
          </FormControl>
          <FormControl>
            <Tooltip label="Total amount" placement="top">
              <Box>
                <PriceInput
                  label=""
                  bgColor="gray.100"
                  isReadOnly
                  fontWeight="medium"
                  placeholder="Total Amount"
                  fontSize="md"
                  value={currency(item.lineDetails?.amountCents || 0, {
                    fromCents: true,
                  }).value.toString()}
                />
              </Box>
            </Tooltip>
          </FormControl>
        </HStack>
      </CardBody>
    </Card>
  )
}

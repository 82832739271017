import Homepage from './components/Homepage'
import LoggedIn, { FullscreenLoggedIn } from './components/LoggedIn'
import DashboardHome from './components/dash/DashboardHome'
import {
  Registration,
  NewTeammate,
} from './components/register/RegistrationComponents'
import Admin from './components/dash/Admin'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import Team from './components/settings/Team'
import { Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider, useAuth } from './lib/auth'
import React, { useEffect } from 'react'
import Settings from './components/settings/Settings'
import BillingSettings from './components/settings/BillingSettings'
import Payments from './components/payments/Payments'
import Payouts from './components/payouts/Payouts'
import Customers from './components/customers/Customers'
import PaymentOverlay from './components/payments/PaymentOverlay'
import Profile from './components/settings/Profile'
import NickelPay from './components/settings/nickelpay/NickelPay'
import { Notifications } from './components/settings/notifications/Notifications'
import QuestionFormsTable from './components/forms/QuestionForms'
import AirtableAuthentication from './components/erp/AirtableAuthentication'
import _Sentry from './lib/sentry'
import Refunds from './components/payments/refunds/Refunds'
import {
  DEPOSIT_CHECK_URL,
  PAYMENTS_SLUG,
  PAYMENTS_URL,
  PAYOUTS_SLUG,
  PAYOUTS_URL,
  REFUNDS_SLUG,
  REFUNDS_URL,
  SETTLEMENTS_URL,
  SETTLEMENTS_SLUG,
  TRANSACTIONS_URL,
  TRANSACTION_REFUND,
  TRANSACTION_RECEIVABLE,
  TRANSACTION_RETURN,
  TRANSACTION_PAYABLE,
  GET_PAID_URL,
  QUESTION_FORM_URL,
  AP_SEND_MONEY_URL_V2,
  TRANSACTION_VENDOR_RETURN,
  NICKEL_PLANS_URL,
  NEW_CLIENT_URL,
  PAYMENT_LINK_BULK,
  HUBSPOT_AUTHENTICATED_URL,
  HUBSPOT_CREATE_PAYMENT_LINK_URL,
  TRADE_ACCOUNTS_URL,
  TRADE_ACCOUNT_URL,
  TRANSACTION_CHARGEBACK,
  PAY_VENDORS_BILLS_URL,
  PAY_VENDORS_APPROVALS_URL,
  REGISTRATION_SELF_SERVE_URL,
  TRANSACTION_ADVANCE,
  PAY_VENDORS_VENDORS_URL,
  REFERRALS_URL,
  PAY_VENDORS_VENDOR_URL,
  BATCH_BILL_PAY_URL,
} from './lib/urls'
import PayoutOverlay from './components/payouts/PayoutOverlay'
import RefundOverlay from './components/payments/refunds/RefundOverlay'
import EditPaymentLink from './components/paymentLinks/EditPaymentLink'
import Webhooks from './components/settings/Webhooks'
import PageNotFound from './PageNotFound'
import CheckOverlay from './components/payments/CheckOverlay'
import { Settlements } from './components/settlements/Settlements'
import Transactions from './components/transactions/Transactions'
import { TransactionOverlay } from './components/transactions/TransactionOverlay'
import PaymentLinks from './components/paymentLinks/PaymentLinks'
import PaymentLinkOverlay from './components/paymentLinks/PaymentLinkOverlay'
import { SendMoneyV2 } from './components/ap/SendMoney'
import Receipt from './components/Receipt'
import { NickelPlans } from './components/settings/billing/nickelplans/NickelPlans'
import { AccountingRegistrationFlow } from './components/register/accountant/RegistrationFlow'
import { AddClientFlow } from './components/accounting/AddClientFlow'
import AccountingClients from './components/accounting/AccountingClients'
import Loading from './components/utils/Loading'
import { UserRole } from './operations-types'
import { useLoggedInStore } from './components/layout/LoggedInStore'
import { BulkPaymentLayout } from './components/paymentLinks/BulkPaymentLayout'
import { OwnerInvite } from './components/register/OwnerInvite'
import HubSpotAuth from './components/dash/HubSpot'
import { HubSpotCreatePaymentLink } from './components/paymentLinks/HubSpotCreatePaymentLink'
import { TradeAccounts } from './components/credit/TradeAccounts'
import TradeAccountOverlay from './components/credit/tradeAccounts/TradeAccountOverlay'
import { Bills } from './components/bills/Bills'
import { Approvals } from './components/bills/Approvals'
import { SelfServiceRegistration } from './components/register/selfservice/SelfServiceRegistration'
import Vendors from './components/ap/vendor/Vendors'
import { Referrals } from './components/referrals/Referrals'
import LoginTwoFactor from './components/LogInTwoFactor'
import { VendorOverlay } from './components/ap/vendor/VendorOverlay'
import { BatchBillPay } from './components/bills/batch/BatchBillPay'

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <RedirectToDashboard>
              <Homepage />
            </RedirectToDashboard>
          }
        />
        <Route
          path="/login"
          element={
            <RedirectToDashboard>
              <Homepage />
            </RedirectToDashboard>
          }
        />
        <Route
          path="/two-factor-authentication"
          element={
            <RedirectToDashboard>
              <LoginTwoFactor />
            </RedirectToDashboard>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <RedirectToDashboard>
              <ForgotPassword />
            </RedirectToDashboard>
          }
        />
        <Route
          path="/password-reset/:token"
          element={
            <RedirectToDashboard>
              <ResetPassword />
            </RedirectToDashboard>
          }
        />
        <Route
          path="/accountingfirm/:token"
          element={<AccountingRegistrationFlow />}
        />
        <Route
          path="/newretailer/:token"
          element={<Registration vendor={true} />}
        />
        <Route
          path="newretailervendor/:token"
          element={<Registration vendor={true} />}
        />
        <Route path="/inviteowner/:token" element={<OwnerInvite />} />
        <Route
          path="/newteammate/:token"
          element={
            <RegistrationRoute>
              <NewTeammate />
            </RegistrationRoute>
          }
        />
        <Route
          path={REGISTRATION_SELF_SERVE_URL}
          element={<SelfServiceRegistration />}
        />
        <Route path="/receipt/:id" element={<Receipt />} />
        <Route
          path="/quickbooks/authenticate"
          element={<Loading additionalClasses={{ 'h-screen': true }} />}
        />
        <Route path="/airtable" element={<LoggedIn />}>
          <Route
            path="/airtable/authenticate"
            element={<AirtableAuthentication />}
          />
        </Route>
        <Route path="/dashboard" element={<LoggedIn />}>
          <Route index element={<DashboardHome />} />
          <Route path="/dashboard/customers" element={<Customers />} />
          <Route path={HUBSPOT_AUTHENTICATED_URL} element={<HubSpotAuth />} />
          <Route path="/dashboard/clients" element={<AccountingClients />} />
          <Route path={REFUNDS_URL} element={<Refunds />}>
            <Route path={REFUNDS_SLUG} element={<RefundOverlay />} />
          </Route>
          <Route path={TRADE_ACCOUNTS_URL} element={<TradeAccounts />}>
            <Route path={TRADE_ACCOUNT_URL} element={<TradeAccountOverlay />} />
          </Route>
          <Route path={REFERRALS_URL} element={<Referrals />} />
          <Route path={TRANSACTIONS_URL} element={<Transactions />}>
            <Route
              path={TRANSACTION_RECEIVABLE}
              element={<TransactionOverlay />}
            />
            <Route path={TRANSACTION_REFUND} element={<TransactionOverlay />} />
            <Route path={TRANSACTION_RETURN} element={<TransactionOverlay />} />
            <Route
              path={TRANSACTION_VENDOR_RETURN}
              element={<TransactionOverlay />}
            />
            <Route
              path={TRANSACTION_PAYABLE}
              element={<TransactionOverlay />}
            />
            <Route
              path={TRANSACTION_CHARGEBACK}
              element={<TransactionOverlay />}
            />
            <Route
              path={TRANSACTION_ADVANCE}
              element={<TransactionOverlay />}
            />
          </Route>
          <Route path={PAYMENTS_URL} element={<Payments />}>
            <Route path={DEPOSIT_CHECK_URL} element={<CheckOverlay />} />
            <Route path={PAYMENTS_SLUG} element={<PaymentOverlay />} />
          </Route>
          <Route path={PAYOUTS_URL} element={<Payouts />}>
            <Route path={PAYOUTS_SLUG} element={<PayoutOverlay />} />
          </Route>
          <Route path={SETTLEMENTS_URL} element={<Settlements />}>
            <Route path={SETTLEMENTS_SLUG} element={<PayoutOverlay />} />
          </Route>
          <Route path="/dashboard/admin" element={<Admin />} />
          <Route
            path="/dashboard/payment-links"
            element={<QuestionFormsTable />}
          />
          <Route path={PAY_VENDORS_BILLS_URL} element={<Bills />} />
          <Route path={PAY_VENDORS_APPROVALS_URL} element={<Approvals />} />
          <Route path={PAY_VENDORS_VENDORS_URL} element={<Vendors />}>
            <Route path={PAY_VENDORS_VENDOR_URL} element={<VendorOverlay />} />
          </Route>
          <Route path={GET_PAID_URL} element={<PaymentLinks />}>
            <Route path={QUESTION_FORM_URL} element={<PaymentLinkOverlay />} />
          </Route>
          <Route path="/dashboard/settings" element={<Settings />}>
            <Route index element={<Profile />} />
            <Route path="/dashboard/settings/team" element={<Team />} />
            <Route
              path="/dashboard/settings/nickelpay"
              element={<NickelPay />}
            />
            <Route
              path="/dashboard/settings/notifications"
              element={<Notifications />}
            />
            <Route
              path="/dashboard/settings/billing"
              element={<BillingSettings />}
            />
            <Route path="/dashboard/settings/webhooks" element={<Webhooks />} />
          </Route>
        </Route>
        <Route element={<FullscreenLoggedIn />}>
          <Route
            path={HUBSPOT_CREATE_PAYMENT_LINK_URL}
            element={<HubSpotCreatePaymentLink />}
          />
          <Route path={AP_SEND_MONEY_URL_V2} element={<SendMoneyV2 />} />
          <Route
            path={`${AP_SEND_MONEY_URL_V2}/bill/:billId`}
            element={<SendMoneyV2 />}
          />
          <Route path={PAYMENT_LINK_BULK} element={<BulkPaymentLayout />} />
          <Route path={BATCH_BILL_PAY_URL} element={<BatchBillPay />} />
          <Route path={NICKEL_PLANS_URL} element={<NickelPlans />} />
          <Route path={NEW_CLIENT_URL} element={<AddClientFlow />} />
        </Route>
        <Route path="/payment-links" element={<FullscreenLoggedIn />}>
          <Route path="/payment-links/:id" element={<EditPaymentLink />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AuthProvider>
  )
}

function RedirectToDashboard({ children }: { children: React.ReactNode }) {
  return <RedirectRoute>{children}</RedirectRoute>
}

type ProctedRouteProps = {
  children: React.ReactNode
  permissionLevel: UserRole
}

export function ProtectedRoute({
  children,
  permissionLevel,
}: ProctedRouteProps) {
  const loggedInUser = useLoggedInStore((state) => state.user)

  if (!loggedInUser.isUserPermitted(permissionLevel)) {
    return <Navigate to={{ pathname: '/dashboard' }} />
  }

  return <>{children}</>
}

function RegistrationRoute({ children }: { children: React.ReactNode }) {
  const { signOut } = useAuth()

  useEffect(() => {
    const execute = async () => {
      await signOut()
    }

    execute()
  }, [signOut])

  return <>{children}</>
}

function RedirectRoute({ children }: { children: React.ReactNode }) {
  const { isSignedIn } = useAuth()

  if (isSignedIn()) {
    return <Navigate to={{ pathname: '/dashboard' }} />
  }

  return <>{children}</>
}

export default App

import { Divider } from '@chakra-ui/react'
import { useEffect } from 'react'
import { BillOverlayHeader } from 'ui'
import { Vendor } from '../../operations-types'
import { NewBillPage } from './NewBillPage'
import { useAddBillStore } from '../stores/addBillStore'

export type NewBillOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  vendor?: Vendor | null
  onPayBill?: (billId: string) => void
}

export function NewBillOverlay({
  isOpen,
  setOpen,
  vendor,
  onPayBill,
}: NewBillOverlayProps) {
  const { setVendorId } = useAddBillStore((state) => {
    return {
      setVendorId: state.setVendorId,
    }
  })

  useEffect(() => {
    if (vendor) {
      setVendorId(vendor.id)
    }
  }, [vendor])

  return (
    <>
      <BillOverlayHeader
        type="bills"
        onClose={() => {
          setOpen(false)
          setVendorId(null)
        }}
        title={`New Bill`}
        subtitle={`Create a new bill to track and pay in Nickel`}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Bills' },
          { label: 'Create Bill', isActive: true },
        ]}
      />
      <Divider />
      <NewBillPage
        isOpen={isOpen}
        setOpen={(open) => {
          setOpen(open)
          setVendorId(null)
        }}
        onPayBill={onPayBill}
      />
    </>
  )
}

import {
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  Icon,
  Card,
  Select,
  Textarea,
  useToast,
  Fade,
  Tooltip,
} from '@chakra-ui/react'
import currency from 'currency.js'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  addBusinessDays,
  BoxIcon,
  Button,
  dateIsFutureAndNotWeekend,
  DatePickerCardInputSmall,
  ExclamationCircleIcon,
  filterDeliveryDate,
  HFlex,
  subtractBusinessDays,
  VFlex,
  Icon as NickelIcon,
  LineItem,
} from 'ui'
import { IconName } from 'ui/src/components/v3/Icons'
import { BuildingStorefrontIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PAY_VENDORS_BILLS_URL } from '../../../lib/urls'
import {
  ApprovalPolicy,
  BatchBillPayDocument,
  BillsByIdDocument,
  CheckApprovalsDocument,
  GetAllMerchantPaymentMethodsDocument,
  OrganizationTier,
  PaymentMethod,
  SelfDocument,
  VendorPayoutMethod,
} from '../../../operations-types'
import { BatchLayout } from '../../BatchLayout'
import { useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import Loading from '../../utils/Loading'
import { useBatchBillStore } from '../../stores/batchBillStore'
import { VendorEmailsSmall } from '../components/VendorEmails'
import './multi-email.css'
import { Fragment, useEffect, useState } from 'react'
import {
  getEstimatedDaysToDelivery,
  vendorPayoutMethodToIdentifier,
} from '../../../lib/utils'
import { getPaymentMethodString } from '../../ap/components/DashboardChoosePaymentMethod'
import { Bill, ValidBillWithPaymentData } from '../../stores/batchStoreTypes'
import moment from 'moment'
import { BillWithPaymentData } from '../../stores/BillWithPaymentData'
import pluralize from 'pluralize'
import { NewDeliveryMethodModal } from '../NewDeliveryMethodModal'
import { getRoleLabel } from '../../settings/Team'

type FooterItemProps = {
  label: string
  value: string
}

function FooterItem(props: FooterItemProps) {
  return (
    <VFlex>
      <Text fontSize="sm" color="gray.500">
        {props.label}
      </Text>
      <Text fontSize="lg" fontWeight="semibold" color="gray.800">
        {props.value}
      </Text>
    </VFlex>
  )
}

export type BatchBillPayFooterProps = {
  footerItems: FooterItemProps[]
  onClick: () => void
  isDisabled: boolean
  loading: boolean
  iconName?: IconName
  iconPosition?: 'left' | 'right'
  label: string
}

export function BatchBillPayFooter(props: BatchBillPayFooterProps) {
  return (
    <Box w="100%" pt={10}>
      <Divider />
      <HStack w="100%" px={10} py={6} justifyContent="space-between">
        <HStack gap={8}>
          {props.footerItems.map((item, idx) => (
            <FooterItem key={idx} {...item} />
          ))}
        </HStack>
        <Button
          size="sm"
          label={props.label}
          isDisabled={props.isDisabled}
          onClick={props.onClick}
          isLoading={props.loading}
          iconName={props.iconName}
          iconPosition={props.iconPosition}
        />
      </HStack>
    </Box>
  )
}

type ExitButtonProps = {
  onClick: () => void
}

export function ExitButton(props: ExitButtonProps) {
  return (
    <Box
      bg={`gray.100`}
      p={3}
      borderRadius="full"
      boxShadow="lg"
      cursor="pointer"
      onClick={props.onClick}
    >
      <Icon as={XMarkIcon} boxSize={6} color={`gray.600`} />
    </Box>
  )
}

type BatchBillReviewHeaderProps = {
  numBills: number
}

function BatchBillReviewHeader(props: BatchBillReviewHeaderProps) {
  return (
    <HStack gap={3}>
      <BoxIcon type="bills" />
      <VFlex>
        <Text fontSize="xl" fontWeight="medium" color="gray.500">
          Pay{' '}
          <Text fontSize="xl" fontWeight="medium" color="black" as="span">
            {props.numBills} Bills
          </Text>
        </Text>
        <Text fontSize="sm" color="gray.500">
          Review the bills you've selected for payment
        </Text>
      </VFlex>
    </HStack>
  )
}

type VendorHeaderProps = {
  readyToPay: boolean
  vendorName: string
  vendorTotalAmount: string
  vendorTotalFeeAmount?: string
  vendorEmails: string[]
  setVendorEmails?: (emails: string[]) => void
  errorTooltipText?: string
}

function VendorHeader({
  readyToPay,
  vendorName,
  vendorTotalAmount,
  vendorTotalFeeAmount,
  vendorEmails,
  setVendorEmails,
  errorTooltipText,
}: VendorHeaderProps) {
  return (
    <HStack justifyContent="space-between">
      <HStack gap={2.5} w="100%">
        <Box
          borderRadius="full"
          p={1.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgColor={readyToPay ? 'gray.200' : 'red.200'}
        >
          <Tooltip
            label={errorTooltipText}
            hasArrow
            shouldWrapChildren
            isDisabled={readyToPay}
          >
            <Flex>
              <Icon
                as={readyToPay ? BuildingStorefrontIcon : ExclamationCircleIcon}
                boxSize={5}
                color={readyToPay ? 'gray.600' : 'red.600'}
              />
            </Flex>
          </Tooltip>
        </Box>
        <VFlex w="100%">
          <Text fontSize="sm" fontWeight="medium" color="gray.800">
            {vendorName}
          </Text>
          {setVendorEmails && (
            <VendorEmailsSmall
              vendorEmails={vendorEmails}
              setVendorEmails={setVendorEmails}
              errorState={vendorEmails.length === 0}
            />
          )}
        </VFlex>
      </HStack>
      <VFlex>
        <Text
          fontSize="sm"
          fontWeight="medium"
          color="gray.800"
          textAlign="right"
        >
          {vendorTotalAmount}
        </Text>
        {vendorTotalFeeAmount && (
          <Text fontSize="xs" color="gray.500" whiteSpace="nowrap">
            + {vendorTotalFeeAmount}
          </Text>
        )}
      </VFlex>
    </HStack>
  )
}

type BillHeaderProps = {
  billNumber: string
  billAmount: string
  feeAmount?: string
  dueDate?: string
  approvalPolicy?: ApprovalPolicy
}

function BillHeader(props: BillHeaderProps) {
  // TODO(claudio.wilson): Support multiple approval steps and conditions and different steps and conditions
  const approvalStep = props.approvalPolicy?.approvalSteps?.at(0)
  const approvalCondition = props.approvalPolicy?.approvalConditions?.at(0)
  const approvalLabel = `This payment requires approval from ${
    approvalStep?.approver?.email ||
    `a ${getRoleLabel(approvalStep?.approverRole || '')}`
  } because it is greater than ${currency(
    approvalCondition?.paymentAmountCents || 0,
    { fromCents: true },
  ).format()}.`

  return (
    <Box>
      <HFlex justifyContent="space-between">
        <VFlex w="100%">
          <HStack gap={1}>
            <Text fontSize="sm" fontWeight="medium" color="gray.800">
              {props.billNumber}
            </Text>
            {props.approvalPolicy && (
              <Tooltip label={approvalLabel}>
                <HStack gap={1}>
                  <NickelIcon name="exclamationCircle" status="warning" />
                  <Text fontSize="xs" fontWeight="normal" color="yellow.700">
                    Approval Needed
                  </Text>
                </HStack>
              </Tooltip>
            )}
          </HStack>
          {props.dueDate && (
            <Text fontSize="xs" color="gray.500" as="span">
              Due on {props.dueDate}
            </Text>
          )}
        </VFlex>
        <VFlex>
          <Text
            fontSize="sm"
            fontWeight="medium"
            color="gray.800"
            textAlign="right"
          >
            {props.billAmount}
          </Text>
          {props.feeAmount && (
            <Text fontSize="xs" color="gray.500" whiteSpace="nowrap">
              + {props.feeAmount}
            </Text>
          )}
        </VFlex>
      </HFlex>
    </Box>
  )
}

type BillDropdownOption<T> = {
  label: string
  value: T
}

type BillDropdownDeliveryMethodProps = {
  achOption?: BillDropdownOption<VendorPayoutMethod>
  checkOption?: BillDropdownOption<VendorPayoutMethod>
  onChange: (deliveryMethod: VendorPayoutMethod) => void
  onNewOption: (type: 'ACH' | 'Check') => void
  currentlySelected?: BillDropdownOption<VendorPayoutMethod | null>
}

function BillDropdownDeliveryMethod(props: BillDropdownDeliveryMethodProps) {
  const noOptions = !props.achOption && !props.checkOption

  const onClickOrChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Find a better way to do this instead of finding label, because there might be the same label?
    props.onChange(
      props.achOption?.label === e.target.value
        ? props.achOption.value
        : props.checkOption?.value!,
    )
    if (e.target.value === 'ach' && props.onNewOption) {
      props.onNewOption('ACH')
    }
    if (e.target.value === 'check' && props.onNewOption) {
      props.onNewOption('Check')
    }
  }
  return (
    <VFlex gap={1} grow={1} basis={0}>
      <Text fontSize="xs" fontWeight="medium" color="gray.500">
        Delivery Method
      </Text>
      <Select
        size="sm"
        borderRadius="4px"
        bg="gray.100"
        borderColor={noOptions ? 'red.500' : undefined}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        placeholder={noOptions ? 'Select' : undefined}
        value={props.currentlySelected?.label}
        onChange={onClickOrChange}
      >
        {props.achOption && (
          <option value={props.achOption.label}>{props.achOption.label}</option>
        )}
        {props.checkOption && (
          <option value={props.checkOption.label}>
            {props.checkOption.label}
          </option>
        )}
        {!props.achOption && (
          <option value="ach">Add a New ACH Delivery Method</option>
        )}
        {!props.checkOption && (
          <option value="check">Add a New Check Delivery Method</option>
        )}
      </Select>
    </VFlex>
  )
}

type BillDropdownProps<T> = {
  label: string
  options: BillDropdownOption<T>[]
  onChange: (option: T) => void
  onNewOption?: (type: 'ACH' | 'Check') => void
}

function BillDropdown<T>(props: BillDropdownProps<T>) {
  return (
    <VFlex gap={1} grow={1} basis={0}>
      <Text fontSize="xs" fontWeight="medium" color="gray.500">
        {props.label}
      </Text>
      <Select
        size="sm"
        borderRadius="4px"
        bg="gray.100"
        borderColor={props.options.length === 0 ? 'red.500' : undefined}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        placeholder={props.options.length === 0 ? 'Select' : undefined}
        onChange={(e) => {
          // Find a better way to do this instead of finding label, because there might be the same label?
          props.onChange(
            props.options.find((x) => x.label === e.target.value)?.value!,
          )
          if (e.target.value === 'ach' && props.onNewOption) {
            props.onNewOption('ACH')
          }
          if (e.target.value === 'check' && props.onNewOption) {
            props.onNewOption('Check')
          }
        }}
      >
        {props.options.map((option, idx) => (
          <option key={idx}>{option.label}</option>
        ))}
        {props.onNewOption && (
          <>
            <option value="ach">Add a New ACH Delivery Method</option>
            <option value="check">Add a New Check Delivery Method</option>
          </>
        )}
      </Select>
    </VFlex>
  )
}

type BillDateSchedulerWithdrawalProps = {
  billId: string
}

type BillDateSchedulerProps = {
  label: string
  filterDate: (date: Date) => boolean
  onChange: (date: Date) => void
  selected: Date
}

function BillDateScheduler(props: BillDateSchedulerProps) {
  return (
    <VFlex gap={1}>
      <Text fontSize="xs" fontWeight="medium" color="gray.500">
        {props.label}
      </Text>
      <DatePickerCardInputSmall
        className="text-xs"
        selected={props.selected}
        dateFormat="MMM dd, yyyy"
        filterDate={(date) => props.filterDate(date)}
        onChange={(d) => {
          if (d) {
            props.onChange(d)
          }
        }}
      />
    </VFlex>
  )
}

function BillDateSchedulerWithdrawal({
  billId,
}: BillDateSchedulerWithdrawalProps) {
  const { getBillWithPaymentData, setBillWithdrawalDate } = useBatchBillStore(
    (state) => ({
      getBillWithPaymentData: state.getBillWithPaymentData,
      setBillWithdrawalDate: state.setBillWithdrawalDate,
    }),
  )

  const paymentData = getBillWithPaymentData(billId)
  const paymentMethod = paymentData.paymentMethod

  return (
    <BillDateScheduler
      label="Withdrawal Date"
      filterDate={(date) =>
        dateIsFutureAndNotWeekend(
          date,
          paymentMethod?.card?.brand ? 'card' : 'ach_debit',
        )
      }
      onChange={(d) => {
        if (d) {
          setBillWithdrawalDate(billId, d)
        }
      }}
      selected={paymentData.withdrawalDate}
    />
  )
}

function BillDateSchedulerEstDelivery(props: BillDateSchedulerWithdrawalProps) {
  const { getBillWithPaymentData, setBillWithdrawalDate } = useBatchBillStore(
    (state) => ({
      getBillWithPaymentData: state.getBillWithPaymentData,
      setBillWithdrawalDate: state.setBillWithdrawalDate,
    }),
  )

  const { data: loggedInUser } = useQuery(SelfDocument)

  const paymentData = getBillWithPaymentData(props.billId)
  const paymentMethod = paymentData.paymentMethod
  const withdrawalDate = paymentData.withdrawalDate

  const deliveryMethodToDays = getEstimatedDaysToDelivery(
    paymentData.deliveryMethod?.type === 'CHECK' ? 'CHECK' : 'ACH',
    paymentMethod?.card?.brand ? 'card' : 'ach_debit',
    loggedInUser?.user?.user?.organization?.accountInfo?.tier ||
      OrganizationTier.FreeTier,
    moment(paymentData.withdrawalDate).isSame(moment(), 'day'),
  )

  return (
    <BillDateScheduler
      label="Est. Delivery Date"
      selected={addBusinessDays(withdrawalDate, deliveryMethodToDays).toDate()}
      filterDate={(date) => filterDeliveryDate(date, deliveryMethodToDays)}
      onChange={(d) => {
        if (d) {
          paymentMethod?.card?.brand
            ? setBillWithdrawalDate(
                props.billId,
                moment(d).subtract(deliveryMethodToDays, 'days').toDate(),
              )
            : setBillWithdrawalDate(
                props.billId,
                subtractBusinessDays(d, deliveryMethodToDays).toDate(),
              )
        }
      }}
    />
  )
}

type BillDetailsProps = {
  billId: string
  vendorPayoutMethods: VendorPayoutMethod[]
  onSaveVendorMemo: (memo: string) => void
  onChangePaymentMethod: (paymentMethod: PaymentMethod) => void
  onChangeDeliveryMethod: (deliveryMethod: VendorPayoutMethod) => void
  onNewDeliveryMethod: (type: 'ACH' | 'Check') => void
} & BillHeaderProps

function BillDetails(props: BillDetailsProps) {
  const [localNote, setLocalNote] = useState('')

  const { savedPaymentMethods, getBillWithPaymentData } = useBatchBillStore(
    (state) => ({
      savedPaymentMethods: state.savedPaymentMethods,
      getBillWithPaymentData: state.getBillWithPaymentData,
    }),
  )

  const achOption = props.vendorPayoutMethods.find((x) => x.type === 'ACH') as
    | VendorPayoutMethod
    | undefined
  const checkOption = props.vendorPayoutMethods.find(
    (x) => x.type === 'CHECK',
  ) as VendorPayoutMethod | undefined
  const paymentData = getBillWithPaymentData(props.billId)

  const approvalPolicy = paymentData?.approvalPolicies?.at(0)

  const deliveryMethodToOption = (
    deliveryMethod: VendorPayoutMethod | undefined,
  ) => {
    if (!deliveryMethod) {
      return undefined
    }

    return {
      label: `${vendorPayoutMethodToIdentifier(deliveryMethod)} via ${
        deliveryMethod.type
      }`,
      value: deliveryMethod,
    }
  }

  return (
    <VFlex gap={4} py={5}>
      <BillHeader
        billNumber={props.billNumber}
        billAmount={props.billAmount}
        feeAmount={props.feeAmount}
        dueDate={props.dueDate}
        approvalPolicy={approvalPolicy}
      />
      <HFlex gap={5}>
        <BillDropdown<PaymentMethod>
          label="Payment Method"
          options={savedPaymentMethods.map((x) => ({
            label: getPaymentMethodString(x),
            value: x,
          }))}
          onChange={props.onChangePaymentMethod}
        />
        <BillDropdownDeliveryMethod
          currentlySelected={
            paymentData.deliveryMethod
              ? deliveryMethodToOption(paymentData.deliveryMethod)
              : {
                  label: 'Select a delivery method',
                  value: null,
                }
          }
          achOption={deliveryMethodToOption(achOption)}
          checkOption={deliveryMethodToOption(checkOption)}
          onChange={props.onChangeDeliveryMethod}
          onNewOption={props.onNewDeliveryMethod}
        />
        <BillDateSchedulerWithdrawal billId={props.billId} />
        <BillDateSchedulerEstDelivery billId={props.billId} />
      </HFlex>
      <Textarea
        value={localNote}
        placeholder="Type here to add a memo to your vendor for this bill"
        _placeholder={{
          color: 'gray.500',
          fontSize: 'xs',
        }}
        fontSize="xs"
        rows={1}
        border="none"
        color="gray.700"
        overflow="hidden"
        p={0}
        width="100%"
        resize="none"
        minH="20px"
        onInput={(e) => {
          e.currentTarget.style.height = 'auto'
          e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px'
        }}
        onBlur={() => props.onSaveVendorMemo(localNote)}
        onChange={(e) => setLocalNote(e.target.value)}
      />
    </VFlex>
  )
}

type VendorCardProps = {
  bills: Bill[]
  payoutMethods: VendorPayoutMethod[]
  setVendorEmails: (emails: string[]) => void
  onNewDeliveryMethod: (type: 'ACH' | 'Check') => void
} & VendorHeaderProps

function VendorCard({
  vendorName,
  readyToPay,
  vendorTotalAmount,
  vendorTotalFeeAmount,
  vendorEmails,
  payoutMethods,
  bills,
  setVendorEmails,
  errorTooltipText,
  onNewDeliveryMethod,
}: VendorCardProps) {
  const {
    setBillVendorMemo,
    setBillPaymentMethod,
    setBillDeliveryMethod,
    getBillWithPaymentData,
  } = useBatchBillStore((state) => ({
    setBillVendorMemo: state.setBillVendorMemo,
    setBillPaymentMethod: state.setBillPaymentMethod,
    setBillDeliveryMethod: state.setBillDeliveryMethod,
    getBillWithPaymentData: state.getBillWithPaymentData,
  }))

  return (
    <Card w="720px">
      <Box py={5} px={6}>
        <VendorHeader
          readyToPay={readyToPay}
          errorTooltipText={errorTooltipText}
          vendorName={vendorName}
          vendorTotalAmount={vendorTotalAmount}
          vendorTotalFeeAmount={vendorTotalFeeAmount}
          vendorEmails={vendorEmails}
          setVendorEmails={setVendorEmails}
        />
      </Box>
      <Divider />
      <Box px={6}>
        {bills.map((bill, idx) => {
          const paymentSummary = getBillWithPaymentData(
            bill.id,
          ).getPaymentSummary()

          const hasFees = paymentSummary.feeTotalCents() > 0

          return (
            <Fragment key={idx}>
              <BillDetails
                billId={bill.id}
                dueDate={moment(bill.billData?.dueDate).format('MMM D, YYYY')}
                billNumber={bill.billData?.reference || 'Unnamed Bill'}
                billAmount={paymentSummary.amountWithoutFeeString()}
                onNewDeliveryMethod={(type) => onNewDeliveryMethod(type)}
                feeAmount={
                  hasFees ? paymentSummary.feeTotalString() : undefined
                }
                onSaveVendorMemo={(memo: string) =>
                  setBillVendorMemo(bill.id, memo)
                }
                vendorPayoutMethods={payoutMethods}
                onChangePaymentMethod={(paymentMethod) => {
                  setBillPaymentMethod(bill.id, paymentMethod)
                }}
                onChangeDeliveryMethod={(deliveryMethod) => {
                  setBillDeliveryMethod(bill.id, deliveryMethod)
                }}
              />
              {idx !== bills.length - 1 && <Divider />}
            </Fragment>
          )
        })}
      </Box>
    </Card>
  )
}

type BatchBillPagePages = 'review' | 'success' | 'loading'

type BatchBillPayReviewProps = {
  totalBillsNum: number
  onNewDeliveryMethod: (vendorId: string, type: 'ACH' | 'Check') => void
}

function BatchBillPayReview(props: BatchBillPayReviewProps) {
  const {
    getBillsGroupedByVendorId,
    getVendorById,
    getVendorTotalAmountWithoutFees,
    getVendorTotalFeeAmount,
    setVendorEmails,
  } = useBatchBillStore((state) => ({
    getBillsGroupedByVendorId: state.getBillsGroupedByVendorId,
    getVendorById: state.getVendorById,
    getVendorTotalAmountWithoutFees: state.getVendorTotalAmountWithoutFees,
    getVendorTotalFeeAmount: state.getVendorTotalFeeAmount,
    setVendorEmails: state.setVendorEmails,
  }))

  return (
    <VFlex gap={6} pb={10}>
      <BatchBillReviewHeader numBills={props.totalBillsNum} />
      {Object.entries(getBillsGroupedByVendorId()).map(
        ([vendorId, bills], idx) => {
          const vendor = getVendorById(vendorId)
          const vendorTotalAmount = currency(
            getVendorTotalAmountWithoutFees(vendorId),
            { fromCents: true },
          ).format()
          const vendorTotalFeeAmount = currency(
            getVendorTotalFeeAmount(vendorId),
            { fromCents: true },
          )

          const hasFees = vendorTotalFeeAmount.value > 0

          const payoutMethods =
            vendor?.vendorPayoutMethods?.filter(
              (x): x is VendorPayoutMethod => !!x,
            ) || []

          const vendorEmails = vendor?.emails || []

          return (
            <VendorCard
              onNewDeliveryMethod={(type) =>
                props.onNewDeliveryMethod(vendorId, type)
              }
              key={idx}
              vendorName={vendor?.name || ''}
              vendorTotalAmount={vendorTotalAmount}
              vendorTotalFeeAmount={
                hasFees ? vendorTotalFeeAmount.format() : undefined
              }
              vendorEmails={vendor?.emails || []}
              readyToPay={payoutMethods.length > 0 && vendorEmails.length > 0}
              errorTooltipText={
                payoutMethods.length === 0
                  ? 'You must create a delivery method for this vendor'
                  : vendorEmails.length === 0
                  ? 'You must add at least one contact email for this vendor'
                  : undefined
              }
              payoutMethods={payoutMethods}
              bills={bills}
              setVendorEmails={(emails) => {
                for (const bill of bills) {
                  setVendorEmails(bill.id, emails)
                }
              }}
            />
          )
        },
      )}
    </VFlex>
  )
}

type VendorSuccessBillListProps = {
  vendorName: string
  vendorTotalAmount: string
  vendorTotalFeeAmount: string
  vendorEmails: string[]
  bills: Bill[]
}
function VendorSuccessBillList(props: VendorSuccessBillListProps) {
  const { getBillWithPaymentData } = useBatchBillStore((state) => ({
    getBillWithPaymentData: state.getBillWithPaymentData,
  }))

  const billsWithPaymentData = props.bills.map((bill) =>
    getBillWithPaymentData(bill.id),
  )

  return (
    <Card p={4} w="720px">
      <VFlex gap={4}>
        <VendorHeader
          readyToPay={props.vendorEmails.length > 0}
          vendorName={props.vendorName}
          vendorTotalAmount={props.vendorTotalAmount}
          vendorTotalFeeAmount={props.vendorTotalFeeAmount}
          vendorEmails={props.vendorEmails}
        />
        <Divider />
        {billsWithPaymentData.map((bill) => (
          <HFlex key={bill.bill.id} justifyContent="space-between" w="100%">
            <VFlex>
              <Text fontSize="sm" fontWeight="medium">
                {bill.bill.billData?.reference}
              </Text>
              <Text fontSize="13px" color="gray.500">
                {getPaymentMethodString(bill.paymentMethod!)}
              </Text>
            </VFlex>
            <VFlex>
              <Text
                fontSize="sm"
                fontWeight="medium"
                color="gray.800"
                textAlign="right"
              >
                {bill.getPaymentSummary().amountWithoutFeeString()}
              </Text>
              <Text fontSize="xs" color="gray.500" whiteSpace="nowrap">
                + {bill.getPaymentSummary().feeTotalString()}
              </Text>
            </VFlex>
          </HFlex>
        ))}
      </VFlex>
    </Card>
  )
}

function BatchBillPaySuccess() {
  const {
    bills,
    getTotalAmountCharged,
    getTotalFees,
    getTotalAmountChargedMinusFees,
    getBillsGroupedByVendorId,
    getVendorById,
    getVendorTotalAmountWithoutFees,
    getVendorTotalFeeAmount,
    getBillWithPaymentData,
  } = useBatchBillStore((state) => ({
    bills: state.bills,
    getTotalAmountCharged: state.getTotalAmountCharged,
    getTotalFees: state.getTotalFees,
    getTotalAmountChargedMinusFees: state.getTotalAmountChargedMinusFees,
    getBillsGroupedByVendorId: state.getBillsGroupedByVendorId,
    getVendorById: state.getVendorById,
    getVendorTotalAmountWithoutFees: state.getVendorTotalAmountWithoutFees,
    getVendorTotalFeeAmount: state.getVendorTotalFeeAmount,
    getBillWithPaymentData: state.getBillWithPaymentData,
  }))

  const billsGroupedByVendorId = getBillsGroupedByVendorId()
  const vendorCount = Object.keys(billsGroupedByVendorId).length

  const billsSentToApprovals = new Set(
    bills
      .filter((bill) => {
        return getBillWithPaymentData(bill.id).approvalPolicies.length > 0
      })
      .map((bill) => bill.id),
  )

  const billsPaidOrScheduled = bills.filter(
    (bill) => !billsSentToApprovals.has(bill.id),
  )

  return (
    <VFlex gap={6} pb={10}>
      <HStack>
        <NickelIcon
          name="checkCircleFull"
          className="fill-green-600 !h-[54px] !w-[54px]"
        />
        <Box>
          <Text fontSize="xl" color="gray.800" fontWeight="medium">
            You scheduled payments for {bills.length}{' '}
            {pluralize('bill', bills.length)}
          </Text>
          <Text fontSize="sm" fontWeight="medium" color="gray.500">
            Your {pluralize('vendor', vendorCount)} will receive an email once
            your payment is processed.
          </Text>
        </Box>
      </HStack>
      <Divider />
      <VFlex gap={5} py={5} px={6}>
        <Text fontSize="lg" fontWeight="medium" color="gray.800">
          Transaction Details
        </Text>
        <Card p={4} w="720px">
          <Flex flexDirection="column" gap={3}>
            <LineItem
              title="Payment Amount"
              subtitle={currency(getTotalAmountChargedMinusFees(), {
                fromCents: true,
              }).format()}
            />
            <LineItem
              title="Fees Total"
              subtitle={currency(getTotalFees(), {
                fromCents: true,
              }).format()}
            />
            <Divider />
            <LineItem
              bold
              icon="bolt"
              title="You will be charged"
              subtitle={currency(getTotalAmountCharged(), {
                fromCents: true,
              }).format()}
            />
          </Flex>
        </Card>
      </VFlex>
      <Box px={6}>
        <Divider />
      </Box>
      {billsPaidOrScheduled.length > 0 && (
        <VFlex gap={5} px={6} py={5}>
          <Text fontSize="lg" fontWeight="medium" color="gray.800">
            Bills Paid
          </Text>
          {Object.entries(billsGroupedByVendorId)
            .filter(([vendorId, bills]) => {
              return bills.some((bill) => !billsSentToApprovals.has(bill.id))
            })
            .map(([vendorId, bills], idx) => {
              const vendor = getVendorById(vendorId)
              const vendorTotalAmount = currency(
                getVendorTotalAmountWithoutFees(vendorId),
                { fromCents: true },
              ).format()
              const vendorTotalFeeAmount = currency(
                getVendorTotalFeeAmount(vendorId),
                { fromCents: true },
              ).format()

              return (
                <VendorSuccessBillList
                  key={idx}
                  vendorName={vendor?.name || ''}
                  vendorTotalAmount={vendorTotalAmount}
                  vendorTotalFeeAmount={vendorTotalFeeAmount}
                  vendorEmails={vendor?.emails || []}
                  bills={bills}
                />
              )
            })}
        </VFlex>
      )}
      {billsSentToApprovals.size > 0 && (
        <VFlex gap={5} px={6} py={5}>
          <Text fontSize="lg" fontWeight="medium" color="gray.800">
            Bills Sent to Approvals
          </Text>
          {Object.entries(billsGroupedByVendorId)
            .filter(([vendorId, bills]) => {
              return bills.some((bill) => billsSentToApprovals.has(bill.id))
            })
            .map(([vendorId, bills], idx) => {
              const vendor = getVendorById(vendorId)
              const vendorTotalAmount = currency(
                getVendorTotalAmountWithoutFees(vendorId),
                { fromCents: true },
              ).format()
              const vendorTotalFeeAmount = currency(
                getVendorTotalFeeAmount(vendorId),
                { fromCents: true },
              ).format()

              return (
                <VendorSuccessBillList
                  key={idx}
                  vendorName={vendor?.name || ''}
                  vendorTotalAmount={vendorTotalAmount}
                  vendorTotalFeeAmount={vendorTotalFeeAmount}
                  vendorEmails={vendor?.emails || []}
                  bills={bills}
                />
              )
            })}
        </VFlex>
      )}
    </VFlex>
  )
}

export function BatchBillPay() {
  const [param] = useSearchParams()
  const navigate = useNavigate()
  const billIds = param.get('billIds')
  const toast = useToast()

  const {
    bills,
    setSavedPaymentMethods,
    setBills,
    savedPaymentMethods,
    setBillPaymentMethod,
    setBillDeliveryMethod,
    getBillWithPaymentData,
    getTotalAmountCharged,
    getTotalFees,
    getTotalAmountChargedMinusFees,
    reset,
    getBillsByVendorId,
    setBillApprovalPolicies,
  } = useBatchBillStore((state) => ({
    bills: state.bills,
    setSavedPaymentMethods: state.setSavedPaymentMethods,
    setBills: state.setBills,
    setVendorEmails: state.setVendorEmails,
    savedPaymentMethods: state.savedPaymentMethods,
    setBillPaymentMethod: state.setBillPaymentMethod,
    setBillDeliveryMethod: state.setBillDeliveryMethod,
    getBillWithPaymentData: state.getBillWithPaymentData,
    getTotalAmountCharged: state.getTotalAmountCharged,
    getTotalFees: state.getTotalFees,
    getTotalAmountChargedMinusFees: state.getTotalAmountChargedMinusFees,
    reset: state.reset,
    getBillsByVendorId: state.getBillsByVendorId,
    setBillApprovalPolicies: state.setBillApprovalPolicies,
  }))

  const [batchBillPayPage, setBatchBillPayPage] =
    useState<BatchBillPagePages>('loading')
  const [newDeliveryMethodModalOpen, setNewDeliveryMethodModalOpen] =
    useState(false)

  const [vendorId, setVendorId] = useState<string | null>(null)
  const [type, setType] = useState<'ACH' | 'Check' | null>(null)

  const exitOverlay = () => {
    reset()
    navigate(PAY_VENDORS_BILLS_URL)
  }

  const getBillValidationErrors = (bill: Bill) => {
    const billNumber = bill.billData?.reference || bill?.vendor?.name
    const billWithVendorData = getBillWithPaymentData(bill.id)
    const paymentMethod = billWithVendorData.paymentMethod
    const deliveryMethod = billWithVendorData.deliveryMethod
    const vendorEmails = billWithVendorData.vendorEmails

    if (!paymentMethod) {
      return `Please select a payment method for ${billNumber}`
    }

    if (!deliveryMethod) {
      return `Please select a delivery method for ${billNumber}`
    }

    if (vendorEmails.length === 0) {
      return `Please add at least one email for ${bill.vendor?.name}`
    }

    return undefined
  }

  const validateBill = (
    bill: BillWithPaymentData,
  ): bill is ValidBillWithPaymentData => {
    return getBillValidationErrors(bill.bill) === undefined
  }

  const [batchBillPay, { loading: batchBillPayLoading }] = useMutation(
    BatchBillPayDocument,
    {
      onCompleted: (data) => {
        if (data.batchBillPay?.error?.message) {
          toast({
            title: 'Error',
            description: data.batchBillPay.error.message,
            status: 'error',
          })
          return
        }

        setBatchBillPayPage('success')
      },
    },
  )

  const { loading } = useQuery(BillsByIdDocument, {
    variables: {
      billIds: billIds ? billIds.split(',') : [],
    },
    skip: !billIds,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setBills(data?.billsById?.bills || [])
      setBatchBillPayPage('review')
    },
  })

  useQuery(CheckApprovalsDocument, {
    variables: {
      checkApprovalInput: bills
        .map((bill) => getBillWithPaymentData(bill.id))
        .filter((bill): bill is ValidBillWithPaymentData => validateBill(bill))
        .map((bill) => ({
          billId: bill.bill.id,
          paymentMethodId: bill.paymentMethod.id,
        })),
    },
    skip: bills.length === 0,
    onCompleted: (data) => {
      data?.checkApprovals?.billAndApprovalPolicies.map((bap) =>
        setBillApprovalPolicies(bap.bill.id, bap.approvalPolicies),
      )
    },
  })

  const { loading: paymentMethodsLoading } = useQuery(
    GetAllMerchantPaymentMethodsDocument,
    {
      onCompleted: (data) => {
        const paymentMethods =
          data?.getAllMerchantPaymentMethods?.paymentMethods

        setSavedPaymentMethods(paymentMethods || [])
      },
    },
  )

  useEffect(() => {
    if (bills.length > 0 && savedPaymentMethods.length > 0) {
      const defaultPaymentMethod = savedPaymentMethods[0]

      for (const bill of bills) {
        const deliveryMethod = bill.vendor?.vendorPayoutMethods?.at(0)

        setBillPaymentMethod(bill.id, defaultPaymentMethod)
        if (deliveryMethod) {
          setBillDeliveryMethod(bill.id, deliveryMethod)
        }
      }
    }
  }, [bills, savedPaymentMethods, setBillPaymentMethod, setBillDeliveryMethod])

  const totalBills = bills

  const totalBillsNum = totalBills.length

  const currentPageToComponent: Record<BatchBillPagePages, React.ReactNode> = {
    review: (
      <BatchBillPayReview
        totalBillsNum={totalBillsNum}
        onNewDeliveryMethod={(vendorId, type) => {
          setNewDeliveryMethodModalOpen(true)
          setVendorId(vendorId)
          setType(type)
        }}
      />
    ),
    success: <BatchBillPaySuccess />,
    loading: <Loading />,
  }

  const currentPageToFooterItems: Record<BatchBillPagePages, React.ReactNode> =
    {
      review: (
        <BatchBillPayFooter
          footerItems={[
            {
              label: 'Bills Total',
              value: currency(getTotalAmountChargedMinusFees(), {
                fromCents: true,
              }).format(),
            },
            {
              label: 'Fees Total',
              value: currency(getTotalFees(), { fromCents: true }).format(),
            },
            {
              label: "You'll be charged",
              value: currency(getTotalAmountCharged(), {
                fromCents: true,
              }).format(),
            },
          ]}
          label="Schedule Payments"
          onClick={() => {
            const billErrors = bills
              .map((bill) => getBillValidationErrors(bill))
              .filter(Boolean)

            if (billErrors.length > 0) {
              for (const error of billErrors) {
                toast({
                  title: 'Error',
                  description: error,
                  status: 'error',
                })
              }
              return
            }

            const validBills = bills
              .map((bill) => getBillWithPaymentData(bill.id))
              .filter((bill) => validateBill(bill))

            batchBillPay({
              variables: {
                batchBillPayInput: validBills.map((bill) => ({
                  billId: bill.bill.id,
                  merchantPaymentMethodId: bill.paymentMethod.id,
                  notificationEmails: bill.vendorEmails,
                  vendorId: bill.bill.vendor.id,
                  vendorPayoutMethodId: bill.deliveryMethod.id,
                  withdrawalDateEpochMillis: moment(
                    bill.withdrawalDate,
                  ).isAfter(moment(), 'days')
                    ? bill.withdrawalDate.getTime()
                    : undefined,
                  vendorMemo: bill.vendorMemo,
                })),
              },
            })
          }}
          isDisabled={false}
          loading={batchBillPayLoading}
          iconName="wallet"
          iconPosition="right"
        />
      ),
      success: (
        <BatchBillPayFooter
          footerItems={[]}
          onClick={exitOverlay}
          isDisabled={false}
          loading={false}
          label="Back to Dashboard"
        />
      ),
      loading: <></>,
    }

  return (
    <BatchLayout
      verticallyCentered={loading || paymentMethodsLoading}
      header={
        <Box>
          <Box px={10} py={5}>
            <Flex justify="flex-end" align="center" height="40px">
              <ExitButton onClick={exitOverlay} />
            </Flex>
          </Box>
        </Box>
      }
      footer={currentPageToFooterItems[batchBillPayPage]}
    >
      <NewDeliveryMethodModal
        isOpen={newDeliveryMethodModalOpen}
        setModalOpen={setNewDeliveryMethodModalOpen}
        type={type || 'ACH'}
        vendorId={vendorId || ''}
        onSave={(deliveryMethod) => {
          getBillsByVendorId(vendorId || '').forEach((bill) => {
            setBillDeliveryMethod(bill.id, deliveryMethod)
          })
          setNewDeliveryMethodModalOpen(false)
          setVendorId(null)
          setType(null)
        }}
      />
      <Fade
        key={batchBillPayPage}
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ width: '100%', height: '100%' }}
      >
        {currentPageToComponent[batchBillPayPage]}
      </Fade>
    </BatchLayout>
  )
}

import Column from '../Layout/Column'
import Row from '../Layout/Row'
import { Text } from '@chakra-ui/react'
import AccountSelectInput from '../Select/AccountSelect'
import BankRoutingNumberField from './BankRoutingNumberField'
import BankAccountNumberField from './BankAccountNumberField'
import { useEffect, useState } from 'react'

export type ACHFormProps = {
  bankName?: string | null
  accountSelectInputName?: string
  routingNumber: string
  accountNumber: string
  accountType?: string
  confirmedAccountNumber: string
  onChangeRoutingNumber: (routingNumber: string) => void
  onChangeAccountNumber: (accountNumber: string) => void
  onChangeConfirmAccountNumber: (accountNumber: string) => void
  onChangeAccountType?: (accountType: string) => void
  loading: boolean
  previewMode?: boolean
  isDemo?: boolean
  routingNumberLabel?: string
  accountNumberLabel?: string
}

export function ACHForm({
  onChangeRoutingNumber,
  onChangeAccountNumber,
  onChangeConfirmAccountNumber,
  accountSelectInputName,
  onChangeAccountType,
  bankName,
  accountType,
  loading,
  previewMode,
  isDemo,
  routingNumber,
  accountNumber,
  confirmedAccountNumber,
  routingNumberLabel,
  accountNumberLabel,
}: ACHFormProps) {
  const [validRoutingNumber, setValidRoutingNumber] = useState<boolean | null>(
    null,
  )
  const [validAccountNumber, setValidAccountNumber] = useState<boolean | null>(
    null,
  )

  const [accountNumberFieldTouched, setAccountNumberFieldTouched] =
    useState(false)
  const [
    confirmAccountNumberFieldTouched,
    SetConfirmAccountNumberFieldTouched,
  ] = useState(false)

  useEffect(() => {
    if (routingNumber.length === 9) {
      setValidRoutingNumber(true)
      onChangeRoutingNumber(routingNumber)
    } else if (routingNumber.length > 0) {
      setValidRoutingNumber(false)
    }
  }, [routingNumber])

  useEffect(() => {
    if (
      accountNumber &&
      accountNumber.length <= 17 &&
      accountNumber.length >= 5
    ) {
      setValidAccountNumber(true)
      if (isDemo) {
        onChangeAccountNumber(accountNumber)
        onChangeConfirmAccountNumber(accountNumber)
        setAccountNumberFieldTouched(true)
        SetConfirmAccountNumberFieldTouched(true)
      }
    } else if (accountNumber.length === 0) {
      setValidAccountNumber(null)
    } else {
      setValidAccountNumber(false)
    }
  }, [accountNumber])

  return (
    <Column grow y="center" wGrow>
      <Column gap="medium" className="pb-4" wGrow>
        {accountSelectInputName && (
          <Row gap="small" y="center" x="right" className="relative" grow>
            <Column wGrow gap="small">
              <Text className="text-sm font-medium text-gray-800">
                Bank Account Type
              </Text>
              <AccountSelectInput
                noBorder
                className="w-full border rounded"
                isClearable={false}
                initialValue={accountType || 'checking'}
                placeholder="Account Type"
                previewMode={previewMode}
                name={accountSelectInputName}
                containerHeight={'38px'}
                onChange={(e) => {
                  onChangeAccountType?.(e.value)
                }}
                options={[
                  {
                    label: 'Checking',
                    value: 'checking',
                  },
                  {
                    label: 'Savings',
                    value: 'savings',
                  },
                ]}
              />
            </Column>
          </Row>
        )}
        <Row grow between y="center" className="relative">
          <Column wGrow>
            <BankRoutingNumberField
              {...{
                label: routingNumberLabel || 'Bank Routing Number',
                placeholder: 'Enter Routing Number',
                id: 'tilled_iframe_bankRoutingNumber',
                value: routingNumber,
                bankName: bankName || null,
                previewMode: previewMode,
                isDisabled: isDemo ? true : false,
                onChange: (e) => {
                  const routingNumberTrimmed = e.target.value
                    .trim()
                    .replace(/[^0-9]/, '')
                    .substring(0, 9)

                  onChangeRoutingNumber(routingNumberTrimmed)
                },
                loading: loading,
              }}
              isInvalid={!validRoutingNumber}
              validated={validRoutingNumber !== null}
              error={
                !validRoutingNumber
                  ? 'Please enter a valid 9-digit routing number.'
                  : ''
              }
              _focus={{
                boxShadow: 'none',
                outline: 'none',
                borderColor: 'gray.300',
              }}
              _invalid={{
                borderColor: 'red.500',
                borderBottom: '1',
              }}
            />
          </Column>
        </Row>
      </Column>
      <Column gap="medium" wGrow>
        <Column grow between y="center" className="relative" wGrow>
          <BankAccountNumberField
            accountNumber={accountNumber}
            confirmedAccountNumber={confirmedAccountNumber}
            accountNumberLabel={accountNumberLabel}
            previewMode={previewMode}
            onBlurAccountNumber={() => {
              setAccountNumberFieldTouched(true)
            }}
            onBlurConfirmAccountNumber={() => {
              SetConfirmAccountNumberFieldTouched(true)
            }}
            error={
              !validAccountNumber && accountNumber !== ''
                ? 'Invalid Account Number'
                : confirmedAccountNumber !== accountNumber
                ? 'Account Number does not match'
                : ''
            }
            onChangeAccountNumber={(e: React.ChangeEvent<HTMLInputElement>) => {
              const accountNumber = e.target.value
                .replace(/[^0-9]/, '')
                .substring(0, 17)
              onChangeAccountNumber(accountNumber)
            }}
            onChangeConfirmAccountNumber={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => {
              const confirmedAccountNumber = e.target.value
                .replace(/[^0-9]/, '')
                .substring(0, 17)
              onChangeConfirmAccountNumber(confirmedAccountNumber)
            }}
            accountNumberInvalid={
              (!validAccountNumber && validAccountNumber !== null) ||
              (accountNumberFieldTouched && validAccountNumber === null)
            }
            confirmAccountNumberInvalid={
              confirmedAccountNumber !== '' &&
              confirmedAccountNumber !== accountNumber
            }
            validateAccountNumber={
              accountNumberFieldTouched && accountNumber.length > 0
            }
            validateConfirmAccountNumber={
              confirmAccountNumberFieldTouched &&
              confirmedAccountNumber.length > 0
            }
          />
        </Column>
      </Column>
    </Column>
  )
}

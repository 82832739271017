import { Icon, IconName, Row } from 'ui'

export type MultiSelectToastProps = {}

export type MultiSelectToastButtonProps = {
  icon?: IconName
  label: string
  onClick: () => void
  disabled?: boolean
  primary?: boolean
  grow?: boolean
}

export function MultiSelectToastButton({
  icon,
  label,
  onClick,
  disabled,
  primary,
  grow,
}: MultiSelectToastButtonProps) {
  return (
    <Row
      className={`px-2 py-1 rounded border cursor-pointer ${
        primary
          ? 'bg-sidebar-purple border-sidebar-purple hover:bg-sidebar-purple'
          : 'border-gray-200 hover:bg-gray-100'
      }`}
      y="center"
      x="center"
      gap="small"
      grow={grow}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <Icon
          name={icon}
          className={`w-3 h-3 ${
            primary ? 'stroke-white' : 'stroke-gray-400'
          } relative`}
        />
      )}
      <div className={`${primary ? 'text-white' : 'text-gray-900'} text-xs`}>
        {label}
      </div>
    </Row>
  )
}

type MultiSelectToastOptions = {
  buttons: MultiSelectToastButtonProps[]
}

export function getMultiSelectToastOptions({
  buttons,
}: MultiSelectToastOptions) {
  const toastOptions = {
    isClosable: false,
    duration: 9999999,
    containerStyle: {
      pb: 8,
      minWidth: '150px',
    },
    render: () => {
      return (
        <Row gap="small" y="center" className="border p-2 bg-white rounded">
          {buttons.map((button) => (
            <MultiSelectToastButton key={button.label} {...button} />
          ))}
        </Row>
      )
    },
  }

  return toastOptions
}

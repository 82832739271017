import { tw } from '@nickeltech/brise'
import {
  Button,
  OverlayHeaderWithStatus,
  OverlayTitle,
  Row,
  Separator,
  StatusPill,
} from 'ui'
import ContainerProps from 'ui/src/types'
import { Box, HStack, Link, VStack, Text, Flex, Spacer } from '@chakra-ui/react'
import {
  OrganizationEmployeesDocument,
  UpdateTeammateDocument,
  User,
  UserRole,
} from '../../operations-types'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import RoleSelectRadioGroup from '../ap/components/permissions/RoleSelectRadioGroup'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { Deactivate } from './Team'
import { ScrollableColumn } from 'ui'

type TeammateOverlayProps = {
  employee: User
  status: string
  onClose?: () => void
  onBack?: () => void
  className?: string
}

export const PERMISSIONS_URL =
  'https://www.notion.so/nickelhq/Permissions-6677c5ca4bd54e868bc1f54f8b21c238'

const OverlayHeader = tw.div<ContainerProps>`
  text-lg
  font-bold
  text-gray-800
`

function TeammateOverlay(props: TeammateOverlayProps) {
  const loggedInUser = useOutletContext() as User
  const loggedInUserRole = loggedInUser.role || UserRole.User
  const [newRole, setNewRole] = useState<UserRole>(
    props.employee.role || UserRole.User,
  )

  const [updateTeammate] = useMutation(UpdateTeammateDocument, {
    refetchQueries: [
      {
        query: OrganizationEmployeesDocument,
      },
    ],
  })

  const { getUser } = useLoggedInStore()

  const [openDeactivate, setOpenDeactivate] = useState(false)

  const DELETE_TEAMMATE_PERMITTED = getUser().isUserPermitted(
    UserRole.UserAdmin,
  )

  return (
    <>
      <OverlayHeaderWithStatus onBack={props.onBack} onClose={props.onClose}>
        <StatusPill status={props.status === 'Active' ? 'success' : 'info'}>
          {props.status}
        </StatusPill>
      </OverlayHeaderWithStatus>
      <Separator orientation="horizontal" />
      <ScrollableColumn className="w-full h-full pt-5">
        <Row className="w-full" y="center" between>
          <VStack alignItems="left" w="100%" spacing="0" gap="2">
            <OverlayTitle
              title={`${props.employee.firstName} ${props.employee.lastName}`}
              subtitle={`${props.employee.email}`}
              className="mx-8 pb-4"
            />
          </VStack>
        </Row>
        <Separator orientation="horizontal" />
        <VStack w="100%" pt="10" pb="10" gap="4" spacing="4" alignItems="left">
          <HStack w="100%" gap="0" spacing="0" px="8">
            <VStack spacing="0" gap="0" alignItems="left" w="100%">
              <Flex flexDirection="row" w="100%">
                <OverlayHeader>User Permissions</OverlayHeader>
                <Spacer />
                <Link
                  fontSize="md"
                  href={PERMISSIONS_URL}
                  color="purple.600"
                  target="_blank"
                >
                  Learn More
                  <ExternalLinkIcon m="1" mb="2" />
                </Link>{' '}
              </Flex>
            </VStack>
          </HStack>
          <Box w="100%" px="8">
            <RoleSelectRadioGroup
              value={''}
              selectedRole={newRole}
              currentRole={loggedInUserRole || 'USER'}
              onChange={(e: UserRole) => {
                if (getUser().isUserPermitted(e)) {
                  setNewRole(e)
                }
              }}
            >
              <></>
            </RoleSelectRadioGroup>
          </Box>
          <VStack alignItems="start" px="8" gap="0" spacing="0">
            <Text fontSize="sm">
              Have questions about permissions and which roles can perform what
              actions?
            </Text>
            <HStack spacing="0" gap="0">
              <Text fontSize="sm">
                Read our documentation to{' '}
                <Link
                  fontSize="sm"
                  href={PERMISSIONS_URL}
                  color="purple.600"
                  target="_blank"
                >
                  learn more
                  <ExternalLinkIcon m="1" mb="2" />
                </Link>{' '}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </ScrollableColumn>
      <Separator orientation="horizontal" />
      <Row grow y="center" x="right" spacing="medium" gap="medium">
        {DELETE_TEAMMATE_PERMITTED && (
          <Button
            isDisabled={false}
            status="secondary"
            variant="ghost"
            label="Remove"
            {...{
              onClick: async () => {
                setOpenDeactivate(true)
              },
            }}
          />
        )}
        <Button
          isDisabled={false}
          label="Update"
          {...{
            onClick: async () => {
              await updateTeammate({
                variables: {
                  userId: props.employee.id,
                  role: newRole,
                },
              })
              props.onClose?.()
            },
          }}
        />
      </Row>
      <Deactivate
        open={openDeactivate}
        teammate={props.employee}
        name={getUser().user.organization?.name || ''}
        setOpen={setOpenDeactivate}
        orgId={getUser().user.organization?.id || ''}
      />
    </>
  )
}

export default TeammateOverlay

import { ColorTextCell, TransactionStatusCell } from 'ui'
import {
  BankAccount,
  PaymentActivitiesQuery,
  VendorPayout,
} from '../../operations-types'
import { StatusCell } from '../payments/utils'
import currency from 'currency.js'
import {
  getBankAccountIdentifier,
  getPaymentMethodPayoutIdentifier,
  getPayoutMethodIdentifier,
  getTransactionStatusFromActivity,
} from '../../lib/utils'

type PaymentActivity = NonNullable<
  NonNullable<PaymentActivitiesQuery['paymentActivities']>['paymentActivities']
>[0]

type ActivityWithPayment = PaymentActivity & {
  payment: NonNullable<PaymentActivity['payment']>
}

type ActivityWithRefund = PaymentActivity & {
  refund: NonNullable<PaymentActivity['refund']>
}

type ActivityWithReturn = PaymentActivity & {
  achReturn: NonNullable<PaymentActivity['achReturn']>
}

type ActivityWithVendorReturn = PaymentActivity & {
  achVendorReturn: NonNullable<PaymentActivity['achVendorReturn']>
}

type ActivityWithBillPayment = PaymentActivity & {
  billPayment: NonNullable<PaymentActivity['billPayment']>
}

type ActivityWithChargeback = PaymentActivity & {
  chargeback: NonNullable<PaymentActivity['chargeback']>
}

type ActivityWithLoan = PaymentActivity & {
  loan: NonNullable<PaymentActivity['loan']>
}
type VendorPayoutMethod =
  ActivityWithBillPayment['billPayment']['vendorPayoutMethod']

type BankAccountForDashboard = Omit<BankAccount, 'id'>

export type PaymentActivityPresenter = {
  id: string
  activityId: string
  createdAt: number
  amount: ColorTextCell
  paymentMethod: {
    type: string
    identifier: string
    name: string
  }
  payoutMethod: {
    type: string
    identifier: string
    name: string
  }
  status: StatusCell
  type: StatusCell
  reference: {
    notes: string
    paymentSource: string
  }
  direction: 'left' | 'right'
  flags: Array<string>
  transactionStatus: TransactionStatusCell
  label: ColorTextCell
  originalAmount?: number
  note?: {
    flagged?: boolean | null
    reconciled?: boolean | null
    note?: string | null
  } | null
}

const statusMap: Record<string, StatusCell> = {
  PENDING: {
    label: 'Processing',
    status: 'info',
  },
  COMPLETED: {
    label: 'Completed',
    status: 'success',
  },
  FAILED: {
    label: 'Failed',
    status: 'error',
  },
  CANCELLED: {
    label: 'Canceled',
    status: 'error',
  },
  '': {
    label: 'Unknown',
    status: 'warning',
  },
}

const typeMap: Record<string, StatusCell> = {
  Receivable: {
    label: 'Receivable',
    status: 'success',
  },
  Loan: {
    label: 'Advance',
    status: 'success',
  },
  Refund: {
    label: 'Refund',
    status: 'warning',
  },
  Return: {
    label: 'ACH Return',
    status: 'error',
  },
  Chargeback: {
    label: 'Chargeback',
    status: 'error',
  },
  VendorReturn: {
    label: 'Vendor ACH Return',
    status: 'error',
  },
  Payable: {
    label: 'Payable',
    status: 'success',
  },
  '': {
    label: 'Unknown',
    status: 'warning',
  },
}

function transformPaymentIntoPresenter(
  paymentActivity: ActivityWithPayment,
  defaultBankAccount: BankAccountForDashboard | null,
): PaymentActivityPresenter {
  const loan = paymentActivity.payment.answerForm?.questionForm?.loans?.find(
    (loan) => loan.funded === true,
  )

  const submittedAmountInCents =
    paymentActivity.payment.submittedAmountInCents || 0

  const amount = !loan
    ? submittedAmountInCents || 0
    : submittedAmountInCents -
        ((loan?.principalAmountInCents || 0) + (loan?.feeAmountInCents || 0)) ||
      0

  return {
    id: paymentActivity.payment.id,
    createdAt: paymentActivity.createdAt,
    amount: {
      label: currency(amount || 0, {
        fromCents: true,
      }).format(),
      color: 'gray.500',
    },
    paymentMethod: getPaymentMethodPayoutIdentifier(
      paymentActivity.payment.paymentMethod || null,
    ),
    status: statusMap[paymentActivity.status?.toUpperCase() || ''],
    type: typeMap['Receivable'],
    reference: {
      notes:
        paymentActivity.payment.answerForm?.questionForm?.name ||
        paymentActivity.payment.notes ||
        '',
      paymentSource: !!paymentActivity.payment.answerForm?.id
        ? `${paymentActivity.payment.answerForm?.questionForm?.id}`
        : 'Portal',
    },
    payoutMethod: paymentActivity.payout?.payoutMethod
      ? getPayoutMethodIdentifier(paymentActivity?.payout?.payoutMethod)
      : getBankAccountIdentifier(defaultBankAccount),
    direction: 'left',
    flags: paymentActivity.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      paymentActivity.status,
      paymentActivity.flags || [],
    ),
    label: {
      label: 'RECEIVABLE',
      color: 'gray.500',
    },
    activityId: paymentActivity.id,
    note: paymentActivity.note,
  }
}

function transformRefundIntoPresenter(
  refundActivity: ActivityWithRefund,
  defaultBankAccount: BankAccountForDashboard | null,
): PaymentActivityPresenter {
  return {
    id: refundActivity.refund.id,
    createdAt: refundActivity.createdAt,
    amount: {
      label: currency(refundActivity.refund.amountCents || 0, {
        fromCents: true,
      }).format(),
      color: 'yellow.600',
    },
    paymentMethod: getPaymentMethodPayoutIdentifier(
      refundActivity.refund.charge.paymentMethod || null,
    ),
    status: statusMap[refundActivity.status?.toUpperCase() || ''],
    type: typeMap['Refund'],
    reference: {
      notes: refundActivity.refund.charge.payment?.notes || '',
      paymentSource: '',
    },
    payoutMethod: refundActivity.payout?.payoutMethod
      ? getPayoutMethodIdentifier(refundActivity?.payout?.payoutMethod)
      : getBankAccountIdentifier(defaultBankAccount),
    direction: 'right',
    flags: refundActivity.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      refundActivity.status,
      refundActivity.flags || [],
    ),
    label: {
      label: 'REFUND',
      color: 'yellow.600',
    },
    originalAmount: currency(
      refundActivity.refund.charge.amountCapturedInCents || 0,
      {
        fromCents: true,
      },
    ).value,
    activityId: refundActivity.id,
    note: refundActivity.note,
  }
}

function transformLoanIntoPresenter(
  activityWithLoan: ActivityWithLoan,
  defaultBankAccount: BankAccountForDashboard | null,
) {
  return {
    id: activityWithLoan.loan.id,
    createdAt: activityWithLoan.createdAt,
    amount: {
      label: currency(activityWithLoan.loan.principalAmountInCents || 0, {
        fromCents: true,
      }).format(),
      color: 'gray.500',
    },
    paymentMethod: {
      type: 'bankAccount',
      name: 'Nickel',
      identifier: `N/A`,
    },

    status: statusMap[activityWithLoan.status?.toUpperCase() || ''],
    type: typeMap['Loan'],
    reference: {
      notes: activityWithLoan.loan.questionForm?.name || '',
      paymentSource: '',
    },
    payoutMethod: getBankAccountIdentifier(defaultBankAccount),
    direction: 'left',
    flags: activityWithLoan.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      activityWithLoan.status,
      activityWithLoan.flags || [],
    ),
    label: {
      label: 'ADVANCE',
      color: 'gray.500',
    },
    activityId: activityWithLoan.id,
    note: activityWithLoan.note || '',
  }
}

function transformChargebackIntoPresenter(
  activityWithReturn: ActivityWithChargeback,
  defaultBankAccount: BankAccountForDashboard | null,
) {
  return {
    id: activityWithReturn.chargeback.id,
    createdAt: activityWithReturn.createdAt,
    amount: {
      label: currency(
        activityWithReturn.chargeback.charge.amountCapturedInCents || 0,
        {
          fromCents: true,
        },
      ).format(),
      color: 'red.600',
    },
    paymentMethod: getPaymentMethodPayoutIdentifier(
      activityWithReturn.chargeback.charge.paymentMethod || null,
    ),
    status: statusMap[activityWithReturn.status?.toUpperCase() || ''],
    type: typeMap['Chargeback'],
    reference: {
      notes: activityWithReturn.chargeback.charge.payment?.notes || '',
      paymentSource: '',
    },
    payoutMethod: activityWithReturn.payout?.payoutMethod
      ? getPayoutMethodIdentifier(activityWithReturn?.payout?.payoutMethod)
      : getBankAccountIdentifier(defaultBankAccount),
    direction: 'right',
    flags: activityWithReturn.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      activityWithReturn.status,
      activityWithReturn.flags || [],
    ),
    label: {
      label: 'CHARGEBACK',
      color: 'red.600',
    },
    activityId: activityWithReturn.id,
    note: activityWithReturn.note,
  }
}

function transformReturnIntoPresenter(
  activityWithReturn: ActivityWithReturn,
  defaultBankAccount: BankAccountForDashboard | null,
): PaymentActivityPresenter {
  return {
    id: activityWithReturn.achReturn.id,
    createdAt: activityWithReturn.createdAt,
    amount: {
      label: currency(
        activityWithReturn.achReturn.charge.amountCapturedInCents || 0,
        {
          fromCents: true,
        },
      ).format(),
      color: 'red.600',
    },
    paymentMethod: getPaymentMethodPayoutIdentifier(
      activityWithReturn.achReturn.charge.paymentMethod || null,
    ),
    status: statusMap[activityWithReturn.status?.toUpperCase() || ''],
    type: typeMap['Return'],
    reference: {
      notes: activityWithReturn.achReturn.charge.payment?.notes || '',
      paymentSource: '',
    },
    payoutMethod: activityWithReturn.payout?.payoutMethod
      ? getPayoutMethodIdentifier(activityWithReturn?.payout?.payoutMethod)
      : getBankAccountIdentifier(defaultBankAccount),
    direction: 'right',
    flags: activityWithReturn.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      activityWithReturn.status,
      activityWithReturn.flags || [],
    ),
    label: {
      label: 'RETURN',
      color: 'red.600',
    },
    activityId: activityWithReturn.id,
    note: activityWithReturn.note,
  }
}

function transformVendorReturnIntoPresenter(
  activityWithVendorReturn: ActivityWithVendorReturn,
  defaultBankAccount: BankAccountForDashboard | null,
): PaymentActivityPresenter {
  return {
    id: activityWithVendorReturn.achVendorReturn.id,
    createdAt: activityWithVendorReturn.createdAt,
    amount: {
      label: currency(
        activityWithVendorReturn.achVendorReturn.vendorPayout?.charges?.[0]
          ?.billPayment?.submittedAmountInCents || 0,
        {
          fromCents: true,
        },
      ).format(),
      color: 'red.600',
    },
    paymentMethod: {
      type: 'bankAccount',
      identifier: getVendorPayoutMethodIdentifier(
        activityWithVendorReturn.achVendorReturn.vendorPayout?.charges?.[0]
          ?.billPayment?.vendorPayoutMethod,
      ),
      name:
        activityWithVendorReturn.achVendorReturn.vendorPayout?.charges?.[0]
          ?.billPayment?.billPayable.vendor.name || '',
    },
    status: statusMap[activityWithVendorReturn.status?.toUpperCase() || ''],
    type: typeMap['VendorReturn'],
    reference: {
      notes:
        activityWithVendorReturn.billPayment?.billPayable.bill?.billData
          ?.reference || '',
      paymentSource: '',
    },
    payoutMethod: activityWithVendorReturn.payout?.payoutMethod
      ? getPayoutMethodIdentifier(
          activityWithVendorReturn?.payout?.payoutMethod,
        )
      : getBankAccountIdentifier(defaultBankAccount),
    direction: 'left',
    flags: activityWithVendorReturn.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      activityWithVendorReturn.status,
      activityWithVendorReturn.flags || [],
    ),
    label: {
      label: 'PAYABLE RETURN',
      color: 'red.600',
    },
    activityId: activityWithVendorReturn.id,
    note: activityWithVendorReturn.note,
  }
}

function getVendorPayoutMethodIdentifier(
  vendorPayoutMethod: VendorPayoutMethod,
  vendorPayout?: VendorPayout,
) {
  if (!vendorPayoutMethod) {
    return 'Unknown'
  }

  if (vendorPayoutMethod.type === 'CHECK') {
    return `${
      vendorPayout
        ? `${vendorPayout.checkNumber}`
        : `${vendorPayoutMethod.city}, ${vendorPayoutMethod.state}`
    }`
  }
  return `${vendorPayoutMethod.bankName} ·· ${
    vendorPayoutMethod.accountNumber?.slice(-2) || ''
  }`
}

function transformBillPaymentIntoPresenter(
  activityWithBillPayment: ActivityWithBillPayment,
): PaymentActivityPresenter {
  const deliveryMethod =
    activityWithBillPayment.billPayment.vendorPayoutMethod?.type
  return {
    id: activityWithBillPayment.billPayment.id,
    createdAt: activityWithBillPayment.createdAt,
    amount: {
      label: currency(activityWithBillPayment.billPayment.amountInCents || 0, {
        fromCents: true,
      }).format(),
      color: 'gray.500',
    },
    payoutMethod: getPaymentMethodPayoutIdentifier(
      activityWithBillPayment.billPayment.paymentMethod || null,
    ),
    status: statusMap[activityWithBillPayment.status?.toUpperCase() || ''],
    type: typeMap['Payable'],
    reference: {
      notes:
        activityWithBillPayment.billPayment.billPayable.bill?.internalNote ||
        activityWithBillPayment.billPayment.billPayable.bill?.billData
          ?.reference ||
        '',
      paymentSource: '',
    },
    paymentMethod: {
      type: deliveryMethod === 'ACH' ? 'bankAccount' : 'checkDeposit',
      identifier: getVendorPayoutMethodIdentifier(
        activityWithBillPayment.billPayment.vendorPayoutMethod,
        activityWithBillPayment.billPayment.charges?.at(0)?.vendorPayout ||
          undefined,
      ),
      name: activityWithBillPayment.billPayment?.billPayable.vendor?.name || '',
    },
    direction: 'right',
    flags: activityWithBillPayment.flags || [],
    transactionStatus: getTransactionStatusFromActivity(
      activityWithBillPayment.status,
      activityWithBillPayment.flags || [],
    ),
    label: {
      label: 'PAYABLE',
      color: 'gray.500',
    },
    activityId: activityWithBillPayment.id,
    note: activityWithBillPayment.note,
  }
}

function validPayment(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithPayment {
  return !!paymentActivity.payment
}

function validRefund(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithRefund {
  return !!paymentActivity.refund
}

function validReturn(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithReturn {
  return !!paymentActivity.achReturn
}

function validBillPayment(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithBillPayment {
  return !!paymentActivity.billPayment
}

function validChargeback(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithChargeback {
  return !!paymentActivity.chargeback
}

function validVendorReturn(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithVendorReturn {
  return !!paymentActivity.achVendorReturn
}

function validLoan(
  paymentActivity: PaymentActivity,
): paymentActivity is ActivityWithLoan {
  return !!paymentActivity.loan
}

export function transformTransactionData(
  paymentActivities: PaymentActivity[],
  defaultBankAccount: BankAccountForDashboard | null,
) {
  return paymentActivities.map((paymentActivity) => {
    const refund = paymentActivity?.refund
    const payment = paymentActivity?.payment
    const achReturn = paymentActivity?.achReturn
    const billPayment = paymentActivity?.billPayment
    const achVendorReturn = paymentActivity?.achVendorReturn
    const chargeback = paymentActivity?.chargeback
    const loan = paymentActivity?.loan

    if (
      [
        refund,
        payment,
        achReturn,
        billPayment,
        achVendorReturn,
        chargeback,
        loan,
      ].every((x) => !x)
    ) {
      console.error('No payment activity found')
      return []
    }

    if (validPayment(paymentActivity)) {
      return transformPaymentIntoPresenter(paymentActivity, defaultBankAccount)
    }

    if (validRefund(paymentActivity)) {
      return transformRefundIntoPresenter(paymentActivity, defaultBankAccount)
    }

    if (validReturn(paymentActivity)) {
      return transformReturnIntoPresenter(paymentActivity, defaultBankAccount)
    }

    if (validBillPayment(paymentActivity)) {
      return transformBillPaymentIntoPresenter(paymentActivity)
    }

    if (validVendorReturn(paymentActivity)) {
      return transformVendorReturnIntoPresenter(
        paymentActivity,
        defaultBankAccount,
      )
    }

    if (validChargeback(paymentActivity)) {
      return transformChargebackIntoPresenter(
        paymentActivity,
        defaultBankAccount,
      )
    }

    if (validLoan(paymentActivity)) {
      return transformLoanIntoPresenter(paymentActivity, defaultBankAccount)
    }

    return []
  })
}

import { Button, HFlex, Separator } from 'ui'

import {
  Card,
  CardBody,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import * as yup from 'yup'
import { useAddVendorStore } from './AddVendorStore'
import { AddressInput, Form, FormikValidatingInput, VFlex } from 'ui'
import AddVendorPayoutDetailsForm from './AddVendorPayoutDetailsForm'
import { VendorEmails } from '../../bills/components/VendorEmails'
import { VendorType } from '../../../operations-types'

const ManualAddVendorSchema = yup.object({
  vendorName: yup.string().required('Vendor name is required'),
  vendorEmails: yup
    .array()
    .of(yup.string().required())
    .min(1, 'At least one contact email is required')
    .required()
    .transform((value) => value?.filter(Boolean)),
  recipientAddress: yup.object({
    streetAddress: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string(),
  }),
  vendorType: yup.mixed<VendorType>().optional(),
})

type ManualAddVendorFormData = yup.InferType<typeof ManualAddVendorSchema>

type ManualVendorDetailsFormProps = {
  onCancel: () => void
  onSubmit: (values: ManualAddVendorFormData) => void
  loading: boolean
}

export const VendorTypeOptions = [
  { label: 'Vendor is a business', value: VendorType.Business },
  { label: 'Vendor is an individual', value: VendorType.Individual },
]

type VendorTypeRadioGroupProps = {
  value: VendorType
  onChange: (value: VendorType) => void
}

export const VendorTypeRadioGroup = ({
  value,
  onChange,
}: VendorTypeRadioGroupProps) => {
  return (
    <RadioGroup value={value} onChange={onChange} w="100%">
      <FormLabel py={0}>Vendor Type (optional)</FormLabel>
      <HFlex gap={4} width="100%" justifyContent="space-between">
        {VendorTypeOptions.map((option) => (
          <Card
            key={option.value}
            border="1.5px solid"
            borderColor={value === option.value ? 'gray.800' : 'gray.200'}
            borderRadius="4px"
            onClick={() => onChange(option.value)}
            cursor="pointer"
            w="100%"
          >
            <CardBody p="3">
              <HFlex gap={4} width="100%" justifyContent="space-between">
                <VStack align="start" spacing={1}>
                  <Text
                    color={value === option.value ? 'gray.800' : 'gray.500'}
                  >
                    {option.label}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {option.value === VendorType.Business
                      ? 'Corporation, LLC, etc.'
                      : 'Contractor or freelancer'}
                  </Text>
                </VStack>
                <Radio
                  isChecked={value === option.value}
                  colorScheme="dark"
                  key={option.value}
                  value={option.value}
                  border="2px solid"
                  borderColor="gray.200"
                  p={4}
                  sx={{
                    '.chakra-radio__control': {
                      order: 2,
                      marginLeft: 'auto',
                      marginRight: 0,
                    },
                    '.chakra-radio__label': {
                      order: 1,
                      marginRight: 'auto',
                      marginLeft: 0,
                    },
                  }}
                />
              </HFlex>
            </CardBody>
          </Card>
        ))}
      </HFlex>
    </RadioGroup>
  )
}

export function ManualVendorDetailsForm({
  onCancel,
  onSubmit,
  loading,
}: ManualVendorDetailsFormProps) {
  const { manualVendorDetails, setManualVendorDetails } = useAddVendorStore(
    (state) => ({
      manualVendorDetails: state.manualVendorDetails,
      setManualVendorDetails: state.setManualVendorDetails,
    }),
  )

  const toast = useToast()

  return (
    <>
      <Form<ManualAddVendorFormData>
        initialValues={{
          vendorName: manualVendorDetails?.vendorName || '',
          vendorEmails: manualVendorDetails?.vendorEmails || [],
          recipientAddress: {
            streetAddress: manualVendorDetails?.recipientAddress?.street1 || '',
            city: manualVendorDetails?.recipientAddress?.city || '',
            state: manualVendorDetails?.recipientAddress?.state || '',
            zipCode: manualVendorDetails?.recipientAddress?.zipCode || '',
          },
          vendorType: manualVendorDetails?.vendorType,
        }}
        validationSchema={ManualAddVendorSchema}
        onSubmit={(values) => {
          onSubmit(values)
        }}
        className="h-full"
      >
        {(formik) => (
          <VFlex justifyContent="space-between" w="100%" h="100%" gap={8}>
            <VStack gap={4} px={8} alignItems="start">
              <VStack spacing={0} alignItems="start">
                <Text fontSize="xl" fontWeight="semibold" color="gray.800">
                  Basic Information
                </Text>
                <Text fontSize="sm" color="gray.500">
                  Tell us about the vendor you're trying to pay
                </Text>
              </VStack>
              <FormikValidatingInput
                fieldName="vendorName"
                label="Vendor Name"
                placeholder="Enter vendor name"
              />

              <VendorTypeRadioGroup
                value={formik.values.vendorType}
                onChange={(value) => {
                  if (formik) formik.setFieldValue('vendorType', value)
                }}
              />
              <AddressInput
                valueKey="recipientAddress"
                apiKey={import.meta.env.VITE_SMARTY_STREETS_KEY}
                label="Billing Address (optional)"
                onChangeAddress={(address: string) => {}}
                onChangeCity={(city: string) => {}}
                onChangeState={(state: string) => {}}
                onChangeZip={(zip: string) => {}}
              />
            </VStack>
            <Separator orientation="horizontal" />
            <VStack gap={4} px={8} alignItems="start">
              <VStack spacing={0} alignItems="start">
                <Text fontSize="xl" fontWeight="semibold" color="gray.800">
                  Vendor Contact
                </Text>
                <Text fontSize="sm" color="gray.500">
                  Add contacts to receive payment receipts
                </Text>
              </VStack>
              <VendorEmails
                vendorEmails={formik.values.vendorEmails}
                setVendorEmails={(emails: string[]) => {
                  formik.setFieldValue('vendorEmails', emails)
                }}
              />
            </VStack>
            <Separator orientation="horizontal" />
            <AddVendorPayoutDetailsForm
              manualVendorDetails={manualVendorDetails}
              setManualVendorDetails={setManualVendorDetails}
              onContinue={() => {}}
              onBack={() => {}}
            />
            <VStack spacing={0} mt="auto" w="100%" alignItems="start">
              <Separator orientation="horizontal" />
              <HFlex px={8} pt={4} gap={4} justify="space-between" width="100%">
                <Button label="Go back" variant="outline" onClick={onCancel} />
                <Button
                  label="Create"
                  isLoading={loading}
                  onClick={() => {
                    if (formik.isValid) {
                      formik.submitForm()
                    } else {
                      Object.values(formik.errors).forEach((error) => {
                        toast({
                          title: 'Error',
                          description: error.toString(),
                          status: 'error',
                        })
                      })
                    }
                  }}
                  iconPosition="right"
                  iconName="arrowRight"
                />
              </HFlex>
            </VStack>
          </VFlex>
        )}
      </Form>
    </>
  )
}

export default ManualVendorDetailsForm

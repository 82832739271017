import {
  Divider,
  Input,
  Select,
  Spacer,
  Text,
  Box,
  FormLabel,
} from '@chakra-ui/react'
import {
  calculatePaymentSummaryForMerchant,
  Form,
  SlimVendorCard,
  VFlex,
  Button,
  DatePickerCardInput,
  HFlex,
  PriceInput,
  BillOverlayHeader,
  dateIsFutureAndNotWeekend,
  getBusinessDay,
} from 'ui'
import * as yup from 'yup'
import { useBillStore } from '../../stores/billStore'
import currency from 'currency.js'
import { useState } from 'react'
import {
  Vendor,
  RecurringBillFrequency,
  GetQuickBooksExpenseAccountsAndItemsDocument,
} from '../../../operations-types'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { VendorSelect } from '../../ap/NewBillPage'
import { useQuery } from '@apollo/client'
import { useCodatLinkedConnection } from '../../utils/CodatUtils'
import { useLoggedInStore } from '../../layout/LoggedInStore'
import { Category, Item, LineItem } from 'ui/src/types'
import { CategoriesAndItemsSection } from './CategoriesAndItemsSection'

const RecurringBillSchema = yup.object({
  amountCents: yup.number().min(1).required('Amount is required'),
  reason: yup.string().required('Reason is required'),
  schedule: yup
    .mixed<RecurringBillFrequency>()
    .oneOf(Object.values(RecurringBillFrequency))
    .required('Schedule is required'),
  vendorId: yup.string().required('Vendor is required'),
  startDate: yup.date().required('Start date is required'),
  endDate: yup.date().required('End date is required'),
  lineItems: yup.array().when('$hasLinkedConnection', {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        id: yup.string().required('Selection is required'),
        type: yup.string().oneOf(['category', 'item']).required(),
        lineDetails: yup.object().shape({
          amountCents: yup
            .number()
            .min(1, 'Amount is required')
            .required('Amount is required'),
          name: yup.string().required('Name is required'),
          description: yup.string(),
          rate: yup.string().when('type', {
            is: 'item',
            then: yup.string().required('Rate is required'),
          }),
          quantity: yup.string().when('type', {
            is: 'item',
            then: yup.string().required('Quantity is required'),
          }),
        }),
      }),
    ),
  }),
})

export type RecurringBillFormData = yup.InferType<typeof RecurringBillSchema>

const RecurringBillScheduleOptions = [
  { value: RecurringBillFrequency.Monthly, label: 'Every month' },
  { value: RecurringBillFrequency.Weekly, label: 'Every week' },
  { value: RecurringBillFrequency.Quarterly, label: 'Every quarter' },
  { value: RecurringBillFrequency.Yearly, label: 'Every year' },
]

type CreateRecurringBillProps = {
  setOpen: (open: boolean) => void
}

type PaymentSchedulePreviewProps = {
  startDate: Date | string
  endDate: Date | string
  schedule?: RecurringBillFrequency
}

type DateIncrement = {
  unit: moment.unitOfTime.DurationConstructor
  amount: number
}

export function PaymentSchedulePreview({
  startDate,
  endDate,
  schedule = RecurringBillFrequency.Monthly,
}: PaymentSchedulePreviewProps) {
  const getDateIncrement = (): DateIncrement => {
    switch (schedule) {
      case RecurringBillFrequency.Weekly:
        return { unit: 'weeks', amount: 1 }
      case RecurringBillFrequency.Monthly:
        return { unit: 'months', amount: 1 }
      case RecurringBillFrequency.Quarterly:
        return { unit: 'months', amount: 3 }
      case RecurringBillFrequency.Yearly:
        return { unit: 'years', amount: 1 }
      default:
        return { unit: 'months', amount: 1 }
    }
  }

  const { unit, amount } = getDateIncrement()

  const calculateNumberOfBills = (): number => {
    const start = moment(startDate)
    const end = moment(endDate)

    if (!start.isValid() || !end.isValid()) {
      return 0
    }

    if (end.isBefore(start)) {
      return 0
    }

    // For quarterly, we need to calculate the number of quarters
    if (schedule === RecurringBillFrequency.Quarterly) {
      // Calculate months between dates
      const monthsDiff = end.diff(start, 'months')
      // Divide by 3 (months per quarter) and round up to include partial quarters
      return Math.floor(monthsDiff / 3) + 1
    }

    // For yearly, calculate years between
    if (schedule === RecurringBillFrequency.Yearly) {
      const yearsDiff = end.diff(start, 'years', true)
      return Math.floor(yearsDiff) + 1
    }

    // For weekly, calculate weeks between
    if (schedule === RecurringBillFrequency.Weekly) {
      const weeksDiff = end.diff(start, 'weeks')
      return weeksDiff + 1 // +1 to include the start date
    }

    // For monthly
    const monthsDiff = end.diff(start, 'months')
    return monthsDiff + 1 // +1 to include the start date
  }

  const totalBills = calculateNumberOfBills()

  // Determine how many bills to show in preview (max 3)
  const billsToShow = Math.min(totalBills, 3)

  // Only show "..." and final bill if we have more than 3 bills total
  const showEllipsis = totalBills > 3

  return (
    <VFlex gap="4">
      <Text fontWeight="medium" color="gray.700">
        Payment schedule preview
      </Text>
      <Box overflowX="auto">
        <HFlex gap="4" pb="2" minW="max-content">
          {Array.from({ length: billsToShow }).map((_, index) => (
            <VFlex key={index} alignItems="center">
              <VFlex
                p="4"
                bgColor="gray.100"
                borderRadius="md"
                minW="100px"
                alignItems="center"
              >
                <Text fontWeight="medium">
                  {moment(startDate)
                    .add(index * amount, unit)
                    .format('MMM D')}
                </Text>
                <Text color="gray.500">
                  {moment(startDate)
                    .add(index * amount, unit)
                    .format('YYYY')}
                </Text>
              </VFlex>
              <Text fontSize="sm" mt="2">
                Bill {index + 1}
              </Text>
            </VFlex>
          ))}

          {showEllipsis && totalBills - billsToShow - 1 > 1 && (
            <VFlex gap="2" alignItems="center">
              <VFlex
                p="4"
                bgColor="gray.100"
                borderRadius="md"
                minW="100px"
                alignItems="center"
              >
                <Text fontWeight="medium">...</Text>
                <Text color="gray.500">
                  {totalBills - billsToShow - 1} Bills
                </Text>
              </VFlex>
            </VFlex>
          )}

          {showEllipsis && (
            <VFlex alignItems="center">
              <VFlex
                p="4"
                bgColor="gray.100"
                borderRadius="md"
                minW="100px"
                alignItems="center"
              >
                <Text fontWeight="medium">
                  {moment(startDate)
                    .add((totalBills - 1) * amount, unit)
                    .format('MMM D')}
                </Text>
                <Text color="gray.500">
                  {moment(startDate)
                    .add((totalBills - 1) * amount, unit)
                    .format('YYYY')}
                </Text>
              </VFlex>
              <Text fontSize="sm" mt="2">
                Final Bill
              </Text>
            </VFlex>
          )}

          {totalBills === 0 && (
            <VFlex alignItems="center">
              <VFlex
                p="4"
                bgColor="gray.100"
                borderRadius="md"
                minW="200px"
                alignItems="center"
              >
                <Text fontWeight="medium" color="gray.600">
                  No bills in selected period
                </Text>
              </VFlex>
            </VFlex>
          )}
        </HFlex>
      </Box>
    </VFlex>
  )
}

function RecurringBillForm() {
  const { getUser } = useLoggedInStore((state) => ({
    getUser: state.getUser,
  }))
  const { hasLinkedConnection } = useCodatLinkedConnection(
    getUser().organization.accountInfo ?? null,
  )
  const { data: expenseAccountsAndItems } = useQuery(
    GetQuickBooksExpenseAccountsAndItemsDocument,
    {
      skip: !hasLinkedConnection,
    },
  )

  const categories =
    expenseAccountsAndItems?.getQuickBooksExpenseAccountsAndItems?.accounts
  const items =
    expenseAccountsAndItems?.getQuickBooksExpenseAccountsAndItems?.items

  const categoryLines: Category[] =
    categories?.map((category) => ({
      id: category.id,
      type: 'category' as const,
      lineDetails: {
        amountCents: '0',
        name: category.name,
        description: category.description || '',
      },
    })) || []

  const itemLines: Item[] =
    items?.map((item) => ({
      id: item.id,
      type: 'item' as const,
      lineDetails: {
        amountCents: '0',
        name: item.name,
        description: item.description || '',
        quantity: '0',
        rate: item.unitPrice.toString(),
      },
    })) || []
  const { setFieldValue, values, handleChange } =
    useFormikContext<RecurringBillFormData>()

  const getAvailableOptions = (
    currentLineItem: LineItem,
    allLineItems: LineItem[],
  ) => {
    const selectedIds = allLineItems
      ?.filter((item) => item?.id && item.id !== currentLineItem.id)
      .map((item) => item.id)

    return {
      availableCategories:
        categoryLines?.filter(
          (category) => !selectedIds.includes(category.id),
        ) || [],
      availableItems:
        itemLines?.filter((item) => !selectedIds.includes(item.id)) || [],
    }
  }

  const { setAchWithdrawalDate, setCardWithdrawalDate } = useBillStore(
    (state) => ({
      setAchWithdrawalDate: state.setAchWithdrawalDate,
      setCardWithdrawalDate: state.setCardWithdrawalDate,
    }),
  )

  return (
    <>
      <VFlex px="8" py="8" gap="6" w="100%">
        <Box>
          <Text fontSize="xl" fontWeight="semibold" color="gray.800" mb="6">
            What are you paying for?
          </Text>

          <HFlex gap="4" w="100%">
            <VFlex flex="1">
              <FormLabel fontSize="xs">Bill Number</FormLabel>
              <Input
                name="reason"
                value={values.reason}
                onChange={handleChange}
                bgColor="white"
                placeholder="Monthly Retainer"
                fontWeight="medium"
                fontSize="md"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                height="40px"
                _hover={{
                  borderColor: 'gray.300',
                }}
                _focus={{
                  boxShadow: 'none',
                  outline: 'none',
                  borderColor: 'gray.300',
                }}
              />
            </VFlex>

            {!hasLinkedConnection ? (
              <VFlex flex="1">
                <FormLabel fontSize="xs">Payment Amount</FormLabel>

                <PriceInput
                  label=""
                  placeholder="$100.00"
                  value={currency(values.amountCents || 0, {
                    fromCents: true,
                  }).value.toString()}
                  fontWeight="medium"
                  fontSize="md"
                  onPriceChange={(price) => {
                    handleChange({
                      target: {
                        name: 'amountCents',
                        value: currency(price).intValue,
                      },
                    })
                  }}
                />
              </VFlex>
            ) : (
              ''
            )}
          </HFlex>

          <VFlex gap="6" mt="6">
            <HFlex gap="4" w="100%">
              <VFlex flex="1">
                <FormLabel>Schedule</FormLabel>
                <Select
                  name="schedule"
                  value={values.schedule}
                  onChange={handleChange}
                  height="42px"
                  fontSize="md"
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  _hover={{
                    borderColor: 'gray.300',
                  }}
                >
                  {RecurringBillScheduleOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </VFlex>

              <VFlex
                flex="1"
                alignItems="end"
                justifyContent="end"
                verticalAlign="bottom"
              >
                <FormLabel alignSelf="start">Start on</FormLabel>
                <DatePickerCardInput
                  includeDateIntervals={[
                    {
                      start: moment()
                        .tz('America/New_York')
                        .isBefore(
                          moment().tz('America/New_York').hours(14).minutes(45),
                        )
                        ? moment().toDate()
                        : getBusinessDay(moment().toDate(), 1),
                      end: moment(new Date()).add(365, 'days').toDate(),
                    },
                  ]}
                  filterDate={(date) => {
                    return dateIsFutureAndNotWeekend(date, 'ach_debit')
                  }}
                  name="startDate"
                  selected={values.startDate}
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('startDate', date)
                      setFieldValue(
                        'endDate',
                        moment(date).add(1, 'year').toDate(),
                      )
                      setAchWithdrawalDate(date)
                      setCardWithdrawalDate(date)
                    }
                  }}
                  placeholderText="Select start date"
                />
              </VFlex>

              <VFlex
                flex="1"
                alignItems="end"
                justifyContent="end"
                verticalAlign="bottom"
              >
                <FormLabel alignSelf="start">End on</FormLabel>
                <DatePickerCardInput
                  name="endDate"
                  selected={values.endDate}
                  includeDateIntervals={[
                    {
                      start: moment()
                        .tz('America/New_York')
                        .isBefore(
                          moment().tz('America/New_York').hours(14).minutes(45),
                        )
                        ? moment().toDate()
                        : moment().toDate(),
                      end: moment(values.startDate).add(10, 'year').toDate(),
                    },
                  ]}
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('endDate', date)
                    }
                  }}
                  placeholderText="Select end date"
                />
              </VFlex>
            </HFlex>

            <PaymentSchedulePreview
              startDate={values.startDate}
              endDate={values.endDate}
              schedule={values.schedule}
            />
          </VFlex>
        </Box>
      </VFlex>
      {hasLinkedConnection ? (
        <CategoriesAndItemsSection
          categoryLines={categoryLines}
          itemLines={itemLines}
          getAvailableOptions={getAvailableOptions}
        />
      ) : (
        ''
      )}
    </>
  )
}

export function CreateRecurringBill({ setOpen }: CreateRecurringBillProps) {
  const {
    setRecurringPage,
    paymentSummary,
    setPaymentSummary,
    reset,
    setRecurringVendor,
    setSendMoneyAmountResult,
    setRecurringPageFrequency,
    setRecurringStartDate,
    setRecurringEndDate,
    recurringDetails,
    sendMoneyAmountResult,
    setLineItems,
  } = useBillStore((state) => ({
    setRecurringPage: state.setRecurringPage,
    paymentSummary: state.paymentSummary,
    setPaymentSummary: state.setPaymentSummary,
    reset: state.reset,
    setRecurringVendor: state.setRecurringVendor,
    setSendMoneyAmountResult: state.setSendMoneyAmountResult,
    setRecurringPageFrequency: state.setRecurringFrequency,
    setRecurringStartDate: state.setRecurringStartDate,
    setRecurringEndDate: state.setRecurringEndDate,
    recurringDetails: state.recurringDetails,
    sendMoneyAmountResult: state.sendMoneyAmountResult,
    setLineItems: state.setLineItems,
  }))

  const [vendor, setVendor] = useState<Vendor | null>(
    recurringDetails?.vendor || null,
  )

  return (
    <>
      <BillOverlayHeader
        onClose={() => {
          setOpen(false)
          reset()
        }}
        breadcrumbs={[
          { label: 'Bill Pay' },
          { label: 'Recurring Bills' },
          { label: 'Create', isActive: true },
        ]}
        title="Create a recurring bill"
        type="recurring"
      />
      <Divider />
      <Form<RecurringBillFormData>
        className="w-full h-full flex flex-col"
        initialValues={{
          amountCents: paymentSummary?.amountWithoutFeeCents() || 0,
          reason: sendMoneyAmountResult?.reason || '',
          schedule:
            recurringDetails?.frequency || RecurringBillFrequency.Monthly,
          vendorId: vendor?.id || '',
          startDate:
            recurringDetails?.startDate ||
            getBusinessDay(moment().add(1, 'days').toDate(), 1),
          endDate:
            recurringDetails?.endDate ||
            getBusinessDay(moment().add(1, 'days').toDate(), 365),
          lineItems: recurringDetails?.lineItems || [],
        }}
        validationSchema={RecurringBillSchema}
        onSubmit={(values) => {
          setPaymentSummary(
            calculatePaymentSummaryForMerchant(
              currency(values.amountCents, { fromCents: true }).intValue,
              'ACH Transfer',
              'ACH',
            ),
          )
          setSendMoneyAmountResult({
            reason: values.reason,
            submittedAmountCents: currency(values.amountCents, {
              fromCents: true,
            }).intValue,
          })

          setRecurringPageFrequency(values.schedule)
          setRecurringStartDate(values.startDate)
          setRecurringEndDate(values.endDate)
          setLineItems(values.lineItems || [])
          setRecurringPage('recurringPaymentPayout')
        }}
      >
        {(formik) => (
          <VFlex gap={4} flex={1} w="100%" h="full">
            <VFlex flex="1" w="100%">
              <VFlex w="100%">
                {vendor ? (
                  <VFlex py="8" px="8" gap="4">
                    <Box gap="4">
                      <Text
                        fontSize="lg"
                        fontWeight="semibold"
                        color="gray.800"
                      >
                        Who are you paying?
                      </Text>
                      <FormLabel
                        color={vendor ? 'purple.600' : 'gray.500'}
                        fontSize="sm"
                        cursor={vendor ? 'pointer' : 'default'}
                        onClick={() => {
                          if (vendor) {
                            setVendor(null)
                            formik.setFieldValue('vendorId', '')
                          }
                        }}
                      >
                        {vendor ? 'Change vendor' : 'Select a vendor'}
                      </FormLabel>
                    </Box>

                    <Box>
                      <SlimVendorCard
                        vendorName={vendor.name || ''}
                        vendorEmail={vendor.emails?.at(0) || ''}
                        vendorId={vendor.id || ''}
                        vendorPayoutMethod={
                          vendor?.vendorPayoutMethods?.[0] || undefined
                        }
                      />
                    </Box>
                  </VFlex>
                ) : (
                  <VendorSelect
                    onVendor={(vendor) => {
                      setVendor(vendor)
                      formik.setFieldValue('vendorId', vendor?.id || '')
                      if (vendor) {
                        setRecurringVendor(vendor)
                      }
                    }}
                  />
                )}
              </VFlex>

              {vendor && (
                <>
                  <Divider />
                  <RecurringBillForm />
                </>
              )}
            </VFlex>

            {vendor && (
              <Box w="100%">
                <Divider />
                <HFlex gap={4} w="100%" justifyContent="flex-end" p="4">
                  <Button
                    label="Cancel"
                    variant="outline"
                    onClick={() => {
                      setRecurringPage('overview')
                      reset()
                    }}
                  />
                  <Spacer />
                  <Button
                    label="Pay"
                    iconName="arrowRight"
                    iconPosition="right"
                    isDisabled={
                      !formik.isValid ||
                      (formik.values.lineItems &&
                        formik.values.lineItems.some(
                          (lineItem) => lineItem?.id === '',
                        ))
                    }
                    onClick={() => {
                      formik.handleSubmit()
                    }}
                  />
                </HFlex>
              </Box>
            )}
          </VFlex>
        )}
      </Form>
    </>
  )
}

export default CreateRecurringBill

import { property, styles, tw } from '@nickeltech/brise'
import {
  ArrowDownCircleIcon as HeroArrowDownCircleIcon,
  ArrowDownTrayIcon as HeroArrowDownTrayIcon,
  ArrowLeftIcon as HeroLeftArrowIcon,
  ArrowsRightLeftIcon as HeroArrowsRightLeftIcon,
  ArrowRightIcon as HeroRightArrowIcon,
  ArrowRightOnRectangleIcon as HeroArrowRightOnRectangleIcon,
  ArrowTopRightOnSquareIcon as HeroArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon as HeroArrowUTurnLeftIcon,
  ArrowPathIcon as HeroArrowPathIcon,
  AtSymbolIcon as HeroAtSymbolIcon,
  BanknotesIcon as HeroBankotesIcon,
  BarsArrowDownIcon as HeroBarsArrowDownIcon,
  BeakerIcon as HeroBeakerIcon,
  BellIcon as HeroBellIcon,
  BoltIcon as HeroBoltIcon,
  BuildingLibraryIcon as HeroBuildingLibraryIcon,
  CalculatorIcon as HeroCalculatorIcon,
  CalendarIcon as HeroCalendarIcon,
  ChartBarSquareIcon as HeroChartBarSquareIcon,
  ChartPieIcon as HeroChartPieIcon,
  ChatBubbleLeftIcon as HeroChatBubbleLeftIcon,
  CheckBadgeIcon as HeroCheckBadgeIcon,
  CheckCircleIcon as HeroCheckCircleIcon,
  CheckIcon as HeroCheckIcon,
  ChevronDoubleLeftIcon as HeroChevronDoubleLeftIcon,
  ChevronDoubleRightIcon as HeroChevronDoubleRightIcon,
  ChevronDownIcon as HeroChevronDownIcon,
  ChevronLeftIcon as HeroChevronLeftIcon,
  ChevronRightIcon as HeroChevronRightIcon,
  ChevronUpIcon as HeroChevronUpIcon,
  ClipboardIcon as HeroClipboardIcon,
  QuestionMarkCircleIcon as HeroQuestionMarkCircleIcon,
  CloudArrowUpIcon as HeroCloudArrowUpIcon,
  CogIcon as HeroCogIcon,
  CreditCardIcon as HeroCreditCardIcon,
  CurrencyDollarIcon as HeroCurrencyDollarIcon,
  EllipsisHorizontalIcon as HeroEllipsisHorizontalIcon,
  EllipsisVerticalIcon as HeroEllipsisVerticalIcon,
  EnvelopeIcon as HeroEnvelopeIcon,
  ExclamationCircleIcon as HeroExclamationCircleIcon,
  EyeIcon as HeroEyeIcon,
  FunnelIcon as HeroFunnelIcon,
  InboxArrowDownIcon as HeroInboxArrowDownIcon,
  InboxIcon as HeroInboxIcon,
  InformationCircleIcon as HeroInformationCircleIcon,
  LinkIcon as HeroLinkIcon,
  MagnifyingGlassIcon as HeroMagnifyingGlassIcon,
  MapPinIcon as HeroMapPinIcon,
  MinusIcon as HeroMinusIcon,
  NoSymbolIcon as HeroNoSymbolIcon,
  PencilIcon as HeroPencilIcon,
  PencilSquareIcon as HeroPencilSquareIcon,
  PlusIcon as HeroPlusIcon,
  PowerIcon as HeroPowerIcon,
  ReceiptPercentIcon as HeroReceiptPercentIcon,
  ReceiptRefundIcon as HeroReceiptRefundIcon,
  ShieldExclamationIcon as HeroShieldExclamationIcon,
  SparklesIcon as HeroSparklesIcon,
  TrashIcon as HeroTrashIcon,
  UserCircleIcon as HeroUserCircleIcon,
  UserIcon as HeroUserIcon,
  XMarkIcon as HeroXMarkIcon,
  DocumentTextIcon as HeroDocumentTextIcon,
  SquaresPlusIcon as HeroSquaresPlusIcon,
  ArrowUpIcon as HeroArrowUpIcon,
  WindowIcon as HeroWindowIcon,
  WalletIcon as HeroWalletIcon,
  DocumentArrowDownIcon as HeroDocumentArrowDownIcon,
  DocumentMagnifyingGlassIcon as HeroDocumentMagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { CheckCircleIcon as HeroCheckCircleIconFull } from '@heroicons/react/24/solid'
import {
  PayoutProcessing,
  PayoutScheduled,
  PayoutSent,
  PayoutReversed,
} from './PayoutIcons'
import { BlackCreditCard as HeroBlackCreditCard } from './BlackCreditCard'
import { PaymentPie as HeroPaymentPie } from './PaymentPie'
import {
  NickelCondensed as UINickelCondensed,
  NickelCondensedProps,
} from './NickelCondensed'
import {
  CheckmarkBoxIcon as UICheckmarkBoxIcon,
  CheckmarkBoxIconProps,
} from './CheckmarkBoxIcon'
import { Nickel as UINickel, NickelProps } from './Nickel'
import { NickelGray as UINickelGray } from './NickelGray'

import ContainerProps, { Size, Status, Variant } from '../../types'
import { ReactElement, ReactNode } from 'react'
import { DisabledTransition, DisabledTransitionProps } from '../Transitions'
import SupplyCo from './SupplyCo'
import MasterCard from './Mastercard'
import Visa from './Visa'
import Amex from './Amex'
import Discover from './Discover'
import PCIDss from './PCIDss'
import Lock from './Lock'
import Diners from './Diners'
import JCB from './Jcb'
import Maestro from './Maestro'
import VisaElectron from './VisaElectron'
import VisaDebit from './VisaDebit'
import { MoneyCheck } from './MoneyCheck'
import { Paycheck } from './Paycheck'
import UnknownCard from './UnknownCard'
import { PaperPlane as UIPaperPlane, PaperPlaneIconProps } from './PaperPlane'
import DollarMap from './DollarMap'
import React from 'react'
import { DollarReceipt } from './DollarReceipt'
import Quickbooks from './Quickbooks'

export type IconName =
  | 'chevronRight'
  | 'chevronUp'
  | 'chevronDown'
  | 'chevronDoubleLeft'
  | 'chevronDoubleRight'
  | 'chatBubbleLeft'
  | 'chartBar'
  | 'chartPie'
  | 'cloudArrowUp'
  | 'link'
  | 'ellipsisVertical'
  | 'magnifyingGlass'
  | 'bankNotes'
  | 'bell'
  | 'currencyDollar'
  | 'checkBadge'
  | 'creditCard'
  | 'buildingLibrary'
  | 'bolt'
  | 'envelope'
  | 'sparkles'
  | 'inboxArrowDown'
  | 'user'
  | 'trash'
  | 'leftArrow'
  | 'rightArrow'
  | 'xMark'
  | 'cog'
  | 'funnel'
  | 'shieldExclamation'
  | 'arrowDownTray'
  | 'arrowTopRightOnSquare'
  | 'ellipsisHorizontal'
  | 'userCircle'
  | 'pencil'
  | 'pencilSquare'
  | 'barsArrowDown'
  | 'arrowUTurnLeft'
  | 'eye'
  | 'inbox'
  | 'paymentPie'
  | 'blackCreditCard'
  | 'receiptPercent'
  | 'receiptRefund'
  | 'calculator'
  | 'calendar'
  | 'paperPlane'
  | 'arrowRightOnRectangle'
  | 'nickelBlack'
  | 'nickelWhite'
  | 'nickelCondensedBlack'
  | 'nickelCondensedWhite'
  | 'minus'
  | 'plusIcon'
  | 'payoutScheduled'
  | 'payoutProcessing'
  | 'payoutSent'
  | 'payoutReversed'
  | 'supplyCo'
  | 'check'
  | 'masterCard'
  | 'visa'
  | 'amex'
  | 'discover'
  | 'diners'
  | 'jcb'
  | 'maestro'
  | 'visaElectron'
  | 'visaDebit'
  | 'chevronLeft'
  | 'nickelGray'
  | 'pciDSS'
  | 'lock'
  | 'checkmarkBox'
  | 'power'
  | 'checkCircle'
  | 'exclamationCircle'
  | 'unknownCard'
  | 'noSymbol'
  | 'arrowDownCircle'
  | 'beaker'
  | 'clipboard'
  | 'mapPin'
  | 'atSymbol'
  | 'informationCircle'
  | 'documentText'
  | 'dollarMap'
  | 'moneyCheck'
  | 'arrowsRightLeft'
  | 'squaresPlus'
  | 'arrowUp'
  | 'paycheck'
  | 'window'
  | 'wallet'
  | 'documentArrowDown'
  | 'questionMarkCircle'
  | 'documentMagnifyingGlass'
  | 'checkCircleFull'
  | 'dollarReceipt'
  | 'quickbooks'
  | 'arrowPath'
export type IconCombinedProps = ContainerProps & {
  disabled?: boolean
  hoverable?: boolean
}

export const DisabledStyle = styles<IconCombinedProps>`
  ${(props) => (props.disabled ? `opacity-50 pointer-events-none` : '')}
`

export const HoverStyle = styles<IconCombinedProps>`
  ${({ hoverable = false }) =>
    hoverable ? `hover:stroke-gray-400 cursor-pointer` : 'cursor-default'}
`

export const IconCombinedStyle = styles<IconCombinedProps>`
  ${DisabledStyle}
  ${HoverStyle}
`

export type IconProps = {
  size?: Size
  variant?: Variant
  name: IconName
  status?: Status
} & ContainerProps &
  DisabledTransitionProps &
  IconCombinedProps

export const IconSizeStyle = property(
  'size',
  {
    tiny: 'w-2 h-2 min-w-2 min-h-2',
    xsmall: 'w-3 h-3 min-w-3 min-h-3',
    small: 'w-4 h-4 min-w-4 min-h-4 min-w-[16px]',
    medium: 'w-5 h-5 min-w-5',
    large: 'w-6 h-6 min-w-6',
    xlarge: 'w-8 h-8 min-w-8',
  },
  'small',
)

export const IconStatusStyle = property(
  'status',
  {
    info: 'stroke-gray-600',
    warning: 'stroke-yellow-600',
    error: 'stroke-red-600',
    success: 'stroke-green-600',
    action: 'stroke-blue-600',
    none: '',
  },
  'none',
)

export const IconVariantStyle = property(
  'variant',
  {
    tertiary: 'transition-transform',
    secondary: 'transition-transform ',
    primary: 'transition-transform',
  },
  'secondary',
)

export const IconVariantStyleV2 = property(
  'variant',
  {
    tertiary: 'min-h-4 transition-transform stroke-2',
    secondary: 'min-h-4 transition-transform stroke-2',
    primary: 'transition-transform stroke-2',
  },
  'secondary',
)

type IconHelperProps = IconProps & {
  children: ReactNode
}

const IconHelper = tw<IconHelperProps>((props: IconHelperProps) => {
  const { hoverable, ...rest } = props
  return <>{React.cloneElement(props.children, { ...rest })}</>
})`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CloudArrowUpIcon = tw<IconProps>(HeroCloudArrowUpIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ChevronDoubleLeftIcon = tw<IconProps>(
  HeroChevronDoubleLeftIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ChatBubbleLeftIcon = tw<IconProps>(HeroChatBubbleLeftIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const LinkIcon = tw<IconProps>((props: IconProps) => {
  const { hoverable, ...rest } = props
  return <HeroLinkIcon {...rest} />
})`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const MagnifyingGlassIcon = tw<IconProps>(
  HeroMagnifyingGlassIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const BanknotesIcon = tw<IconProps>(HeroBankotesIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const BellIcon = tw<IconProps>(HeroBellIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CheckBadgeIcon = tw<IconProps>(HeroCheckBadgeIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CreditCardIcon = tw<IconProps>(HeroCreditCardIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PayoutProcessingIcon = tw<IconProps>(PayoutProcessing as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PayoutScheduledIcon = tw<IconProps>(PayoutScheduled as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PayoutSentIcon = tw<IconProps>(PayoutSent as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PayoutReversedIcon = tw<IconProps>(PayoutReversed as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ChartBarIcon = tw<IconProps>(HeroChartBarSquareIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const BuildingLibraryIcon = tw<IconProps>(
  HeroBuildingLibraryIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const BoltIcon = tw<IconProps>(HeroBoltIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const EnvelopeIcon = tw<IconProps>(HeroEnvelopeIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const SparklesIcon = tw<IconProps>(HeroSparklesIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const InboxArrowDownIcon = tw<IconProps>(HeroInboxArrowDownIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const UserIcon = tw<IconProps>(HeroUserIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const TrashIcon = tw<IconProps>(HeroTrashIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const LeftArrowIcon = tw<IconProps>(HeroLeftArrowIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const RightArrowIcon = tw<IconProps>(HeroRightArrowIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PowerIcon = tw<IconProps>(HeroPowerIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CogIcon = tw<IconProps>(HeroCogIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const FunnelIcon = tw<IconProps>(HeroFunnelIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const ShieldExclamationIcon = tw<IconProps>(
  HeroShieldExclamationIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
`

export const ArrowDownTrayIcon = tw<IconProps>(HeroArrowDownTrayIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
`

export const ArrowTopRightOnSquareIcon = tw<IconProps>(
  HeroArrowTopRightOnSquareIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PencilIcon = tw<IconProps>(HeroPencilIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PencilSquareIcon = tw<IconProps>(HeroPencilSquareIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`
export const BarsArrowDownIcon = tw<IconProps>(HeroBarsArrowDownIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const ArrowUTurnLeftIcon = tw<IconProps>(HeroArrowUTurnLeftIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PlusIcon = tw<IconProps>(HeroPlusIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const MinusIcon = tw<IconProps>(HeroMinusIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const EyeIcon = tw<IconProps>(HeroEyeIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ChevronDoubleRightIcon = tw<IconProps>(
  HeroChevronDoubleRightIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const InboxIcon = tw<IconProps>(HeroInboxIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
`

export const PaymentPie = tw<IconProps>(HeroPaymentPie as any)`
  ${IconSizeStyle}
`

export const BlackCreditCard = tw<IconProps>(HeroBlackCreditCard as any)`
`

export const Nickel = tw<NickelProps>(UINickel as any)`
`

export const NickelCondensed = tw<NickelCondensedProps>(
  UINickelCondensed as any,
)`
  ${IconCombinedStyle}
`

export const CheckmarkBoxIcon = tw<CheckmarkBoxIconProps>(
  UICheckmarkBoxIcon as any,
)`
  ${IconStatusStyle}
`

export const NickelGray = tw<IconProps>(UINickelGray as any)`
`

export const ReceiptPercentIcon = tw<IconProps>(HeroReceiptPercentIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ReceiptRefundIcon = tw<IconProps>(HeroReceiptRefundIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CalculatorIcon = tw<IconProps>(HeroCalculatorIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CalendarIcon = tw<IconProps>(HeroCalendarIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const PaperPlaneIcon = tw<PaperPlaneIconProps>(UIPaperPlane as any)`
  ${IconSizeStyle}
`

export const ArrowRightOnRectangleIcon = tw<IconProps>(
  HeroArrowRightOnRectangleIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const CheckIcon = tw<IconProps>(HeroCheckIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}

`

export const ChevronLeft = tw<IconProps>(HeroChevronLeftIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const CheckCircle = tw<IconProps>(HeroCheckCircleIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ExclamationCircleIcon = tw<IconProps>(
  HeroExclamationCircleIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const NoSymbolIcon = tw<IconProps>(HeroNoSymbolIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyle}
  ${IconStatusStyle}
  ${IconCombinedStyle}
`

export const ArrowDownCircleIcon = tw<IconProps>(
  HeroArrowDownCircleIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const BeakerIcon = tw<IconProps>(HeroBeakerIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const ClipboardIcon = tw<IconProps>(HeroClipboardIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const AtSymbolIcon = tw<IconProps>(HeroAtSymbolIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const MapPinIcon = tw<IconProps>(HeroMapPinIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const InformationCircleIcon = tw<IconProps>(
  HeroInformationCircleIcon as any,
)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const ArrowUpIcon = tw<IconProps>(HeroArrowUpIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const PaycheckIcon = tw<IconProps>(Paycheck as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const WindowIcon = tw<IconProps>(HeroWindowIcon as any)`
  ${IconSizeStyle}
  ${IconVariantStyleV2}
  ${IconStatusStyle}
  ${IconCombinedStyle}
  ${DisabledTransition}
`

export const Icon = (props: IconProps) => {
  const { ...rest } = props

  const getIcon = (props: IconProps, node: React.ReactNode) => {
    return <IconHelper {...props}>{node}</IconHelper>
  }

  const items: Record<IconName, ReactNode | Element> = {
    chevronRight: getIcon(props, <HeroChevronRightIcon />),
    pciDSS: <PCIDss {...rest} />,
    lock: <Lock {...rest} />,
    chevronUp: getIcon(props, <HeroChevronUpIcon />),
    chevronDown: getIcon(props, <HeroChevronDownIcon />),
    chevronDoubleLeft: <ChevronDoubleLeftIcon {...rest} />,
    chevronDoubleRight: <ChevronDoubleRightIcon {...rest} />,
    chatBubbleLeft: <ChatBubbleLeftIcon {...rest} />,
    chartBar: <ChartBarIcon {...rest} />,
    link: <LinkIcon {...rest} />,
    ellipsisVertical: getIcon(props, <HeroEllipsisVerticalIcon />),
    magnifyingGlass: <MagnifyingGlassIcon {...rest} />,
    bankNotes: <BanknotesIcon {...rest} />,
    bell: <BellIcon {...rest} />,
    currencyDollar: getIcon(props, <HeroCurrencyDollarIcon />),
    checkBadge: <CheckBadgeIcon {...rest} />,
    chartPie: getIcon(props, <HeroChartPieIcon />),
    creditCard: <CreditCardIcon {...rest} />,
    buildingLibrary: <BuildingLibraryIcon {...rest} />,
    bolt: <BoltIcon {...rest} />,
    envelope: <EnvelopeIcon {...rest} />,
    sparkles: <SparklesIcon {...rest} />,
    inboxArrowDown: <InboxArrowDownIcon {...rest} />,
    user: <UserIcon {...rest} />,
    trash: <TrashIcon {...rest} />,
    leftArrow: getIcon(props, <HeroLeftArrowIcon />),
    rightArrow: <RightArrowIcon {...rest} />,
    xMark: getIcon(props, <HeroXMarkIcon />),
    cog: <CogIcon {...rest} />,
    funnel: <FunnelIcon {...rest} />,
    shieldExclamation: <ShieldExclamationIcon {...rest} />,
    arrowDownTray: <ArrowDownTrayIcon {...rest} />,
    arrowTopRightOnSquare: <ArrowTopRightOnSquareIcon {...rest} />,
    ellipsisHorizontal: getIcon(props, <HeroEllipsisHorizontalIcon />),
    userCircle: getIcon(props, <HeroUserCircleIcon />),
    pencil: <PencilIcon {...rest} />,
    pencilSquare: <PencilSquareIcon {...rest} />,
    barsArrowDown: <BarsArrowDownIcon {...rest} />,
    arrowUTurnLeft: <ArrowUTurnLeftIcon {...rest} />,
    eye: <EyeIcon {...rest} />,
    inbox: <InboxIcon {...rest} />,
    paymentPie: <PaymentPie {...rest} />,
    blackCreditCard: <BlackCreditCard {...rest} />,
    receiptPercent: <ReceiptPercentIcon {...rest} />,
    receiptRefund: <ReceiptRefundIcon {...rest} />,
    calculator: <CalculatorIcon {...rest} />,
    calendar: <CalendarIcon {...rest} />,
    paperPlane: getIcon(props, <PaperPlaneIcon {...rest} />),
    arrowRightOnRectangle: <ArrowRightOnRectangleIcon {...rest} />,
    nickelBlack: <Nickel {...{ ...rest, fill: 'black' }} />,
    nickelWhite: <Nickel {...{ ...rest, fill: 'white' }} />,
    nickelCondensedBlack: <NickelCondensed {...{ ...rest, fill: 'black' }} />,
    nickelCondensedWhite: <NickelCondensed {...{ ...rest, fill: 'white' }} />,
    nickelGray: <UINickelGray {...rest} />,
    supplyCo: <SupplyCo {...rest} />,
    minus: <MinusIcon {...rest} />,
    plusIcon: <PlusIcon {...rest} />,
    payoutScheduled: <PayoutScheduledIcon {...rest} />,
    payoutSent: <PayoutSentIcon {...rest} />,
    payoutReversed: <PayoutReversedIcon {...rest} />,
    payoutProcessing: <PayoutProcessingIcon {...rest} />,
    check: <CheckIcon {...rest} />,
    masterCard: getIcon(props, <MasterCard {...rest} />),
    visa: getIcon(props, <Visa {...rest} />),
    amex: getIcon(props, <Amex {...rest} />),
    diners: getIcon(props, <Diners {...rest} />),
    jcb: getIcon(props, <JCB {...rest} />),
    maestro: getIcon(props, <Maestro {...rest} />),
    discover: getIcon(props, <Discover {...rest} />),
    unknownCard: getIcon(props, <UnknownCard {...rest} />),
    visaElectron: <VisaElectron {...rest} />,
    visaDebit: <VisaDebit {...rest} />,
    moneyCheck: <MoneyCheck {...rest} />,
    chevronLeft: <ChevronLeft {...rest} />,
    checkmarkBox: <CheckmarkBoxIcon {...rest} />,
    power: <PowerIcon {...rest} />,
    checkCircle: <CheckCircle {...rest} />,
    exclamationCircle: <ExclamationCircleIcon {...rest} />,
    noSymbol: <NoSymbolIcon {...rest} />,
    arrowDownCircle: <ArrowDownCircleIcon {...rest} />,
    beaker: <BeakerIcon {...rest} />,
    clipboard: <ClipboardIcon {...rest} />,
    mapPin: <MapPinIcon {...rest} />,
    atSymbol: <AtSymbolIcon {...rest} />,
    informationCircle: <InformationCircleIcon {...rest} />,
    documentText: getIcon(props, <HeroDocumentTextIcon />),
    dollarMap: <DollarMap {...rest} />,
    cloudArrowUp: <CloudArrowUpIcon {...rest} />,
    arrowsRightLeft: getIcon(props, <HeroArrowsRightLeftIcon />),
    squaresPlus: getIcon(props, <HeroSquaresPlusIcon />),
    arrowUp: <ArrowUpIcon {...rest} />,
    paycheck: <PaycheckIcon {...rest} />,
    window: <WindowIcon {...rest} />,
    wallet: getIcon(props, <HeroWalletIcon />),
    documentArrowDown: getIcon(props, <HeroDocumentArrowDownIcon />),
    documentMagnifyingGlass: getIcon(
      props,
      <HeroDocumentMagnifyingGlassIcon />,
    ),
    questionMarkCircle: getIcon(props, <HeroQuestionMarkCircleIcon />),
    checkCircleFull: getIcon(props, <HeroCheckCircleIconFull {...rest} />),
    dollarReceipt: getIcon(props, <DollarReceipt {...rest} />),
    quickbooks: getIcon(props, <Quickbooks {...rest} />),
    arrowPath: getIcon(props, <HeroArrowPathIcon />),
  }

  return <>{(items[props.name as IconName] as ReactElement) || <div />}</>
}

export default Icon

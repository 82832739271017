import { OperationVariables } from '@apollo/client'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { PAY_VENDORS_VENDORS_URL } from '../../../lib/urls'
import { Overlay } from 'ui'

type VendorOutletProps = {
  queryOpts: OperationVariables
}

export function VendorOutlet({ queryOpts }: VendorOutletProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const exitOverlay = () => navigate(PAY_VENDORS_VENDORS_URL)

  const vendorMatch = location.pathname.match(
    /\/dashboard\/accounts-payable\/vendors\/(.*)/,
  )

  const { vendorId } = useParams() as { vendorId: string }

  return (
    <Overlay
      open={!!vendorMatch}
      setOpen={(bool) => {
        if (!bool) {
          exitOverlay()
        }
      }}
    >
      <Outlet
        context={{
          queryOpts: queryOpts,
          vendorId: vendorId,
        }}
      />
    </Overlay>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { Box, Divider, Flex, HStack, Text } from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import {
  CardInput,
  DatePickerCardInput,
  PriceSelector,
  VFlex,
  Button,
  SlimVendorCard,
} from 'ui'
import {
  AddBillDocument,
  UserRole,
  Vendor,
  VendorDocument,
} from '../../operations-types'
import { SlimSelectVendor } from './vendor/SlimSelectVendor'
import * as yup from 'yup'
import currency from 'currency.js'
import { useLoggedInStore } from '../layout/LoggedInStore'
import { useAddBillStore } from '../stores/addBillStore'
import { AddVendorOverlay } from './vendor/AddVendorOverlay'
import { useAddVendorStore } from './vendor/AddVendorStore'

function SelectedVendor({
  selectedVendor,
  onCancel,
}: {
  selectedVendor: Vendor
  onCancel?: () => void
}) {
  return (
    <VFlex px={8} py={6} w="100%" gap={4}>
      <Box>
        <Text fontSize="lg" fontWeight="semibold" color="gray.800">
          Who are you paying?
        </Text>
        <Text fontSize="sm" color="gray.500">
          Select your vendor
        </Text>
      </Box>
      <SlimVendorCard
        vendorId={selectedVendor.id}
        vendorName={selectedVendor.name || ''}
        vendorEmail={selectedVendor.emails?.at(0) || ''}
        vendorPayoutMethod={
          selectedVendor.vendorPayoutMethods?.[0] || undefined
        }
      />
    </VFlex>
  )
}

function BillInformation() {
  const [amount, setAmount] = useState('')

  const { setFieldValue, values, handleChange } =
    useFormikContext<BillFormData>()

  return (
    <VFlex px={8} py={4} w="100%" gap={4}>
      <Box>
        <Text fontSize="lg" fontWeight="semibold" color="gray.800">
          What are you paying for?
        </Text>
      </Box>
      <VFlex gap={4}>
        <PriceSelector
          name="amount"
          value={amount}
          onChange={(e) => {
            setAmount(e)
            setFieldValue('amountCents', currency(e).intValue)
          }}
          bgColor="white"
          labelColor="gray.800"
          placeholder="$0"
          fontSize="xl"
        />
        <HStack>
          <CardInput
            label="Reason for Payment"
            name="reason"
            value={values.reason}
            onChange={handleChange}
            inputGroupSize="sm"
            fontSize="md"
            bgColor="white"
            labelColor="gray.800"
            placeholder="Deposit or invoice number"
          />
          <DatePickerCardInput
            label="Due Date"
            className="text-sm"
            labelColor="gray.800"
            selected={values.dueDate}
            onChange={(date) => setFieldValue('dueDate', date)}
          />
        </HStack>
      </VFlex>
    </VFlex>
  )
}

const NewBillSchema = yup.object({
  amountCents: yup.number().min(1).required('Approval amount is required'),
  dueDate: yup.date().required('Due date is required'),
  vendorId: yup.string().required('Vendor is required'),
  reason: yup.string().required('Reason is required'),
})

type BillFormData = yup.InferType<typeof NewBillSchema>

type VendorSelectProps = {
  onVendor: (vendor: Vendor | null) => void
}

export function VendorSelect({ onVendor }: VendorSelectProps) {
  const [newVendorOpen, setNewVendorOpen] = useState(false)

  const { setManualVendorDetails } = useAddVendorStore()
  return (
    <Box px={8} py={6} w="100%">
      <VFlex gap={4}>
        <Box>
          <Text fontSize="lg" fontWeight="semibold" color="gray.800">
            Who are you paying?
          </Text>
          <Text fontSize="sm" color="gray.500">
            Select your vendor
          </Text>
        </Box>
        <SlimSelectVendor
          onNewVendor={(vendorName) => {
            setNewVendorOpen(true)
            setManualVendorDetails({
              vendorName: vendorName || '',
              vendorEmails: [],
            })
          }}
          onVendorSelect={(vendor) => onVendor(vendor)}
          label={false}
        />
        <AddVendorOverlay
          isOpen={newVendorOpen}
          setOpen={setNewVendorOpen}
          onVendor={(vendor) => onVendor(vendor)}
        />
      </VFlex>
    </Box>
  )
}

export type NewBillOverlayProps = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onPayBill?: (billId: string) => void
}
export function NewBillPage({
  isOpen,
  setOpen,
  onPayBill,
}: NewBillOverlayProps) {
  const { vendorId, setVendorId } = useAddBillStore()

  const { data: vendorData, refetch } = useQuery(VendorDocument, {
    variables: {
      vendorId: vendorId,
    },
    skip: !vendorId,
  })

  const vendor = vendorData?.vendor?.vendor

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(
    vendor || null,
  )

  const [addBill] = useMutation(AddBillDocument, {
    refetchQueries: ['bills', 'vendor'],
  })

  const { getUser } = useLoggedInStore()

  const IS_PERMITTED =
    getUser().isUserPermitted(UserRole.Manager) ||
    !!getUser()?.organization?.approvalPolicies?.length

  refetch()
  useEffect(() => {
    if (vendor) {
      setSelectedVendor(vendor)
    }
  }, [vendor])

  return (
    <Formik<BillFormData>
      initialValues={{
        amountCents: 0,
        dueDate: new Date(),
        vendorId: vendorId || '',
        reason: '',
      }}
      validateOnMount={true}
      validationSchema={NewBillSchema}
      onSubmit={(values) => {}}
    >
      {(props) => {
        return (
          <>
            {!selectedVendor && (
              <VendorSelect
                onVendor={(vendor) => {
                  if (vendor) {
                    setSelectedVendor(vendor)
                    props.setFieldValue('vendorId', vendor.id)
                    setVendorId(vendor.id)
                  }
                }}
              />
            )}
            {selectedVendor && (
              <VFlex w="100%" gap={4}>
                <SelectedVendor
                  selectedVendor={selectedVendor}
                  onCancel={() => {
                    setSelectedVendor(null)
                    setVendorId(null)
                  }}
                />
                <Divider />
                <BillInformation />
              </VFlex>
            )}
            <Box w="100%" mt="auto">
              <Divider />
              <Flex justifyContent="flex-end" py={4} px={8} gap={4}>
                <Button
                  label="Save and Close"
                  variant="outline"
                  isDisabled={!props.isValid}
                  onClick={async () => {
                    await addBill({
                      variables: {
                        amountCents: props.values.amountCents,
                        dueDateEpochMillis: props.values.dueDate.valueOf(),
                        vendorId: props.values.vendorId,
                        reason: props.values.reason,
                      },
                    })
                    setOpen(false)
                    setSelectedVendor(null)
                    setVendorId(null)
                  }}
                />
                {IS_PERMITTED && (
                  <Button
                    label="Make a payment"
                    isDisabled={!props.isValid}
                    onClick={async () => {
                      const { data } = await addBill({
                        variables: {
                          amountCents: props.values.amountCents,
                          dueDateEpochMillis: props.values.dueDate.valueOf(),
                          vendorId: props.values.vendorId,
                          reason: props.values.reason,
                        },
                      })
                      setSelectedVendor(null)
                      setVendorId(null)
                      onPayBill && onPayBill(data?.addBill?.bill?.id || '')
                    }}
                  />
                )}
              </Flex>
            </Box>
          </>
        )
      }}
    </Formik>
  )
}

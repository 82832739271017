import { property, styles, tw } from '@nickeltech/brise'
import { MotionProps, motion } from 'framer-motion'
import { Ref } from 'react'
import SectionedStyle, {
  OverflowHiddenProps,
  OverflowScrollableProps,
  SectionedProps,
} from '.'
import ContainerProps, { SizeProps, StatusProps } from '../../types'
import { StatusBackgroundStyle } from '../Indicators'
import {
  DisabledTransition,
  DisabledTransitionProps,
  VisibilityTransition,
  VisibilityTransitionProps,
} from '../Transitions'
import Spacing, { Gap, GapProps, SpacingProps } from './Spacing'
import styled from 'styled-components'

export type ColumnXType = 'left' | 'center' | 'right'
export type ColumnYType = 'top' | 'center' | 'bottom'

export type VisibilityColumnProps = {
  visible?: boolean
}

export type ColumnProps = {
  x?: ColumnXType
  y?: ColumnYType
  between?: boolean
  around?: boolean
  selected?: boolean
  ref?: Ref<HTMLElement>
  grow?: boolean
  wGrow?: boolean
  hoverable?: boolean
  sectioned?: boolean
  forwadedRef?: Ref<HTMLDivElement>
} & ContainerProps &
  SpacingProps &
  GapProps &
  VisibilityTransitionProps &
  DisabledTransitionProps &
  StatusProps &
  SectionedProps &
  SizeProps &
  MotionProps &
  OverflowHiddenProps &
  OverflowScrollableProps

export const ColumnXPositionStyle = property(
  'x',
  {
    left: 'items-start',
    center: 'items-center',
    right: 'items-end',
  },
  'left',
)

export const ColumnYPositionStyle = property(
  'y',
  {
    top: 'justify-start',
    center: 'justify-center',
    bottom: 'justify-end',
  },
  'top',
)

export const ColumnAdditionalStyles = styles<ColumnProps>`
  ${(props: ColumnProps) => (props.between ? 'justify-between' : '')}
  ${(props: ColumnProps) => (props.around ? 'justify-around' : '')}
  ${(props: ColumnProps) => (props.onClick ? 'cursor-pointer' : '')}
  ${(props: ColumnProps) => (props.selected ? 'bg-gray-50' : '')}
  ${(props: ColumnProps) => (props.grow ? 'h-full grow' : '')}
  ${(props: ColumnProps) => (props.wGrow ? 'w-full' : '')}
  ${(props: ColumnProps) =>
    props.onClick && !props.selected && props.hoverable
      ? 'hover:bg-gray-200'
      : ''}
  ${(props: ColumnProps) => (props.overflowHidden ? 'overflow-hidden' : '')}
  ${(props: ColumnProps) => (props.overflowScrollable ? 'overflow-scroll' : '')}

`

export const Column = tw((props: ColumnProps) => {
  return (
    <motion.div
      className={props.className}
      onClick={props.onClick}
      {...{
        animate: props.animate,
        initial: props.initial || false,
        ref: props.forwadedRef,
        disabled: props.disabled,
        onAnimationComplete: props.onAnimationComplete,
      }}
    >
      {props.children}
    </motion.div>
  )
})`
  flex
  flex-col
  ${ColumnXPositionStyle}
  ${ColumnYPositionStyle}
  ${ColumnAdditionalStyles}
  ${Gap}
  ${Spacing}
  ${VisibilityTransition}
  ${DisabledTransition}
  ${StatusBackgroundStyle}
  ${SectionedStyle}
`

// Spacing not standardized
export const UnstandardizedColumn = tw((props: ColumnProps) => {
  return (
    <motion.div
      className={props.className}
      onClick={props.onClick}
      {...{
        animate: props.animate,
        initial: props.initial || false,
        ref: props.forwadedRef,
        disabled: props.disabled,
        onAnimationComplete: props.onAnimationComplete,
      }}
    >
      {props.children}
    </motion.div>
  )
})`
  flex
  flex-col
  ${ColumnXPositionStyle}
  ${ColumnYPositionStyle}
  ${ColumnAdditionalStyles}
  ${Gap}
  ${VisibilityTransition}
  ${DisabledTransition}
  ${StatusBackgroundStyle}
  ${SectionedStyle}
`

export const ScrollableColumn = styled(Column)`
  height: -webkit-fill-available;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export default Column
